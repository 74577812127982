import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import SideNavBody from './Body';
import SideNavFooter from './Footer';
import SideNavHeader from './Header';
import SideNavLink from './Link';

class SideNav extends PureComponent {
  static Header = SideNavHeader;
  static Body = SideNavBody;
  static Footer = SideNavFooter;
  static Link = SideNavLink;

  render() {
    const { children, className } = this.props;
    return <div className={className}>{children}</div>;
  }
}

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
SideNav.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
SideNav.defaultProps = {
  children: null,
  className: '',
};

export default styled(SideNav)`
  display: grid;
  grid-template-rows: 6rem auto 6.5rem;
  grid-template-areas:
    'sidenavHeader'
    'sidenavBody'
    'sidenavFooter';
  height: 100vh;

  /* For small devices */
  ${breakpoint('xs', 'md')`
    grid-template-columns: auto;
    grid-template-areas: 'sidenavBody';
  `};

  /* For medium devices */
  ${breakpoint('md', 'lg')`
    grid-template-rows: 6rem auto 10.5rem;
  `};
`;
