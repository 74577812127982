import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, ButtonGroup, IconButton } from '@sumup/circuit-ui';
import { Alert, Confirm } from '@sumup/icons';

import { IntegrationRequestStatusType } from '../types';

import { StatusWithAction } from './elements';
import useStatusMutation from './useStatusMutation';

interface Props {
  readonly requestUUID: string;
  readonly status: IntegrationRequestStatusType;
}

const getBadgeVariant = (status: IntegrationRequestStatusType) => {
  if (status === 'ACCEPTED') return 'success';
  if (status === 'DENIED') return 'danger';
  if (status === 'PENDING') return 'warning';
  return 'neutral';
};

const IntegrationRequestStatus: FC<Props> = ({ requestUUID, status }) => {
  const { t } = useTranslation();
  const { mutateStatusTo, isLoading } = useStatusMutation(requestUUID);

  return (
    <StatusWithAction>
      <Badge variant={getBadgeVariant(status)}>
        {t(`app_market.integration_requests_status.${status}`)}
      </Badge>
      {status === 'PENDING' ? (
        <ButtonGroup>
          <IconButton
            size="kilo"
            onClick={() => mutateStatusTo('ACCEPTED')}
            disabled={isLoading}
            label={t('app_market.integration_requests_status.accept')}
          >
            <Confirm size="16" />
          </IconButton>
          <IconButton
            size="kilo"
            onClick={() => mutateStatusTo('DENIED')}
            disabled={isLoading}
            label={t('app_market.integration_requests_status.deny')}
          >
            <Alert size="16" />
          </IconButton>
        </ButtonGroup>
      ) : null}
    </StatusWithAction>
  );
};

export default IntegrationRequestStatus;
