import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Input,
  ListItemGroup,
  spacing,
  SubHeadline,
  useNotificationToast,
} from '@sumup/circuit-ui';
import { Close, Download } from '@sumup/icons';

import StatusIcon from './StatusIcon';
import { Migration } from './types';
import { useGoodTillMigration } from './useGoodTillMigration';
import { extractValidMigrations, parseMigrationsFile } from './utils';

const MultiStore: React.FC = () => {
  const { setToast } = useNotificationToast();
  const { t } = useTranslation();
  const [migrations, setMigrations] = useState<Migration[]>([]);

  const migrationFileRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);

  const { isLoading, status, subscribe, mutate, clear } = useGoodTillMigration({
    onSuccess: () => {
      setToast({
        body: t('migration.goodTillMigration.multiStore.success.message'),
        variant: 'confirm',
      });
    },
    onError: () => {
      setToast({
        body: t('migration.goodTillMigration.multiStore.error.message'),
        variant: 'alert',
      });
    },
  });

  const handleInputFile: React.FormEventHandler<HTMLInputElement & HTMLTextAreaElement> = () => {
    const file = migrationFileRef.current.files.item(0);

    parseMigrationsFile(file, ({ data, errors }) => {
      const validMigrations = extractValidMigrations(data);
      setMigrations(validMigrations);
      subscribe(validMigrations);

      if (errors.length) {
        setToast({
          body: t('migration.goodTillMigration.multiStore.partial.message', {
            errors: errors.length,
            lines: errors.map(({ row }) => row).join(', '),
          }),
          variant: 'notify',
        });
      }
    });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    if (!migrations.length) {
      setToast({
        body: t('migration.goodTillMigration.multiStore.empty.message'),
        variant: 'notify',
      });
      return;
    }

    mutate(migrations);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <CardHeader>
          <SubHeadline as="h4">{t('migration.goodTillMigration.multiStore.title')}</SubHeadline>
        </CardHeader>
        <Body>
          <Input
            ref={migrationFileRef}
            type="file"
            label={t('migration.goodTillMigration.action.select.label')}
            name="migrationFile"
            accept="text/csv"
            onInput={handleInputFile}
            disabled={isLoading}
          />
          <ListItemGroup
            label={t('migration.goodTillMigration.list.title.label')}
            variant="plain"
            details={t('migration.goodTillMigration.list.title.trailingLabel')}
            items={
              status.length
                ? status.map(({ goodTillClientId, posProStoreId, status }, index) => ({
                    key: index,
                    variant: 'action',
                    label: goodTillClientId,
                    prefix: <StatusIcon status={status} />,
                    suffixLabel: posProStoreId,
                  }))
                : [{ key: 0, label: t('migration.goodTillMigration.list.empty.label') }]
            }
          />
        </Body>
        <CardFooter>
          <Button
            icon={Close}
            type="reset"
            variant="secondary"
            onClick={() => {
              setMigrations([]);
              clear();
            }}
            disabled={isLoading}
            css={spacing({ right: 'kilo' })}
          >
            {t('migration.goodTillMigration.action.clear.label')}
          </Button>
          <Button
            icon={Download}
            loadingLabel={t('migration.goodTillMigration.action.import.loadingLabel')}
            isLoading={isLoading}
            type="submit"
            variant="primary"
            disabled={!status.length || isLoading}
          >
            {t('migration.goodTillMigration.action.import.label')}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default MultiStore;
