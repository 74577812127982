import { findKey, isEmpty, omit, pickBy, reduce } from 'lodash';
import { createSelector } from 'reselect';

import { LOADING, NONE, UPDATING } from '../../constants/Status';
import { getPaymentMethods } from '../../modules/PaymentMethod/selectors';

/**
 * Payments config selectors
 */

/**
 * @typedef {Object} CountryPaymentConfig
 * @property {string} category
 * @property {import('./reducer').PaymentOptions} options
 *
 * @typedef {Object.<string, CountryPaymentConfig>} CountriesPaymentConfig
 *
 * @typedef {Object.<string, CountriesPaymentConfig>} LabelPaymentsConfig
 */

const DEFAULT_CATEGORY = 'cash';
const DEFAULT_OPTIONS = { hasExtraConfig: false, creditNoteOnly: false };

/**
 * Get the loading status about the default payment config
 *
 * @return {string}
 */
export const getDefaultPaymentConfigStatus = ({ paymentsConfig }) =>
  paymentsConfig.status.default || NONE;

/**
 * Get the loading status about the payments config
 *
 * @return {string[]}
 */
export const getPaymentsConfigStatus = ({ paymentsConfig }) =>
  Object.values(omit(paymentsConfig.status, 'default'));

/**
 * If default payment config is loading or being updated
 *
 * @return {boolean}
 */
export const isDefaultPaymentConfigChanging = createSelector(
  [getDefaultPaymentConfigStatus],
  status => status === LOADING || status === UPDATING,
);

/**
 * If data is being fetched or updating
 *
 * @return {boolean}
 */
export const isPaymentsConfigChanging = createSelector(
  [getPaymentsConfigStatus],
  status =>
    status.length > 0 &&
    status.find(status => status === LOADING || status === UPDATING) !== undefined,
);

/**
 * If default payment config is done fetching
 *
 * @return {boolean}
 */
export const isDefaultPaymentConfigDoneLoading = createSelector(
  [getDefaultPaymentConfigStatus],
  status => ![NONE, LOADING].includes(status),
);

/**
 * If data is done fetching
 *
 * @return {boolean}
 */
export const isPaymentsConfigDoneLoading = createSelector(
  [getPaymentsConfigStatus],
  status =>
    status.length > 0 && status.find(status => [NONE, LOADING].includes(status)) === undefined,
);

/**
 * Get the global payments config
 * It will have the following format:
 *  {
 *    default: {
 *      [category:string]: {
 *        [label:string]: {
 *          hasExtraConfig: boolean,
 *          creditNoteOnly: boolean,
 *          featureCode?: string,
 *        }
 *      }
 *    }
 *    [country:string]: {
 *      [category:string]: {
 *        [label:string]: {
 *          hasExtraConfig: boolean,
 *          creditNoteOnly: boolean,
 *          featureCode?: string,
 *        }
 *      }
 *    }
 *  }
 * @returns {import('./reducer').PaymentsConfig}
 */
const getGlobalPaymentsConfig = ({ paymentsConfig }) => paymentsConfig.entities;

/**
 * Get the default payment config
 * It will have the following format:
 *  {
 *    [category:string]: {
 *      [label:string]: {
 *        hasExtraConfig: boolean,
 *        creditNoteOnly: boolean,
 *        featureCode?: string,
 *      }
 *    }
 *  }
 * @returns {import('./reducer').PaymentConfig}
 */
export const getDefaultPaymentConfig = ({ paymentsConfig }) => paymentsConfig.entities.default;

/**
 * Get the global payments config grouped by country and category
 * It will have the following format:
 *  {
 *    [country:string]: {
 *      [category:string]: {
 *        [label:string]: {
 *          hasExtraConfig: boolean,
 *          creditNoteOnly: boolean,
 *          featureCode?: string,
 *        }
 *      }
 *    }
 *  }
 * @returns {import('./reducer').PaymentsConfig}
 */
export const getPaymentsConfig = ({ paymentsConfig }) => omit(paymentsConfig.entities, 'default');

/**
 * Get all countries with payments config
 * @returns {string[]} an array of country codes
 */
export const getPaymentsConfigCountries = createSelector(getPaymentsConfig, paymentsConfig =>
  Object.keys(paymentsConfig),
);

/**
 * Get the currently selected label or null if none
 *
 * @return {string|null} the currently selected label or null if none
 */
const getSelectedPaymentConfigLabel = ({ paymentsConfig }) => paymentsConfig.selectedLabel;

/**
 * Get the payment config for a label
 *  {
 *    [country: string]: {
 *      category: string,
 *      options: {
 *        hasExtraConfig: boolean,
 *        creditNoteOnly: boolean,
 *        featureCode?: string,
 *      },
 *    }
 *  }
 * @return {LabelPaymentsConfig|null}
 */
const getPaymentConfigForLabel = (paymentsConfig, label) => {
  if (!label || isEmpty(paymentsConfig)) {
    return null;
  }

  return reduce(
    paymentsConfig,
    (paymentConfigByLabel, countryConfig, country) => {
      // look for the label in all categories of the country config
      // stop as soon as a category is found with the label
      // if the label was not found, it means the payment method was not setup for this country
      const category = findKey(countryConfig, categoryConfig => categoryConfig[label]);
      if (category) {
        paymentConfigByLabel[country] = {
          category,
          options: countryConfig[category][label],
        };
      }

      return paymentConfigByLabel;
    },
    {},
  );
};

/**
 * Get the payment config for the currently selected label
 *
 * @return {LabelPaymentsConfig|null}
 */
export const getSelectedPaymentConfig = createSelector(
  getGlobalPaymentsConfig,
  getSelectedPaymentConfigLabel,
  getPaymentConfigForLabel,
);

/**
 * Get the payment methods with their config by countries
 *
 * @return {LabelPaymentsConfig[]}
 */
export const getPaymentMethodsWithConfig = createSelector(
  getPaymentMethods,
  getGlobalPaymentsConfig,
  (paymentMethods, paymentsConfig) => {
    if (isEmpty(paymentMethods) || isEmpty(paymentsConfig)) {
      return [];
    }

    return paymentMethods.map(paymentMethod => ({
      ...paymentMethod,
      config: getPaymentConfigForLabel(paymentsConfig, paymentMethod.label),
    }));
  },
);

/**
 * Get countries list of a payment config
 *
 * @returns {string[]}
 */
export const getPaymentConfigCountries = createSelector(
  getSelectedPaymentConfig,
  paymentConfig => (!isEmpty(paymentConfig) && Object.keys(omit(paymentConfig, 'default'))) || [],
);

/**
 * Get the default category of a payment config
 *
 * @returns {string|null}
 */
export const getPaymentConfigDefaultCategory = createSelector(
  getSelectedPaymentConfig,
  paymentConfig => paymentConfig?.default?.category || DEFAULT_CATEGORY,
);

/**
 * Get the default options of a payment config
 *
 * @returns {import('./reducer').PaymentOptions}
 */
export const getPaymentConfigDefaultOptions = createSelector(
  getSelectedPaymentConfig,
  paymentConfig => paymentConfig?.default?.options || DEFAULT_OPTIONS,
);

export const getPaymentConfigSpecificConfig = createSelector(
  getSelectedPaymentConfig,
  getPaymentConfigDefaultCategory,
  (paymentConfig, defaultCategory) =>
    pickBy(paymentConfig, ({ category }) => category !== defaultCategory),
);
