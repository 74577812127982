import styled, { css } from 'styled-components';

import { Theme } from '@tillersystems/stardust';

export const Container = styled.div`
  height: 4rem;
  width: 100%;
  display: flex;
  margin-top: 2rem;

  ${({ align }) =>
    align === 'left' &&
    css`
      justify-content: flex-start;
    `}

  ${({ align }) =>
    align === 'right' &&
    css`
      justify-content: flex-end;
    `}

  ${({ align }) =>
    align === 'center' &&
    css`
      justify-content: center;
    `}
`;

export const PaginationContainer = styled.div`
  width: ${({ width }) => width};
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${Theme.dimensions.radius};
  border: 1px solid ${Theme.palette.lightGrey};
  background-color: ${Theme.palette.white};
`;

export const PaginationNumbers = styled.div`
  height: 3rem;
  display: flex;
`;

export const PageItem = styled.div`
  border-radius: ${Theme.dimensions.radius};
  background-color: ${Theme.palette.white};
  height: 3rem;
  width: 3rem;
  line-heigh: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.palette.mediumGrey};
  cursor: pointer;

  &:hover {
    background-color: ${Theme.palette.lightGrey};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${Theme.palette.primary.default};
      color: ${Theme.palette.white};
      &:hover {
        background-color: ${Theme.palette.primary.darker};
      }
    `}
`;

export const ArrowLeftButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border-right: 1px solid ${Theme.palette.lightGrey};
  cursor: pointer;
`;

export const ArrowRightButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border-left: 1px solid ${Theme.palette.lightGrey};
  cursor: pointer;
`;
