import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Icon, Loader, ToggleButton } from '@tillersystems/stardust';

import {
  Card,
  ConfirmButton,
  LoaderOverlay,
  OrderEventForm,
  OrderEventTable,
  OrderEventTypeSelect,
  Theme,
} from '../../../../components';
import { getCommentOptions } from '../../../../components/OrderEvent/Form/definitions';
import { useAuth } from '../../../../contexts/Auth';
import { AdminOrderEvent, Order, OrderEvent } from '../../../../helpers/models';
import connect from '../connect';

import {
  Body,
  CardTitle,
  CommentSelect,
  ErrorContent,
  Footer,
  Header,
  NewEventButton,
  SettingsBar,
  SettingsBarTitle,
  SynchronizeButton,
  SynchronizeContainer,
  Toggle,
  ToggleLabel,
} from './elements';

const EventsCard = ({
  actions,
  order,
  events,
  newEvents,
  errors,
  loading,
  hasReplayed,
  rawTicket,
  settings: { isDetailedView, selectedEventTypes },
}) => {
  const [t] = useTranslation();
  const [formOpen, setFormOpen] = useState();
  const [comment, setComment] = useState(null);
  const { user } = useAuth();

  const isLoading = loading.order || loading.events;

  const handleCreateEvent = useCallback(
    event => {
      actions.orderEventsCreate({ order, event, user });
      return setFormOpen(false);
    },
    [order, setFormOpen],
  );

  const handleUpdateEvent = useCallback(
    event => {
      actions.orderEventsUpdate({ order, event });
    },
    [order],
  );

  const handleRemoveEvent = useCallback(
    uuid => {
      actions.orderEventsRemove({ order, uuid });
    },
    [order],
  );

  /**
   * When executing an action on event (remove or rollback)
   * It creates a new admin event specifig to action.
   */
  const handleActionEvent = useCallback(
    data => actions.orderEventsCreate({ order, event: data, user }),
    [order],
  );

  const handleCancel = useCallback(() => setFormOpen(false), []);

  const handleSync = useCallback(() => {
    actions.orderEventsSyncRequested({ order, comment });
  }, [order, comment]);

  const handleReplay = useCallback(() => {
    actions.orderEventsReplayRequested({ order });
  }, [order]);

  const handleDetailedViewToggle = useCallback(() => {
    actions.setIsDetailedView(!isDetailedView);
  }, [isDetailedView]);

  const commentOptions = useMemo(() => getCommentOptions(), []);

  const handleCommentChange = useCallback(([value]) => setComment(value), [setComment]);

  const isSyncing = newEvents && newEvents.some(event => event.getSyncStatus() === 'syncing');
  const mayHaveRawTicketV2 = !rawTicket || (rawTicket && rawTicket.version <= '3.0.0');

  return (
    <Card>
      <Header>
        <CardTitle>{t('order_management.events')}</CardTitle>
        {order && order.canBeModified() && (
          <ConfirmButton
            confirmLabel={t('order_management.actions.replay_confirm')}
            appearance="secondary"
            icon={
              loading.replay ? (
                <Loader color="currentColor" />
              ) : (
                <Icon color={Theme.palette.spaceGrey} name="refresh" />
              )
            }
            disabled={loading.replay || hasReplayed}
            onClick={handleReplay}
          >
            {t('order_management.actions.replay')}
          </ConfirmButton>
        )}
      </Header>
      <SettingsBar>
        <SettingsBarTitle>{t('order_management.settings_title')}</SettingsBarTitle>
        <Toggle>
          <ToggleLabel onClick={handleDetailedViewToggle}>
            {t('order_management.detailed_view')}
          </ToggleLabel>
          <ToggleButton isChecked={isDetailedView} onToggle={handleDetailedViewToggle} />
        </Toggle>
        <OrderEventTypeSelect
          values={selectedEventTypes}
          onChange={actions.setSelectedEventTypes}
        />
      </SettingsBar>
      <Body>
        {errors.events instanceof Error && <ErrorContent>{errors.events.message}</ErrorContent>}
        {errors.replay instanceof Error && <ErrorContent>{errors.replay.message}</ErrorContent>}
        {isLoading && <LoaderOverlay />}
        {events && (
          <OrderEventTable
            data={events}
            newEvents={newEvents}
            filterEventTypes={selectedEventTypes}
            onSync={handleSync}
            isDetailedView={isDetailedView}
            onUpdateEvent={handleUpdateEvent}
            onRemoveEvent={handleRemoveEvent}
            onActionEvent={handleActionEvent}
          />
        )}
      </Body>
      {order && order.canBeModified() && mayHaveRawTicketV2 && (
        <Footer>
          {formOpen && <OrderEventForm onRemove={handleCancel} onSubmit={handleCreateEvent} />}
          {!formOpen && (
            <NewEventButton
              appearance="primary"
              icon={<Icon color={Theme.palette.paleGrey} name="plus" />}
              onClick={() => setFormOpen(true)}
            >
              <Icon color="currentColor" name="plus" />
              {t('order_management.actions.new_event')}
            </NewEventButton>
          )}
          {newEvents && !!newEvents.length && (
            <SynchronizeContainer>
              <CommentSelect
                values={[comment]}
                options={commentOptions}
                onChange={handleCommentChange}
                usePortal
                placeholder={t('order_management.fields.comment_placeholder')}
              />
              <SynchronizeButton
                appearance="success"
                onClick={handleSync}
                disabled={isSyncing || !comment}
              >
                {isSyncing && <Loader color="currentColor" />}
                {t('order_management.actions.synchronize')}
              </SynchronizeButton>
            </SynchronizeContainer>
          )}
        </Footer>
      )}
    </Card>
  );
};

EventsCard.propTypes = {
  actions: PropTypes.shape({
    orderEventsCreate: PropTypes.func.isRequired,
    orderEventsSyncRequested: PropTypes.func.isRequired,
    orderEventsReplayRequested: PropTypes.func.isRequired,
    setSelectedEventTypes: PropTypes.func,
    setIsDetailedView: PropTypes.func,
    orderEventsRemove: PropTypes.func,
    orderEventsUpdate: PropTypes.func,
  }).isRequired,
  loading: PropTypes.shape({
    order: PropTypes.bool,
    events: PropTypes.bool,
    replay: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    events: PropTypes.instanceOf(Error),
    replay: PropTypes.object,
  }),
  order: PropTypes.instanceOf(Order),
  events: PropTypes.arrayOf(PropTypes.instanceOf(OrderEvent)),
  newEvents: PropTypes.arrayOf(PropTypes.instanceOf(AdminOrderEvent)),
  hasReplayed: PropTypes.bool,
  rawTicket: PropTypes.object,
  settings: PropTypes.shape({
    isDetailedView: PropTypes.bool.isRequired,
    selectedEventTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }).isRequired,
};

EventsCard.defaultProps = {
  order: null,
  events: null,
  newEvents: null,
  errors: {
    events: null,
    replay: null,
  },
  loading: {
    order: false,
    events: false,
    replay: false,
  },
  hasReplayed: false,
  rawTicket: null,
};

export default connect(EventsCard);
