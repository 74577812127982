import styled from '@emotion/styled';
import { Button } from '@sumup/circuit-ui';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

export const StyledButton = styled(Button)`
  margin: 0 2px;
`;
