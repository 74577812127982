import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import OrderEventDetail from '../Detail';

import { DateTime, StyledEvent } from './Cells';
import {
  Body,
  Cell,
  CollapsibleCell,
  CollapsibleRow,
  Container,
  DateTimeCell,
  Header,
  HeaderCell,
  HeaderRow,
  HiddenRow,
  NewEventRow,
  NewEventsHeaderCell,
  NewEventsHeaderRow,
  Row,
  Table,
} from './elements';
import { addingEvents, getDevices, mapTypeToUUIDTypes } from './helpers';

const OrderEventTable = ({
  data,
  newEvents,
  onUpdateEvent,
  onRemoveEvent,
  onActionEvent,
  isDetailedView,
  filterEventTypes,
}) => {
  const [t] = useTranslation();
  const [openRows, setOpenRows] = useState([]);
  const [refUUID, setRefUUID] = useState({});

  const devices = useMemo(() => getDevices(data, newEvents), [data, newEvents]);

  const handleClick = ({ uuid, payload, type }) => {
    if (openRows.includes(uuid)) {
      setOpenRows(openRows.filter(rows => rows !== uuid));
    } else {
      setOpenRows(openRows.concat([uuid]));
    }
    if (mapTypeToUUIDTypes(type).length !== 0) {
      const UUIDLinked = Object.assign(
        {},
        ...mapTypeToUUIDTypes(type).map(UUIDType =>
          addingEvents.includes(type) ? { [UUIDType]: uuid } : { [UUIDType]: payload[UUIDType] },
        ),
      );
      setRefUUID(UUIDLinked);
    } else {
      setRefUUID({});
    }
  };

  const handleUpdateEvent = useCallback(
    event => {
      if (onUpdateEvent) onUpdateEvent(event);
      setOpenRows(openRows.filter(rows => rows !== event.uuid));
    },
    [onUpdateEvent],
  );

  const handleRemoveEvent = useCallback(
    event => {
      if (onRemoveEvent) onRemoveEvent(event);
      setOpenRows(openRows.filter(rows => rows !== event.uuid));
    },
    [onRemoveEvent],
  );

  const handleActionEvent = useCallback(
    event => {
      if (onActionEvent) {
        const action = onActionEvent(event);

        if (action && action.payload && action.payload.event && action.payload.event.uuid) {
          setOpenRows(
            openRows.filter(rows => rows !== event.uuid).concat([action.payload.event.uuid]),
          );
        }
      }
    },
    [onActionEvent],
  );

  const isRowOpen = uuid => openRows.includes(uuid);

  const canSync = newEvents && newEvents.some(event => event.getSyncStatus() !== 'applied');
  const hasSyncErrors =
    newEvents && newEvents.some(event => ['error', 'invalid'].includes(event.getSyncStatus()));

  const correlatedEvent = data.filter(event =>
    mapTypeToUUIDTypes(event.type).some(UUIDType => {
      if (refUUID[UUIDType]) {
        return addingEvents.includes(event.type)
          ? refUUID[UUIDType] === event.uuid
          : refUUID[UUIDType] === event.payload[UUIDType];
      }
    }),
  ).length;

  const isPairedCorrelatedEvents = correlatedEvent > 1;

  return (
    <Container>
      <Table>
        <Header>
          <HeaderRow>
            <HeaderCell as={DateTimeCell} scope="col">
              {t('order_management.date')}
            </HeaderCell>
            <HeaderCell as={DateTimeCell} scope="col">
              {t('order_management.fields.uuid')}
            </HeaderCell>
            {Object.keys(devices).map(deviceID => (
              <HeaderCell scope="col" key={deviceID}>
                {devices[deviceID].identifier || t('order_management.device_id', { id: deviceID })}
              </HeaderCell>
            ))}
          </HeaderRow>
        </Header>
        <Body>
          {data.map(event =>
            !filterEventTypes ||
            filterEventTypes === 'all' ||
            filterEventTypes.includes(event.type) ? (
              <Fragment key={event.uuid}>
                <Row
                  isActive={
                    mapTypeToUUIDTypes(event.type).some(UUIDType => {
                      if (refUUID[UUIDType]) {
                        return addingEvents.includes(event.type)
                          ? refUUID[UUIDType] === event.uuid
                          : refUUID[UUIDType] === event.payload[UUIDType];
                      }
                    }) && isPairedCorrelatedEvents
                  }
                  onClick={() => handleClick(event)}
                  isOpen={isRowOpen(event.uuid)}
                >
                  <DateTimeCell key="happenedOn">
                    <DateTime date={event.happenedOn} />
                  </DateTimeCell>
                  <DateTimeCell key="uuid">{event.uuid.split('-')[0]}</DateTimeCell>
                  {devices.map(device =>
                    device.id === event.deviceID ? (
                      <Cell key={device.id}>
                        <StyledEvent event={event} isOpen={isRowOpen(event.uuid)} />
                      </Cell>
                    ) : (
                      <Cell key={device.id}></Cell>
                    ),
                  )}
                </Row>
                {isRowOpen(event.uuid) && (
                  <CollapsibleRow>
                    <CollapsibleCell colSpan={devices.length + 2}>
                      <OrderEventDetail
                        data={event}
                        events={data}
                        isDetailedView={isDetailedView}
                        onAction={handleActionEvent}
                      />
                    </CollapsibleCell>
                  </CollapsibleRow>
                )}
              </Fragment>
            ) : (
              <HiddenRow key={event.uuid}>
                <td colSpan={devices.length + 2}></td>
              </HiddenRow>
            ),
          )}
          {canSync && (
            <NewEventsHeaderRow>
              <NewEventsHeaderCell colSpan={devices.length + 2}>
                {hasSyncErrors
                  ? t('order_management.synchronize_errors')
                  : t('order_management.synchronize_explain')}
              </NewEventsHeaderCell>
            </NewEventsHeaderRow>
          )}
          {newEvents &&
            newEvents.map(event => (
              <Fragment key={event.uuid}>
                <NewEventRow onClick={() => handleClick(event)} isOpen={isRowOpen(event.uuid)}>
                  <DateTimeCell key="happenedOn">
                    <DateTime date={event.happenedOn} />
                  </DateTimeCell>
                  <DateTimeCell key="uuid">{event.uuid.split('-')[0]}</DateTimeCell>
                  {devices.map(device =>
                    device.id === event.deviceID ? (
                      <Cell key={device.id}>
                        <Event event={event} isOpen={isRowOpen(event.uuid)} />
                      </Cell>
                    ) : (
                      <Cell key={device.id}></Cell>
                    ),
                  )}
                </NewEventRow>
                {isRowOpen(event.uuid) && (
                  <CollapsibleRow>
                    <CollapsibleCell colSpan={devices.length + 2}>
                      <OrderEventDetail
                        data={event}
                        isDetailedView={isDetailedView}
                        onUpdate={handleUpdateEvent}
                        onRemove={handleRemoveEvent}
                      />
                    </CollapsibleCell>
                  </CollapsibleRow>
                )}
              </Fragment>
            ))}
        </Body>
      </Table>
    </Container>
  );
};

OrderEventTable.propTypes = {
  data: PropTypes.array.isRequired,
  newEvents: PropTypes.array,
  onUpdateEvent: PropTypes.func.isRequired,
  onRemoveEvent: PropTypes.func.isRequired,
  onActionEvent: PropTypes.func.isRequired,
  isDetailedView: PropTypes.bool,
  filterEventTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

OrderEventTable.defaultProps = {
  newEvents: null,
  isDetailedView: false,
  filterEventTypes: 'all',
};

export default OrderEventTable;
