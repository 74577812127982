import styled from 'styled-components';

export const Title = styled.h1`
  font-size: ${({ theme: { fonts } }) => fonts.size.huge};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
`;

export const Subtitle = styled.h2`
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
`;

export const Ball = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;

  margin: 0 auto;

  border-radius: 50%;

  transform-origin: 50% 100%

  font-size: 18px;
  font-weight: 700;

  cursor: pointer;

  background: ${({ theme: { palette } }) => palette.red};
`;
