import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ToggleButton } from '@tillersystems/stardust';

const BooleanField = ({ className, setValue, options, value }) => {
  return (
    <ToggleButton className={className} isChecked={!!value} onToggle={setValue} {...options} />
  );
};

BooleanField.propTypes = {
  className: PropTypes.string,
  options: PropTypes.object,
  value: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
};

BooleanField.defaultProps = {
  className: '',
  value: false,
  options: {},
};

export default styled(BooleanField)`
  padding: 0.6rem 0;
  height: 3.2rem;
`;
