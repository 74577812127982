import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { OrderEvent } from '../../../../helpers/models';
import { getColorForType } from '../../Detail/definitions';

const EventTag = styled.span`
  display: inline-flex;
  position: relative;
  border: 0.1rem solid currentColor;
  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
  margin: 1.4rem auto 1rem;

  color: ${({ theme: { palette } }) => palette.mediumGrey};
  background: currentColor;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      &:after {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 1.4rem 1.4rem 0 1.4rem;
        border-color: currentColor transparent transparent transparent;
        content: ' ';
        top: 100%;
        left: 50%;
        margin-left: -1.4rem;
      }
    `}
`;

const EventLabel = styled.span`
  padding: 0.3rem 0.7rem;
  color: ${({ theme: { palette } }) => palette.white};
`;

const EventUser = styled.span`
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border: 1px solid currentColor;
  background: white;
  font-size: 10px;
  padding: 0 1em;
  max-width: 100%;
  transform: translate3d(-50%, 0, 0);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventPlayHead = styled.span`
  border-radius: ${({ theme: { dimensions } }) => `${dimensions.radiusInt - 0.1}rem`};
  background: ${({ theme: { palette } }) => palette.white};
  color: currentColor;
  padding: 0.3rem 0.7rem;
`;

const StyledEvent = ({ event, isOpen }) => {
  let username =
    (event.source && event.source.admin && event.source.admin.name) ||
    (event.source.waiter && event.source.waiter.name);
  const color = getColorForType(event.type);

  return (
    <EventTag color={color} isOpen={isOpen}>
      {username && <EventUser>{username}</EventUser>}
      {event.playhead !== null && <EventPlayHead>{event.playhead}</EventPlayHead>}
      <EventLabel>{event.type}</EventLabel>
    </EventTag>
  );
};

StyledEvent.propTypes = {
  isOpen: PropTypes.bool,
  event: PropTypes.instanceOf(OrderEvent),
};

StyledEvent.defaultProps = {
  isOpen: false,
  event: null,
};
export default StyledEvent;
