export const appVersionRegEx = /^[0-9]\.[0-9]{1,2}\.[0-9]+$/;

export const buildRegEx = /^[0-9a-zA-Z]+$/;

export const isEmpty = value => value === '';

export const apiEndpoints = {
  staging: 'api-events-preprod.tillersystems.com',
  production: 'api-events.tillersystems.com',
};

export const backEndpoints = {
  staging: 'preprod.tillersystems.com',
  production: 'app.tillersystems.com',
};

export const kongEndpoints = {
  staging: 'kong-preprod.tillersystems.com',
  production: 'kong.tillersystems.com',
};
