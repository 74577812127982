import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: ${({ theme: { dimensions } }) => dimensions.medium};
`;

export const Row = styled.div`
  width: 100%;
  height: ${({ chart }) => (chart ? '45rem' : '12.5rem')};

  display: flex;
  justify-content: space-around;
  align-items: center;
`;
