import { motion } from 'framer-motion';
import styled from 'styled-components';

import { DatePicker } from '@tillersystems/stardust';

export const PeriodPickerContent = styled(motion.div)`
  background-color: ${({ theme: { palette } }) => palette.white};
  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
  box-shadow: 0 0.2rem 1.6rem 0 hsla(0, 0%, 0%, 0.1), 0 0.2rem 1.6rem 0 hsla(206, 23%, 69%, 0.1),
    0 0 0 0.1rem hsl(207, 22%, 90%);
  display: flex;

  position: absolute;
  z-index: 999;

  ${DatePicker} {
    padding: 1.6rem;
    width: calc((24.4rem * 2) + 5.6rem);
  }
`;
