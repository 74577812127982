import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import OrderDetail from '../OrderDetail';
import SearchBar from '../SearchBar';

import { DraftEventsSection, Header, Wrapper } from './elements';

const OrderContainer = ({ match, newEventsByRootUUID }) => {
  const [t] = useTranslation();
  const rootPath = match.path;

  const newEventsRootUUIDs = Object.keys(newEventsByRootUUID).filter(
    rootUUID => newEventsByRootUUID[rootUUID].length,
  );

  return (
    <>
      <Header>
        <Route path={`${rootPath}/:id?`}>
          <SearchBar placeholder={t('order_management.search_order_placeholder')} />
        </Route>
      </Header>
      <Wrapper>
        <Switch>
          <Route path={`${rootPath}/:id`}>
            <OrderDetail />
          </Route>
          <Route path="*">
            <p>{t('order_management.introduction')}</p>
            {!!newEventsRootUUIDs.length && (
              <DraftEventsSection>
                <h2>{t('order_management.draft_events_title')}</h2>
                <ul>
                  {newEventsRootUUIDs.map(rootUUID => (
                    <li key={rootUUID}>
                      <NavLink to={`${rootPath}/${rootUUID}`}>{rootUUID}</NavLink>
                      {': '}
                      {t('order_management.draft_events_label', {
                        count: newEventsByRootUUID[rootUUID].length,
                      })}
                    </li>
                  ))}
                </ul>
              </DraftEventsSection>
            )}
          </Route>
        </Switch>
      </Wrapper>
    </>
  );
};

OrderContainer.propTypes = {
  newEventsByRootUUID: PropTypes.object,
  match: PropTypes.object.isRequired,
};

OrderContainer.defaultProps = {
  newEventsByRootUUID: {},
};

export default withRouter(OrderContainer);
