import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '@tillersystems/stardust';

import { AdminOrderEvent } from '../../../helpers/models';
import DefinitionList from '../../DefinitionList';
import OrderEventForm from '../Form';

import EventDefinitions from './definitions';
import { Body, Container, Header } from './elements';

const OrderEventDetail = ({ data, events, isDetailedView, onUpdate, onRemove, onAction }) => {
  const [t] = useTranslation();

  const eventType = useMemo(() => (data && data.type) || null, [data]);
  const eventConfig = useMemo(() => (eventType && EventDefinitions[eventType]) || {}, [eventType]);

  const handleSubmit = useCallback(values => {
    onUpdate(values);
  }, []);

  const handleRollback = useCallback(() => {
    const rollbackData = eventConfig.rollback(data, events);
    onAction(rollbackData);
  }, [data, events, onAction]);

  const handleRemove = useCallback(() => onRemove(data.uuid), []);

  if (data instanceof AdminOrderEvent && !isDetailedView) {
    return (
      <OrderEventForm data={data} onSubmit={handleSubmit} onRemove={handleRemove}></OrderEventForm>
    );
  }

  return (
    <Container>
      {eventConfig.rollback && (
        <Header>
          <Button appearance="primary" onClick={handleRollback}>
            {t('order_management.actions.rollback')}
          </Button>
        </Header>
      )}
      <Body>
        <DefinitionList data={data} definitions={isDetailedView ? null : eventConfig.definitions} />
      </Body>
    </Container>
  );
};

OrderEventDetail.propTypes = {
  data: PropTypes.object.isRequired,
  events: PropTypes.array,
  isDetailedView: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onAction: PropTypes.func,
};

OrderEventDetail.defaultProps = {
  events: [],
  onUpdate: null,
  onRemove: null,
  onAction: null,
};

export default OrderEventDetail;
