import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Card Footer
 *
 * This component is in charge of displaying
 * a footer of a card
 *
 * @param {string} children // children.
 * @param {string} className // className.
 *
 * @return {jsx}
 */

const CardFooter = ({ children, className }) => <div className={className}>{children}</div>;

/**
 * PropTypes Validation
 */
const { node, string, oneOf } = PropTypes;
CardFooter.propTypes = {
  children: node,
  className: string,
  // eslint-disable-next-line react/no-unused-prop-types
  justifyContent: oneOf(['flex-start', 'center', 'flex-end', 'space-between']),
};

/**
 * Default props
 */
CardFooter.defaultProps = {
  children: null,
  className: '',
  justifyContent: 'flex-start',
};

export default styled(CardFooter)`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};

  font-size: ${({ theme: { fonts } }) => fonts.medium};

  padding-top: 3rem;

  background-color: ${({ theme: { palette } }) => palette.greygoose};
`;
