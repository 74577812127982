import Theme from '../../../../../components/Theme';

/** Constant bar chart margins. */
export const margins = {
  top: 20,
  right: 20,
  bottom: 70,
  left: 20,
};

/** Constant bar chart legend definition. */
export const legends = [
  {
    anchor: 'bottom',
    dataFrom: 'keys',
    direction: 'row',
    translateY: 50,
    itemWidth: 140,
    itemHeight: 14,
    symbolSize: 14,
    symbolShape: 'circle',
  },
];

/** Constant bar chart axes definition. */
export const axes = {
  left: {
    orient: 'left',
    tickSize: 7,
    tickPadding: 3,
    tickRotation: 0,
    legendPosition: 'middle',
    legendOffset: -40,
  },
  bottom: {
    orient: 'bottom',
    tickSize: 7,
    tickPadding: 3,
    tickRotation: 0,
    legendPosition: 'middle',
    legendOffset: 36,
  },
};

const { palette } = Theme;

/**
 * Gets the color of a single bar.
 *
 * @param {Function} t - The I18n instance.
 * @param {string} id - The id of the bar.
 *
 * @returns {string} - The color of the bar.
 */
export const getColor = (t, id) => {
  if (id == t('reports.appmarket.variation.variation_history.activations'))
    return palette.success.default;

  return palette.failure.default;
};
