import { PropsWithChildren } from 'react';

export enum ErrorCode {
  BadRequest = 400,
  Forbidden = 403,
  NotFound = 404,
  ServerError = 500,
}

export interface AuthenticationError {
  code: ErrorCode;
  message: string;
  original?: unknown;
}

export interface AwsAuthenticationError {
  name: string;
  code: string;
  message: string;
}

export interface Authentication {
  readonly accessToken?: string;
  readonly error?: AuthenticationError;
  readonly isAuthenticated: boolean;
  readonly isRequesting: boolean;
  readonly user?: { email: string; name: string; picture: string };
  signOut: () => Promise<unknown>;
}

export type AuthenticationProviderProps = PropsWithChildren<Record<string, unknown>>;
