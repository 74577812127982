import { parse, ParseResult } from 'papaparse';

import { FeatureActivationStatus, Status } from './types';

export function extractValidfeatureActivations(
  data: ParseResult<unknown>['data'],
): FeatureActivationStatus[] {
  return data.reduce<FeatureActivationStatus[]>((featureActivations, data) => {
    if (
      typeof data === 'object' &&
      data !== null &&
      'featureCode' in data &&
      data.featureCode &&
      'storeId' in data &&
      data.storeId
    ) {
      featureActivations.push({
        featureCode: String(data.featureCode),
        storeId: Number(data.storeId),
        status: 'idle',
      });
    }
    return featureActivations;
  }, []);
}

export function parsefeatureActivationsFile(
  file: File,
  complete: (results: ParseResult<unknown>) => void,
) {
  parse(file, {
    header: true,
    skipEmptyLines: true,
    transformHeader(header) {
      if (/Feature Code/.test(header)) return 'featureCode';
      if (/Store ID/.test(header)) return 'storeId';
      return header;
    },
    complete,
  });
}

export function setStatus(status: 'DONE' | 'ERROR'): Status {
  switch (status) {
    case 'DONE':
      return 'succeeded';
    case 'ERROR':
      return 'failed';
    default:
      return 'pending';
  }
}
