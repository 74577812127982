import React from 'react';

import { Loader } from '@tillersystems/stardust';

import { Container } from './elements';

const LoaderOverlay = () => (
  <Container>
    <Loader height="64" width="64" />
  </Container>
);

export default LoaderOverlay;
