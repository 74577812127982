import styled from 'styled-components';

export const DraftEventsSection = styled.section`
  h2 {
    border-bottom: 1px solid currentColor;
    margin: 1rem 0;
  }

  a {
    color: ${({ theme: { palette } }) => palette.primary.default};
    font-weight: bold;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
`;

export const Wrapper = styled.div`
  display: flex;
  overflow-y: auto;
}
`;
