import { create as createApi } from 'apisauce';

import { ApiError } from '../../helpers/errors';

/**
 * rawTicketApi
 *
 * Create a raw ticket api
 *
 * @return {void}
 */
const rawTicketApi = createApi({
  baseURL: process.env.REACT_APP_TILLER_RAW_TICKET_API_URL,
  headers: {
    'X-API-KEY': process.env.REACT_APP_TILLER_RAW_TICKET_API_TOKEN,
  },
});

/**
 * getRawTicket
 *
 * Return an array of raw tickets according to a store and a order root uuid
 *
 * @param {string} storeID - The store id which you want to trigger order
 * @param {string} rootUUID - The order uuid which you want to trigger raw tickets
 * @param {ApisauceInstance} api - The ApisauceInstance that will be used to fetch the raw tickets
 *
 * @return {array} Return an array of raw tickets
 */
export const getRawTicket = async (storeID, rootUUID, api = rawTicketApi) => {
  const results = await api.get(`/stores/${storeID}/orders/${rootUUID}/raw-tickets`);
  const { data, ok, problem } = results;

  // skip rawTickets without metadata.signature.type, they currently cannot be displayed
  const validatedData = data.filter(rawTicket => rawTicket?.metadata?.signature?.type != null);

  if (!ok) throw new ApiError(problem);
  return validatedData;
};
