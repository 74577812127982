/**
 * ApiError.
 * Error thrown when a validation fail.
 *
 * @extends Error
 * @class ApiError
 */
export class ApiError extends Error {
  /**
   * Create a new instance of ApiError.
   *
   * @param {string} message - the message received as error
   * @param {number} [code] - the code related to the error
   */
  constructor(message, code) {
    const errorMessage = message || 'An unknown error occured';
    const errorCode = code ? ` (code ${code})` : '';

    super(`ApiError${errorCode}: ${errorMessage}`);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.errorMessage = errorMessage;
    this.code = code;

    // Jest compliance
    this.constructor = ApiError;
    this.__proto__ = ApiError.prototype;
  }
}
