import { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Amplify, Hub, HubCallback } from '@aws-amplify/core';

import amplifyConfig from '../../amplify-config';

import { logError, transformToAuthenticationError } from './helper';
import { Authentication, AuthenticationError, AwsAuthenticationError } from './types';

const REFRESH_INTERVAL = 10 * 60 * 1000; // 10 minutes

Amplify.configure(amplifyConfig);

export const useCognito = (): Authentication => {
  const [accessToken, setAccessToken] = useState<string>();
  const [user, setUser] = useState<{ email: string; name: string; picture: string }>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRequesting, setIsRequesting] = useState(true);
  const [error, setError] = useState<AuthenticationError>();

  const handleError = (authError: AwsAuthenticationError): void => {
    const loginError = transformToAuthenticationError(authError);
    setError(loginError);
    logError(loginError);
  };

  /**
   * Logout the user from the app using aws cognito auth system
   */
  const signOut = async (): Promise<void> => {
    try {
      setIsRequesting(true);
      await Auth.signOut();
      setAccessToken(undefined);
      setIsAuthenticated(false);
      setIsRequesting(false);
      setUser(null);
    } catch (e) {
      setIsRequesting(false);
      handleError(e as AwsAuthenticationError);
    }
  };

  // Listen to amplify events and update user authentication status accordingly
  useEffect(() => {
    const authListener: HubCallback = async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn': {
          const session = await Auth.currentSession();
          const currentUser = await Auth.currentUserInfo();
          setIsAuthenticated(true);
          setAccessToken(session.getAccessToken().getJwtToken());
          setUser({
            email: currentUser.attributes.email,
            name: currentUser.attributes.name,
            picture: currentUser.attributes.picture,
          });
          setError(undefined);
          break;
        }
        case 'signOut':
          setAccessToken(undefined);
          setIsAuthenticated(false);
          setUser(null);
          setError(undefined);
          break;
        case 'tokenRefresh':
          setIsAuthenticated(true);
          setError(undefined);
          break;
        case 'signIn_failure':
        case 'tokenRefresh_failure':
          setAccessToken(undefined);
          setIsAuthenticated(false);
          setUser(null);
          handleError(data); // Token refresh error
          break;
      }
      setIsRequesting(false);
    };

    Hub.listen('auth', authListener);

    return () => {
      Hub.remove('auth', authListener);
    };
  }, []);

  // Check user is authenticated on app init
  useEffect(() => {
    async function checkAuthenticated() {
      setIsRequesting(true);
      try {
        const session = await Auth.currentSession();
        if (session && session.isValid()) {
          setIsAuthenticated(true);
          setAccessToken(session.getAccessToken().getJwtToken());
          const currentUser = await Auth.currentUserInfo();
          setUser({
            email: currentUser.attributes.email,
            name: currentUser.attributes.name,
            picture: currentUser.attributes.picture,
          });
        }
      } catch (e) {
        setIsAuthenticated(false);
        setAccessToken(undefined);
        setUser(null);
      }
      setIsRequesting(false);
    }

    checkAuthenticated().catch(null);
  }, []);

  // Setup a 10 min check to refresh user token if necessary
  useEffect(() => {
    const intervalId = setInterval(() => {
      Auth.currentSession()
        .then(session => {
          setAccessToken(session.getAccessToken().getJwtToken());
        })
        .catch(refreshTokenError => {
          handleError(refreshTokenError);
        });
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return { accessToken, user, error, isAuthenticated, isRequesting, signOut };
};
