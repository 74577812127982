import styled from 'styled-components';

export const Name = styled.h2`
  font-size: ${({ theme: { fonts } }) => `${fonts.size.mediumInt + 0.3}rem`};
  text-transform: uppercase;
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};

  color: ${({ theme: { palette } }) => palette.primary.default};
`;

export const NameLoading = styled.div`
  height: 1.5rem;
  width: 4rem;

  background: ${({ theme: { palette } }) => palette.bodyBackground};

  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
`;

export const PublishDate = styled.span`
  margin-left: 1rem;

  color: ${({ theme: { palette } }) => palette.darkGrey};
`;

export const PublishDateLoading = styled.div`
  height: 1.5rem;
  width: 7.5rem;

  background: ${({ theme: { palette } }) => palette.bodyBackground};

  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  cursor: pointer;

  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};

  margin-bottom: 1.5rem;
  padding-bottom: 0.7rem;
`;

export const Body = styled.div`
  h1,
  h2 {
    font-size: ${({ theme: { fonts } }) => `${fonts.size.mediumInt + 0.4}rem`};
    font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  }
  ul {
    padding-left: 3rem;
    margin-bottom: 1rem;
  }
`;

export const MarkdownLoading = styled.div`
  height: 1.5rem;
  width: 20rem;

  margin-bottom: 1rem;

  background: ${({ theme: { palette } }) => palette.bodyBackground};

  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
`;
