import { createSelector } from 'reselect';

import { LOADING, NONE } from '../../constants/Status';

/**
 * Category selectors
 */

/**
 * @typedef {Object} Category
 * @property {string} label
 * @property {string} translation_key
 */

/**
 * Get the loading status about the fetched datas
 *
 * @return {string}
 */
export const getCategoriesStatus = ({ categories }) => categories.status;

/**
 * If data is being fetched
 *
 * @return {boolean}
 */
export const isCategoriesLoading = createSelector(
  [getCategoriesStatus],
  status => status === LOADING,
);

/**
 * If data is done fetching
 *
 * @return {boolean}
 */
export const isCategoriesDoneLoading = createSelector(
  [getCategoriesStatus],
  status => status !== NONE && status !== LOADING,
);

/**
 * Get the list of categories
 *
 * @return {Object.<string, Category>} a list of categories
 */
export const getCategories = ({ categories }) => categories.entities;

/**
 * Get an array of Categories
 *
 * @return {Category[]} an array of Categories
 */
export const getCategoriesArray = createSelector(getCategories, categories =>
  Object.values(categories),
);
