import { create as createApi } from 'apisauce';

export function init() {
  /**
   * Instance of the api to Tiller Captain.
   */
  const manager = createApi({
    baseURL: process.env.REACT_APP_TILLER_ORDER_ADMIN_URL,
    headers: {
      'X-API-KEY': process.env.REACT_APP_TILLER_ORDER_TOKEN,
    },
  });

  return manager;
}
