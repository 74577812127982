import { useTheme } from '@emotion/react';
import { Alert, Confirm, Minus, Play } from '@sumup/icons';

import { Status } from './types';

interface StatusIconProps {
  status: Status;
}

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  const { colors } = useTheme();

  switch (status) {
    case 'idle':
      return <Minus size="16" role="presentation" color={colors.n700} />;
    case 'pending':
      return <Play size="16" role="presentation" color={colors.p700} />;
    case 'failed':
      return <Alert size="16" role="presentation" color={colors.r700} />;
    case 'succeeded':
      return <Confirm size="16" role="presentation" color={colors.g700} />;
  }
};

export default StatusIcon;
