import React from 'react';

import { formatNumber } from '@tillersystems/stardust';

import i18n from '../../../i18n';

import { formatDate } from './helpers';

//i18n.t('period.order_id')
export const colsDef = [
  {
    name: 'order_id',
    title: i18n.t('order_management.table.order_id'),
    value: d => d.order_id,
    format: v => <span style={{ color: 'hsl(213, 17%, 20%)', fontWeight: 600 }}>{v}</span>,
    align: 'left',
    isRowHeader: true,
    isSortable: true,
  },
  {
    name: 'name',
    title: i18n.t('order_management.table.name'),
    value: d => d.name,
    align: 'right',
    isSortable: true,
  },
  {
    name: 'table_id',
    title: i18n.t('order_management.table.table_id'),
    value: d => d.table_id,
    isSortable: true,
    align: 'right',
  },
  {
    name: 'waiter_id',
    title: i18n.t('order_management.table.waiter_id'),
    value: d => d.waiter_id,
    isSortable: true,
    align: 'right',
  },
  {
    name: 'number',
    title: i18n.t('order_management.table.number'),
    value: d => d.number,
    isSortable: true,
    align: 'right',
  },
  {
    name: 'nb_customers',
    title: i18n.t('order_management.table.nb_customers'),
    value: d => d.nb_customers,
    isSortable: true,
    align: 'right',
  },
  {
    name: 'status',
    title: i18n.t('order_management.table.status'),
    value: d => d.status,
    isSortable: true,
    align: 'right',
  },
  {
    name: 'open_date',
    title: i18n.t('order_management.table.open_date'),
    value: d => d.open_date,
    format: v => formatDate(v),
    isSortable: true,
    align: 'right',
  },
  {
    name: 'cached_price',
    title: i18n.t('order_management.table.cashed_price'),
    value: d =>
      formatNumber({
        currency: 'EUR',
        locale: 'fr',
        number: d.cached_price,
      }),
    isSortable: true,
    align: 'right',
  },
  {
    name: 'cached_payed',
    title: i18n.t('order_management.table.cashed_payed'),
    value: d => formatNumber({ currency: 'EUR', locale: 'fr', number: d.cached_payed }),
    isSortable: true,
    align: 'right',
  },
];
