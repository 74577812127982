import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  display: flex;
  /* For small devices */
  ${breakpoint('xs', 'lg')`
      display: block;
  `}
`;
