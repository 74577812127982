import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const SideNavFooter = ({ children, className, onClick }) => (
  <div className={className} onClick={onClick} onKeyPress={null} role="button" tabIndex="0">
    {children}
  </div>
);

/**
 * PropTypes Validation
 */
const { node, string, func } = PropTypes;
SideNavFooter.propTypes = {
  children: node,
  className: string,
  onClick: func,
};

/**
 * Default props
 */
SideNavFooter.defaultProps = {
  children: null,
  className: '',
  onClick: () => {
    // handle onClick here
  },
};

export default styled(SideNavFooter)`
  grid-area: sidenavFooter;
  display: grid;
  grid-template-columns: 4.1rem auto auto;
  align-items: center;

  padding: ${({ theme: { dimensions } }) => dimensions.medium};

  background: rgba(0, 0, 0, 0.2);
  border-top: 1px solid ${({ theme: { palette } }) => palette.clay};
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.clay};

  color: ${({ theme: { palette } }) => palette.white};
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};

  cursor: pointer;

  /* For small devices */
  ${breakpoint('xs', 'sm')`
    display: none;
  `};

  /* For medium devices */
  ${breakpoint('sm', 'lg')`
    align-items: center;
    justify-content: center;
    grid-template-columns: auto;
    grid-template-rows: 4.1rem 5rem;
  `};
`;
