import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Loader, Table } from '@tillersystems/stardust';

import NoDataCardMessage from '../NoDataCardMessage';
import WrongIdMessage from '../WrongIdMessage';

import { colsDef } from './config';
import connect from './connect';
import { ErrorMsg, LoaderContainer } from './elements';

const StoreDetail = ({
  match,
  actions,
  orders,
  isLoading,
  errors,
  isPeriodLongerThanOneMonth,
  isOrdersExist,
  startDate,
  endDate,
}) => {
  const history = useHistory();

  const rowsDef = {
    onClick: ({ uuid }) => {
      history.push(`/order-management/order/${uuid}`);
    },
  };

  const { id: shopId } = match.params;

  useEffect(() => {
    if (shopId) {
      actions.shopOrdersRequested({ shopId, startDate, endDate });
    }
  }, [shopId, startDate, endDate]);

  if (isPeriodLongerThanOneMonth) {
    return <NoDataCardMessage isPeriodTooLarge />;
  }

  return isLoading ? (
    <LoaderContainer>
      <Loader width="6rem" height="6rem" />
    </LoaderContainer>
  ) : errors instanceof Error ? (
    errors.message === 'store_not_found' ? (
      <WrongIdMessage />
    ) : (
      <ErrorMsg>{errors.message}</ErrorMsg>
    )
  ) : isOrdersExist ? (
    <Table isScrollable isHoverable data={orders} colsDef={colsDef} rowsDef={rowsDef} />
  ) : (
    <NoDataCardMessage />
  );
};

StoreDetail.propTypes = {
  match: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    shopOrdersRequested: PropTypes.func,
  }).isRequired,
  orders: PropTypes.array,
  isPeriodLongerThanOneMonth: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isOrdersExist: PropTypes.bool.isRequired,
  errors: PropTypes.instanceOf(Error),
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

StoreDetail.defaultProps = {
  orders: [],
  isPeriodLongerThanOneMonth: false,
  errors: null,
};

export default connect(StoreDetail);
