import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTime } from '../../../../../components/OrderEvent/Table/Cells';
import {
  Body as TableBody,
  Cell,
  CollapsibleCell,
  CollapsibleRow,
  Container,
  DateTimeCell,
  Header as TableHeader,
  HeaderCell,
  HeaderRow,
  Row,
  Table as BaseTable,
} from '../../../../../components/OrderEvent/Table/elements';
import { Order } from '../../../../../modules/OrderOMS/types';
import { JSONViewer } from '../elements';

import { Event } from './Event';

type Props = {
  order: Order;
  isDetailedToggle: boolean;
};

export const Table: FC<Props> = ({ order, isDetailedToggle }) => {
  const [t] = useTranslation();
  const [uuids, setUuids] = useState([]);

  const isRowOpen = (uuid: string) => {
    return uuids.includes(uuid);
  };

  const deviceUuids: { licenseName: string; deviceUUID: string }[] = [];
  order?.events.forEach(event => {
    const deviceUUID = event.header?.sourceInformation?.device?.deviceUUID;
    const licenseName = event.header?.sourceInformation?.device?.licenseName;

    if (!deviceUuids.find(d => d.deviceUUID === deviceUUID)) {
      deviceUuids.push({ deviceUUID, licenseName });
    }
  });

  return (
    <Container>
      <BaseTable>
        <TableHeader>
          <HeaderRow>
            <HeaderCell as={DateTimeCell} scope="col">
              {t('order_management.date')}
            </HeaderCell>
            <HeaderCell as={DateTimeCell} scope="col">
              {t('order_management.fields.uuid')}
            </HeaderCell>
            {deviceUuids.map(({ deviceUUID, licenseName }) => (
              <HeaderCell scope="col" key={deviceUUID}>
                {licenseName}
              </HeaderCell>
            ))}
          </HeaderRow>
        </TableHeader>
        <TableBody>
          {order.events.map(event => (
            <Fragment key={event.header.uuid}>
              <Row
                onClick={() => {
                  const eventUuid = event.header.uuid;
                  setUuids(
                    uuids.includes(eventUuid)
                      ? uuids.filter(u => u !== eventUuid)
                      : uuids.concat(eventUuid),
                  );
                }}
                isOpen={false}
              >
                <DateTimeCell key="happenedOn">
                  <DateTime date={event.header.generatedAt} />
                </DateTimeCell>
                <DateTimeCell key="uuid">{event.header.uuid.split('-')[0]}</DateTimeCell>
                {deviceUuids.map(({ deviceUUID }) =>
                  deviceUUID === event.header.sourceInformation.device.deviceUUID ? (
                    <Cell key={deviceUUID}>
                      <Event
                        event={event}
                        isOpen={isDetailedToggle || isRowOpen(event.header.uuid)}
                      />
                    </Cell>
                  ) : (
                    <Cell key={deviceUUID}></Cell>
                  ),
                )}
              </Row>
              {(isDetailedToggle || isRowOpen(event.header.uuid)) && (
                <CollapsibleRow>
                  <CollapsibleCell colSpan={deviceUuids.length + 2}>
                    <JSONViewer name="event" src={event} collapsed={true} />
                  </CollapsibleCell>
                </CollapsibleRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </BaseTable>
    </Container>
  );
};
