import Theme from '../../../Theme';

import rollbackOrderLineWasAdded from './strategies/rollbackOrderLineWasAdded';
import DiscountWasAddedToProduct from './DiscountWasAddedToProduct';
import OrderLineWasAdded from './OrderLineWasAdded';
import OrderLineWasRemoved from './OrderLineWasRemoved';
import OrderWasClosed from './OrderWasClosed';
import OrderWasOpened from './OrderWasOpened';
import PaymentWasAddedToOrder from './PaymentWasAddedToOrder';
import ProductQuantityWasUpdated from './ProductQuantityWasUpdated';

export const COLORS = {
  ADMIN: Theme.palette.failure.default,
  ORDER: Theme.palette.primary.default,
  ORDERLINE: Theme.palette.success.default,
  PAYMENT: Theme.palette.warning.default,
  INFO: Theme.palette.mediumGrey,
};

export const CATEGORIES = Object.keys(COLORS);

const EventDefinitions = {
  CanceledOrderWasPrinted: { category: 'INFO' },
  CommentWasAddedToProduct: { category: 'INFO' },
  CoversNumberWasUpdated: { category: 'INFO' },
  CustomerWasAssigned: { category: 'INFO' },
  CustomerWasUnassigned: { category: 'INFO' },
  DiscountWasAddedToOrder: { category: 'ORDERLINE' },
  DiscountWasAddedToProduct: {
    category: 'ORDERLINE',
    definitions: DiscountWasAddedToProduct,
  },
  DiscountWasRemovedFromOrder: { category: 'ORDERLINE' },
  DiscountWasRemovedFromProduct: { category: 'ORDERLINE' },
  EmailTicketWasSent: { category: 'INFO' },
  EmptySendingsWereCleared: { category: 'INFO' },
  ExpenseReceiptWasPrinted: { category: 'INFO' },
  MealWasAddedToOrder: { category: 'ORDERLINE' },
  MealWasDissociated: { category: 'ORDERLINE' },
  NextMealWasSentToKitchen: { category: 'INFO' },
  OptionWasAdded: { category: 'ORDERLINE' },
  OptionWasRemoved: { category: 'ORDERLINE' },
  OrderLineParentWasChanged: { category: 'ORDERLINE' },
  OrderLineWasAdded: {
    category: 'ORDERLINE',
    definitions: OrderLineWasAdded,
    rollback: rollbackOrderLineWasAdded,
  },
  OrderLineWasRemoved: {
    category: 'ORDERLINE',
    definitions: OrderLineWasRemoved,
  },
  OrderLineWasTransferredToOrder: { category: 'ORDERLINE' },
  OrderWasCanceled: { category: 'ORDER' },
  OrderWasClosed: {
    category: 'ORDER',
    definitions: OrderWasClosed,
  },
  OrderWasOpened: {
    category: 'ORDER',
    definitions: OrderWasOpened,
  },
  OrderWasPrinted: { category: 'INFO' },
  OrderWasRenamed: { category: 'INFO' },
  OrderWasTransferred: { category: 'ORDER' },
  OrderWasTransferredToTable: { category: 'INFO' },
  OrderWasTransferredToWaiter: { category: 'INFO' },
  PaymentTypeWasUpdated: { category: 'INFO' },
  PaymentWasAddedToOrder: {
    category: 'PAYMENT',
    definitions: PaymentWasAddedToOrder,
  },
  PaymentWasCanceled: { category: 'PAYMENT' },
  PaymentWasRefused: { category: 'PAYMENT' },
  PaymentWasTransferredToOrder: { category: 'PAYMENT' },
  PrintInfoWasAdded: { category: 'INFO' },
  ProductQuantityWasUpdated: {
    category: 'ORDERLINE',
    definitions: ProductQuantityWasUpdated,
  },
  ProductSendingWasChanged: { category: 'INFO' },
  ProductWasAddedToMeal: { category: 'ORDERLINE' },
  ProductWasRemovedFromMeal: { category: 'ORDERLINE' },
  SendingClaimWasCanceled: { category: 'INFO' },
  SendingWasAdded: { category: 'INFO' },
  SendingWasClaimed: { category: 'INFO' },
  SendingWasRecalled: { category: 'INFO' },
  SendingWasTransferred: { category: 'INFO' },
  TableWasUnassignedFromOrder: { category: 'INFO' },
  WaiterWasUnassignedFromOrder: { category: 'INFO' },
  OrderLineWasAddedByAdmin: { category: 'ADMIN' },
  PaymentWasAddedByAdmin: { category: 'ADMIN' },
  OrderWasClosedByAdmin: { category: 'ADMIN' },
  OrderWasCanceledByAdmin: { category: 'ADMIN' },
};

export const getColorForType = type =>
  EventDefinitions[type] ? COLORS[EventDefinitions[type].category] : COLORS.INFO;

export const getTypesFromCategory = category =>
  Object.entries(EventDefinitions)
    .filter(([, properties]) => properties.category === category)
    .map(([type]) => type);

export default EventDefinitions;
