import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TimePart = styled.span``;
const TimeMSPart = styled.span``;
const DatePart = styled.span``;

const DateTimeCellContainer = styled.div`
  display: inline-flex;
  flex-direction: column;

  ${TimePart} {
    font-weight: 600;
  }

  ${TimeMSPart} {
    font-weight: normal;
    font-size: 0.8em;
  }

  ${DatePart} {
    font-weight: normal;
    color: ${({ theme: { palette } }) => palette.darkGrey};
  }
`;

const DateTimeCell = ({ date }) => {
  const luxonDate = DateTime.fromISO(date);
  return (
    <DateTimeCellContainer title={luxonDate.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}>
      <TimePart>
        {luxonDate.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
        <TimeMSPart>{luxonDate.toFormat('.u')}</TimeMSPart>
      </TimePart>
      <DatePart>{luxonDate.toLocaleString(DateTime.DATE_SHORT)}</DatePart>
    </DateTimeCellContainer>
  );
};

DateTimeCell.propTypes = {
  date: PropTypes.string,
};

export default DateTimeCell;
