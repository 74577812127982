/**
 * @typedef {Object} Country
 * @property {string} code
 * @property {string} translation_key
 */

/**
 * Retrieve list of countries
 *
 * @return {Promise<Country[]>} an array of countries
 */
export const retrieveCountries = async () =>
  Promise.resolve([
    { countryCode: 'FR', translation_key: 'model.country.fr' },
    { countryCode: 'SP', translation_key: 'model.country.es' },
    { countryCode: 'IT', translation_key: 'model.country.it' },
  ]);
