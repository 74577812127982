/* eslint-disable no-underscore-dangle */

import OrderEvent from './OrderEvent';

const ADMIN_DEVICE_ID = -1;
const ADMIN_DEVICE_UUID = '00000000-0000-4000-8000-000000000001';

const APP_NAME = process.env.APP_NAME;
const APP_VERSION = process.env.APP_VERSION;

export default class AdminOrderEvent extends OrderEvent {
  constructor(data) {
    super(data);
    this._syncStatus = 'draft';
  }

  static create(shopID, rootUUID, event, source) {
    const data = {
      shopID: shopID,
      rootUUID: rootUUID,
      uuid: event.uuid, // Comes directly from Action creator
      deviceID: ADMIN_DEVICE_ID,
      deviceUUID: ADMIN_DEVICE_UUID,
      playhead: null,
      type: event.type,
      recordedOn: null,
      happenedOn: Date.now(),
      payload: event.payload,
      source: {
        appName: APP_NAME,
        appVersion: APP_VERSION,
        device: {
          id: -1,
          identifier: 'ADMIN',
        },
        ...source,
      },
    };

    return new AdminOrderEvent(data);
  }

  parsePayload(payload) {
    return payload || {};
  }

  parseSource(source) {
    return source || {};
  }

  setPlayhead(playhead) {
    this.playhead = playhead;
  }

  setSyncStatus(status) {
    this._syncStatus = status;
  }

  getSyncStatus() {
    return this._syncStatus;
  }

  serialize() {
    return {
      uuid: this.uuid,
      rootUUID: this.rootUUID,
      date: this.happenedOn.toISO(),
      deviceID: this.deviceID,
      deviceUUID: this.deviceUUID,
      playhead: this.playhead,
      type: this.type,
      data: this.payload,
      source: this.source,
    };
  }
}
