import { formatNumber } from '@tillersystems/stardust';

import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';

import BaseEvent from './BaseEvent';

const OrderWasClosed = [
  ...BaseEvent,
  {
    name: 'separator',
    isSeparator: true,
  },
  {
    name: 'sequentialNumber',
    label: i18n.t('order_management.fields.sequentialNumber'),
    value: item => item.payload.sequentialNumber,
    Component: DefinitionList.Cells.TextField,
  },
  {
    name: 'orderLines',
    label: i18n.t('order_management.fields.orderLines'),
    value: item => item.payload.taxInformation && item.payload.taxInformation.orderLinesTaxes,
    Component: DefinitionList.Cells.Table,
    options: {
      colsDef: [
        {
          name: 'lineUUID',
          title: i18n.t('order_management.fields.orderLineUUID'),
          value: d => d.lineUUID,
        },

        {
          name: 'taxRate',
          title: i18n.t('order_management.fields.tax'),
          value: d => ({ tax: d.taxRate, taxIdentifier: d.taxIdentifier }),
          format: v => `${v.taxIdentifier} - ${v.tax.toFixed()}%`,
        },
        {
          name: 'priceDiscount',
          title: i18n.t('order_management.fields.priceDiscount'),
          value: d => d.priceDiscount,
          format: v => formatNumber({ locale: i18n.language, number: v }),
        },
        {
          name: 'priceTaxCollected',
          title: i18n.t('order_management.fields.priceTaxCollected'),
          value: d => d.priceTaxCollected,
          format: v => formatNumber({ locale: i18n.language, number: v }),
        },
        {
          name: 'priceTaxExcl',
          title: i18n.t('order_management.fields.priceTaxExcl'),
          value: d => d.priceTaxExcl,
          format: v => formatNumber({ locale: i18n.language, number: v }),
        },
        {
          name: 'priceTaxIncl',
          title: i18n.t('order_management.fields.priceTaxIncl'),
          value: d => d.priceTaxIncl,
          format: v => formatNumber({ locale: i18n.language, number: v }),
        },
      ],
    },
    hideEmpty: true,
  },
  {
    name: 'totalDiscount',
    label: i18n.t('order_management.fields.totalDiscount'),
    value: item => item.payload.taxInformation && item.payload.taxInformation.totalDiscount,
    Component: DefinitionList.Cells.NumberField,
  },
  {
    name: 'totalTaxExcl',
    label: i18n.t('order_management.fields.totalTaxExcl'),
    value: item => item.payload.taxInformation && item.payload.taxInformation.totalTaxExcl,
    Component: DefinitionList.Cells.NumberField,
  },
  {
    name: 'totalTaxIncl',
    label: i18n.t('order_management.fields.totalTaxIncl'),
    value: item => item.payload.taxInformation && item.payload.taxInformation.totalTaxIncl,
    Component: DefinitionList.Cells.NumberField,
  },
];

export default OrderWasClosed;
