import { Translation } from 'react-i18next';
import Media from 'react-media';
import styled from 'styled-components';

import { Avatar, Icon, Logo, Theme } from '@tillersystems/stardust';

import { useAuth } from '../../contexts/Auth';
import { SideNav } from '..';

import { DisconnectWrapper, UserName } from './elements';

const SideBar = ({ className }) => {
  const { isAuthenticated, user, signOut } = useAuth();

  return isAuthenticated ? (
    <div className={className}>
      <SideNav>
        <SideNav.Header>
          <Media query={{ maxWidth: Theme.breakpoints.lg }}>
            {matches =>
              matches ? (
                <Icon color="white" name="tiller" width="32" height="32" />
              ) : (
                <Logo width="120" height="28" />
              )
            }
          </Media>
        </SideNav.Header>

        <SideNav.Body>
          <Translation>
            {t => (
              <>
                <SideNav.Link
                  to="/"
                  activeOnlyWhenExact
                  title={t('navigation.home')}
                  icon={
                    <Icon color={Theme.palette.white} name="home" width="1.4rem" height="1.4rem" />
                  }
                />
                <SideNav.Link
                  to="/reports"
                  title="Reports"
                  icon={
                    <Icon
                      color={Theme.palette.white}
                      name="activity"
                      width="1.4rem"
                      height="1.4rem"
                    />
                  }
                />
                <SideNav.Link
                  to="/releases"
                  title="Releases"
                  icon={
                    <Icon
                      color={Theme.palette.white}
                      name="github"
                      width="1.4rem"
                      height="1.4rem"
                    />
                  }
                />
                <SideNav.Link
                  to="/settings"
                  activeOnlyWhenExact
                  title={t('navigation.settings')}
                  icon={
                    <Icon
                      color={Theme.palette.white}
                      name="settings"
                      width="1.4rem"
                      height="1.4rem"
                    />
                  }
                />
                <SideNav.Link
                  to="/ops"
                  activeOnlyWhenExact
                  title={t('navigation.ops')}
                  icon={
                    <Icon color={Theme.palette.white} name="tag" width="1.4rem" height="1.4rem" />
                  }
                />
                <SideNav.Link
                  to="/payment-methods"
                  activeOnlyWhenExact
                  title={t('navigation.payment_methods')}
                  icon={
                    <Icon color={Theme.palette.white} name="euro" width="1.4rem" height="1.4rem" />
                  }
                />
                <SideNav.Link
                  to="/order-management"
                  title={t('navigation.order_management')}
                  icon={
                    <Icon color={Theme.palette.white} name="list" width="1.4rem" height="1.4rem" />
                  }
                />
                <SideNav.Link
                  to="/data-integrity"
                  title={t('navigation.data_integrity')}
                  icon={
                    <Icon
                      color={Theme.palette.white}
                      name="check-circle"
                      width="1.4rem"
                      height="1.4rem"
                    />
                  }
                />
                <SideNav.Link
                  to="/appmarket"
                  title={t('navigation.appmarket')}
                  icon={
                    <Icon color={Theme.palette.white} name="plus" width="1.4rem" height="1.4rem" />
                  }
                />
                <SideNav.Link
                  to="/features"
                  title={t('navigation.features')}
                  icon={
                    <Icon color={Theme.palette.white} name="plus" width="1.4rem" height="1.4rem" />
                  }
                />
                <SideNav.Link
                  to="/migration/goodtill"
                  title={t('navigation.migration')}
                  icon={
                    <Icon color={Theme.palette.white} name="plus" width="1.4rem" height="1.4rem" />
                  }
                />
              </>
            )}
          </Translation>
        </SideNav.Body>

        {user && (
          <SideNav.Footer>
            <Avatar size={2.5} name={user.name} src={user.picture} />
            <UserName>{user.name}</UserName>
            <DisconnectWrapper onClick={signOut}>
              <Icon color="white" name="power-off" width="1.4rem" height="1.4rem" />
            </DisconnectWrapper>
          </SideNav.Footer>
        )}
      </SideNav>
    </div>
  ) : null;
};

export default styled(SideBar)`
  grid-area: sidebar;

  display: flex;
  flex-direction: column;

  background: ${({ theme: { palette } }) => palette.darkBlue};
`;
