import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { formatNumber, Icon, Tag, Theme } from '@tillersystems/stardust';

import { OrderLine } from '../../../../modules/OrderOMS/types';
import { DONE } from '../helpers';

import {
  CardTitle,
  CollapsibleCell,
  CollapsibleRow,
  HeaderCell,
  OrderLinesTitle,
  Row,
  Table,
  TableHeader,
  Wrapper,
} from './elements';

type Props = {
  currency: string;
  orderLines: OrderLine[];
};

const OrderLines: FC<Props> = ({ orderLines, currency }) => {
  const [t] = useTranslation();
  const [openRows, setOpenRows] = useState([]);

  const isRowOpen = (uuid: string) => openRows.includes(uuid);

  const handleRowClick = ({ uuid }) => {
    setOpenRows(
      openRows.includes(uuid) ? openRows.filter(rows => rows !== uuid) : openRows.concat([uuid]),
    );
  };

  return (
    <Wrapper>
      <OrderLinesTitle>
        <CardTitle>{t('order_management.order_lines.title')}</CardTitle>
      </OrderLinesTitle>
      <Table>
        <TableHeader>
          <tr>
            <HeaderCell scope="col" style={{ textAlign: 'left' }}>
              {t('order_management.order_lines.product_name')}
            </HeaderCell>
            <HeaderCell scope="col">{t('order_management.order_lines.type')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.order_lines.initial_price')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.order_lines.price')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.order_lines.vat')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.order_lines.status')}</HeaderCell>
            <HeaderCell scope="col"></HeaderCell>
          </tr>
        </TableHeader>
        <tbody>
          {orderLines.map(orderLine => (
            <Fragment key={orderLine.uuid}>
              <Row onClick={() => handleRowClick(orderLine)} isOpen={isRowOpen(orderLine.uuid)}>
                <td>{orderLine.name}</td>
                <td>{orderLine.type}</td>
                <td>
                  {formatNumber({
                    currency,
                    locale: 'fr',
                    number: orderLine.price.tax_included,
                  })}
                </td>
                <td>
                  {formatNumber({
                    currency,
                    locale: 'fr',
                    number: orderLine.price.tax_excluded,
                  })}
                </td>
                <td>
                  {formatNumber({
                    currency,
                    locale: 'fr',
                    number: orderLine.tax,
                  })}
                </td>
                <td>
                  <Tag color={orderLine.status === DONE ? Theme.palette.success.default : 'orange'}>
                    {orderLine.status}
                  </Tag>
                </td>
                <td>
                  <Icon
                    name={`chevron-${isRowOpen(orderLine.uuid) ? 'top' : 'down'}`}
                    color={Theme.palette.darkBlue}
                    size="25px"
                  />
                </td>
              </Row>
              {isRowOpen(orderLine.uuid) && (
                <CollapsibleRow>
                  <td colSpan={8}>
                    <Table>
                      <TableHeader>
                        <tr>
                          <th scope="col">{t('order_management.order_lines.external_id')}</th>
                          <th scope="col">{t('order_management.order_lines.date')}</th>
                          <th scope="col">{t('order_management.order_lines.product_id')}</th>
                        </tr>
                      </TableHeader>
                      <tbody>
                        <tr>
                          <CollapsibleCell>{orderLine.uuid}</CollapsibleCell>
                          <CollapsibleCell>
                            {DateTime.fromISO(orderLine.date).toLocaleString(
                              DateTime.DATETIME_SHORT,
                            )}
                          </CollapsibleCell>
                          <CollapsibleCell>{orderLine.productId}</CollapsibleCell>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </CollapsibleRow>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default OrderLines;
