import styled from 'styled-components';

import { Modal } from '@tillersystems/stardust';

export const Header = styled(Modal.Header)`
  font-size: 2.5rem;
  height: initial;
  padding-bottom: 0.5rem;
`;

export const Body = styled(Modal.Body)`
  padding: 1.5rem 0;
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Footer = styled(Modal.Footer)`
  height: initial;
  justify-content: flex-end;
`;
export const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin: 2rem 0 3rem;
`;

export const TableHeader = styled.div`
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.veryLightBlue};
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  display: flex;
  height: 7.4rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`;

export const TableContainer = styled.div`
  margin: -1.5rem -2rem;
`;

export const Bold = styled.div`
  font-weight: 700;
`;

export const JiraLink = styled.a`
  color: ${({ theme: { palette } }) => palette.primary.default};
  text-decoration: underline;
  padding-left: 0.8rem;
`;

export const JiraLinkContainer = styled.span`
  display: inline-flex;
`;

export const TableBody = styled.div`
  min-height: 10rem;
  position: relative;
`;

export const CenterItem = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BetaDetails = styled.div`
  > div:first-of-type {
    padding: 0 0 0.5rem 0;
  }
  > div {
    padding: 0.5rem 0;
  }
  > div:last-of-type {
    padding: 0.5rem 0 0 0;
  }
`;

export const BetaDetailsRight = styled(BetaDetails)`
  padding-left: 1.2rem;
`;

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 0.5rem;
  }
`;

export const JiraModalLink = styled(JiraLink)`
  padding: 0 0.6rem;
  :hover {
    text-decoration: none;
  }
`;

export const StyledDate = styled.div`
  padding-top: 0.2rem;
  color: ${({ theme: { palette } }) => palette.darkGrey};
`;
