import styled from 'styled-components';

export const ErrorMsg = styled.div`
  padding: 1.5rem 3rem;
`;
export const LoaderContainer = styled.div`
  padding: 3rem 0;
  display: flex;
  justify-content: center;
`;
