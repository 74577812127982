import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Select } from '@tillersystems/stardust';

const SelectField = ({ className, name, error, setValue, options, value }) => {
  const { fluid = true, ...rest } = options;

  const handleChange = values => {
    return setValue(values[0]);
  };

  return (
    <Select
      className={className}
      id={name}
      onChange={handleChange}
      values={[value]}
      error={!!error}
      fluid={fluid}
      contentWrapperStyle={{ zIndex: 100 }}
      usePortal
      {...rest}
    />
  );
};

/** Prop types. */
SelectField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.object,
  value: PropTypes.any,
};

/** Default prop types. */
SelectField.defaultProps = {
  className: null,
  options: {},
  value: null,
  error: null,
};

export default styled(SelectField)`
  width: 100%;
`;
