import { capitalize } from 'lodash';

/**
 * Gets a pretty app name from the given app code.
 *
 * @param {string} code - The app code.
 *
 * @returns {string} - A pretty app name.
 */
export const getPrettyAppName = code =>
  code
    .split('_')
    .map(s => capitalize(s))
    .join(' ');
