import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Input,
  ListItemGroup,
  spacing,
  SubHeadline,
  useNotificationToast,
} from '@sumup/circuit-ui';
import { Close, Download } from '@sumup/icons';

import StatusIcon from './StatusIcon';
import { FeatureActivation } from './types';
import { useFeatureActivation } from './useFeatureActivation';
import {
  extractValidfeatureActivations as extractValidFeatureActivations,
  parsefeatureActivationsFile,
} from './utils';

const MultiStore: React.FC = () => {
  const { setToast } = useNotificationToast();
  const { t } = useTranslation();
  const [featureActivations, setFeatureActivations] = useState<FeatureActivation[]>([]);

  const featureActivationFileRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);

  const { isLoading, status, subscribe, mutate, clear } = useFeatureActivation({
    onSuccess: () => {
      setToast({
        body: t('features.featureActivation.multiStore.success.message'),
        variant: 'confirm',
      });
    },
    onError: () => {
      setToast({
        body: t('features.featureActivation.multiStore.error.message'),
        variant: 'alert',
      });
    },
  });

  const handleInputFile: React.FormEventHandler<HTMLInputElement & HTMLTextAreaElement> = () => {
    const file = featureActivationFileRef.current.files.item(0);

    parsefeatureActivationsFile(file, ({ data, errors }) => {
      const validFeatureActivations = extractValidFeatureActivations(data);
      setFeatureActivations(validFeatureActivations);
      subscribe(validFeatureActivations);

      if (errors.length) {
        setToast({
          body: t('features.featureActivation.multiStore.partial.message', {
            errors: errors.length,
            lines: errors.map(({ row }) => row).join(', '),
          }),
          variant: 'notify',
        });
      }
    });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    if (!featureActivations.length) {
      setToast({
        body: t('features.featureActivation.multiStore.empty.message'),
        variant: 'notify',
      });
      return;
    }

    mutate(featureActivations);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <CardHeader>
          <SubHeadline as="h4">{t('features.featureActivation.multiStore.title')}</SubHeadline>
        </CardHeader>
        <Body>
          <Input
            ref={featureActivationFileRef}
            type="file"
            label={t('features.featureActivation.action.select.label')}
            name="featureActivationFile"
            accept="text/csv"
            onInput={handleInputFile}
            disabled={isLoading}
          />
          <ListItemGroup
            label={t('features.featureActivation.list.title.label')}
            variant="plain"
            details={t('features.featureActivation.list.title.trailingLabel')}
            items={
              status.length
                ? status.map(({ featureCode, storeId, status }, index) => ({
                    key: index,
                    variant: 'action',
                    label: featureCode,
                    prefix: <StatusIcon status={status} />,
                    suffixLabel: storeId,
                  }))
                : [{ key: 0, label: t('features.featureActivation.list.empty.label') }]
            }
          />
        </Body>
        <CardFooter>
          <Button
            icon={Close}
            type="reset"
            variant="secondary"
            onClick={() => {
              setFeatureActivations([]);
              clear();
            }}
            disabled={isLoading}
            css={spacing({ right: 'kilo' })}
          >
            {t('features.featureActivation.action.clear.label')}
          </Button>
          <Button
            icon={Download}
            loadingLabel={t('features.featureActivation.action.import.loadingLabel')}
            isLoading={isLoading}
            type="submit"
            variant="primary"
            disabled={!status.length || isLoading}
          >
            {t('features.featureActivation.action.import.label')}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default MultiStore;
