import { ConvertedRawTicket, RawTicket } from './types';

export const convertRawTicket = (rawTicket?: RawTicket): ConvertedRawTicket => {
  if (!rawTicket) {
    return null;
  }

  return {
    id: rawTicket.uuid,
    date: rawTicket.date.raw,
    currency: rawTicket.order.currency.code,
    orderLines: rawTicket.lines.map(
      ({ uuid, status, name, quantity: { count }, prices, type, open_date, item }) => ({
        uuid,
        status,
        count,
        name,
        type,
        productId: item.id,
        date: open_date.raw,
        price: {
          tax_included: prices.item.initial_price.value,
          tax_excluded: prices.item.tax_excluded.value || prices.item.tax_excluded.raw,
        },
        tax: prices.item.vat_amount.value || prices.item.vat_amount.raw,
        total: prices.total.price.value,
        taxIdentifier: prices.item.vat.length ? prices.item.vat[0].identifier : '',
      }),
    ),
    taxes: rawTicket.taxes.map(({ identifier, rate, tax_excluded, tax_amount, tax_included }) => ({
      key: `${tax_excluded.value}${tax_included.value}`,
      identifier,
      rate,
      taxExcluded: tax_excluded.value || tax_excluded.raw,
      taxAmount: tax_amount.value || tax_amount.raw,
      taxIncluded: tax_included.value || tax_included.raw,
    })),
    total: {
      taxExcluded:
        rawTicket.summary.total_tax_excluded.value || rawTicket.summary.total_tax_excluded.raw,
      taxIncluded:
        rawTicket.summary.total_tax_included.value || rawTicket.summary.total_tax_included.raw,
    },
    payments: rawTicket.payments.map(
      ({ uuid, status, type, label, source, amount, date, identifier }) => ({
        uuid,
        id: identifier,
        status,
        type,
        label,
        date: date.raw,
        deviceName: source.device.name,
        price: amount.value || amount.raw,
      }),
    ),
    signature: rawTicket.metadata.signature.ticket_signature,
    version: rawTicket.version,
  };
};
