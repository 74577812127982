import { Link } from 'react-router-dom';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Container = styled(Link)`
  display: grid;
  grid-template-columns: 1.8rem auto;
  grid-column-gap: 1.5rem;
  grid-template-areas: 'icon title';

  padding: ${({ theme: { dimensions } }) => dimensions.medium};

  color: ${({ theme: { palette } }) => palette.lightGrey};
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
  letter-spacing: ${({ theme: { fonts } }) => fonts.spacing.small};

  cursor: pointer;

  border-bottom: 1px solid ${({ theme: { palette } }) => palette.clay};
  border-left: 2px solid
    ${({ active, theme: { palette } }) => (active ? palette.primary.default : 'transparent')};

  background: ${({ active }) => (active ? 'hsla(0, 0%, 0%, 0.2);' : 'transparent')};

  &:first-child {
    border-top: 1px solid ${({ theme: { palette } }) => palette.clay};
  }

  /* For small devices */
  ${breakpoint('xs', 'md')`
    grid-template-columns: 1.8rem;
    grid-template-areas: 'icon' 'title';
    justify-items: center;
    align-items: center;
    justify-content: center;

    border-left: none;
    border-top: 2px solid
      ${({ active, theme: { palette } }) => (active ? palette.primary.default : 'transparent')};
    &:first-child {
      border-top: 2px solid
        ${({ active, theme: { palette } }) => (active ? palette.primary.default : 'transparent')};
      border-bottom: none;
    }

    padding: 15px 0;
  `};

  /* For medium devices */
  ${breakpoint('md', 'lg')`
    grid-template-columns: auto;
    grid-template-areas: 'icon';
    justify-items: center;
    align-items: center;
    justify-content: center;
  `};
`;

export const Icon = styled.div`
  grid-area: icon;
  display: flex;
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
`;

export const Title = styled.span`
  grid-area: title;
  align-self: center;
`;
