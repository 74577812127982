import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useField } from 'react-form';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { FieldContainer, FieldContent, FieldError, FieldLabel } from './elements';

const Field = ({ definition, onBlurCallback }) => {
  const fieldOptions = useMemo(() => definition.fieldOptions || {}, [definition]);

  const { value, meta, fieldName, setValue, runValidation } = useField(
    definition.fieldPath,
    fieldOptions,
  );

  const [fieldValue, setFieldValue] = useState(value);

  const { error } = meta;

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const setFormValue = useCallback(
    debounce(fieldValue => setValue(fieldValue), 1000),
    [],
  );

  const onChange = useCallback(value => {
    setFieldValue(value);
    setFormValue(value);
  }, []);

  const onBlur = useCallback(() => {
    setValue(fieldValue);
    if (Number.isInteger(fieldValue) && onBlurCallback) {
      onBlurCallback(fieldValue);
    }
  }, [fieldValue]);

  const field = useMemo(
    () => (
      <definition.Field
        value={fieldValue}
        name={fieldName}
        error={error}
        onBlur={onBlur}
        options={definition.options}
        onChange={onChange}
        setValue={setValue}
        runValidation={runValidation}
      />
    ),
    [definition, fieldValue, error],
  );

  return (
    <FieldContainer>
      <FieldLabel error={error} htmlFor={fieldName}>
        {definition.label}
      </FieldLabel>
      <FieldContent>
        {field}
        <FieldError>{error}</FieldError>
      </FieldContent>
    </FieldContainer>
  );
};

Field.propTypes = {
  definition: PropTypes.shape({
    fieldPath: PropTypes.string.isRequired,
    fieldOptions: PropTypes.object,
    options: PropTypes.object,
    label: PropTypes.string,
  }).isRequired,
  onBlurCallback: PropTypes.func,
};

Field.defaultProps = {
  onBlurCallback: null,
};

export default Field;
