import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { Headline, Spinner, Table } from '@sumup/circuit-ui';
import { DateTime } from 'luxon';

import { headers, toTableRow } from './config';
import { Pager } from './Pager';
import { getIntegrationRequests } from './services';

const IntegrationRequestList = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    'integrationRequests',
    ({ pageParam }) =>
      getIntegrationRequests({
        to: DateTime.now().toISO(),
        paginate: pageParam ? btoa(JSON.stringify({ lastEvaluatedKey: pageParam })) : undefined,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: lastPage => lastPage?.lastEvaluatedKey,
      onSuccess: ({ pages }) => {
        const currentPage = pages.length - 1;
        setCurrentPage(currentPage);
        setLastPage(currentPage);
      },
    },
  );

  return (
    <>
      <Headline as="h1" size="two">
        {t('app_market.integration_requests_title')}
      </Headline>
      {isLoading ? (
        <Spinner size="giga" />
      ) : (
        <>
          <Pager
            currentPage={currentPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isFetchingNextPage}
            lastPage={lastPage}
            setCurrentPage={setCurrentPage}
          />
          <Table
            headers={headers}
            rows={data.pages[currentPage].data.map(toTableRow(path))}
            condensed
          />
          <Pager
            currentPage={currentPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isFetchingNextPage}
            lastPage={lastPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </>
  );
};

export default IntegrationRequestList;
