import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Loader, Message, Modal, Table } from '@tillersystems/stardust';

import Theme from '../../../../components/Theme';
import { deleteEnvironment, getEnvironments } from '../../service';

import { colsDef } from './config';
import {
  Body,
  Bold,
  CenterItem,
  DeletContainer,
  Footer,
  Header,
  TableBody,
  TableContainer,
  TableHeader,
  TopMarginMessage,
} from './elements';

const PinguiManager = () => {
  const [environments, setEnvironments] = useState([]);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [isModalConfigOpen, setIsModalConfigOpen] = useState(false);
  const [tillerBackBranchName, setTillerBackBranchName] = useState('');
  const [tillerOrderApiBranchName, setTillerOrderApiBranchName] = useState('');
  const [environmentId, setEnvironmentId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEnvironmentLoading, setIsEnvironmentLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const [t] = useTranslation();

  useEffect(() => {
    loadEnvironments();
  }, []);

  const loadEnvironments = () => {
    setIsEnvironmentLoading(true);
    getEnvironments().then(envs => {
      setEnvironments([...envs]);
      setIsEnvironmentLoading(false);
    });
  };

  const handleInfoOnClick = environment => {
    setIsModalInfoOpen(true);
    const [tillerOrderApi, tillerBack] = environment.projects;
    setTillerBackBranchName(tillerBack.image.tag);
    setTillerOrderApiBranchName(tillerOrderApi.image.tag);
  };

  const handleConfigOnClick = environment => {
    setIsModalConfigOpen(true);
    const { id } = environment;
    setEnvironmentId(id);
  };

  const removeEnvironment = () => {
    setIsLoading(true);
    deleteEnvironment(environmentId).then(({ ok }) => {
      setIsLoading(false);
      setIsRequestSent(true);
      ok ? setIsSuccess(true) : setIsSuccess(false);
    });
  };

  return (
    <>
      <TableContainer>
        <TableHeader>
          {t('ops.pingui.environments')}
          <Button disabled={isEnvironmentLoading} appearance="primary" onClick={loadEnvironments}>
            {t('ops.pingui.reload')}
          </Button>
        </TableHeader>
        <TableBody>
          {isEnvironmentLoading ? (
            <CenterItem>
              <Loader width="6rem" height="6rem" />
            </CenterItem>
          ) : environments.length === 0 ? (
            <CenterItem>
              <Message description={t('ops.pingui.no_envs')} type="warning" />
            </CenterItem>
          ) : (
            <Table
              isScrollable
              data={environments}
              colsDef={colsDef(handleInfoOnClick, handleConfigOnClick)}
            />
          )}
        </TableBody>
      </TableContainer>
      <Modal active={isModalInfoOpen} onOverlayClick={() => setIsModalInfoOpen(false)}>
        <Header>{t('ops.pingui.branch_infos')}</Header>
        <Body>
          <div>
            {t('ops.pingui.tiller_back_branch')} <Bold>{tillerBackBranchName}</Bold>
          </div>
          <div>
            {t('ops.pingui.tiller_order_branch')} <Bold>{tillerOrderApiBranchName}</Bold>
          </div>
        </Body>
        <Footer>
          <Button appearance="secondary" onClick={() => setIsModalInfoOpen(false)}>
            {t('ops.pingui.close')}
          </Button>
        </Footer>
      </Modal>
      <Modal
        active={isModalConfigOpen}
        onOverlayClick={() => (isLoading ? null : setIsModalConfigOpen(false))}
      >
        <Header>{t('ops.pingui.env_actions')}</Header>
        <Body>
          <DeletContainer>
            {t('ops.pingui.delete_env')}
            <Button disabled={isLoading} appearance="failure" onClick={removeEnvironment}>
              {isLoading ? (
                <Loader width="2rem" height="2rem" color={Theme.palette.white} />
              ) : (
                t('ops.pingui.delete')
              )}
            </Button>
          </DeletContainer>
          {isRequestSent && (
            <TopMarginMessage
              description={
                isSuccess ? t('ops.pingui.delete_success') : t('ops.pingui.delete_error')
              }
              type={isSuccess ? 'success' : 'error'}
            />
          )}
        </Body>
        <Footer>
          <Button
            disabled={isLoading}
            appearance="secondary"
            onClick={() => setIsModalConfigOpen(false)}
          >
            {t('ops.pingui.close')}
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export default PinguiManager;
