import { persistReducer } from 'redux-persist';
import localeStorage from 'redux-persist/lib/storage';

/**
 * Config is only persisted for the user and the settings
 */
export const persistConfig = {
  key: 'root',
  storage: localeStorage,
  whitelist: ['settings'],
};

export default reducer => persistReducer(persistConfig, reducer);
