import React from 'react';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Cell, Grid } from 'styled-css-grid';

import { KpiBlock, KpiChart, Loader } from '@tillersystems/stardust';

import { withReport } from '../../../../modules/Report';
import { Section, Title } from '../../elements';
import { Row } from '../elements';

import UserTypeDistribution from './UserTypeDistribution';
import VariationHistory from './VariationHistory';
import VariationTable from './VariationTable';

/**
 * Defines the AppMarket's apps variation report.
 *
 * @param {boolean} reportLoaded - A value indicating whether the report is loaded or not.
 * @param {boolean} reportData - The data of the report.
 *
 * @returns {jsx}
 */
const AppsVariation = ({ reportLoaded, reportData }) => {
  const data = reportData && ['ok', 'warning'].includes(reportData.status) ? reportData : null;

  return (
    <Translation>
      {t => (
        <Section>
          <Title>{t('reports.appmarket.variation.title')}</Title>
          {reportLoaded ? (
            <>
              <Grid columns="repeat(auto-fit,minmax(20rem,1fr))" gap="2.3rem">
                <Cell>
                  <KpiBlock
                    title={t('reports.appmarket.variation.activated_apps')}
                    value={data ? data.activations_count.total.toString() : t('reports.no_data')}
                    variation={0}
                  />
                </Cell>
                <Cell>
                  <KpiBlock
                    title={t('reports.appmarket.variation.deactivated_apps')}
                    value={data ? data.deactivations_count.total.toString() : t('reports.no_data')}
                    variation={0}
                  />
                </Cell>
                <Cell>
                  <KpiBlock
                    title={t('reports.appmarket.variation.apps_variation')}
                    value={data ? data.variation.total.toString() : t('reports.no_data')}
                    variation={0}
                  />
                </Cell>
              </Grid>
              <Grid columns="1fr" gap="2.3rem">
                <Cell>
                  <KpiChart>
                    <KpiChart.Header>
                      <KpiChart.Title>
                        {t('reports.appmarket.variation.variation_history.title')}
                      </KpiChart.Title>
                    </KpiChart.Header>
                    <KpiChart.Body>
                      <VariationHistory data={data} />
                    </KpiChart.Body>
                  </KpiChart>
                </Cell>
              </Grid>
              <Grid columns="repeat(auto-fit,minmax(20rem,1fr))" gap="2.3rem">
                <Cell>
                  <KpiChart>
                    <KpiChart.Header>
                      <KpiChart.Title>
                        {t('reports.appmarket.variation.user_type_distribution.title')}
                      </KpiChart.Title>
                    </KpiChart.Header>
                    <KpiChart.Body>
                      <UserTypeDistribution data={data} />
                    </KpiChart.Body>
                  </KpiChart>
                </Cell>
                <Cell>
                  <KpiChart>
                    <KpiChart.Header>
                      <KpiChart.Title>
                        {t('reports.appmarket.variation.variation_table.title')}
                      </KpiChart.Title>
                    </KpiChart.Header>
                    <KpiChart.Body>
                      <VariationTable data={data} />
                    </KpiChart.Body>
                  </KpiChart>
                </Cell>
              </Grid>
            </>
          ) : (
            <>
              <Row>
                <Loader width="5rem" height="5rem" />
                <Loader width="5rem" height="5rem" />
                <Loader width="5rem" height="5rem" />
              </Row>
              <Row chart>
                <Loader width="5rem" height="5rem" />
              </Row>
              <Row chart>
                <Loader width="5rem" height="5rem" />
                <Loader width="5rem" height="5rem" />
              </Row>
            </>
          )}
        </Section>
      )}
    </Translation>
  );
};

/** Component's props type validation. */
const { bool, object } = PropTypes;
AppsVariation.propTypes = {
  reportLoaded: bool.isRequired,
  reportData: object,
};

/** Component's props default values. */
AppsVariation.defaultProps = {
  reportData: null,
};

export default withReport('app-market', 'variation')(AppsVariation);
