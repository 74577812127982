import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { create } from 'apisauce';
import PropTypes from 'prop-types';

import { Card } from '../../../components';
import mapToGithub from '../config';

import ReleaseLoading from './Releases/Release/Loading';
import { Block, Container, StyledError } from './elements';
import Releases from './Releases';

/* map location pathname slug to github project */
export function getUrlToReleases(projectName) {
  const normalizedProjectName = mapToGithub.get(projectName);
  return `/repos/tillersystems/${normalizedProjectName}/releases?access_token=${process.env.REACT_APP_GIT_HUB_TOKEN}`;
}

/**
 * Project
 *
 * This Container is in charge of fetching
 * a project from github and diaply it
 *
 * @param {object} match
 *
 * @return {jsx}
 */

const Project = ({
  match: {
    params: { projectName },
  },
}) => {
  const [t] = useTranslation();

  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetchProject();
  }, [projectName]);

  /**
   * Fetch Github repos releases
   * from github api v3
   */
  const fetchProject = async () => {
    // define the api
    const api = create({
      baseURL: process.env.REACT_APP_GIT_HUB_BASE_URL,
      headers: { Accept: 'application/vnd.github.v3+json' },
    });

    const { ok, data } = await api.get(getUrlToReleases(projectName));

    if (ok) {
      // The Api responded
      setProjectData(data);
      setLoading(false);
      setError(false);
    } else {
      // An error occured
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Container>
          {!loading && error && (
            <Block>
              <StyledError>{t('releases.project.error')}</StyledError>
            </Block>
          )}
          {!loading && !error && projectData && <Releases releasesData={projectData} />}
          {loading && !error && (
            <>
              <ReleaseLoading />
              <ReleaseLoading />
              <ReleaseLoading />
              <ReleaseLoading />
              <ReleaseLoading />
            </>
          )}
        </Container>
      </Card.Body>
    </Card>
  );
};

Project.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Project);
