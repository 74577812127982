import { useTranslation } from 'react-i18next';
import { Headline, Hr } from '@sumup/circuit-ui';

import MultiStore from './MultiStore';
import SingleStore from './SingleStore';

/**
 * Migrate GoodTill to POS Pro page.
 */
const GoodTillMigrationPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Headline as="h4">{t('migration.goodTillMigration.title')}</Headline>

      <SingleStore />
      <Hr />

      <MultiStore />
    </div>
  );
};

export default GoodTillMigrationPage;
