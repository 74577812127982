import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Release Loader
 *
 * This component is in charge of displaying
 * a loader for releases item
 *
 * @return {jsx}
 */
import { Body, Header, MarkdownLoading, NameLoading, PublishDateLoading } from './elements';

const Loading = ({ className }) => (
  <div className={className} data-testid="releaseLoader">
    <Header>
      <NameLoading />
      <PublishDateLoading />
    </Header>
    <Body>
      <MarkdownLoading />
      <MarkdownLoading />
      <MarkdownLoading />
    </Body>
  </div>
);

/**
 * PropTypes Validation
 */
const { string } = PropTypes;
Loading.propTypes = {
  className: string,
};

/**
 * Default props
 */
Loading.defaultProps = {
  className: '',
};

export default styled(Loading)`
  padding: 1.5rem 1rem;

  word-break: break-word;
`;
