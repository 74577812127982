import { combineReducers } from 'redux';

/**
 * Order reducer
 */
import detail from './Detail';
import events from './Events';

/**
 * Order root reducer
 *
 * @param {object} state initial or previous state of data
 * @param {object} action the action object
 *
 * @return {object}
 */
export default combineReducers({
  detail,
  events,
});
