import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  getEndDate,
  getStartDate,
  isPeriodLongerThanOneMonth,
} from '../../../modules/Period/selectors';
import * as ShopActions from '../../../modules/Shop/actions';
import {
  getErrorShop,
  getShopOrders,
  isLoadingShop,
  isOrdersExist,
} from '../../../modules/Shop/selectors';

/**
 * Part of the Redux global state
 * does what our component want
 */
const mapStateToProps = state => {
  return {
    isLoading: isLoadingShop(state),
    errors: getErrorShop(state),
    orders: getShopOrders(state),
    isOrdersExist: isOrdersExist(state),
    isPeriodLongerThanOneMonth: isPeriodLongerThanOneMonth(state),
    startDate: getStartDate(state),
    endDate: getEndDate(state),
  };
};

/**
 * Action creators that our component
 * want to receive by props
 */
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...ShopActions,
    },
    dispatch,
  ),
});

export default Component => withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
