import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card } from '..';

import MenuBody from './Body';
import MenuHeader from './Header';
import MenuLink from './Link';

/**
 * Menu
 *
 * This component is in charge of displaying
 * a menu
 *
 * @param {string} className // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {array} children // The result of the api call
 *
 * @return {jsx}
 */

class Menu extends PureComponent {
  static Header = MenuHeader;
  static Body = MenuBody;
  static Link = MenuLink;

  render() {
    const { children, className } = this.props;
    return <Card className={className}>{children}</Card>;
  }
}

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
Menu.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
Menu.defaultProps = {
  children: null,
  className: '',
};

export default styled(Menu)`
  width: 25rem;
  padding: 1.5rem 2rem;
`;
