import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Description, Title } from './elements';

const WrongIdMessage = () => {
  const [t] = useTranslation();

  return (
    <Container>
      <Title>{t(`order_management.no_data.wrong_id.title`)}</Title>
      <Description>{t(`order_management.no_data.wrong_id.description`)}</Description>
    </Container>
  );
};

export default WrongIdMessage;
