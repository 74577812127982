import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Modal } from '@tillersystems/stardust';

import { Body, Footer } from './elements';

const ConfirmButton = ({
  confirmLabel,
  width,
  height,
  translateOk,
  translateCancel,
  onClick,
  children,
  ...buttonProps
}) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(true), [setIsOpen]);
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleConfirm = useCallback(() => {
    if (onClick) {
      onClick(true);
    }
    setIsOpen(false);
  }, [onClick]);

  return (
    <>
      <Button {...buttonProps} onClick={handleClick}>
        {children}
      </Button>
      <Modal active={isOpen} width={width} height={height} onOverlayClick={handleClose}>
        <Body>{confirmLabel}</Body>
        <Footer>
          <Button appearance="secondary" onClick={handleClose}>
            {t(translateCancel)}
          </Button>
          <Button appearance="primary" onClick={handleConfirm}>
            {t(translateOk)}
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

ConfirmButton.propTypes = {
  confirmLabel: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  translateOk: PropTypes.string,
  translateCancel: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

ConfirmButton.defaultProps = {
  width: 'auto',
  height: 'auto',
  translateOk: 'confirm.ok',
  translateCancel: 'confirm.cancel',
  onClick: null,
};

export default ConfirmButton;
