import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

/**
 * Step
 *
 * This component is in charge of displaying
 * a step
 *
 * @param {string} className // Classes needed by styled component.
 *
 * @return {jsx}
 */
import STATUS from '../constants';

const { COMPLETE, PROCESS } = STATUS;

const Step = ({ className }) => <li className={className} />;

/**
 * PropTypes Validation
 */
const { string } = PropTypes;
Step.propTypes = {
  className: string,
};

/**
 * Default Props
 */
Step.defaultProps = {
  className: '',
};

export default styled(Step)`
  height: 0.4rem;
  width: 2.4rem;

  margin-left: 0.4rem;

  background: ${({ theme: { palette } }) => palette.gray};

  &:first-of-type {
    margin-left: 0;

    border-radius: 10px 0 0 10px;
  }

  &:last-of-type {
    border-radius: 0 10px 10px 0;
  }

  /* status Complete */
  ${({ status }) =>
    status === COMPLETE &&
    css`
      background: ${({ theme: { palette } }) => palette.green};
    `};

  /* status PROCESS */
  ${({ status }) =>
    status === PROCESS &&
    css`
      background: ${({ theme: { palette } }) => palette.primary.back};
    `};
`;
