import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Button,
  Card,
  Checkbox,
  Headline,
  Input,
  Label,
  Select,
  Spinner,
  TextArea,
  useNotificationToast,
} from '@sumup/circuit-ui';

import { CheckboxesPack } from '../../../components';
import {
  AvailableCountries,
  createNewFeature,
  useGetCountryInformationsFeatures,
  useGetFeatureCategories,
} from '../services';

/**
 * Create a new feature form.
 *
 * @returns
 */
const NewfeaturePage: FC = () => {
  const { setToast } = useNotificationToast();
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (success) {
      setInterval(() => {
        window.location.reload();
      }, 2000);
    }
  }, [success]);

  const initialState = {
    name: '',
    code: '',
    description: '',
    extraData: '',
    visible: false,
    category: '',
    selectedCountries: [],
  };

  const [feature, setFeature] = useState(initialState);

  const { data: features, isLoading: fetchCountriesFeaturesIsLoading } =
    useGetCountryInformationsFeatures();

  // Fetch feature categories
  const { data: featureCategories, isLoading: fetchFeatureCategoriesIsLoading } =
    useGetFeatureCategories();

  // Create feature mutation
  const { mutate: createMutation, isLoading: createIsLoading } = useMutation(
    () => {
      const payload = {
        moduleName: feature.name,
        providerName: 'captain',
        isVisible: feature.visible,
        categoryId: Number(feature.category),
        internalDescription: feature.description,
        options: {},
      };

      return createNewFeature(payload, feature.selectedCountries, features);
    },
    {
      onSuccess: () => {
        setToast({ body: t('features.create.success'), variant: 'confirm' });
        setSuccess(true);
      },
      onError: () => setToast({ body: t('features.create.error'), variant: 'alert' }),
    },
  );

  // Update state with selected countries
  const updateCountryList = e => {
    setFeature(feature => ({ ...feature, selectedCountries: e.detail.value }));
  };

  return (
    <div>
      {fetchCountriesFeaturesIsLoading && fetchFeatureCategoriesIsLoading ? (
        <Spinner />
      ) : (
        <>
          <Headline as="h4">{t('features.create.title')}</Headline>
          <Card data-testid="features-container">
            <form
              onSubmit={event => {
                event.preventDefault();
                if (feature.selectedCountries.length > 0) {
                  createMutation();
                } else {
                  setToast({
                    body: t('features.create.form.submit.countriesError'),
                    variant: 'alert',
                  });
                }
              }}
              data-testid="create-new-feature-form"
            >
              <Input
                required={true}
                label={t('features.create.form.code.label')}
                name={feature.code}
                value={feature.code}
                onChange={e => setFeature(feature => ({ ...feature, code: e.target.value }))}
                placeholder={t('features.create.form.code.placeholder')}
              />
              <Input
                required={true}
                label={t('features.create.form.name.label')}
                name={feature.name}
                value={feature.name}
                onChange={e => setFeature(feature => ({ ...feature, name: e.target.value }))}
                placeholder={t('features.create.form.name.placeholder')}
              />
              <TextArea
                required={true}
                value={feature.description}
                label={t('features.create.form.description.label')}
                name={feature.description}
                onChange={e => setFeature(feature => ({ ...feature, description: e.target.value }))}
                placeholder={t('features.create.form.description.placeholder')}
              />
              <Select
                onChange={e => setFeature(feature => ({ ...feature, category: e.target.value }))}
                label={t('features.create.form.category.label')}
                options={
                  featureCategories &&
                  featureCategories.map(featureCategory => ({
                    label: featureCategory.title,
                    value: featureCategory.id,
                  }))
                }
              />
              <Checkbox
                value={feature.visible ? 1 : 0}
                onChange={() => setFeature(feature => ({ ...feature, visible: !feature.visible }))}
              >
                {t('features.create.form.visible.label')}
              </Checkbox>
              <br />
              <TextArea
                value={feature.extraData}
                label={t('features.create.form.extraData.label')}
                name={feature.extraData}
                onChange={e => setFeature(feature => ({ ...feature, extraData: e.target.value }))}
                placeholder={t('features.create.form.extraData.placeholder')}
              />
              <Label htmlFor="countries">{t('features.create.form.countries.label')}</Label>
              <br />
              <CheckboxesPack
                aria-label="selectedCountries"
                aria-labelledby="selectedCountries"
                toggleAllLabel={t('generic.selectAll')}
                name="selectedCountries"
                options={AvailableCountries.map(country => ({
                  label:
                    country === 'DEFAULT' ? t('features.create.form.countries.other') : country,
                  value: country,
                  checked: feature.selectedCountries.includes(country),
                }))}
                onChange={e => updateCountryList(e)}
              />
              <Button
                loadingLabel={t('features.create.form.submit.loading')}
                isLoading={createIsLoading}
                type="submit"
                variant="primary"
                data-testid="form-submit"
              >
                {t('features.create.form.submit.button')}
              </Button>
            </form>
          </Card>
        </>
      )}
    </div>
  );
};

export default NewfeaturePage;
