/**
 * Category sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { categoriesFailed, categoriesSucceeded } from './actions';
import { CATEGORIES_REQUESTED } from './actionTypes';
import { retrieveCategories } from './services';

/**
 * getCategories saga
 *
 * @generator
 */
export function* getCategoriesSaga() {
  try {
    const categories = yield call(retrieveCategories);
    // inform Redux to set our client categories
    yield put(categoriesSucceeded({ categories }));
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put(categoriesFailed(error));
  }
}

/**
 * Global listener.
 */
export default function* categoriesWatcher() {
  yield takeLatest(CATEGORIES_REQUESTED, getCategoriesSaga);
}
