import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem 3rem;
  height: 100%;
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: inherit;
`;

export const StyledError = styled.div`
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};

  color: ${({ theme: { palette } }) => palette.failure.default};
`;
