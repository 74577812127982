/**
 * Settings reducer
 */
import { produce } from 'immer';

import * as t from './actionTypes';

const initialState = {};

/**
 * Settings root reducer
 *
 * @param {Object} state initial or previous state of data
 * @param {Object} action the action object
 *
 * @return {Object}
 */
export default function root(state = initialState, action = {}) {
  switch (action.type) {
    case t.SET_SETTING:
      return produce(state, draft => {
        if (action.payload.namespace) {
          draft[action.payload.namespace] = draft[action.payload.namespace] || {};
          draft[action.payload.namespace][action.payload.key] = action.payload.value;
        } else {
          draft[action.payload.key] = action.payload.value;
        }
      });
    default:
      return state;
  }
}
