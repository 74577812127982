import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Form, Loader, Select } from '@tillersystems/stardust';

import 'styled-components/macro'; // needed to use the css props in this file

import Theme from '../../../../components/Theme';
import { createNewEnvironment, getBranchesName } from '../../service';

import { Link, TopMarginMessage } from './elements';

const cleanBranchName = branchName => branchName.replace(/\//g, '.');

const PinguiLauncher = () => {
  const [tillerBackBranch, setTillerBackBranch] = useState(['master']);
  const [tillerBackBranches, setTillerBackBranches] = useState([]);
  const [tillerOrderApiBranch, setTillerOrderApiBranch] = useState(['master']);
  const [tillerOrderApiBranches, setTillerOrderApiBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const [t] = useTranslation();

  useEffect(() => {
    getBranchesName('tiller-back').then(branchesNames =>
      setTillerBackBranches([...tillerBackBranches, ...branchesNames]),
    );
    getBranchesName('tiller-order-api').then(branchesNames =>
      setTillerOrderApiBranches([...tillerOrderApiBranches, ...branchesNames]),
    );
  }, []);

  const [tillerBackBranchValue] = tillerBackBranch;
  const [tillerOrderApiBranchValue] = tillerOrderApiBranch;

  const createEnvironment = () => {
    setIsLoading(true);
    const amazonUrl = process.env.REACT_APP_TILLER_ORDER_SERVICE_URL;
    const backRepo =
      tillerBackBranchValue === 'master'
        ? `${amazonUrl}/tiller-back-symfony-prod`
        : `${amazonUrl}/tiller-back-symfony-staging`;
    const backTag =
      tillerBackBranchValue === 'master'
        ? tillerOrderApiBranchValue
        : cleanBranchName(tillerOrderApiBranchValue);
    const apiRepo =
      tillerOrderApiBranchValue === 'master'
        ? `${amazonUrl}/tiller-order-api-symfony-prod`
        : `${amazonUrl}/tiller-order-api-symfony-staging`;
    const apiTag =
      tillerOrderApiBranchValue === 'master'
        ? tillerOrderApiBranchValue
        : cleanBranchName(tillerOrderApiBranchValue);
    createNewEnvironment([
      {
        name: 'tiller_order_api',
        image: {
          name: apiRepo,
          tag: apiTag,
        },
      },
      {
        name: 'tiller_back',
        image: {
          name: backRepo,
          tag: backTag,
        },
      },
    ]).then(({ ok }) => {
      ok ? setIsSuccess(true) : setIsSuccess(false);
      setIsLoading(false);
      setIsRequestSent(true);
    });
  };

  const searchMethod = useCallback(({ options, term }) => {
    return options.filter(option =>
      option.label.toString().toLowerCase().includes(term.toLowerCase()),
    );
  }, []);

  return (
    <>
      <Form name="pingui_launcher">
        <Form.Group inlineLabels>
          <Form.Field label={t('ops.pingui.tiller_back_branch')} labelWidth="20rem">
            <Select
              usePortal
              placeholder={tillerBackBranchValue}
              values={tillerBackBranch}
              onChange={setTillerBackBranch}
              css="width: 100%;"
              contentWrapperStyle={{ maxHeight: '26rem' }}
              searchMethod={searchMethod}
            >
              {tillerBackBranches.map(name => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Field>
          <Form.Field label={t('ops.pingui.tiller_order_branch')} labelWidth="20rem">
            <Select
              usePortal
              placeholder={tillerOrderApiBranchValue}
              values={tillerOrderApiBranch}
              onChange={setTillerOrderApiBranch}
              css="width: 100%;"
              contentWrapperStyle={{ maxHeight: '26rem' }}
              searchMethod={searchMethod}
            >
              {tillerOrderApiBranches.map(name => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Field>
          <Button
            disabled={isLoading}
            type="submit"
            appearance="primary"
            onClick={createEnvironment}
          >
            {isLoading ? (
              <Loader width="2rem" height="2rem" color={Theme.palette.white} />
            ) : (
              t('ops.pingui.create')
            )}
          </Button>
        </Form.Group>
      </Form>
      {isRequestSent && (
        <TopMarginMessage
          description={
            isSuccess ? (
              <Trans i18nKey="ops.pingui.create_success">
                <span />
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://circleci.com/gh/tillersystems/tiller-pingui-manager/tree/master"
                >
                  {{
                    link: 'https://circleci.com/gh/tillersystems/tiller-pingui-manager/tree/master',
                  }}
                </Link>
              </Trans>
            ) : (
              t('ops.pingui.create_error')
            )
          }
          type={isSuccess ? 'success' : 'error'}
        />
      )}
    </>
  );
};

export default PinguiLauncher;
