import React from 'react';
import { useTranslation } from 'react-i18next';

import { Head, PageContainer, TopBar } from '../../components';

import { Subtitle, Title } from './elements';

/**
 * Note Found
 *
 * This Container is in charge of display
 * a the not found page (error 404)
 *
 * @return {jsx}
 */

const NotFound = () => {
  const [t] = useTranslation();
  return (
    <>
      <Head title={t('navigation.not_found')} />
      <TopBar title={t('navigation.not_found')} />
      <PageContainer>
        <Title>{t('not_found.title')}</Title>
        <Subtitle>{t('not_found.subtitle')}</Subtitle>
      </PageContainer>
    </>
  );
};

export default NotFound;
