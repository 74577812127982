import React from 'react';

import i18n from '../../../i18n';
import EventDefinitions, { CATEGORIES, COLORS } from '../Detail/definitions';

import { ColoredLabel } from './elements';

const sortTypesByCategory = (typeA, typeB) => {
  const categoryAindex =
    typeA && EventDefinitions[typeA] && CATEGORIES.indexOf(EventDefinitions[typeA].category);
  const categoryBindex =
    typeB && EventDefinitions[typeB] && CATEGORIES.indexOf(EventDefinitions[typeB].category);

  if (categoryAindex > -1 && categoryAindex > categoryBindex) return 1;
  if (categoryAindex > -1 && categoryAindex < categoryBindex) return -1;
  return 0;
};

export const getDefaultEventTypes = Object.keys(EventDefinitions).filter(
  type => EventDefinitions[type].category !== 'INFO',
);

export const getAllOptions = () => [...CATEGORIES, ...Object.keys(EventDefinitions)];

export const getOptions = () => [
  ...CATEGORIES.map(category => ({
    value: category,
    text: category,
    label: (
      <ColoredLabel color={COLORS[category]}>
        {i18n.t(`order_management.events_types_select.${category.toLowerCase()}`)}
      </ColoredLabel>
    ),
  })),
  ...Object.keys(EventDefinitions)
    .sort(sortTypesByCategory)
    .map(type => ({
      value: type,
      text: type,
      label: <ColoredLabel color={COLORS[EventDefinitions[type].category]}>{type}</ColoredLabel>,
    })),
];
