import styled from 'styled-components';

import { Modal } from '@tillersystems/stardust';

export const Body = styled(Modal.Body)``;

export const Footer = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  height: auto;
`;
