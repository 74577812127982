import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabSwitcher } from '@tillersystems/stardust';

import BetaBrowser from './BetaBrowser';
import BetaLauncher from './BetaLauncher';
import { TabHeader } from './elements';

/**
 * BetaContainer
 *
 * This components is in charge of display
 * the beta container
 *
 * @return {jsx}
 */

const BetaContainer = () => {
  const [t] = useTranslation();

  return (
    <>
      <TabSwitcher defaultTabId="tab-1">
        <TabHeader>
          <TabSwitcher.Tab id="tab-1">
            {t('ops.beta_container.navigation.beta_lancher')}
          </TabSwitcher.Tab>
          <TabSwitcher.Tab id="tab-2">
            {t('ops.beta_container.navigation.beta_browser')}
          </TabSwitcher.Tab>
          <TabSwitcher.Tab id="tab-3">{t('ops.beta_container.navigation.how')}</TabSwitcher.Tab>
        </TabHeader>

        <TabSwitcher.Panes>
          <TabSwitcher.Pane tabId="tab-1">
            <BetaLauncher />
          </TabSwitcher.Pane>
          <TabSwitcher.Pane tabId="tab-2">
            <BetaBrowser />
          </TabSwitcher.Pane>
          <TabSwitcher.Pane tabId="tab-3">
            <img
              alt="deployement schema"
              src="https://camo.githubusercontent.com/5769579b35b6b169df5911541116450b45b8d574/68747470733a2f2f73332d65752d776573742d312e616d617a6f6e6177732e636f6d2f74696c6c65722d76332f6769742f74696c6c65722d706f732d626574612d646973747269627574696f6e2e706e67"
              width="100%"
            />
          </TabSwitcher.Pane>
        </TabSwitcher.Panes>
      </TabSwitcher>
    </>
  );
};

export default BetaContainer;
