import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

/**
 * Container
 *
 * This component is in charge of displaying
 * a div container
 *
 * @return {jsx}
 */
const Container = styled.div`
  height: calc(100vh - 6.2rem);
  padding: ${({ theme: { dimensions } }) => dimensions.big};

  overflow-y: auto;

  /* For small devices */
  ${breakpoint('xs', 'md')`
    height: calc(100vh - 6.2rem - 6rem);
    padding: ${({ theme: { dimensions } }) => dimensions.medium};

    padding-bottom: 7.2rem;
  `};
`;

export default Container;
