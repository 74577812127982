import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-form';
import PropTypes from 'prop-types';

import { Button, Loader } from '@tillersystems/stardust';

import { Container, Footer, FormError, SubmitButton } from './elements';
import Field from './Field';
import * as Fields from './Fields';

const DefinitionForm = ({
  definitions,
  data,
  validate,
  validatePristine,
  onBlur,
  onChange,
  onSubmit,
  onReset,
  resetLabel,
  submitLabel,
  footer,
}) => {
  const defaultValues = useMemo(() => data, [data]);

  const { Form, reset, values, setValues, meta } = useForm({
    defaultValues,
    onSubmit,
    validate,
    validatePristine,
  });

  const handleReset = useCallback(async () => {
    if (onReset) {
      onReset();
    }
    reset();
  }, []);

  useEffect(() => {
    if (onChange && values) {
      onChange(values, { setValues });
    }
  }, [onChange, values]);

  const { error, isSubmitting, canSubmit } = meta;

  const fields = useMemo(
    () =>
      definitions.map(definition => (
        <Field key={definition.fieldPath} definition={definition} onBlurCallback={onBlur} />
      )),
    [definitions],
  );
  return (
    <Form autoComplete="off">
      <Container>{fields}</Container>
      {error && <FormError>{error}</FormError>}
      <Footer>
        {!!onReset ||
          (resetLabel && (
            <Button appearance="default" onClick={handleReset}>
              {resetLabel}
            </Button>
          ))}
        {footer}
        {!!onSubmit && submitLabel && (
          <SubmitButton type="submit" appearance="primary" disabled={!canSubmit}>
            {isSubmitting ? <Loader /> : null}
            {submitLabel}
          </SubmitButton>
        )}
      </Footer>
    </Form>
  );
};

DefinitionForm.Fields = Fields;

DefinitionForm.propTypes = {
  definitions: PropTypes.array, // Field definitions
  data: PropTypes.object, // Initial data
  validate: PropTypes.func, // Execute a validation on form - can be async
  validatePristine: PropTypes.bool, // Should validation be run on mount
  onChange: PropTypes.func, // When values changes - do not affect validation
  onSubmit: PropTypes.func, // Handle submit
  onReset: PropTypes.func, // Handle reset, allows resetting default value if needed in a controlled state
  footer: PropTypes.node, // Additional component in footer.
  resetLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  submitLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onBlur: PropTypes.func,
};

DefinitionForm.defaultProps = {
  definitions: null,
  data: {},
  validate: null,
  validatePristine: null,
  onChange: null,
  onSubmit: null,
  onReset: null,
  footer: null,
  resetLabel: null,
  submitLabel: null,
  onBlur: null,
};

export default DefinitionForm;
