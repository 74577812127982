import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Head, Menu, PageContainer, TopBar } from '../../components';

import ProjectsConfig from './config';
import { Project, Wrapper } from './elements';
import ProjectPage from './Project';

/**
 * Releases
 *
 * This Container is in charge of display
 * a Releases page
 *
 * @param {object} match
 * @param {object} location
 *
 * @return {jsx}
 */

const Releases = ({ match: { url }, location: { pathname } }) => {
  const [t] = useTranslation();
  const allowRedirect = url === pathname;

  return (
    <>
      <Head title="Releases" />
      <TopBar title="Releases" />
      <PageContainer>
        <Wrapper>
          <Menu>
            <Menu.Header>{t('releases.menu.title')}</Menu.Header>
            <Menu.Body>
              {ProjectsConfig.projects.map(({ id, title, slug }) => {
                return (
                  <Menu.Link to={`${url}/${slug}`} activeOnlyWhenExact title={title} key={id} />
                );
              })}
            </Menu.Body>
          </Menu>
          <Project>
            {allowRedirect && <Redirect to={`${url}/pos-v2`} />}
            <Switch>
              <Route exact path={`${url}/:projectName`}>
                <ProjectPage />
              </Route>
            </Switch>
          </Project>
        </Wrapper>
      </PageContainer>
    </>
  );
};

Releases.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Releases);
