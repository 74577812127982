/**
 * Defines the actions related to period handling.
 */

import { createAction } from 'redux-act';

/**
 * Defines the action dispatched when the period is changed.
 *
 * @param {Period} period - The new period.
 */
export const PeriodChanged = createAction('[Period] Changed', period => period);
