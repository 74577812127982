/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Checkbox } from '@sumup/circuit-ui';

import { Container, ThreeStateCheckbox } from './styles';
import { CheckboxesPackOptionProps, CheckboxesPackProps } from './types';
import { getValueFromOptions } from './utils';

const CheckboxesPack = ({
  disabled,
  readOnly,
  required,
  name,
  options,
  minRequired: passedMinRequired,
  toggleAllLabel,
  onChange,
  ...containerProps
}: CheckboxesPackProps) => {
  const toggleAllRef = useRef<HTMLInputElement>(null);
  const lastMixedValue = useRef<Set<string>>(new Set());
  const [currentValue, setValueOnly] = useState<string[]>(getValueFromOptions(options));

  const minRequired = (required ? options.length : passedMinRequired) ?? 0;

  const isMixed = useCallback(
    (value: string[]) => Boolean(value.length && value.length < options.length),
    [options],
  );

  useEffect(() => {
    const newValue = getValueFromOptions(options);
    setValueOnly(newValue);
    if (isMixed(newValue)) lastMixedValue.current = new Set(newValue);
  }, [isMixed, options]);

  const setCurrentValue = useCallback(
    (newValue: string[]) => {
      const changeCustomEvent = new CustomEvent('change', { detail: { value: newValue } });
      onChange?.(changeCustomEvent);
      if (!changeCustomEvent.defaultPrevented) setValueOnly(newValue);
    },
    [onChange],
  );

  const getOptionId = ({ id, value }: CheckboxesPackOptionProps) => {
    return id || `${name}_${value}`;
  };

  const toggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget;
    const newValue = el.checked
      ? [...currentValue, el.value]
      : currentValue.filter(v => v !== el.value);

    if (isMixed(newValue)) lastMixedValue.current = new Set(newValue);
    else lastMixedValue.current.clear();

    setCurrentValue(newValue);
  };

  const toggleAll = () => {
    if (currentValue.length === options.length) {
      setCurrentValue([]);
    } else if (currentValue.length === 0 && lastMixedValue.current?.size) {
      // @ts-ignore
      setCurrentValue([...lastMixedValue.current]);
    } else {
      setCurrentValue(options.map(o => o.value));
    }
  };

  useLayoutEffect(() => {
    if (!toggleAllRef.current) return;
    toggleAllRef.current.indeterminate = isMixed(currentValue);
  }, [isMixed, currentValue]);

  return (
    <Container role="group" {...containerProps}>
      {!(disabled || readOnly) && (
        <ThreeStateCheckbox
          noMargin
          ref={toggleAllRef}
          checked={currentValue.length > 0}
          onChange={toggleAll}
          aria-controls={options.map(getOptionId).join(' ')}
        >
          {toggleAllLabel}
        </ThreeStateCheckbox>
      )}

      {options.map((option, index) => (
        <Checkbox
          noMargin
          readOnly={readOnly}
          disabled={disabled}
          required={minRequired > currentValue.length}
          id={getOptionId(option)}
          key={option.id ?? index}
          name={`${name}.${index}`}
          value={option.value}
          checked={currentValue.includes(option.value)}
          onChange={toggleCheckbox}
        >
          {option.label}
        </Checkbox>
      ))}
    </Container>
  );
};

export default CheckboxesPack;
