import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@sumup/circuit-ui';

import { useDataIntegrityContext } from '../context';
import { InputWrapper } from '../elements';
import { InputName } from '../type';

const StoreIdInput = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { getErrorMessage } = useDataIntegrityContext();

  const id = InputName.storeId;
  const error = getErrorMessage(id, errors[id]);

  return (
    <InputWrapper>
      <Input
        data-testid="input-store_id"
        {...register(id, { required: true, pattern: /^\d+$/ })}
        label={t(`data_integrity.search_form.${id}.label`)}
        invalid={!!error}
        validationHint={error}
        noMargin
      />
    </InputWrapper>
  );
};

export default StoreIdInput;
