/**
 * Order Events actions
 */

import uuid from 'uuid/v4';

import * as t from './actionTypes';

/**
 * Order Events Requested
 * dispatch this action when
 * you want to start a request to retrieve all orderEvents.
 */
export const orderEventsStale = payload => ({
  type: t.ORDER_EVENTS_STALE,
  payload,
});

/**
 * Order Events Requested
 * dispatch this action when
 * you want to start a request to retrieve all orderEvents.
 */
export const orderEventsRequested = payload => ({
  type: t.ORDER_EVENTS_REQUESTED,
  payload,
});

/**
 * Order Events Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {object}
 */
export const orderEventsSucceeded = data => ({
  type: t.ORDER_EVENTS_SUCCEEDED,
  payload: data,
});

/**
 * Order Events Failed
 * dispatch this action when request
 * has not been successfully received.
 *
 * @param {object} error
 *
 * @return {object}
 */
export const orderEventsFailed = error => ({
  type: t.ORDER_EVENTS_FAILED,
  payload: error,
});

/**
 * Order Events Create
 * dispatch this action when submittting form to create a new event.
 *
 * @param {object} event - The draft payload of event
 *
 * @return {object}
 */
export const orderEventsCreate = ({ order, event, user }) => ({
  type: t.ORDER_EVENTS_CREATE,
  payload: {
    order,
    event: {
      uuid: uuid().toUpperCase(),
      ...event,
    },
    user,
  },
});

/**
 * Order Events Created
 * dispatch the newly created event
 *
 * @param {Event} event - The new event
 *
 * @return {object}
 */
export const orderEventsCreated = event => ({
  type: t.ORDER_EVENTS_CREATED,
  payload: event,
});

/**
 * Order Events Update
 * dispatch this action when submittting form to create a new event.
 *
 * @param {object} event - The draft payload of event
 *
 * @return {object}
 */
export const orderEventsUpdate = payload => ({
  type: t.ORDER_EVENTS_UPDATE,
  payload,
});

/**
 * Order Events Updated
 * dispatch the newly created event
 *
 * @param {Event} event - The new event
 *
 * @return {object}
 */
export const orderEventsUpdated = event => ({
  type: t.ORDER_EVENTS_UPDATED,
  payload: event,
});

/**
 * Order Events Remove
 * dispatch this action when submittting form to create a new event.
 *
 * @param {object} event - The draft payload of event
 *
 * @return {object}
 */
export const orderEventsRemove = payload => ({
  type: t.ORDER_EVENTS_REMOVE,
  payload,
});

/**
 * Order Events Removed
 * dispatch the newly created event
 *
 * @param {Event} event - The new event
 *
 * @return {object}
 */
export const orderEventsRemoved = event => ({
  type: t.ORDER_EVENTS_REMOVED,
  payload: event,
});

/**
 * Order Events Sync Requested
 * dispatch this action when
 * you want to start a request to retrieve all orderEvents.
 */
export const orderEventsSyncRequested = payload => ({
  type: t.ORDER_EVENTS_SYNC_REQUESTED,
  payload,
});

/**
 * Order Events Sync Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {object}
 */
export const orderEventsSyncSucceeded = data => ({
  type: t.ORDER_EVENTS_SYNC_SUCCEEDED,
  payload: data,
});

/**
 * Order Events Sync Failed
 * dispatch this action when request
 * has not been successfully received.
 *
 * @param {object} error
 *
 * @return {object}
 */
export const orderEventsSyncFailed = error => ({
  type: t.ORDER_EVENTS_SYNC_FAILED,
  payload: error,
});

/**
 * Order Events Sync Requested
 * dispatch this action when
 * you want to start a request to retrieve all orderEvents.
 */
export const orderEventsReplayRequested = payload => ({
  type: t.ORDER_EVENTS_REPLAY_REQUESTED,
  payload,
});

/**
 * Order Events Sync Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {object}
 */
export const orderEventsReplaySucceeded = data => ({
  type: t.ORDER_EVENTS_REPLAY_SUCCEEDED,
  payload: data,
});

/**
 * Order Events Sync Failed
 * dispatch this action when request
 * has not been successfully received.
 *
 * @param {object} error
 *
 * @return {object}
 */
export const orderEventsReplayFailed = error => ({
  type: t.ORDER_EVENTS_REPLAY_FAILED,
  payload: error,
});
