import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CodeBlock from './codeBlock';
/**
 * Release
 *
 * This component is in charge of displaying
 * a releases item
 *
 * @param {string} body // The release publish body.
 * @param {array} className // className needed by styled components.
 * @param {bool} isDraft // If the release is a Draft.
 * @param {array} name // The release name.
 * @param {array} publishAt // The release publish date.
 *
 * @return {jsx}
 */
import { Body, Header, Name, PublishDate } from './elements';

const Release = ({ body, className, isDraft, name, publishDate }) => (
  <div className={className}>
    <Header>
      <Name>{name}</Name>
      <PublishDate>{isDraft ? 'Draft' : publishDate}</PublishDate>
    </Header>
    <Body>
      <ReactMarkdown source={body} renderers={{ code: CodeBlock }} />
    </Body>
  </div>
);

/**
 * PropTypes Validation
 */
const { bool, string } = PropTypes;
Release.propTypes = {
  body: string.isRequired,
  className: string,
  isDraft: bool,
  name: string.isRequired,
  publishDate: string.isRequired,
};

/**
 * Default props
 */
Release.defaultProps = {
  className: '',
  isDraft: false,
};

export default styled(Release)`
  padding: 1.5rem 1rem;

  word-break: break-word;

  color: ${({ theme: { palette } }) => palette.darkBlue};
`;
