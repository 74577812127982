import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Loader } from '@tillersystems/stardust';

import { Head } from '../../../components';
import { convertRawTicket } from '../../../modules/OrderOMS/helper';
import { RawTicket } from '../../../modules/OrderOMS/types';
import { useQueryOrder } from '../../../modules/OrderOMS/useQueryOrder';

import { LoaderContainer } from './OrderCard/elements';
import { Container } from './elements';
import EventsCard from './EventsCard';
import OrderCard from './OrderCard';
import RawTickets from './RawTickets';

const OrderOMSDetail: FC<RouteComponentProps<{ id: string }>> = props => {
  const [t] = useTranslation();

  const { id: orderId } = props.match.params;

  const { order, isOrderLoading, rawTickets, isRawTicketsLoading, rawTicketsError } =
    useQueryOrder(orderId);
  const [rawTicketCursor, setRawTicketCursor] = useState(0);
  const rawTicket: undefined | RawTicket = rawTickets?.[rawTicketCursor];
  const convertedRawTicket = convertRawTicket(rawTicket);

  if (isOrderLoading) {
    return (
      <LoaderContainer>
        <Loader width="3rem" height="3rem" />
      </LoaderContainer>
    );
  }

  if (!order) {
    return <>There is no order with root uuid {orderId}</>;
  }

  return (
    <>
      <Head title={`${t('navigation.order_management')} > ${orderId}`} />
      <Container>
        <OrderCard
          orderUuid={orderId}
          order={order}
          rawTicket={rawTicket}
          convertedRawTicket={convertedRawTicket}
          isRawTicketsLoading={isRawTicketsLoading}
        />
        <EventsCard order={order} isOrderLoading={isOrderLoading} />
      </Container>
      <RawTickets
        onRawTicketSelect={(move: number) => {
          const newCursor = move + rawTicketCursor;
          const indexesLength = rawTickets.length - 1;

          setRawTicketCursor(
            newCursor > indexesLength ? 0 : newCursor > indexesLength ? indexesLength : newCursor,
          );
        }}
        rawTicket={convertedRawTicket}
        rawTicketsError={rawTicketsError}
        rawTicketsCount={rawTickets?.length}
        isRawTicketsLoading={isRawTicketsLoading}
        rawTicketsIndex={rawTicketCursor}
      />
    </>
  );
};

export default withRouter(OrderOMSDetail);
