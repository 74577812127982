import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PeriodPicker } from '../../../components';
import SearchBar from '../SearchBar';
import StoreDetail from '../StoreDetail';

import connect from './connect';
import { Header, TableHeader, TableWrapper, WarningMsg, Wrapper } from './elements';

const StoreContainer = ({ match, shopId }) => {
  const [t] = useTranslation();

  const rootPath = match.path;

  return (
    <Wrapper>
      {shopId && <Redirect to={`${rootPath}/${shopId}`} />}
      <Header>
        <PeriodPicker />
        <Route path={`${rootPath}/:id?`}>
          <SearchBar placeholder={t('order_management.search_store_placeholder')} />
        </Route>
      </Header>
      <TableWrapper>
        <TableHeader>{t('order_management.open_order')}</TableHeader>
        <Switch>
          <Route path={`${rootPath}/:id`}>
            <StoreDetail />
          </Route>
          <Route path="*">
            <WarningMsg>{t('order_management.by_store.warning')}</WarningMsg>
          </Route>
        </Switch>
      </TableWrapper>
    </Wrapper>
  );
};

StoreContainer.propTypes = {
  match: PropTypes.object.isRequired,
  shopId: PropTypes.string,
};

StoreContainer.defaultProps = {
  shopId: null,
};

export default connect(StoreContainer);
