import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-left: 3rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
`;

export const RawTicketSkeleton = styled.div`
  width: 25rem;
  height: 50rem;
  box-shadow: -2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme: { palette } }) => palette.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerWrapper = styled.div`
  overflow-y: auto;
`;

export const Container = styled.div`
  background-color: ${({ theme: { palette } }) => palette.white};
  padding: 0.5rem 0;
  margin: 0.5rem 0 3.5rem;
  width: 28rem;
  box-shadow: -2px 4px 6px 0 rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
`;

export const Id = styled.div`
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
`;

export const StyledDate = styled.div`
  color: ${({ theme: { palette } }) => palette.spaceGrey};
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
`;

export const OrderLines = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  border-spacing: 0;
`;

export const Row = styled.tr`
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
  vertical-align: top;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: hsla(34, 91%, 65%, 0.4);
    `}
`;

export const Status = styled.div`
  text-transform: uppercase;
  color: hsl(34, 92%, 40%);
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
`;

export const Cell = styled.td`
  padding: 0.5rem;

  &:first-child {
    padding: 0.5rem 0.5rem 0.5rem 1.2rem;
  }

  &:last-child {
    padding: 0.5rem 1.2rem 0.5rem 0.5rem;
  }
`;

export const Totals = styled.div`
  border-top: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  margin: 1.5rem;

  td:first-child {
    width: 100%;
  }

  tr:last-child {
    font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};

    td {
      border-top: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
    }
  }
`;

export const CellTax = styled(Cell)`
  &:not(:first-child) {
    text-align: right;
  }

  &:first-child {
    padding: 0.5rem 0.5rem 0.5rem 0;
  }

  &:last-child {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
`;

export const Payments = styled.div`
  border-top: 1px solid ${({ theme: { palette } }) => palette.lightGrey};

  td:nth-child(2) {
    width: 100%;
  }
`;

export const Title = styled.div`
  margin: 1.2rem 0 1rem 1.2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  text-transform: uppercase;
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
`;

export const Signature = styled.div`
  margin: 1.2rem 0 1rem 1.2rem;
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
`;
