/**
 *
 * Modules - Reducers
 *
 * Defines the reducers used in the app (for Redux).
 *
 */
import { combineReducers } from 'redux';

import { CategoryReducer as categories } from './Category';
import { CountryReducer as countries } from './Country';
import { OrderReducer as order } from './Order';
import { PaymentMethodReducer as paymentMethods } from './PaymentMethod';
import { PaymentsConfigReducer as paymentsConfig } from './PaymentsConfig';
import { PeriodReducer as period } from './Period';
import persistReducer from './persist';
import { RawTicketsReducer as rawTickets } from './RawTickets';
import { SettingsReducer as settings } from './Settings';
import { ShopReducer as shop } from './Shop';

const rootReducer = combineReducers({
  categories,
  countries,
  order,
  paymentMethods,
  paymentsConfig,
  period,
  rawTickets,
  settings,
  shop,
});

export default persistReducer(rootReducer);
