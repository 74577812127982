import { QueryClient, QueryKey, SetDataOptions } from 'react-query';

const setQueryDataIfCached = <TData>(
  queryClient: QueryClient,
  key: QueryKey,
  updater: TData | ((arg: TData) => TData),
  options?: SetDataOptions,
) => {
  const isCached = queryClient.getQueryData(key);
  return isCached && queryClient.setQueryData(key, updater, options);
};

export default setQueryDataIfCached;
