import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { Head, PageContainer, TopBar } from '../../components';

import GoodTillMigrationPage from './GoodTillMigrationPage';

const Migration = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head title={t('migration.title')} />
      <TopBar title={t('migration.title')} />
      <PageContainer data-testid="migration-container">
        <Switch>
          <Route exact path="/migration/goodtill">
            <GoodTillMigrationPage />
          </Route>
        </Switch>
      </PageContainer>
    </>
  );
};

export default Migration;
