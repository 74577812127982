import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Head, PageContainer, TopBar } from '../../components';

import IntegrationRequestDetails from './IntegrationRequestDetails/index.tsx';
import IntegrationRequestList from './IntegrationRequestList';

const AppMarket = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <>
      <Head title={t('app_market.title')} />
      <TopBar title={t('app_market.title')} />
      <PageContainer data-testid="appmarket-container">
        <Switch>
          <Route exact path={`${path}`}>
            <IntegrationRequestList />
          </Route>
          <Route path={`${path}/:requestUUID/details/`}>
            <IntegrationRequestDetails />
          </Route>
        </Switch>
      </PageContainer>
    </>
  );
};

export default AppMarket;
