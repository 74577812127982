import styled from 'styled-components';

import { Form } from '@tillersystems/stardust';

export const StyledForm = styled(Form)`
  padding-top: ${({ theme: { dimensions } }) => dimensions.medium};
  max-width: 60rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;
