/**
 * News Voyager
 *
 * This components is in charge of display
 * the news voayger feeds
 *
 * @param {bool} error
 *
 * @return {jsx}
 */
import React from 'react';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Card } from '../../../components';

import { Body, CardTitle, ErrorMessage, Header, Picture, Title, Wrapper } from './elements';

const sortByItem =
  (key, order = 1) =>
  (a, b) =>
    a[key] > b[key] ? order : a[key] < b[key] ? -1 * order : 0;

const NewsVoyager = ({ error, loading, news }) => {
  let sortedData = (news || []).sort(sortByItem('created_at', -1));

  return (
    <Translation>
      {t => (
        <>
          <Header>
            <Title>{t('home.news_voyager.title')}</Title>
          </Header>
          <Body>
            {!loading && error && <ErrorMessage>{t('home.news_voyager.error')}</ErrorMessage>}
            {!loading &&
              !error &&
              sortedData.map((item, index) => (
                <Card key={index}>
                  <Card.Header>
                    <CardTitle>{item.title}</CardTitle>
                  </Card.Header>
                  <Card.Body padding="1rem">
                    <Wrapper>
                      {item.picture_url && (
                        <Picture src={item.picture_url} width="60" height="60" alt={item.title} />
                      )}
                      {item.text}
                    </Wrapper>
                  </Card.Body>
                </Card>
              ))}
          </Body>
        </>
      )}
    </Translation>
  );
};

/**
 * PropTypes Validation
 */
const { array, bool } = PropTypes;
NewsVoyager.propTypes = {
  error: bool,
  loading: bool,
  news: array,
};

/**
 * Default Props
 */
NewsVoyager.defaultProps = {
  error: false,
  loading: true,
  news: null,
};

export default NewsVoyager;
