import Avoir from './images/avoir@2x.png';
import Cash from './images/cash@2x.png';
import CB from './images/cb@2x.png';
import Cheque from './images/cheques@2x.png';
import Mobile from './images/mobile@2x.png';
import TR from './images/tr@2x.png';
import Virement from './images/virement@2x.png';

/**
 * Get the image url depending to its name
 *
 * @param {string} imageName - the image name you want to trigger the url
 *
 * @returns {string} The url of the image you choosen to get. Return an empty string if image name does not match to the existing name.
 */
export const getImageUrl = imageName => {
  const imageUrl = {
    'avoir@2x.png': Avoir,
    'cash@2x.png': Cash,
    'cb@2x.png': CB,
    'cheques@2x.png': Cheque,
    'mobile@2x.png': Mobile,
    'tr@2x.png': TR,
    'virement@2x.png': Virement,
    'default': '',
  };
  return imageUrl[imageName] || imageUrl['default'];
};
