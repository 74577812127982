/**
 * Categories actions
 */

import * as t from './actionTypes';

/**
 * Categories Requested
 * dispatch this action when
 * you want to start a request to retrieve all payment methods
 */
export const categoriesRequested = payload => ({
  type: t.CATEGORIES_REQUESTED,
  payload,
});

/**
 * Categories Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const categoriesSucceeded = data => ({
  type: t.CATEGORIES_SUCCEEDED,
  payload: data,
});

/**
 * Categories Failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {Object} error
 *
 * @return {Object}
 */
export const categoriesFailed = error => ({
  type: t.CATEGORIES_FAILED,
  payload: error,
});
