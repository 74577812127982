import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardBody from './Body';
import CardFooter from './Footer';
import CardHeader from './Header';

/**
 * Card
 *
 * This component is in charge of displaying
 * card
 *
 * @param {node} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {string} className // ClassName needed by styled components.
 *
 * @return {jsx}
 */

class Card extends PureComponent {
  static Body = CardBody;
  static Header = CardHeader;
  static Footer = CardFooter;

  render() {
    const { children, className, hostRef } = this.props;
    return (
      <div className={className} ref={hostRef}>
        {children}
      </div>
    );
  }
}

/**
 * PropTypes Validation
 */
const { func, node, string } = PropTypes;
Card.propTypes = {
  children: node,
  className: string,
  hostRef: func,
};

/**
 * Default props
 */
Card.defaultProps = {
  children: null,
  className: '',
  hostRef: () => {
    // handle hostRef here
  },
};

export default styled(Card)`
  background: ${({ theme: { palette } }) => palette.white};
  display: flex;
  flex-direction: column;

  border-radius: 4px;

  border: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  margin-bottom: 3rem;
`;
