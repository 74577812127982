import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabSwitcher } from '@tillersystems/stardust';

import { TabHeader } from './elements';
import PinguiLauncher from './PinguiLauncher';
import PinguiManager from './PinguiManager';

const PinguiContainer = () => {
  const [t] = useTranslation();
  return (
    <>
      <TabSwitcher defaultTabId="tab-1">
        <TabHeader>
          <TabSwitcher.Tab id="tab-1">{t('ops.pingui.navigation.launcher')}</TabSwitcher.Tab>
          <TabSwitcher.Tab id="tab-2">{t('ops.pingui.navigation.manager')}</TabSwitcher.Tab>
        </TabHeader>
        <TabSwitcher.Panes>
          <TabSwitcher.Pane tabId="tab-1">
            <PinguiLauncher />
          </TabSwitcher.Pane>
          <TabSwitcher.Pane tabId="tab-2">
            <PinguiManager />
          </TabSwitcher.Pane>
        </TabSwitcher.Panes>
      </TabSwitcher>
    </>
  );
};

export default PinguiContainer;
