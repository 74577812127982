import { Link } from 'react-router-dom';
import { Button } from '@sumup/circuit-ui';

import i18n from '../../../i18n';
import IntegrationRequestStatus from '../IntegrationRequestStatus';

export const headers = [
  {
    children: 'uuid',
  },
  {
    children: i18n.t('app_market.integration_request.company_name'),
  },
  {
    children: i18n.t('app_market.integration_request.type'),
  },
  {
    children: i18n.t('app_market.integration_request.main_country'),
  },
  {
    children: i18n.t('app_market.integration_request.targetted_segments'),
  },
  {
    children: i18n.t('app_market.integration_request.category'),
  },
  {
    children: i18n.t('app_market.integration_request.customer_base_size'),
    align: 'right',
  },
  {
    children: i18n.t('app_market.integration_request.status'),
  },
  {
    children: i18n.t('app_market.integration_request.details'),
  },
];

export const toTableRow = path => request =>
  [
    request.UUID,
    request.companyName,
    request.type,
    request.mainCountry,
    request.targettedSegments.join(', '),
    request.category,
    {
      children: request.customerBaseSize,
      align: 'right',
    },
    {
      children: (
        <IntegrationRequestStatus
          status={request.status}
          requestUUID={request.UUID}
          key={`req-${request.UUID}`}
        />
      ),
    },
    {
      children: (
        <Link to={`${path}/${request.UUID}/details`} key={`req-${request.UUID}`}>
          <Button variant="secondary" size="kilo">
            {i18n.t('app_market.integration_request.details')}
          </Button>
        </Link>
      ),
    },
  ];
