/**
 * Defines the listeners (Saga) used in the app.
 */

import { all, fork } from 'redux-saga/effects';

import { CategorySaga } from './Category';
import { CountrySaga } from './Country';
import { OrderSaga } from './Order';
import { PaymentMethodSaga } from './PaymentMethod';
import { PaymentsConfigSaga } from './PaymentsConfig';
import { RawTicketsSaga } from './RawTickets';
import { ReportSaga } from './Report';
import { ShopSaga } from './Shop';

/**
 * Defines the root listener.
 */
function* root() {
  yield all([
    fork(CategorySaga),
    fork(CountrySaga),
    fork(OrderSaga),
    fork(PaymentMethodSaga),
    fork(PaymentsConfigSaga),
    fork(RawTicketsSaga),
    fork(ReportSaga),
    fork(ShopSaga),
  ]);
}

/**
 * Gets the listeners.
 *
 * @return {function}
 */
const getSagas = () => root;

export default getSagas;
