/**
 * Raw Ticket Events reducer
 */
import { produce } from 'immer';

import { FAILED, LOADED, LOADING, NONE } from '../../constants/Status';

import * as t from './actionType';

const initialState = {
  status: NONE,
  error: null,
  entities: [],
  index: null,
  count: 0,
};

/**
 * Raw Ticket Events root reducer
 *
 * @param {object} state initial or previous state of data
 * @param {object} action the action object
 *
 * @return {object}
 */
export default function root(state = initialState, action = {}) {
  switch (action.type) {
    case t.RAW_TICKETS_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status = LOADING;
        draft.error = null;
        draft.index = null;
      });
    case t.RAW_TICKETS_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.status = LOADED;
        draft.error = null;
        draft.entities = action.payload.rawTickets;
        draft.count = action.payload.rawTickets.length;
        draft.index = 0;
      });
    case t.RAW_TICKETS_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.status = FAILED;
        draft.error = action.payload;
        draft.index = null;
      });
    case t.RAW_TICKETS_NEXT_TICKET:
      // If the index is smaller than the count of raw ticket, increase it by one
      return produce(state, draft => {
        draft.index = state.index < state.count - 1 ? state.index + 1 : state.index;
      });
    case t.RAW_TICKETS_PREV_TICKET:
      // If the index is bigger than 0, decrease it by one
      return produce(state, draft => {
        draft.index = state.index > 0 ? state.index - 1 : state.index;
      });
    default:
      return state;
  }
}
