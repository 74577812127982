/**
 *
 * Main Style
 *
 * Defines the global styles used by the application and injects the CSS from JavaScript.
 *
 */

import styled, { createGlobalStyle } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Theme from './components/Theme';

export default createGlobalStyle`
  * {
      border: 0;
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      font-weight: inherit;
      margin: 0;
      outline: 0;
      padding: 0;
      text-decoration: none;
      text-rendering: optimizeLegibility;
      -webkit-appearance: none;
      -moz-appearance: none;
  }


  html {
      display: flex;
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      box-sizing: border-box;
      font-size: 62.5%;
      font-size: calc(1em * .625);
      line-height: 1.5;
      padding: 0;
      margin: 0;
  }

  body {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      font-size: 1.4em;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      font-family: ${Theme.fonts.family};
      -webkit-font-smoothing: antialiased;
      background: ${Theme.palette.bodyBackground};
  }

  a {
      color: currentColor;
      text-decoration: none;
      line-height: inherit;
      tap-highlight-color: transparent;
      &:hover,
      &:focus {
          text-decoration: none;
          cursor: pointer;
      }
      img { border: 0; }
  }

  img {
      /* Get rid of gap under images by making them display: inline-block; by default */
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
  }

  /* Remove Svg outline */
  svg {
      outline: 0;
  }

  ul,
  ol,
  dl,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      margin: 0;
      padding: 0;
  }

  pre,
  code {
      font-family: Consolas, Monaco, "Lucida Console", monospace;
  }

  pre {
    padding: 1rem;
    word-break: break-all;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  #root {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
  }

  .fullscreen-enabled {
    background: ${Theme.palette.bodyBackground};
    padding: 2rem;
  }
`;

export const AppContainer = styled.div`
  display: grid;
  grid-template-columns: 22rem 1fr;
  grid-template-areas: 'sidebar main';

  overflow: hidden;

  /* For small devices */
  ${breakpoint('xs', 'md')`
    grid-template-rows: auto 6rem;
    grid-template-columns: 1fr;
    grid-template-areas: 'main' 'sidebar';
  `};

  /* For medium devices */
  ${breakpoint('md', 'lg')`
    grid-template-columns: 8rem 1fr;
  `};
`;

export const Main = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  /* For small devices */
  ${breakpoint('xs', 'md')`
    height: 100%;
  `};
`;

export const Body = styled.div`
  height: 100%;
`;
