/**
 * Theme exported to the provider
 * This is globally available in styled-components when interpolating a function like so:
 * ${(props) => props.theme}
 * Or using import { withTheme } from 'styled-components';
 */
import { Theme as stardustTheme } from '@tillersystems/stardust';

import { fonts } from './fonts';
import { palette } from './palette';

const Theme = {
  ...stardustTheme,
  palette: {
    ...stardustTheme.palette,
    ...palette,
  },
  fonts: {
    ...stardustTheme.fonts,
    weight: fonts.weight,
  },
};

export default Theme;
