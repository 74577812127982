import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';

import { Button } from '@tillersystems/stardust';

import { Wrapper } from './elements';

const FormFooter = ({ onSubmit, onCancel, disabled }) => {
  const [t] = useTranslation();

  return (
    <Wrapper>
      <Button appearance="secondary" onClick={onCancel}>
        {t('form.back')}
      </Button>
      <Button type="submit" appearance="primary" onClick={onSubmit} disabled={disabled}>
        {t('form.submit')}
      </Button>
    </Wrapper>
  );
};

FormFooter.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  disabled: bool,
};

FormFooter.defaultProps = {
  disabled: false,
};

export default FormFooter;
