import styled from 'styled-components';

import { Button } from '@tillersystems/stardust';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 3rem 0;

  text-align: center;

  color: ${({ theme: { palette } }) => palette.spaceGrey};
`;

export const ContainerMobile = styled.div`
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;
  padding: 2rem;

  text-align: center;
`;

export const Description = styled.div`
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
`;

export const DescriptionMobile = styled.div`
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
  line-height: 1.67;

  color: ${({ theme: { palette } }) => palette.spaceGrey};
`;

export const Title = styled.div`
  margin-bottom: 0.8rem;

  color: ${({ theme: { palette } }) => palette.darkBlue};

  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
`;

export const TitleMobile = styled(Title)`
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
`;

export const PeriodButton = styled(Button)`
  &:not(:last-child) {
    padding: 0 3rem 0 0;
  }

  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};

  color: ${({ theme: { palette } }) => palette.primary.default};
`;

export const RetryPeriodContainer = styled.div`
  margin-top: 0.5rem;
`;

export const RetryPeriodActions = styled.div`
  margin-top: 1rem;

  white-space: nowrap;
`;
