import { FieldError } from 'react-hook-form';
import { DateTime } from 'luxon';

export type DataIntegrityContextType = {
  getErrorMessage: (id: string, error: FieldError) => string;
  integrityChecks: IntegrityCheck[];
  isLoading: boolean;
  setQueryData: (data: DataIntegrityQueryType) => void;
};

export type DataIntegrityFormValues = {
  [InputName.storeId]: number;
  [InputName.timePeriod]: TimePeriod;
  [InputName.from]: string;
};

export type DataIntegrityQueryType = {
  [InputName.storeId]: number;
  [InputName.timePeriod]: TimePeriod;
  [InputName.from]: string;
};

export type CheckResultProps = {
  check: IntegrityCheck;
};

export enum InputName {
  storeId = 'storeId',
  timePeriod = 'timePeriod',
  from = 'from',
}

export type IntegrityCheck = {
  filename: string;
  integrity: boolean;
  period: { from: DateTime; to: DateTime };
};

export type RawIntegrityCheck = {
  filename: string;
  integrity: boolean;
};

export enum TimePeriod {
  daily = 'daily',
  monthly = 'monthly',
  yearly = 'yearly',
}
