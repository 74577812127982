import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { create } from 'apisauce';

/**
 * Home
 *
 * This components is in charge of display
 * the home page
 *
 * @return {jsx}
 */
import { Head, PageContainer, TopBar } from '../../components';
import { useAuth } from '../../contexts/Auth';

import { Main, Rightbar, Title, Wrapper } from './elements';
import NewsVoyager from './NewsVoyager';

const Home = () => {
  const [t] = useTranslation();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    async function fetchNews() {
      // define the api
      const api = create({
        baseURL: process.env.REACT_APP_VOYAGER_ROOT_URL,
      });

      const { ok, data } = await api.get('/', { limit: 20 });

      if (ok) {
        // The Api responded
        setNews(data);
        setLoading(false);
        setError(false);
      } else {
        // An error occured
        setLoading(false);
        setError(true);
      }
    }
    fetchNews();
  }, []);

  return (
    <>
      <Head title={t('navigation.home')} />
      <TopBar title={t('navigation.home')} />
      <PageContainer>
        <Wrapper>
          <Main>
            <Title>{t('home.title', { name: user.name })}</Title>
          </Main>
          <Rightbar>
            <NewsVoyager error={error} loading={loading} news={news} />
          </Rightbar>
        </Wrapper>
      </PageContainer>
    </>
  );
};

export default withRouter(Home);
