/**
 * Raw Tickets actions
 */

import * as t from './actionType';

/**
 * Raw Tickets Requested
 * dispatch this action when
 * you want to start a request to retrieve all raw tickets by order.
 */
export const rawTicketsRequested = payload => ({
  type: t.RAW_TICKETS_REQUESTED,
  payload,
});

/**
 * Raw Tickets Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {object}
 */
export const rawTicketsSucceeded = data => ({
  type: t.RAW_TICKETS_SUCCEEDED,
  payload: data,
});

/**
 * Raw Tickets Failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {object} error
 *
 * @return {object}
 */
export const rawTicketsFailed = error => ({
  type: t.RAW_TICKETS_FAILED,
  payload: error,
});

/**
 * Raw Tickets next ticket
 * dispatch this action when
 * you want to display next ticket.
 */
export const rawTicketsNextTicket = payload => ({
  type: t.RAW_TICKETS_NEXT_TICKET,
  payload,
});

/**
 * Raw Tickets prev ticket
 * dispatch this action when
 * you want to display prev ticket.
 */
export const rawTicketsPrevTicket = payload => ({
  type: t.RAW_TICKETS_PREV_TICKET,
  payload,
});
