import React, { FC } from 'react';

import { Icon } from '@tillersystems/stardust';

import { Button, Container } from './elements';

type Props = {
  onPrevious: (move: number) => void;
  onNext: (move: number) => void;
  counter: number;
  maxCount: number;
};

const PaginationButtons: FC<Props> = ({ onPrevious, counter, maxCount, onNext }) => (
  <Container>
    <Button type="button" onClick={onPrevious}>
      <Icon name="chevron-left" color="hsl(213,17%,20%)" size="15" />
    </Button>
    <div>{`${counter}/${maxCount}`}</div>
    <Button type="button" onClick={onNext}>
      <Icon name="chevron-right" color="hsl(213,17%,20%)" size="15" />
    </Button>
  </Container>
);

export default PaginationButtons;
