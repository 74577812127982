/**
 * Returns a new payload to rollback passed event.
 *
 * @param {object} event
 * @param {object} allEvents - unused, all events, if needed
 */
export default function rollbackOrderLineWasAdded(event /*, allEvents */) {
  return {
    type: 'OrderLineWasAddedByAdmin',
    payload: {
      productID: event.payload.productID,
      name: event.payload.name,
      menu: event.payload.menu,
      price: event.payload.price,
      tax: event.payload.tax,
      taxIdentifier: event.payload.taxIdentifier,
      quantity: event.payload.quantity * -1,
    },
  };
}
