import { DateTime, Interval } from 'luxon';

/**
 * Gets the start of the week from the given date time.
 *
 * @param {Luxon.DateTime} datetime - The date time to get the start of the week of.
 *
 * @returns {Luxon.DateTime} - The start of the week.
 */
const getStartOfWeek = datetime => datetime.minus({ days: datetime.weekday - 1 }).startOf('day');

/**
 * Gets the end of the week from the given date time.
 *
 * @param {Luxon.DateTime} datetime - The date time to get the end of the week of.
 *
 * @returns {Luxon.DateTime} - The end of the week.
 */
const getEndOfWeek = datetime => datetime.plus({ days: 7 - datetime.weekday }).endOf('day');

/**
 * Gets a string representation of the given date time.
 *
 * @param {Luxon.DateTime} datetime - The date time to get the representation of.
 *
 * @returns {string} - The string representation.
 */
const formatDateTime = datetime => datetime.toFormat('dd/LL/yyyy');

/**
 * This class defines a period. It uses the `luxon.Interval` class to store the bounds.
 */
class Period {
  /**
   * Creates a new instance of a period.
   *
   * @param {Luxon.Interval} interval - The interval of the period.
   */
  constructor(interval) {
    this.interval = Interval.fromDateTimes(interval.s.startOf('day'), interval.e.endOf('day'));
  }

  /**
   * Gets the interval of the period.
   *
   * @returns {Luxon.Interval} - The interval.
   */
  getInterval() {
    return this.interval;
  }

  /**
   * Gets a value indicating whether the given period is the same as the current one or not.
   *
   * @returns {boolean} - `true` if the given period is the same; `false` otherwise.
   */
  isSame(period) {
    return (
      this.interval.s.toString() == period.interval.s.toString() &&
      this.interval.e.toString() == period.interval.e.toString()
    );
  }

  /**
   * Gets a value indicating whether the current period is today or not.
   *
   * @returns {boolean} - `true` if the period is today; `false` otherwise.
   */
  isToday() {
    return this.isSame(Period.today());
  }

  /**
   * Gets a value indicating whether the current period is yesterday or not.
   *
   * @returns {boolean} - `true` if the period is today; `false` otherwise.
   */
  isYesterday() {
    return this.isSame(Period.yesterday());
  }

  /**
   * Gets a value indicating whether the current period is this week or not.
   *
   * @returns {boolean} - `true` if the period is this week; `false` otherwise.
   */
  isThisWeek() {
    return this.isSame(Period.thisWeek());
  }

  /**
   * Gets a value indicating whether the current period is last week or not.
   *
   * @returns {boolean} - `true` if the period is last week; `false` otherwise.
   */
  isLastWeek() {
    return this.isSame(Period.lastWeek());
  }

  /**
   * Gets a value indicating whether the current period is this month or not.
   *
   * @returns {boolean} - `true` if the period is this month; `false` otherwise.
   */
  isThisMonth() {
    return this.isSame(Period.thisMonth());
  }

  /**
   * Gets a value indicating whether the current period is last month or not.
   *
   * @returns {boolean} - `true` if the period is last month; `false` otherwise.
   */
  isLastMonth() {
    return this.isSame(Period.lastMonth());
  }

  /**
   * Gets a pretty string representation of the interval.
   *
   * This method basically returns a string of the format `DD/MM/YYYY - DD/MM/YYYY`. However for all
   * available quick periods (i.e. today, yesterday, this week, last week, this month, last month),
   * it returns a translatable key string (i.e. `today`, `yesterday`, `this_week`, `last_week`,
   * `this_month`, `last_month`).
   *
   * @returns {string} - A nice string representation of the period.
   */
  toPrettyString() {
    if (this.isToday()) return 'today';

    if (this.isYesterday()) return 'yesterday';

    if (this.isThisWeek()) return 'this_week';

    if (this.isLastWeek()) return 'last_week';

    if (this.isThisMonth()) return 'this_month';

    if (this.isLastMonth()) return 'last_month';

    return `${formatDateTime(this.interval.s)} - ${formatDateTime(this.interval.e)}`;
  }

  /**
   * Creates a new period for today.
   *
   * @returns {Period} - A period.
   */
  static today() {
    const datetime = DateTime.local();
    return new Period(Interval.fromDateTimes(datetime.startOf('day'), datetime.endOf('day')));
  }

  /**
   * Creates a new period for yesterday.
   *
   * @returns {Period} - A period.
   */
  static yesterday() {
    const datetime = DateTime.local().minus({ days: 1 });
    return new Period(Interval.fromDateTimes(datetime.startOf('day'), datetime.endOf('day')));
  }

  /**
   * Creates a new period for this week.
   *
   * @returns {Period} - A period.
   */
  static thisWeek() {
    const datetime = DateTime.local();
    return new Period(Interval.fromDateTimes(getStartOfWeek(datetime), getEndOfWeek(datetime)));
  }

  /**
   * Creates a new period for last week.
   *
   * @returns {Period} - A period.
   */
  static lastWeek() {
    const datetime = DateTime.local().minus({ days: 7 });
    return new Period(Interval.fromDateTimes(getStartOfWeek(datetime), getEndOfWeek(datetime)));
  }

  /**
   * Creates a new period for this month.
   *
   * @returns {Period} - A period.
   */
  static thisMonth() {
    const datetime = DateTime.local();
    return new Period(Interval.fromDateTimes(datetime.startOf('month'), datetime.endOf('month')));
  }

  /**
   * Creates a new period for last month.
   *
   * @returns {Period} - A period.
   */
  static lastMonth() {
    const datetime = DateTime.local().minus({ months: 1 });
    return new Period(Interval.fromDateTimes(datetime.startOf('month'), datetime.endOf('month')));
  }
}

export default Period;
