/**
 * Payment method actions
 */

import * as t from './actionTypes';

/**
 * Payment method list Requested
 * dispatch this action when
 * you want to start a request to retrieve all payment methods
 */
export const paymentMethodsRequested = () => ({
  type: t.PAYMENT_METHODS_REQUESTED,
});

/**
 * Payment method list Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {object}
 */
export const paymentMethodsSucceeded = data => ({
  type: t.PAYMENT_METHODS_SUCCEEDED,
  payload: data,
});

/**
 * Payment method list Failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {object} error
 *
 * @return {object}
 */
export const paymentMethodsFailed = error => ({
  type: t.PAYMENT_METHODS_FAILED,
  payload: error,
});

/**
 * Select a payment method by its label
 * dispatch this action when user click on a payment method label
 *
 * @param {string} label
 *
 * @return {object}
 */
export const paymentMethodsSelectLabel = label => ({
  type: t.PAYMENTS_METHOD_SELECT_LABEL,
  payload: label,
});
