import styled from 'styled-components';

const statusBgColor = status => {
  const backgroundColor = {
    'To Do': 'rgb(223, 225, 230)',
    'In Progress': 'rgb(222, 235, 255)',
    'In Review': 'rgb(222, 235, 255)',
    'Product review': 'rgb(222, 235, 255)',
    'Done': 'rgb(227, 252, 239)',
  };
  return backgroundColor[status] || backgroundColor.default;
};

const statusFontColor = status => {
  const fontColor = {
    'To Do': 'rgb(66, 82, 110);',
    'In Progress': 'rgb(7, 71, 166)',
    'In Review': 'rgb(7, 71, 166)',
    'Product review': 'rgb(7, 71, 166)',
    'Done': 'rgb(0, 102, 68)',
  };
  return fontColor[status] || fontColor.default;
};

export const Tag = styled.span`
  display: inline-block;
  font-size: ${({ theme: { fonts } }) => fonts.size.default};
  font-weight: 700;
  padding: 0.2rem 0.4rem;
  border-radius: 0.3rem;
  color: ${({ status }) => statusFontColor(status)};
  background: ${({ status }) => statusBgColor(status)};
`;
