import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SearchBar as BaseSearchBar } from '../../../components';

type RouteProps = RouteComponentProps<{
  id: string;
}>;

type SearchBarProps = RouteProps & {
  placeholder: string;
};

const replaceParams = (url: string, params: Record<string, string>) =>
  Object.keys(params).reduce(
    (url, key) => url.replace(`:${key}?`, params[key]).replace(`:${key}`, params[key]),
    url,
  );

const SearchBar: React.FC<SearchBarProps> = ({ match, history, placeholder }) => {
  const routeId = match.params.id || '';

  const [id, setId] = useState(routeId);

  useEffect(() => {
    setId(routeId);
  }, [routeId]);

  const handleSubmit = () => {
    history.push(replaceParams(match.path, { ...match.params, id: id.trim() }));
  };

  return (
    <BaseSearchBar onSubmit={handleSubmit} placeholder={placeholder} value={id} onChange={setId} />
  );
};

export default withRouter(SearchBar);
