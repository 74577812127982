import styled from 'styled-components';

const getTitleFontSize = ({ theme: { fonts } }) => {
  return `calc(0.2rem + ${fonts.size.medium})`;
};

export const Title = styled.div`
  display: flex;
  align-items: center;

  font-size: ${props => getTitleFontSize(props)};
  color: ${({ theme: { palette } }) => palette.anthracite};
  letter-spacing: ${({ theme: { fonts } }) => fonts.spacing.big};
  font-family: ${({ theme: { fonts } }) => fonts.family};
  text-transform: uppercase;
`;
