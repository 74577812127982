import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Drift
 *
 * This component will add a js script to the dom when it is initialized.
 * The js script will add a drift's chat component to the dom.
 *
 * @param {string} appId // The appId is a required parameter which contain the drift app id.
 *
 * @return {null}
 */

class Drift extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
  };

  /**
   * Component did mount.
   * We call here the init methode, if the component was already init it displays the chat as a block.
   */
  componentDidMount() {
    if (document.getElementById('drift-widget-container')) {
      const driftWidgetContainer = document.getElementById('drift-widget-container');
      driftWidgetContainer.style.display = 'block';
    } else {
      this.init();
    }
  }

  /**
   * Component will unmount.
   * If the component is initialized, it is not displayed.
   */
  componentWillUnmount() {
    if (document.getElementById('drift-widget-container')) {
      const driftWidgetContainer = document.getElementById('drift-widget-container');
      driftWidgetContainer.style.display = 'none';
    }
  }

  /**
   * init methode.
   * We init here drift chat.
   */
  init() {
    const { appId } = this.props;

    !(function () {
      const t = (window.driftt = window.drift = window.driftt || []);
      if (!t.init) {
        if (t.invoked)
          return void (
            window.console &&
            console.error &&
            console.error('Drift snippet included twice.')
          );
        t.invoked = !0;
        t.methods = [
          'identify',
          'config',
          'track',
          'reset',
          'debug',
          'show',
          'ping',
          'page',
          'hide',
          'off',
          'on',
        ];
        t.factory = function (e) {
          return function () {
            const n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        };
        t.methods.forEach(function (e) {
          t[e] = t.factory(e);
        });
        t.load = function (t) {
          const e = 3e5;
          const n = Math.ceil(new Date() / e) * e;
          const o = document.createElement('script');
          o.type = 'text/javascript';
          o.async = !0;
          o.crossorigin = 'anonymous';
          o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js';
          const i = document.getElementsByTagName('script')[0];
          if (i) {
            i.parentNode.insertBefore(o, i);
          }
        };
      }
    })();
    window.drift.SNIPPET_VERSION = '0.3.1';
    window.drift.load(appId);
  }

  render() {
    return null;
  }
}

export default Drift;
