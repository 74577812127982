import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

import { Theme } from '@tillersystems/stardust';

const animationPlaceholder = keyframes`
  0% {
    background-position: -150px 0;
  }
  100% {
    background-position: 150px 0;
  }
`;

const cellAlignToJustify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const TableRowContainer = styled.div`
  width: 100%;
  height: auto;
`;

const TableRowItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid ${Theme.palette.lightGrey};
  &:first-child {
    border-top: 1px solid ${Theme.palette.lightGrey};
  }
  &:last-child {
    border: none;
  }
  background-color: ${Theme.palette.white};
`;

const TableRowCell = styled.div`
  box-sizing: content-box;
  display: flex;
  justify-content: ${({ align }) => cellAlignToJustify[align] || 'center'};
  align-items: center;
  width: ${({ width }) => width};
  height: 6rem;
  padding: 0 1rem;
`;

const TableRowCellBody = styled.div`
  animation: ${animationPlaceholder} 1.5s linear infinite forwards;
  background: ${transparentize(0.1, Theme.palette.lightGrey)};
  background: linear-gradient(
    to right,
    ${transparentize(0.1, Theme.palette.lightGrey)} 8%,
    ${transparentize(0.1, Theme.palette.lightGrey)} 18%,
    ${transparentize(0.2, Theme.palette.lightGrey)} 33%
  );
  height: 3rem;
  width: 100%;
`;

export { TableRowCell, TableRowCellBody, TableRowContainer, TableRowItem };
