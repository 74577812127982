export const eventNames = {
  names: ['loaded', 'data'],

  has(eventName) {
    return this.names.includes(eventName);
  },

  get(eventName) {
    if (this.has(eventName)) return eventName;
    return undefined;
  },

  createStorage() {
    const store = {};

    this.names.forEach(name => {
      store[name] = [];
    });

    return store;
  },
};
