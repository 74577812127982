/**
 * Stores sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { init } from '../../service';
import { getOrder } from '../service';

import { orderDetailFailed, orderDetailSucceeded } from './actions';
import { ORDER_DETAIL_REQUESTED } from './actionTypes';

/**
 * getStores saga
 *
 * @generator
 */
export function* getOrderDetail({ payload: { orderId } }) {
  try {
    // try to initialize our userSdk.  Redux Saga
    // will pause here until we either are successful or
    // receive an error
    const manager = yield call(init);

    // Call to our apis in parallel
    const order = yield call(getOrder, manager, orderId);

    // inform Redux to set our client store(s)
    yield put(orderDetailSucceeded({ order }));
  } catch (error) {
    console.warn(error);

    // If we get an error we send Redux the appropiate action and return
    yield put(orderDetailFailed(error));
  }
}

/**
 * Global listener.
 */
export default function* orderDetailWatcher() {
  yield takeLatest(ORDER_DETAIL_REQUESTED, getOrderDetail);
}
