import { createSelector } from 'reselect';

import { LOADING } from '../../constants/Status';

/**
 * Shop Events Selectors
 */

/**
 * If last attempt at fetching data resulted in an error, returns the error value
 *
 * @return {string}
 */
export const getErrorShop = ({ shop }) => shop.error;

/**
 * Get the loading status about the fetched datas
 *
 * @return {string}
 */
export const getStatusShop = ({ shop }) => shop.status;

/**
 * Get the fetched shop id
 *
 * @return {string}
 */
export const getShopId = ({ shop }) => shop.id;

/**
 * If data is being fetched
 *
 * @return {boolean}
 */
export const isLoadingShop = createSelector([getStatusShop], status => status === LOADING);

/**
 * Get all orders by shop
 *
 * @return {Array} orders
 */
export const getShopOrders = ({ shop }) => shop.orders || [];

/**
 * Return true if there is almost one order
 *
 * @return {boolean}
 */
export const isOrdersExist = createSelector([getShopOrders], orders => orders.length !== 0);
