import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;

  padding: 0.5rem;

  border-radius: ${({ theme: { dimensions } }) => `${dimensions.radiusInt - 0.2}rem`};

  background: ${({ active, theme: { palette } }) =>
    active ? palette.bodyBackground : 'transparent'};
`;

export const Title = styled.span`
  font-size: ${({ theme: { fonts } }) => fonts.medium};

  color: ${({ theme: { palette } }) => palette.blackLight};
`;
