import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Input,
  SubHeadline,
  useNotificationToast,
} from '@sumup/circuit-ui';

import { useFeatureActivation } from './useFeatureActivation';

const SingleStore: React.FC = () => {
  const { setToast } = useNotificationToast();
  const { t } = useTranslation();

  const featureCodeIdRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);
  const storeIdIdRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);

  const { mutate, isInProgress, status } = useFeatureActivation();

  useEffect(() => {
    const featureCodeValue = featureCodeIdRef.current?.value;
    const storeIdIdValue = storeIdIdRef.current?.valueAsNumber;

    const featureActivationStatus = status.find(
      featureActivationStatus =>
        featureActivationStatus.featureCode === featureCodeValue &&
        featureActivationStatus.storeId === storeIdIdValue,
    );

    if (!featureActivationStatus) {
      return;
    }

    switch (featureActivationStatus.status) {
      case 'failed':
        setToast({
          body: t('features.featureActivation.singleStore.error.message', {
            featureCode: featureCodeValue,
            storeId: storeIdIdValue,
          }),
          variant: 'alert',
        });
        break;
      case 'succeeded':
        setToast({
          body: t('features.featureActivation.singleStore.success.message', {
            featureCode: featureCodeValue,
            storeId: storeIdIdValue,
          }),
          variant: 'confirm',
        });
        break;
      default:
        break;
    }
  }, [status]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    const featureCodeValue = featureCodeIdRef.current?.value;
    const storeIdValue = storeIdIdRef.current?.valueAsNumber;

    if (!featureCodeValue || !storeIdValue) {
      setToast({ body: t('features.featureActivation.error.message'), variant: 'alert' });
      return;
    }

    mutate([{ featureCode: featureCodeValue, storeId: storeIdValue }]);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <CardHeader>
          <SubHeadline as="h4">{t('features.featureActivation.singleStore.title')}</SubHeadline>
        </CardHeader>
        <Body>
          <Input
            ref={featureCodeIdRef}
            required
            label={t('features.featureActivation.featureCode.label')}
            name="featureCode"
            placeholder={t('features.featureActivation.featureCode.placeholder')}
          />
          <Input
            ref={storeIdIdRef}
            required
            type="number"
            label={t('features.featureActivation.storeId.label')}
            name="storeId"
            placeholder={t('features.featureActivation.storeId.placeholder')}
          />
        </Body>
        <CardFooter>
          <Button
            loadingLabel={t('features.featureActivation.action.toggle.loadingLabel')}
            isLoading={isInProgress}
            type="submit"
            variant="primary"
          >
            {t('features.featureActivation.action.toggle.label')}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default SingleStore;
