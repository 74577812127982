import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 34rem;
  grid-template-areas: 'Main Rightbar';

  /* For small devices */
  ${breakpoint('xs', 'md')`
    grid-template-columns: auto;
    grid-template-areas: 'Main' 'Rightbar';
  `};
`;

export const Main = styled.div`
  grid-area: Main;
`;

export const Rightbar = styled.div`
  grid-area: Rightbar;
`;

export const Title = styled.div`
  font-size: 2rem;
`;
