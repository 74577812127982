import { DateTime } from 'luxon';

import * as Cells from './Cells';
import DefinitionList from '.';

export const createDefinitions = data => {
  return typeof data === 'object'
    ? Object.keys(data).map(key => ({
        name: key,
        label: key,
        value: item => item[key],
        ...getFormat(data[key]),
      }))
    : [];
};

export const getFormat = value => {
  if (value instanceof Array) {
    return {
      Component: Cells.List,
      options: {
        itemFormat: getFormat(value[0]),
      },
    };
  }

  if (value instanceof DateTime || value instanceof Date) {
    return {
      Component: Cells.DateTime,
    };
  }

  if (typeof value === 'boolean') {
    return {
      Component: Cells.BooleanField,
    };
  }

  if (typeof value === 'object' && value !== null) {
    return {
      // eslint-disable-next-line
      Component: ({ value }) => <DefinitionList data={value} />,
      isHeader: true,
    };
  }

  if (typeof value === 'number') {
    return {
      Component: Cells.NumberField,
    };
  }

  return {
    Component: Cells.StringField,
  };
};
