import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.div`
  background: ${({ theme: { palette } }) => palette.white};

  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};

  cursor: pointer;
  ${({ checked, theme: { palette } }) =>
    checked
      ? `border: 2px solid ${palette.primary.default}; padding: 2.3rem 2.9rem;`
      : `border: 1px solid ${palette.lightGrey}; padding: 2.4rem 3rem;`}
  margin: 1.4rem 1.5rem;
  flex: 1 1 0;

  &:first-of-type {
    margin: 1.4rem 1.5rem 1.4rem 0;
  }

  &:last-of-type {
    margin: 1.4rem 0 1.4rem 1.5rem;
  }

  /* For small devices */
  ${breakpoint('xs', 'lg')`
      border: 0;
      border-radius: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: solid 1px ${({ theme: { palette } }) => palette.lightGrey};
      padding: ${({ theme: { dimensions } }) => dimensions.medium} 0;
      &:first-child {
        padding: 0 0 ${({ theme: { dimensions } }) => dimensions.medium} 0;
        border-top: 0;
        margin: 0;
      }
      &:last-child {
        padding: ${({ theme: { dimensions } }) => dimensions.medium} 0 0;
        margin: 0;
      }
  `}
`;

export const Label = styled.div`
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  line-height: ${({ theme: { fonts } }) => fonts.size.h4};
  color: ${({ theme: { palette } }) => palette.darkBlue};
`;

export const Detail = styled.div`
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
  line-height: ${({ theme: { fonts } }) => fonts.size.h4};
  color: ${({ theme: { palette } }) => palette.spaceGrey};
  padding-bottom: 0.9rem;

  /* For small devices */
  ${breakpoint('xs', 'lg')`
    display: none;
  `}
`;

/**
 * Container of the actual check box.
 *
 * @return {jsx}
 */
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.6rem;
  height: 1.6rem;

  border: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  border-radius: 50%;

  background: linear-gradient(
    0deg,
    ${({ theme: { palette } }) => palette.mysticGrey} 0%,
    ${({ theme: { palette } }) => palette.white} 100%
  );
  box-shadow: inset 0 2px 0 0 ${({ theme: { palette } }) => palette.whiteOpacity(0.05)},
    0 1px 1px 0 ${({ theme: { palette } }) => palette.mysticGrey};

  ${({ checked }) =>
    checked &&
    css`
      border: 1px solid ${({ theme: { palette } }) => palette.primary.dark};
      background: ${({ theme: { palette } }) => palette.primary.default}
        linear-gradient(
          0deg,
          ${({ theme: { palette } }) => palette.whiteOpacity(0)} 0%,
          ${({ theme: { palette } }) => palette.whiteOpacity(0.1)} 100%
        );
      box-shadow: inset 0 2px 0 0 ${({ theme: { palette } }) => palette.whiteOpacity(0.1)};
      position: relative;
    `};

  &:after {
    ${({ checked }) =>
      checked &&
      css`
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: ${({ theme: { dimensions } }) => dimensions.small};
        height: ${({ theme: { dimensions } }) => dimensions.small};
        transform: translate3d(-50%, -50%, 0);
        background-color: ${({ theme: { palette } }) => palette.white};
        border-radius: 50%;
      `};
  }

  /* For large devices */
  ${breakpoint('lg')`
    visibility: hidden;
  `}
`;
