import React from 'react';
import PropTypes from 'prop-types';

import { AsideContainer, Container, Price, Title, Wrapper } from './elements';

/**
 * ListItem
 *
 * This component is in charge of displaying
 * a listItem
 *
 * @param {node} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {number} price // the price which cost the item.
 * @param {node} aside // aside component which can be a select, a toggle, etc.
 * @param {bool} wrapped // Change behaviour in responsive mode, if set to true the aside component gonna wrap.
 *
 * @return {jsx}
 */

const Item = ({ children, price, aside, wrapped }) => (
  <Wrapper wrapped={wrapped}>
    <Container>
      <Title>{children}</Title>
      <Price>{price} €</Price>
    </Container>
    {aside && <AsideContainer wrapped={wrapped}>{aside}</AsideContainer>}
  </Wrapper>
);

/**
 * PropTypes Validation
 */
const { node, number, bool } = PropTypes;
Item.propTypes = {
  children: node,
  aside: node,
  price: number,
  wrapped: bool,
};

/**
 * Default props
 */
Item.defaultProps = {
  children: null,
  aside: null,
  price: 0,
  wrapped: false,
};

export default Item;
