/**
 * Shop Events reducer
 */
import { produce } from 'immer';

import { FAILED, LOADED, LOADING, NONE } from '../../constants/Status';

import * as t from './actionType';

const initialState = {
  status: NONE,
  error: null,
  orders: [],
  id: null,
};

/**
 * Shop Events root reducer
 *
 * @param {object} state initial or previous state of data
 * @param {object} action the action object
 *
 * @return {object}
 */
export default function root(state = initialState, action = {}) {
  switch (action.type) {
    case t.SHOP_ORDERS_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status = LOADING;
        draft.error = null;
      });
    case t.SHOP_ORDERS_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.status = LOADED;
        draft.error = null;
        draft.orders = action.payload.orders;
        draft.id = action.payload.shopId;
      });
    case t.SHOP_ORDERS_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.status = FAILED;
        draft.error = action.payload;
        draft.orders = [];
        draft.id = null;
      });
    default:
      return state;
  }
}
