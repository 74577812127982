import { create } from 'apisauce';

import { ApiError } from '../../helpers/errors';

/**
 * @typedef {Object} ApiPaymentMethodConfig
 * @property {string} label
 * @property {boolean} hasExtraConfig
 * @property {boolean} creditNotOnly
 * @property {string} [featureCode]
 *
 * @typedef {Object} ApiPaymentCategoryConfig
 * @property {string} category
 * @property {ApiPaymentMethodConfig[]} payment_methods
 *
 * @typedef {ApiPaymentCategoryConfig[]} ApiPaymentsConfig
 */

/**
 * @typedef {Object} PaymentMethodConfig
 * @property {boolean} hasExtraConfig
 * @property {boolean} creditNotOnly
 * @property {string} [featureCode]
 *
 * @typedef {Object.<string, PaymentMethodConfig>} PaymentCategoryConfig
 *
 * @typedef {Object.<string, PaymentCategoryConfig>} PaymentsConfig
 */

const DEFAULT = 'DEFAULT';

/**
 * Create the country information API
 */
const countryInformation = create({
  baseURL: process.env.REACT_APP_TILLER_COUNTRY_INFORMATION_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_TILLER_COUNTRY_INFORMATION_API_TOKEN,
  },
});

/**
 * Transform payments config data to be usable by the api
 * from:
 *  {
 *    [category: string]: {
 *      [label: string]: {
 *        hasExtraConfig: boolean,
 *        creditNoteOnly: boolean,
 *        featureCode?: string,
 *      }
 *    }
 *  }
 * to:
 *  [
 *    {
 *      category: string,
 *      payment_methods: [
 *        {
 *          label: string,
 *          hasExtraConfig: boolean,
 *          creditNoteOnly: boolean,
 *          featureCode?: string,
 *        }
 *      ]
 *    }
 *  ]
 * @param {PaymentsConfig} paymentsConfig
 * @returns {ApiPaymentsConfig}
 */
const transformToApiPaymentsConfig = paymentsConfig => {
  const apiPaymentsConfig = [];
  for (const category in paymentsConfig) {
    let hasPaymentConfig = false;
    const paymentsOptions = paymentsConfig[category];
    const categoryConfig = {
      category: category,
      payment_methods: [],
    };

    for (const paymentLabel in paymentsOptions) {
      hasPaymentConfig = true;
      const options = paymentsOptions[paymentLabel];
      // The Api does not accept null values,
      // so we need to delete the empty fields
      if (!options.featureCode) {
        delete options.featureCode;
      }
      categoryConfig.payment_methods.push({
        label: paymentLabel,
        ...paymentsOptions[paymentLabel],
      });
    }

    if (hasPaymentConfig) {
      apiPaymentsConfig.push(categoryConfig);
    }
  }
  return apiPaymentsConfig;
};

/**
 * Transform payments config data returned by the api to be used by the app
 * from:
 *  [
 *    {
 *      category: string,
 *      payment_methods: [
 *        {
 *          label: string,
 *          hasExtraConfig: boolean,
 *          creditNoteOnly: boolean,
 *          featureCode?: string,
 *        }
 *      ]
 *    }
 *  ]
 * to:
 *  {
 *    [category:string]: {
 *      [label:string]: {
 *        hasExtraConfig: boolean,
 *        creditNoteOnly: boolean,
 *        featureCode?: string,
 *      }
 *    }
 *  }
 * @param {ApiPaymentsConfig} apiPaymentsConfig
 * @returns {PaymentsConfig}
 */
const transformToPaymentsConfig = apiPaymentsConfig => {
  return apiPaymentsConfig.reduce((paymentConfig, categoryConfig) => {
    paymentConfig[categoryConfig.category] = categoryConfig.payment_methods.reduce(
      (categoryPaymentConfig, { label, ...options }) => {
        categoryPaymentConfig[label] = options;
        return categoryPaymentConfig;
      },
      {},
    );
    return paymentConfig;
  }, {});
};

/**
 * Wrap the payment config to a usable format for the api
 *
 * @param {ApiPaymentsConfig} paymentsConfig
 * @return {Object} Wrapped payment configuration to pass to the api
 */
const wrapPaymentConfig = paymentsConfig => ({
  schemas: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    entities: [],
    paymentMethod: {
      categories: paymentsConfig,
    },
  },
});

/**
 * Unwrap the payment configuration return by the api
 *
 * @param {Object} response
 * @return {ApiPaymentsConfig|null}
 */
const unwrapPaymentConfig = response => response?.schemas?.paymentMethod?.categories || null;

/**
 * Get available configuration version
 *
 * @param {string} [countryCode] - 2 letters ISO country code
 * @param {boolean} [forced=false] - always get a version, even if there is none for the country
 * @return {Promise<string[]>} an array of available configuration versions
 */
const retrieveConfigVersions = async (countryCode, forced) => {
  countryCode = countryCode ? countryCode.toUpperCase() : DEFAULT;
  const { ok, problem, data } = await countryInformation.get(
    `/countries/${countryCode}/informations`,
  );

  if (!ok) {
    throw new ApiError(problem);
  }

  // If no version found for a country and one is required use the version from default
  if (!data.length && forced && countryCode !== DEFAULT) {
    return retrieveConfigVersions(DEFAULT);
  }

  return data;
};

/**
 * Get latest configuration version available
 *
 * @param {string} [countryCode] - 2 letters ISO country code
 * @param {boolean} [forced=false] - always get a version, even if there is none for the country
 * @return {Promise<string>} the latest configuration version available
 */
export const retrieveLatestConfigVersion = async (countryCode, forced) => {
  const version = await retrieveConfigVersions(countryCode, forced);

  return version.pop();
};

/**
 * Get the default payment configuration
 *
 * @param {string} version configuration version to use, usually the latest
 * @return {Promise<PaymentsConfig>} requested payment configuration
 */
export const retrieveDefaultPaymentConfig = async version =>
  retrievePaymentConfig(DEFAULT, version);

/**
 * Get a payment configuration for a country
 *
 * @param {string} countryCode - 2 letters ISO country code
 * @param {string} version configuration version to use, usually the latest
 * @return {Promise<PaymentsConfig>} requested payment configuration
 */
export const retrievePaymentConfig = async (countryCode, version) => {
  const { ok, problem, data } = await countryInformation.get(
    `/countries/${countryCode.toUpperCase()}/informations/${version}/paymentMethod`,
  );

  if (!ok) {
    throw new ApiError(problem);
  }

  return transformToPaymentsConfig(data);
};

/**
 * Create a payment configuration for a country
 *
 * @param {string} countryCode - 2 letters ISO country code
 * @param {string} version configuration version to update, usually the latest
 * @param {PaymentsConfig} paymentConfig the new payment configuration to save
 * @return {Promise<PaymentsConfig>} the new payment configuration
 * @throws {ApiError} if an error occur with api call
 */
export const createPaymentConfig = async (countryCode, version, paymentConfig) => {
  const { ok, problem, data } = await countryInformation.post(
    `/countries/${countryCode.toUpperCase()}/configuration/${version}/paymentMethod`,
    wrapPaymentConfig(transformToApiPaymentsConfig(paymentConfig)),
  );

  if (!ok) {
    throw new ApiError(problem);
  }

  return transformToPaymentsConfig(unwrapPaymentConfig(data));
};

/**
 * Update the default payment configuration
 *
 * @param {string} version configuration version to update, usually the latest
 * @param {PaymentsConfig} paymentConfig the new payment configuration to save
 * @return {Promise<PaymentsConfig>} the updated payment configuration
 * @throws {ApiError} if an error occur with api call
 */
export const updateDefaultPaymentConfig = async (version, paymentConfig) =>
  updatePaymentConfig(DEFAULT, version, paymentConfig);

/**
 * Update the payment configuration for a country
 *
 * @param {string} countryCode - 2 letters ISO country code
 * @param {string} version configuration version to update, usually the latest
 * @param {PaymentsConfig} paymentConfig the new payment configuration to save
 * @return {Promise<PaymentsConfig>} the updated payment configuration
 * @throws {ApiError} if an error occur with api call
 */
export const updatePaymentConfig = async (countryCode, version, paymentConfig) => {
  const { ok, problem, data } = await countryInformation.put(
    `/countries/${countryCode.toUpperCase()}/configuration/${version}/paymentMethod`,
    wrapPaymentConfig(transformToApiPaymentsConfig(paymentConfig)),
  );

  if (!ok) {
    throw new ApiError(problem);
  }

  return transformToPaymentsConfig(unwrapPaymentConfig(data));
};
