import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import { lighten, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Button, Select } from '@tillersystems/stardust';

import { Card } from '../../../../components';

export const Header = styled(Card.Header)`
  white-space: nowrap;
  flex-wrap: wrap;
`;

export const CardTitle = styled.div`
  text-align: left;
  margin-right: auto;
`;

export const SettingsBar = styled(Card.Header)`
  font-size: 1.4rem;
  font-weight: normal;
  white-space: nowrap;
  background ${({ theme: { palette } }) => palette.veryLightGrey};
  flex-wrap: wrap;
`;

export const SettingsBarTitle = styled.div`
  text-align: left;
  width: auto;
  margin-right: auto;
`;

export const Body = styled(Card.Body)`
  padding: 0;
  min-height: 10rem;
  height: auto;
  overflow: initial;
  display: block;
  position: relative;
`;

export const Footer = styled(Card.Footer)`
  display: block;
  text-align: center;
  padding: 0;
  border-top: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  background: ${({ theme: { palette } }) => palette.veryLightBlue};
`;

export const NewEventButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  white-space: nowrap;
  padding: 0.6rem 1rem;
  cursor: pointer;
  margin: 0 auto 1rem;

  ${({ theme: { palette, dimensions } }) => css`
    background: ${transparentize(0.5, palette.white)};
    color: ${palette.primary.default};
    border: 1px solid currentColor;
    border-top: 0;
    border-bottom-left-radius: ${dimensions.radius};
    border-bottom-right-radius: ${dimensions.radius};

    &:hover,
    &:focus,
    &:active {
      background: ${transparentize(0.2, palette.white)};
    }
  `};
`;

export const ErrorContent = styled.div`
  padding: 1rem;
  color: ${({ theme: { palette } }) => palette.failure.default};
  background: ${({ theme: { palette } }) => lighten(0.2, palette.failure.default)};
  border-bottom: 0.1rem solid currentColor;
`;

export const ToggleLabel = styled.span`
  margin-right: 1rem;
`;

export const Toggle = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
  margin-right: 1rem;
`;

export const SynchronizeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
`;

export const SynchronizeButton = styled(Button)``;

export const CommentSelect = styled(Select)`
  width: 30rem;
  max-width: 100%;
`;

export const EventTypesSelect = styled(Select)`
  margin-right: 1.5rem;
  width: 25rem;
`;

export const JSONViewerContainer = styled.div`
  overflow: scroll;
`;

export const JSONViewer = (props: ReactJsonViewProps) => (
  <JSONViewerContainer>
    <ReactJson {...props} />
  </JSONViewerContainer>
);
