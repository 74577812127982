import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, Headline } from '@sumup/circuit-ui';

import { useDataIntegrityContext } from '../context';

import CheckResult from './CheckResult';

const ResultCard = () => {
  const { t } = useTranslation();
  const { isLoading, integrityChecks } = useDataIntegrityContext();

  return (
    <Card data-testid="result_display">
      <CardHeader>
        <Headline size="four" as="h2" noMargin>
          {t('data_integrity.result_display.title')}
        </Headline>
      </CardHeader>
      {isLoading && (
        <span data-testid="searching">{t('data_integrity.result_display.check.searching')}</span>
      )}
      {!isLoading &&
        integrityChecks.length > 0 &&
        integrityChecks.map(check => <CheckResult check={check} key={check.filename} />)}
      {!isLoading && integrityChecks.length === 0 && (
        <span data-testid="nothing_found">
          {t('data_integrity.result_display.check.nothing_found')}
        </span>
      )}
    </Card>
  );
};

export default ResultCard;
