import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';

import { OptionsList } from '@tillersystems/stardust';

import { searchMethod } from '../helpers';

import { StyledSelect } from './elements';

const Select = ({
  values,
  options,
  onChange,
  placeholder,
  allowMultiple,
  toggleAllLabel,
  disabled,
}) => {
  const [t] = useTranslation();

  return (
    <StyledSelect
      usePortal
      values={Array.isArray(values) || allowMultiple ? values : [values]}
      onChange={value => onChange(allowMultiple ? value : value[0])}
      allowMultiple={allowMultiple}
      contentWrapperStyle={{ maxHeight: '26rem' }}
      searchMethod={searchMethod}
      toggleAllLabel={toggleAllLabel}
      OptionComponent={allowMultiple ? OptionsList.CheckboxOption : undefined}
      disabled={disabled}
      placeholder={placeholder}
    >
      {options.map(({ translation_key, label }) => (
        <StyledSelect.Option key={label} value={label}>
          {t(translation_key)}
        </StyledSelect.Option>
      ))}
    </StyledSelect>
  );
};

Select.propTypes = {
  values: oneOfType([string, arrayOf(string)]),
  options: arrayOf(shape({ label: string.isRequired, translation_key: string })).isRequired,
  onChange: func.isRequired,
  placeholder: string,
  allowMultiple: bool,
  toggleAllLabel: string,
  disabled: bool,
};

Select.defaultProps = {
  values: [],
  placeholder: null,
  allowMultiple: false,
  toggleAllLabel: null,
  disabled: false,
};

export default Select;
