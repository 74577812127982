import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, CardHeader } from '@sumup/circuit-ui';
import { DateTime } from 'luxon';

import { CheckResultProps } from '../type';

import { StyledCard } from './elements';

const CheckResult = ({ check }: CheckResultProps) => {
  const { t } = useTranslation();

  const title = t('data_integrity.result_display.check.title', {
    from: check.period.from.toLocaleString(DateTime.DATETIME_MED),
    to: check.period.to.toLocaleString(DateTime.DATETIME_MED),
  });

  return (
    <StyledCard data-testid={`check_result_card`}>
      <CardHeader data-testid={`check_result_title`}>{title}</CardHeader>
      <Badge variant={check.integrity ? 'success' : 'danger'} data-testid={`check_result`}>
        {t(`data_integrity.result_display.check.${check.integrity ? 'success' : 'failure'}`)}
      </Badge>
    </StyledCard>
  );
};

export default CheckResult;
