import React from 'react';
import { Link } from 'react-router-dom';
import { map, sortedUniq } from 'lodash';

import { Tooltip } from '@tillersystems/stardust';

import i18n from '../../../i18n';
import { getImageUrl } from '../helpers';

import { Logo } from './elements';

export const colsDef = (showDefaultCategory = false) =>
  [
    {
      title: i18n.t('payment_methods.table.logo'),
      name: 'logo',
      value: d => d.icon,
      format: v => <Logo src={getImageUrl(v)} alt="" />,
      align: 'right',
      isSortable: true,
      isRowHeader: true,
    },
    {
      title: i18n.t('payment_methods.table.payment_method'),
      name: 'payment_method',
      value: d => d.label,
      format: v => <Link to={`/payment-methods/edit/${v}`}>{v}</Link>,
      isSortable: true,
      align: 'left',
    },
    showDefaultCategory && {
      title: i18n.t('payment_methods.table.default_category'),
      name: 'default_category',
      value: d => d.category,
      align: 'left',
    },
    {
      title: i18n.t('payment_methods.table.countries'),
      name: 'countries',
      value: d => d.countries || [],
      format: v => (
        <Tooltip content={v.join(', ')} appearance="dark" maxWidth={400} trigger="mouseenter">
          {v.join(', ')}
        </Tooltip>
      ),
      align: 'right',
    },
    {
      title: i18n.t('payment_methods.table.categories'),
      name: 'categories',
      value: d => d.categories || [],
      format: v => v.join(', '),
      align: 'left',
    },
  ].filter(Boolean);

export const formatData = paymentMethodsWithConfig => {
  return paymentMethodsWithConfig.map(paymentMethodWithConfig => {
    const {
      config: { default: { category } = {}, ...countriesConfig },
      ...paymentMethod
    } = paymentMethodWithConfig;

    const categories = sortedUniq(Object.values(countriesConfig).map(({ category }) => category));

    const data = {
      ...paymentMethod,
      category,
      categories,
      countries: Object.keys(countriesConfig),
    };
    if (categories.length > 1) {
      data.children = map(countriesConfig, (config, country) => ({
        ...paymentMethod,
        categories: [config.category],
        countries: [country],
      }));
    }

    return data;
  });
};
