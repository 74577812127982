import { Checkbox } from '@sumup/circuit-ui';
import styled from 'styled-components';

export const ThreeStateCheckbox = styled(Checkbox)`
  input:indeterminate + label {
    ::before {
      background: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><rect width='8' height='2' x='4' y='7' rx='1'/></svg>");
      border-color: #999;
      background-color: #999;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    > svg {
      display: none;
    }
  }
`;

export const Container = styled.div`
  margin-bottom: 1.6rem;
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 0 1px #eee;
  background: #e6e6e622;
`;
