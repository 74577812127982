import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Icon, Theme } from '@tillersystems/stardust';

import {
  ArrowLeftButton,
  ArrowRightButton,
  Container,
  PageItem,
  PaginationContainer,
  PaginationNumbers,
} from './elements';

/**
 * This component is responsible of displaying a pagination.
 *
 * @param {number} pageCount - number of page.
 * @param {Function} getRequestedPageNumber - get page number of the requested page.
 * @param {string} width - width of the pagination container.
 * @param {string} align - alignement of the pagination.
 */
class Pagination extends PureComponent {
  /**
   * Component prop validation.
   *
   * @static
   */
  static propTypes = {
    pageCount: PropTypes.number,
    getRequestedPageNumber: PropTypes.func,
    width: PropTypes.string,
    align: PropTypes.oneOf(['left', 'right', 'center']),
  };

  /**
   * Component default props.
   *
   * @static
   */
  static defaultProps = {
    width: '10rem',
    pageCount: 0,
    getRequestedPageNumber: () => {
      // handle getRequestedPageNumber here
    },
    align: 'right',
  };

  /**
   * Component initial state.
   */
  state = {
    currentPage: 1,
  };

  /**
   * Action when page item is clicked.
   *
   * @param {number} currentPage - current page clicked.
   */
  pageItemClicked = currentPage => {
    const { getRequestedPageNumber } = this.props;
    this.setState({
      currentPage,
    });
    getRequestedPageNumber(currentPage);
  };

  /**
   * Render each button for pagination.
   *
   * @param {number} count - number of page to display.
   */
  renderPaginationItems = count => {
    const { currentPage } = this.state;
    let childrens = [];
    for (let i = 1; i <= count; i++) {
      childrens.push(
        <PageItem
          data-testid={`page-number-${i}`}
          active={i === currentPage}
          onClick={() => this.pageItemClicked(i)}
          key={i}
        >
          {i}
        </PageItem>,
      );
    }
    return childrens;
  };

  /**
   * Click on previous page item.
   */
  handlePrevious = () => {
    const { getRequestedPageNumber } = this.props;
    const { currentPage } = this.state;

    this.setState(
      prevState => (
        {
          currentPage: prevState.currentPage - 1,
        },
        getRequestedPageNumber(currentPage)
      ),
    );
  };

  /**
   * Click on next page item.
   */
  handleNext = () => {
    const { currentPage } = this.state;
    const { getRequestedPageNumber } = this.props;
    this.setState(
      prevState => (
        {
          currentPage: prevState.currentPage + 1,
        },
        getRequestedPageNumber(currentPage)
      ),
    );
  };

  /**
   * Render method.
   *
   * @return {jsx}
   */
  render() {
    const { pageCount, width, align } = this.props;
    return (
      <Container align={align} data-testid="pagination-main-container">
        <PaginationContainer width={width} data-testid="pagination-container">
          <ArrowLeftButton onClick={this.handlePrevious} data-testid="arrow-left">
            <Icon name="chevron-left" color={Theme.palette.lightGrey} />
          </ArrowLeftButton>
          <PaginationNumbers>{this.renderPaginationItems(pageCount)}</PaginationNumbers>
          <ArrowRightButton onClick={this.handleNext} data-testid="arrow-right">
            <Icon name="chevron-right" color={Theme.palette.lightGrey} />
          </ArrowRightButton>
        </PaginationContainer>
      </Container>
    );
  }
}

export default Pagination;
