/**
 * Payment method reducer
 */
import { produce } from 'immer';

import { FAILED, LOADING, NONE, SUCCEEDED } from '../../constants/Status';

import * as t from './actionTypes';

/**
 * @typedef {Object} PaymentMethod
 * @property {string} id
 * @property {string} label
 * @property {string} icon
 *
 * @typedef {Object} State
 * @property {Object<string, PaymentMethod>} entities
 * @property {number} count
 * @property {string} status
 * @property {Error} [error]
 * @property {string} [selectedLabel]
 */

/**
 * @type {State}
 */
const initialState = {
  entities: {},
  count: 0,
  status: NONE,
  error: null,
  selectedLabel: null,
};

/**
 * Payment method root reducer
 *
 * @param {State} state initial or previous state of data
 * @param {Object} action the action object
 *
 * @return {State}
 */
export default function root(state = initialState, action = {}) {
  switch (action.type) {
    case t.PAYMENT_METHODS_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status = LOADING;
        draft.error = null;
      });
    case t.PAYMENT_METHODS_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.status = SUCCEEDED;
        draft.error = null;
        for (const paymentMethod of action.payload.paymentMethods) {
          draft.entities[paymentMethod.label] = {
            ...draft.entities[paymentMethod.label],
            ...paymentMethod,
          };
        }
        draft.count = action.payload.paymentMethods.length;
      });
    case t.PAYMENT_METHODS_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.status = FAILED;
        draft.error = action.payload;
      });
    case t.PAYMENTS_METHOD_SELECT_LABEL:
      return produce(state, draft => {
        draft.selectedLabel = action.payload;
      });
    default:
      return state;
  }
}
