import styled from 'styled-components';

import { Button } from '@tillersystems/stardust';

export const AddButton = styled(Button)`
  margin: ${({ theme: { dimensions } }) => dimensions.tiny} 0;
`;

export const ResetButton = styled(Button)`
  margin: ${({ theme: { dimensions } }) => dimensions.tiny}
    ${({ theme: { dimensions } }) => dimensions.small} 0;
`;

export const ConfigWrapper = styled.div`
  background: ${({ theme: { palette } }) => palette.white};

  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};

  border: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  padding: ${({ theme: { dimensions } }) => dimensions.medium};
`;

export const ConfigGrid = styled.div`
  display: grid;
  grid-template-columns: max-content repeat(2, auto);
  grid-template-rows: auto;
  row-gap: ${({ theme: { dimensions } }) => dimensions.tiny};
  column-gap: ${({ theme: { dimensions } }) => dimensions.small};
  /* position: relative; */
`;

export const CountryCell = styled.div`
  justify-self: end;
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
`;

export const CategoryCell = styled.div`
  justify-self: start;
  font-weight: ${({ theme: { fonts } }) => fonts.weight.normal};
`;

export const ActionsCell = styled.div`
  justify-self: end;

  /* ::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ffa94d;
  } */
`;

export const EditorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { dimensions } }) => dimensions.tiny} 0;
  align-items: center;
  & > :nth-child(1n):not(:first-child) {
    margin: 0 0 0 ${({ theme: { dimensions } }) => dimensions.small};
  }
  b {
    font-weight: ${({ theme: { fonts } }) => fonts.weight.big};
  }
`;
