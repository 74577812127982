import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import OrderOMSDetail from '../OrderOMSDetail';
import SearchBar from '../SearchBar';

import { Header, Wrapper } from './elements';

const OrderOMSContainer: FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation();
  const rootPath = match.path;

  return (
    <>
      <Header>
        <Route path={`${rootPath}/:id?`}>
          <SearchBar placeholder={String(t('order_management.search_order_placeholder'))} />
        </Route>
      </Header>
      <Wrapper>
        <Switch>
          <Route path={`${rootPath}/:id`}>
            <OrderOMSDetail />
          </Route>
        </Switch>
      </Wrapper>
    </>
  );
};

export default withRouter(OrderOMSContainer);
