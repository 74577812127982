import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Loader } from '@aws-amplify/ui-react';

import { useAuth } from '../../contexts/Auth';

/**
 * Defines a private route.
 *
 * A private route is a declarative route that either renders the route's target component if the
 * user is logged in and authenticated; otherwise it redirects to the login page.
 */
const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated, isRequesting } = useAuth();
  const { pathname } = useLocation();

  if (isRequesting) return <Loader />;

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: '/login', search: `from=${pathname}` }} />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
