import { lighten } from 'polished';
import styled from 'styled-components';

import { Button } from '@tillersystems/stardust';

export const Container = styled.div`
  padding: 1rem;
  flex-grow: 1;
  text-align: initial;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-item: center;
`;

export const FieldLabel = styled.label`
  width: 24rem;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.7rem 0.5rem 0 0;
  font-weight: bold;
`;

export const FieldContent = styled.dd`
  flex-grow: 1;
  padding: 0;
`;

export const FieldError = styled.div`
  padding: 0 0.8rem;
  font-size: 0.7em;
  width: 100%;
  text-align: left;
  height: 1.6rem;
  color: ${({ theme: { palette } }) => palette.failure.default};
`;
export const FormError = styled.div`
  padding: 1rem;
  background: ${({ theme: { palette } }) => lighten(0.2, palette.failure.default)};
  border-top: 0.1rem solid ${({ theme: { palette } }) => palette.failure.default};
`;

export const Footer = styled.div`
  display: flex;
  padding: 1rem;
  background: ${({ theme: { palette } }) => palette.veryLightGrey};
  border-top: 1px solid ${({ theme: { palette } }) => palette.veryLightBlue};
  text-align: initial;
`;

export const SubmitButton = styled(Button)`
  margin-left: auto;
`;
