/**
 * Order Events sagas
 */

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { AdminOrderEvent } from '../../../helpers/models';
import { init } from '../../service';
import { getEvents, replayEvents, syncEvents } from '../service';

import {
  orderEventsCreated,
  orderEventsFailed,
  orderEventsRemoved,
  orderEventsReplayFailed,
  orderEventsReplaySucceeded,
  orderEventsRequested,
  orderEventsStale,
  orderEventsSucceeded,
  orderEventsSyncFailed,
  orderEventsSyncSucceeded,
  orderEventsUpdated,
} from './actions';
import {
  ORDER_EVENTS_CREATE,
  ORDER_EVENTS_REMOVE,
  ORDER_EVENTS_REPLAY_REQUESTED,
  ORDER_EVENTS_REQUESTED,
  ORDER_EVENTS_SYNC_REQUESTED,
  ORDER_EVENTS_UPDATE,
} from './actionTypes';
import { getNewOrderEvents, getOrderEvents } from './selectors';

/**
 * getOrderEvents saga
 *
 * @generator
 */
export function* fetchOrderEvents({ payload: { shopID, rootUUID } }) {
  try {
    // try to initialize our userSdk.  Redux Saga
    // will pause here until we either are successful or
    // receive an error
    const manager = yield call(init);

    // Call to our apis in parallel
    const events = yield call(getEvents, manager, shopID, rootUUID);

    // inform Redux to set our client store(s)
    yield put(orderEventsSucceeded({ shopID, rootUUID, events }));
  } catch (error) {
    console.warn(error);

    // If we get an error we send Redux the appropiate action and return
    yield put(orderEventsFailed(error));
  }
}

export function* syncOrderEvents({ payload: { order, comment } }) {
  const { shopID, rootUUID } = order;
  const currentEvents = yield select(getNewOrderEvents, rootUUID);

  const events = currentEvents.map(event => {
    const serializedEvent = event.serialize();

    // Add a comment to all events
    if (comment) {
      serializedEvent.data = { ...serializedEvent.data, comment };
    }
    return serializedEvent;
  });

  try {
    // try to initialize our userSdk.  Redux Saga
    // will pause here until we either are successful or
    // receive an error
    const manager = yield call(init);

    // Call to our apis in parallel
    const response = yield call(syncEvents, manager, shopID, rootUUID, events);

    // inform Redux to set our client store(s)
    yield put(orderEventsSyncSucceeded({ shopID, rootUUID, response }));
    yield put(orderEventsStale());

    yield delay(2000);

    yield put(orderEventsRequested(order));
  } catch (error) {
    console.warn(error);

    // If we get an error we send Redux the appropiate action and return
    yield put(orderEventsSyncFailed({ shopID, rootUUID, error }));
  }
}

export function* replayOrderEvents({ payload: { order } }) {
  const { shopID, rootUUID } = order;

  try {
    // try to initialize our userSdk.  Redux Saga
    // will pause here until we either are successful or
    // receive an error
    const manager = yield call(init);

    // Call to our apis in parallel
    yield call(replayEvents, manager, shopID, rootUUID);

    // inform Redux to set our client store(s)
    yield put(orderEventsReplaySucceeded({ shopID, rootUUID }));
  } catch (error) {
    console.warn(error);

    // If we get an error we send Redux the appropiate action and return
    yield put(orderEventsReplayFailed(error));
  }
}

export function* createOrderEvent({ payload: { order, event, user } }) {
  const { shopID, rootUUID } = order;
  const currentEvents = yield select(getOrderEvents, rootUUID);

  const source = {
    admin: {
      email: user.getEmail(),
      name: user.getFullName(),
    },
  };

  const adminEvent = AdminOrderEvent.create(shopID, rootUUID, event, source);

  yield put(orderEventsCreated({ shopID, rootUUID, event: adminEvent, currentEvents }));
}

export function* updateOrderEvent({ payload: { order, event } }) {
  const { shopID, rootUUID } = order;

  const adminEvent = new AdminOrderEvent(event);

  yield put(orderEventsUpdated({ shopID, rootUUID, event: adminEvent, uuid: adminEvent.uuid }));
}

export function* removeOrderEvent({ payload: { order, uuid } }) {
  const { shopID, rootUUID } = order;
  const currentEvents = yield select(getOrderEvents, rootUUID);

  yield put(orderEventsRemoved({ shopID, rootUUID, uuid, currentEvents }));
}

/**
 * Global listener.
 */
export default function* orderEventsWatcher() {
  yield takeLatest(ORDER_EVENTS_REQUESTED, fetchOrderEvents);
  yield takeLatest(ORDER_EVENTS_CREATE, createOrderEvent);
  yield takeLatest(ORDER_EVENTS_UPDATE, updateOrderEvent);
  yield takeLatest(ORDER_EVENTS_REMOVE, removeOrderEvent);
  yield takeLatest(ORDER_EVENTS_SYNC_REQUESTED, syncOrderEvents);
  yield takeLatest(ORDER_EVENTS_REPLAY_REQUESTED, replayOrderEvents);
}
