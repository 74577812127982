import { hot } from 'react-hot-loader/root';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import LoginRoute from './components/LoginRoute';
import AppMarket from './pages/AppMarket';
import DataIntegrityPage from './pages/DataIntegrity';
import Features from './pages/Features';
import HomePage from './pages/Home';
import Migration from './pages/Migration';
import NotFoundPage from './pages/NotFound';
import OpsPage from './pages/Ops';
import OrderManagementPage from './pages/OrderManagement';
import PaymentMethodsPage from './pages/PaymentMethods';
import ReleasesPage from './pages/Releases';
import ReportsPage from './pages/Reports';
import SettingsPage from './pages/Settings';
import { PrivateRoute, SideBar, Theme } from './components';
import { AppContainer, Body, Main } from './styles';

const Routes = () => {
  return (
    <ThemeProvider theme={Theme}>
      <AppContainer>
        <Route component={SideBar} />
        <Main>
          <Body>
            <Switch>
              <LoginRoute path="/login" fallbackRedirect="/" />
              <PrivateRoute exact path="/">
                <HomePage />
              </PrivateRoute>
              <PrivateRoute path="/reports">
                <ReportsPage />
              </PrivateRoute>
              <PrivateRoute path="/releases">
                <ReleasesPage />
              </PrivateRoute>
              <PrivateRoute path="/settings">
                <SettingsPage />
              </PrivateRoute>
              <PrivateRoute path="/ops">
                <OpsPage />
              </PrivateRoute>
              <PrivateRoute path="/payment-methods">
                <PaymentMethodsPage />
              </PrivateRoute>
              <PrivateRoute path="/order-management">
                <OrderManagementPage />
              </PrivateRoute>
              <PrivateRoute path="/data-integrity">
                <DataIntegrityPage />
              </PrivateRoute>
              <PrivateRoute path="/appmarket">
                <AppMarket />
              </PrivateRoute>
              <PrivateRoute path="/features">
                <Features />
              </PrivateRoute>
              <PrivateRoute path="/migration">
                <Migration />
              </PrivateRoute>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </Body>
        </Main>
      </AppContainer>
    </ThemeProvider>
  );
};

export default hot(Routes);
