import styled from 'styled-components';

import { Button } from '@tillersystems/stardust';

export const SectionBody = styled.div`
  display: flex;

  ${Button} {
    margin-right: 1rem;
  }
`;

export const CardTitle = styled.div`
  text-align: left;
  width: 100%;
`;
