import { createSelector } from 'reselect';

import { LOADING } from '../../constants/Status';

/**
 * Raw Tickets Selectors
 */

/**
 * If last attempt at fetching data resulted in an error, returns the error value
 *
 * @return {string}
 */
export const getRawTicketsError = ({ rawTickets }) => rawTickets.error;

/**
 * Get the loading status about the fetched datas
 *
 * @return {string}
 */
export const getRawTicketsStatus = ({ rawTickets }) => rawTickets.status;

/**
 * If data is being fetched
 *
 * @return {boolean}
 */
export const isRawTicketsLoading = createSelector(
  [getRawTicketsStatus],
  status => status === LOADING,
);

/**
 * Get rawTickets from the payload
 *
 * @return {Array} raw tickets
 */
export const getRawTickets = ({ rawTickets }) => rawTickets.entities || [];

/**
 * Get number of raw ticket in the payload
 *
 * @return {number} raw tickets count
 */
export const getRawTicketsCount = ({ rawTickets }) => rawTickets.count;

/**
 * Get the current raw tickets index
 *
 * @return {number|null} raw tickets index
 */
export const getRawTicketsIndex = ({ rawTickets }) => rawTickets.index;

/**
 * Return true if there is almost one raw tickets
 *
 * @return {boolean}
 */
export const hasRawTickets = createSelector(
  [getRawTicketsCount],
  rawTicketsCount => rawTicketsCount !== 0,
);

/**
 * Return the current selected raw ticket
 *
 * @return {(object|null)}
 */
export const getCurrentRawTicket = createSelector(
  [getRawTickets, getRawTicketsIndex],
  (rawTickets, index) => (index !== null && rawTickets[index]) || null,
);

/**
 * Return the current raw ticket id
 *
 * @return {(string|null)}
 */
export const getRawTicketId = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.uuid,
);

/**
 * Return the current raw ticket creation date in ISO format
 *
 * @return {(string|null)} raw ticket date ISO format
 */
export const getRawTicketISODate = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.date.raw,
);

/**
 * Return the current raw ticket currency
 *
 * @return {(string|null)} raw ticket currency
 */
export const getRawTicketCurrency = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.order.currency.code,
);

/**
 * Return the current raw ticket formated order lines
 *
 * @return {(array|null)} order lines
 */
export const getRawTicketOrderLines = createSelector(
  [getCurrentRawTicket],
  rawTicket =>
    rawTicket &&
    rawTicket.lines.map(
      ({ uuid, status, name, quantity: { count }, prices, type, open_date, item }) => ({
        uuid,
        status,
        count,
        name,
        type,
        productId: item.id,
        date: open_date.raw,
        price: {
          tax_included: prices.item.initial_price.value,
          tax_excluded: prices.item.tax_excluded.value || prices.item.tax_excluded.raw,
        },
        tax: prices.item.vat_amount.value || prices.item.vat_amount.raw,
        total: prices.total.price.value,
        taxIdentifier: prices.item.vat.length ? prices.item.vat[0].identifier : '',
      }),
    ),
);

/**
 * Return the current raw ticket formated taxes
 *
 * @return {(array|null)} taxes
 */
export const getRawTicketTaxes = createSelector(
  [getCurrentRawTicket],
  rawTicket =>
    rawTicket &&
    rawTicket.taxes.map(({ identifier, rate, tax_excluded, tax_amount, tax_included }) => ({
      key: `${tax_excluded.value}${tax_included.value}`,
      identifier,
      rate,
      taxExcluded: tax_excluded.value || tax_excluded.raw,
      taxAmount: tax_amount.value || tax_amount.raw,
      taxIncluded: tax_included.value || tax_included.raw,
    })),
);

/**
 * Return the current raw ticket formated total prices
 *
 * @return {(array|null)} total prices
 */
export const getRawTicketTotal = createSelector(
  [getCurrentRawTicket],
  rawTicket =>
    rawTicket && {
      taxExcluded:
        rawTicket.summary.total_tax_excluded.value || rawTicket.summary.total_tax_excluded.raw,
      taxIncluded:
        rawTicket.summary.total_tax_included.value || rawTicket.summary.total_tax_included.raw,
    },
);

/**
 * Return the current raw ticket formated payments
 *
 * @return {(array|null)} payments
 */
export const getRawTicketPayments = createSelector(
  [getCurrentRawTicket],
  rawTicket =>
    rawTicket &&
    rawTicket.payments.map(({ uuid, status, type, label, source, amount, date, identifier }) => ({
      uuid,
      id: identifier,
      status,
      type,
      label,
      date: date.raw,
      deviceName: source.device.name,
      price: amount.value || amount.raw,
    })),
);

/**
 * Return the current raw ticket signature
 *
 * @return {(string|null)} signature
 */
export const getRawTicketSignature = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.metadata.signature.ticket_signature,
);

/**
 * Return the current raw ticket version
 *
 * @return {(string|null)} raw ticket version
 */
export const getRawTicketVersion = createSelector(
  [getCurrentRawTicket],
  rawTicket => (rawTicket && rawTicket.version) || null,
);

/**
 * Return the order status
 *
 * @return {(string|null)} order status
 */
export const getOrderStatus = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.order.status,
);

/**
 * Return the order name
 *
 * @return {(string|null)} order name
 */
export const getOrderName = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.order.name,
);

/**
 * Return the store name
 *
 * @return {(string|null)} store name
 */
export const getStoreName = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.store.store_name,
);

/**
 * Return the store id
 *
 * @return {(string|null)} store id
 */
export const getStoreId = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.store.id,
);

/**
 * Return the store cash payed
 *
 * @return {(string|null)} store cash payed
 */
export const getStoreCashPayed = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.summary.total_paid.value,
);

/**
 * Return the store cash price
 *
 * @return {(string|null)} store cash price
 */
export const getStoreCashPrice = createSelector(
  [getCurrentRawTicket],
  rawTicket => rawTicket && rawTicket.summary.total_tax_included.value,
);
