import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const UserName = styled.div`
  flex: 1;

  font-family: ${({ theme: { fonts } }) => fonts.family};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thin};
  letter-spacing: ${({ theme: { fonts } }) => fonts.spacing.medium};

  font-size: ${({ theme: { fonts } }) => fonts.size.default};
  overflow: hidden;
  text-overflow: ellipsis;

  /* For medium devices */
  ${breakpoint('sm', 'lg')`
    display: none;
  `};
`;

export const DisconnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
