import { useQuery as BaseUseQuery } from 'react-query';
import { Auth } from '@aws-amplify/auth';

import { Order, RawTicket } from './types';

const useQuery = <T>(key: string, callback: () => unknown) =>
  BaseUseQuery<unknown, Error, T>(key, callback, { refetchOnWindowFocus: false, retry: false });

const useQueryEvents = (orderUuid: string) => {
  const { error, data, isLoading } = useQuery<Order>(
    `event-sync-fetchEvents-${orderUuid}`,
    async () => {
      const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
      const headers = { authorization: `Bearer ${token}` };

      return fetch(`${process.env.REACT_APP_EVENT_SYNC_URL}/events?rootUuid=${orderUuid}`, {
        headers,
      }).then(res => res.json());
    },
  );

  return {
    order: data,
    orderError: error,
    isOrderLoading: isLoading,
  };
};

const useQueryRawTickets = (orderUuid: string, storeId?: number) => {
  const { error, data, isLoading } = useQuery<RawTicket[]>(
    `rawticket-fetchRawTickets-${storeId}-${orderUuid}`,
    async () => {
      if (!storeId) {
        return;
      }

      return fetch(
        `${process.env.REACT_APP_TILLER_RAW_TICKET_API_URL}/stores/${storeId}/orders/${orderUuid}/raw-tickets`,
        {
          headers: { 'X-API-KEY': process.env.REACT_APP_TILLER_RAW_TICKET_API_TOKEN },
        },
      ).then(res => res.json());
    },
  );

  return {
    rawTickets: data,
    rawTicketsError: error,
    isRawTicketsLoading: isLoading,
  };
};

export const useQueryOrder = (orderUuid: string) => {
  const queryEvents = useQueryEvents(orderUuid);
  const storeId = queryEvents.order?.storeId;
  const queryRawTickets = useQueryRawTickets(orderUuid, storeId);

  return {
    ...queryEvents,
    ...queryRawTickets,
  };
};
