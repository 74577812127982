/**
 * Order Events reducer
 */
import { produce } from 'immer';

import { FAILED, LOADED, LOADING, NONE, SUCCEEDED } from '../../../constants/Status';

import * as t from './actionTypes';
import { cleanSyncedEvents, remapEventsPlayhead, setEventsSyncStatus, sortEvents } from './helpers';

const initialState = {
  entities: null,
  newEntities: {},
  error: null,
  status: NONE,
  replayStatus: NONE,
  replayError: null,
};

/**
 * Order Events root reducer
 *
 * @param {object} state initial or previous state of data
 * @param {object} action the action object
 *
 * @return {object}
 */
export default function root(state = initialState, action = {}) {
  switch (action.type) {
    case t.ORDER_EVENTS_STALE:
    case t.ORDER_EVENTS_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status = LOADING;
        draft.error = null;
      });
    case t.ORDER_EVENTS_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        const { rootUUID, events } = action.payload;
        draft.status = LOADED;
        draft.replayStatus = NONE;
        draft.error = null;
        draft.entities = sortEvents(events);
        if (draft.newEntities[rootUUID]) {
          draft.newEntities[rootUUID] = cleanSyncedEvents(
            draft.newEntities[rootUUID],
            draft.entities,
          );
        }
      });
    case t.ORDER_EVENTS_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.entities = null;
        draft.status = FAILED;
        draft.error = action.payload;
      });

    case t.ORDER_EVENTS_CREATED:
      return produce(state, draft => {
        const { rootUUID, event, currentEvents } = action.payload;
        draft.newEntities[rootUUID] = [...(draft.newEntities[rootUUID] || []), event];
        draft.newEntities[rootUUID] = remapEventsPlayhead(
          currentEvents,
          draft.newEntities[rootUUID],
        );
      });

    case t.ORDER_EVENTS_UPDATED:
      return produce(state, draft => {
        const { rootUUID, event, uuid } = action.payload;
        const eventIndex = draft.newEntities[rootUUID].findIndex(event => event.uuid === uuid);
        if (eventIndex > -1) {
          draft.newEntities[rootUUID].splice(eventIndex, 1, event);
        }
      });

    case t.ORDER_EVENTS_REMOVED:
      return produce(state, draft => {
        const { rootUUID, uuid, currentEvents } = action.payload;
        const eventIndex = draft.newEntities[rootUUID].findIndex(event => event.uuid === uuid);
        if (eventIndex > -1) {
          draft.newEntities[rootUUID].splice(eventIndex, 1);
        }
        draft.newEntities[rootUUID] = remapEventsPlayhead(
          currentEvents,
          draft.newEntities[rootUUID],
        );
      });

    case t.ORDER_EVENTS_SYNC_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        const {
          order: { rootUUID },
        } = action.payload;

        draft.newEntities[rootUUID] = (draft.newEntities[rootUUID] || []).map(event => {
          event.setSyncStatus('syncing');
          return event;
        });
      });
    case t.ORDER_EVENTS_SYNC_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        const { rootUUID, response } = action.payload;

        draft.newEntities[rootUUID] = setEventsSyncStatus(
          draft.newEntities[rootUUID] || [],
          response,
        );
      });
    case t.ORDER_EVENTS_SYNC_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        const { rootUUID } = action.payload;

        draft.newEntities[rootUUID] = (draft.newEntities[rootUUID] || []).map(event => {
          event.setSyncStatus('error');
          return event;
        });
      });

    case t.ORDER_EVENTS_REPLAY_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.replayStatus = LOADING;
        draft.replayError = null;
      });
    case t.ORDER_EVENTS_REPLAY_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.replayStatus = SUCCEEDED;
        draft.replayError = null;
      });
    case t.ORDER_EVENTS_REPLAY_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.replayStatus = FAILED;
        draft.replayError = action.payload;
      });

    default:
      return state;
  }
}
