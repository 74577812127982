import styled, { css } from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  width: 100%;
`;

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  position: relative;
  table-layout: fixed;
`;

export const Header = styled.thead`
  color: ${({ theme: { palette } }) => palette.darkGrey};
  font-size: ${({
    theme: {
      fonts: { size },
    },
  }) => size.default};
  font-weight: ${({
    theme: {
      fonts: { weight },
    },
  }) => weight.thick};

  line-height: 1.8rem;
  text-transform: uppercase;
`;

export const HeaderRow = styled.tr``;

export const HeaderCell = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme: { palette } }) => palette.white};
  box-sizing: border-box;
  height: 4.4rem;
  padding: 0 1rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
`;

export const Body = styled.tbody`
  > tr > td {
    border-top: 1px solid ${({ theme: { palette } }) => palette.veryLightBlue};
  }

  > tr:last-child > td,
  > tr:last-child > th {
    border-bottom: 0;
  }

  > tr > td,
  > tr > th {
    min-height: 5.2rem;
    padding: 0.4rem 1.2rem;
    white-space: nowrap;
    vertical-align: middle;
    box-sizing: border-box;
    font-feature-settings: 'tnum';
  }
`;

export const Row = styled.tr`
  ${({ isActive, theme: { palette } }) =>
    isActive &&
    css`
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      &:after {
        content: '';
        position: absolute;
        z-index: 3;
        right: 0;
        height: 6rem;
        width: 0.4rem;
        background-color: ${palette.primary.default};
      }
    `}

  position: relative;
  &:hover {
    > td {
      background: ${({ theme: { palette } }) => palette.veryLightGrey};
      cursor: pointer;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      > td {
        background: ${({ theme: { palette } }) => palette.veryLightGrey};
        cursor: pointer;
      }
    `}
`;

export const HiddenRow = styled.tr`
  tr& > td {
    padding: 0.2rem 0;
  }
`;

export const DateTimeCell = styled.td`
  position: sticky;
  left: 0;
  z-index: 2;
  background: ${({ theme: { palette } }) => palette.white};
  border-right: 1px solid ${({ theme: { palette } }) => palette.veryLightBlue};
  text-align: right;
  width: 10rem;

  &${HeaderCell} {
    z-index: 3;
  }
`;

export const Cell = styled.td`
  position: relative;
  width: 100%;
  text-align: center;
  width: fit-content;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    content: ' ';
    left: 50%;
    margin-left: -0.25rem;
    top: 0;
    bottom: 0;
    width: 0.5rem;
    background: rgba(0, 0, 0, 0.03);
    z-index: 0;
  }
`;

export const CollapsibleRow = styled.tr`
  & > td {
    padding: 0;
  }
  ${({ isActive, theme: { palette } }) =>
    isActive &&
    css`
      box-shadow: inset -4px 0 0 0 ${palette.primary.default};
    `}
`;

export const CollapsibleCell = styled.td``;

export const NewEventsHeaderRow = styled.tr`
  & > th {
    border-top: 1rem solid #fff;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3QgZmlsbD0iI2ZmZiIgaGVpZ2h0PSIxMiIgd2lkdGg9IjI0IiB5PSIwIiB4PSIwIi8+CiAgPHBvbHlnb24gcG9pbnRzPSIwLDEyIDEyLDAgMjQsMTIiIHN0cm9rZS13aWR0aD0iMCIgeT0iMCIgeD0iMCIgZmlsbD0iI2VjZWVmNCIvPgo8L3N2Zz4K');
    background-repeat: repeat-x;
    background-position: top center;
    background-color: #eceef4;
    padding: 2rem 1rem 1rem;
  }
`;

export const NewEventsHeaderCell = styled.th`
  color: ${({ theme: { palette } }) => palette.failure.default};
  margin-bottom: 0.5rem;
`;

export const NewEventRow = styled(Row)`
  > td {
    border-color: ${({ theme: { palette } }) => palette.lightGrey};
  }
  background: #eceef4;

  font-weight: bold;

  > ${DateTimeCell}, &:hover > td,
  > td {
    background: #eceef4;
  }
`;
