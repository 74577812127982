import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';

import BaseEvent from './BaseEvent';

const OrderLineWasRemoved = [
  ...BaseEvent,
  {
    name: 'separator',
    isSeparator: true,
  },
  {
    name: 'orderLineUUID',
    label: i18n.t('order_management.fields.orderLineUUID'),
    value: item => item.payload.orderLineUUID,
    Component: DefinitionList.Cells.StringField,
  },
];

export default OrderLineWasRemoved;
