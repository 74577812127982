/**
 * Country sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { countriesFailed, countriesSucceeded } from './actions';
import { COUNTRIES_REQUESTED } from './actionTypes';
import { retrieveCountries } from './services';

/**
 * getCountries saga
 *
 * @generator
 */
export function* getCountriesSaga() {
  try {
    const countries = yield call(retrieveCountries);
    // inform Redux to set our client countries
    yield put(countriesSucceeded({ countries }));
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put(countriesFailed(error));
  }
}

/**
 * Global listener.
 */
export default function* countriesWatcher() {
  yield takeLatest(COUNTRIES_REQUESTED, getCountriesSaga);
}
