import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getShopId } from '../../../modules/Shop/selectors';

/**
 * Part of the Redux global state
 * does what our component want
 */
const mapStateToProps = state => {
  return {
    shopId: getShopId(state),
  };
};

export default Component => withRouter(connect(mapStateToProps)(Component));
