import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const EnumContent = styled.span`
  padding: 0;
`;

const NullContent = styled.span`
  opacity: 0.7;
  font-style: italic;
`;

const Enum = ({ value, options }) => {
  if (value === null) return <NullContent>null</NullContent>;

  const option = options.find(option => option.value === value);

  if (option) {
    return <EnumContent>{option.label}</EnumContent>;
  }

  return <EnumContent>{value}</EnumContent>;
};

Enum.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
  ),
};

Enum.defaultProps = {
  value: null,
  options: [],
};

export default Enum;
