import styled from 'styled-components';

import { Card } from '../../../components';

export const Wrapper = styled.div`
  padding-right: 3rem;
`;

export const Header = styled.div`
  margin-bottom: 3rem;
`;

export const CenterItem = styled.span`
  display: flex;
  padding: ${({ theme: { dimensions } }) => dimensions.big} 0;
  justify-content: center;
`;

export const Id = styled.span`
  font-size: 0.8em;
  color: grey;
`;

export const StyledCard = styled(Card)`
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
`;

export const CardTitle = styled.div`
  text-align: left;
  width: 100%;
`;

export const StyledDL = styled.dl`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  > div {
    overflow: hidden;
  }
  dt {
    text-transform: uppercase;
    font-weight: bold;
  }
  dd {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const Section = styled.div`
  width: 100%;
  background: white;
`;
