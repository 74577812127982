export default class Order {
  constructor(data) {
    this.shopID = data.shopID;
    this.rootUUID = data.rootUUID;
    this.orderId = data.orderId || null;
    this.inCurrentTill = !!data.inCurrentTill;
  }

  canBeModified() {
    return !this.inCurrentTill;
  }
}
