import styled, { css } from 'styled-components';

import { Card } from '@tillersystems/stardust';

import { DefinitionList } from '../../../../components';

export const Header = styled(Card.Header)`
  flex-direction: column;
  align-items: initial;
  justify-content: center;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CardTitle = styled.div`
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
`;

export const Body = styled.div`
  padding: 2rem;
  position: relative;
  border-bottom: solid 1px ${({ theme: { palette } }) => palette.veryLightBlue};
`;

export const LoaderContainer = styled.div`
  margin: auto;
`;

export const Content = styled(DefinitionList)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > div {
    display: block;
    padding: 0 2rem;
    :first-of-type {
      padding: 0 2rem 0 0;
    }
    :last-of-type {
      padding: 0 0 0 2rem;
    }
  }

  dd {
    padding: 0.2rem 0;
  }

  dt {
    width: initial;
    color: ${({ theme: { palette } }) => palette.darkGrey};
    font-size: ${({
      theme: {
        fonts: { size },
      },
    }) => size.default};
    font-weight: ${({
      theme: {
        fonts: { weight },
      },
    }) => weight.thick};

    text-transform: uppercase;
  }

  pre {
    font-family: ${({ theme }) => theme.fonts.family};
  }
`;

export const Wrapper = styled(Card)`
  margin-bottom: 3rem;
`;

export const OrderId = styled.div`
  color: ${({ theme: { palette } }) => palette.spaceGrey};
`;

export const Table = styled.table`
  border-spacing: 0;
  min-width: 100%;
  position: relative;
  width: initial;
  table-layout: fixed;
`;

export const TableHeader = styled.thead`
  color: ${({ theme: { palette } }) => palette.darkGrey};
  font-size: ${({
    theme: {
      fonts: { size },
    },
  }) => size.default};
  font-weight: ${({
    theme: {
      fonts: { weight },
    },
  }) => weight.thick};

  text-transform: uppercase;
`;

export const HeaderCell = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 3rem;
  text-align: right;
  vertical-align: middle;
  white-space: nowrap;
`;

export const Row = styled.tr`
  height: 4rem;
  position: relative;

  td:not(:first-child) {
    text-align: right;
  }

  &:hover {
    > td {
      background: ${({ theme: { palette } }) => palette.veryLightGrey};
      cursor: pointer;
    }
  }

  > td {
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      td {
        background: ${({ theme: { palette } }) => palette.veryLightGrey};
        cursor: pointer;
        border-bottom: none;
      }
    `}
`;

export const CollapsibleRow = styled.tr`
  height: 4rem;
  background: ${({ theme: { palette } }) => palette.veryLightGrey};

  th {
    text-align: left;

    &:last-child {
      width: 30%;
    }
  }

  > td {
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  }
`;

export const CollapsibleCell = styled.td`
  height: 4rem;
`;

export const OrderLinesTitle = styled.div`
  padding: 1rem 0;
`;
