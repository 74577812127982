import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';

import BaseEvent from './BaseEvent';

const OrderWasOpened = [
  ...BaseEvent,
  {
    name: 'separator',
    isSeparator: true,
  },
  {
    name: 'prefix',
    label: i18n.t('order_management.fields.prefix'),
    value: item => item.payload.prefix,
    Component: DefinitionList.Cells.TextField,
    hideEmpty: true,
  },
  {
    name: 'number',
    label: i18n.t('order_management.fields.number'),
    value: item => item.payload.number,
    Component: DefinitionList.Cells.NumberField,
  },
];

export default OrderWasOpened;
