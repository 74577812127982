import { Spinner } from '@sumup/circuit-ui';

import { StyledButton, StyledContainer } from './styles';

interface PagerProps {
  currentPage: number;
  isLoading: boolean;
  hasNextPage: boolean;
  lastPage: number;
  fetchNextPage: () => void;
  setCurrentPage: (currentPage: number) => void;
}

export function Pager({
  currentPage,
  hasNextPage,
  isLoading,
  lastPage,
  fetchNextPage,
  setCurrentPage,
}: PagerProps) {
  return (
    <StyledContainer>
      <StyledButton
        size="kilo"
        onClick={() => setCurrentPage(0)}
        disabled={currentPage <= 0 || isLoading}
      >
        {'<<<'}
      </StyledButton>
      <StyledButton
        size="kilo"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage <= 0 || isLoading}
      >
        {'<'}
      </StyledButton>
      <StyledButton size="kilo" disabled>
        {isLoading ? <Spinner size="byte" /> : currentPage + 1}
        {hasNextPage ? '' : ` / ${lastPage + 1}`}
      </StyledButton>
      <StyledButton
        size="kilo"
        onClick={() =>
          currentPage === lastPage ? fetchNextPage() : setCurrentPage(currentPage + 1)
        }
        disabled={(currentPage === lastPage && !hasNextPage) || isLoading}
      >
        {'>'}
      </StyledButton>
      <StyledButton
        size="kilo"
        onClick={() => (hasNextPage ? fetchNextPage() : setCurrentPage(lastPage))}
        disabled={(currentPage === lastPage && !hasNextPage) || isLoading}
      >
        {'>>'}
        {hasNextPage ? '' : '>'}
      </StyledButton>
    </StyledContainer>
  );
}
