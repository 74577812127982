/**
 * Order Events action types
 */

// Get order events
export const ORDER_EVENTS_STALE = '[ORDER_EVENTS] STALE';
export const ORDER_EVENTS_REQUESTED = '[ORDER_EVENTS] REQUESTED';
export const ORDER_EVENTS_SUCCEEDED = '[ORDER_EVENTS] SUCCEEDED';
export const ORDER_EVENTS_FAILED = '[ORDER_EVENTS] FAILED';

// Form Actions
export const ORDER_EVENTS_CREATE = '[ORDER_EVENTS] CREATE';
export const ORDER_EVENTS_CREATED = '[ORDER_EVENTS] CREATED';
export const ORDER_EVENTS_UPDATE = '[ORDER_EVENTS] UPDATE';
export const ORDER_EVENTS_UPDATED = '[ORDER_EVENTS] UPDATED';
export const ORDER_EVENTS_REMOVE = '[ORDER_EVENTS] REMOVE';
export const ORDER_EVENTS_REMOVED = '[ORDER_EVENTS] REMOVED';

// Sync Actions
export const ORDER_EVENTS_SYNC_REQUESTED = '[ORDER_EVENTS] SYNC REQUESTED';
export const ORDER_EVENTS_SYNC_SUCCEEDED = '[ORDER_EVENTS] SYNC SUCCEEDED';
export const ORDER_EVENTS_SYNC_FAILED = '[ORDER_EVENTS] SYNC FAILED';
// Sync Actions
export const ORDER_EVENTS_REPLAY_REQUESTED = '[ORDER_EVENTS] REPLAY REQUESTED';
export const ORDER_EVENTS_REPLAY_SUCCEEDED = '[ORDER_EVENTS] REPLAY SUCCEEDED';
export const ORDER_EVENTS_REPLAY_FAILED = '[ORDER_EVENTS] REPLAY FAILED';
