import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Menu } from '../../components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  /* For small devices */
  ${breakpoint('xs', 'md')`
    flex-direction: column;
  `};

  ${Menu} {
    width: 25rem;
    flex-shrink: 0;
    margin-right: 3rem;
    position: sticky;
    top: 0;

    /* For small devices */
    ${breakpoint('xs', 'md')`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      position: fixed;
      bottom: 6rem;
      left: 0;

      height: 5.7rem;
      width: 100%;

      margin-right: 0;

      border-radius: 0;

    `};
  }
`;

export const Container = styled.div`
  width: calc(100% - 28.1rem);
  padding: 1.5rem 2rem;
  background: ${({ theme: { palette } }) => palette.white};
  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
  border: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  margin-bottom: 3rem;
`;
