import React from 'react';
import { Translation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Theme from '../../../../../components/Theme';

import { axes, getColor, legends, margins } from './config';

/**
 * Gets the bar chart data from the given "raw" data object.
 *
 * @param {Function} t - The I18n instance.
 * @param {Object} data - The "raw" data.
 *
 * @returns {Array} - The pie data.
 */
const { palette } = Theme;
const getChartData = (t, data) =>
  data.history.map(d => {
    const chartData = { date: d.date };
    chartData[t('reports.appmarket.variation.variation_history.activations')] = d.activations_count;
    chartData[t('reports.appmarket.variation.variation_history.deactivations')] =
      d.deactivations_count;

    return chartData;
  });

/**
 * Defines the bar chart that displays apps activation and deactivation history.
 *
 * @param {string} className - className needed by styled components.
 * @param {Object} data - The data to display.
 *
 * @returns {jsx}
 */
const VariationHistory = ({ className, data }) =>
  data ? (
    <div className={className}>
      <Translation>
        {t => (
          <ResponsiveBar
            data={getChartData(t, data)}
            keys={[
              t('reports.appmarket.variation.variation_history.activations'),
              t('reports.appmarket.variation.variation_history.deactivations'),
            ]}
            indexBy={d => d.date}
            groupMode="grouped"
            margin={margins}
            padding={0.3}
            colorBy={d => getColor(t, d.id)}
            borderColor="inherit:darker(1.6)"
            axisBottom={axes.bottom}
            axisLeft={axes.left}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={palette.white}
            animate
            motionStiffness={90}
            motionDamping={15}
            legends={legends}
          />
        )}
      </Translation>
    </div>
  ) : null;

/** Display name. */
VariationHistory.displayName = 'VariationHistory';

/** Prop types. */
const { string, object } = PropTypes;
VariationHistory.propTypes = {
  className: string,
  data: object,
};

/** Default props. */
VariationHistory.defaultProps = {
  className: '',
  data: null,
};

export default styled(VariationHistory)`
  height: 40rem;
  width: 100%;
`;
