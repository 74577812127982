import styled from 'styled-components';
import { Grid } from 'styled-css-grid';

export const Menu = styled.div`
  padding: 1.6rem 3.1rem 0;

  background: ${({ theme: { palette } }) => palette.white};
`;

export const MenuItem = styled.span`
  text-transform: uppercase;

  padding: 0 2rem;

  a {
    padding-bottom: 1rem;
    display: inline-flex;
  }

  .active {
    color: ${({ theme: { palette } }) => palette.primary.default};

    border-bottom: 1px solid ${({ theme: { palette } }) => palette.primary.default};
  }
`;

export const Section = styled.div`
  margin-bottom: ${({ theme: { dimensions } }) => dimensions.medium};

  &:last-child {
    margin-bottom: 0;
  }

  > ${Grid} {
    margin-bottom: 2.3rem;
  }

  > ${Grid}:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.div`
  width: 100%;
  margin-bottom: ${({ theme: { dimensions } }) => dimensions.medium};
  margin-left: ${({ theme: { dimensions } }) => dimensions.small};

  font-size: ${({ theme: { fonts } }) => fonts.size.h4};
`;
