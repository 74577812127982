import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StringContent = styled.pre`
  padding: 0;
`;

const NullContent = styled.span`
  opacity: 0.7;
  font-style: italic;
`;

const StringField = ({ value }) =>
  value === null ? <NullContent>null</NullContent> : <StringContent>{value}</StringContent>;

StringField.propTypes = {
  value: PropTypes.any,
};

StringField.defaultProps = {
  value: null,
};

export default StringField;
