/* eslint-disable react/display-name */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Table } from '@tillersystems/stardust';

import { getPrettyAppName } from '../../utils';

/**
 * Gets the columns definition of the table.
 *
 * @param {Function} t - The I18n instance.
 */
const getColsDef = t => [
  {
    name: 'app_name',
    title: t('reports.appmarket.variation.variation_table.app_name'),
    value: d => getPrettyAppName(d.code),
    align: 'left',
    sortable: true,
  },
  {
    name: 'activations',
    title: t('reports.appmarket.variation.variation_table.activations'),
    value: d => d.activations_count,
    width: '10rem',
    sortable: true,
  },
  {
    name: 'deactivations',
    title: t('reports.appmarket.variation.variation_table.deactivations'),
    value: d => d.deactivations_count,
    width: '10rem',
    sortable: true,
  },
];

/**
 * This class defines the table that displays the variations per app.
 *
 * @param {string} className - Styled components' class name.
 * @param {Object} data - The raw data.
 *
 * @returns {jsx}
 */
const VariationTable = ({ className, data }) => {
  const [t] = useTranslation();

  return data ? (
    <div className={className}>
      <Table data={data.apps} colsDef={getColsDef(t)} />
    </div>
  ) : null;
};

/** Display name. */
VariationTable.displayName = 'VariationTable';

/** Prop types. */
const { string, object } = PropTypes;
VariationTable.propTypes = {
  className: string,
  data: object,
};

/** Default props. */
VariationTable.defaultProps = {
  className: '',
  data: null,
};

export default styled(VariationTable)`
  height: 100%;
  overflow-y: scroll;
`;
