import { createSelector } from 'reselect';

import { LOADING } from '../../../constants/Status';

/**
 * Order Events Selectors
 */

/**
 * If last attempt at fetching data resulted in an error, returns the error value
 *
 * @return {string}
 */
export const getErrorOrder = ({ order }) => order.detail.error;

/**
 * If last attempt at fetching data resulted in an error, returns the error value
 *
 * @return {string}
 */
export const getStatusOrder = ({ order }) => order.detail.status;

/**
 * If data is being fetched
 *
 * @return {boolean}
 */
export const isLoadingOrder = createSelector([getStatusOrder], status => status === LOADING);

/**
 * Get current order id for which events have been retrieved from api
 * @param {Object} state
 *
 * @return {Object} orderId
 */
export const getCurrentOrder = ({ order }) => order.detail.entity || null;

/**
 * Get the order with matching orderId if it has been retrieved
 */
export const getOrderDetail = createSelector(
  [getCurrentOrder, (_state, orderId) => orderId],
  (currentOrder, orderId) =>
    currentOrder && (currentOrder.rootUUID === orderId || currentOrder.orderId == orderId)
      ? currentOrder
      : null,
);
