const transition = {
  y: {
    type: 'spring',
    stiffness: 900,
    damping: 35,
  },
  default: { duration: 0.15 },
};

export const periodPickerVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition,
  },
  hidden: {
    y: -5,
    opacity: 0,
    transition,
  },
};
