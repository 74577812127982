import PaymentTypes from '../../../../constants/PaymentTypes';
import i18n from '../../../../i18n';
import DefinitionForm from '../../../DefinitionForm';

const FIELDS = [
  {
    fieldPath: 'payload.type',
    Field: DefinitionForm.Fields.Select,
    label: i18n.t('order_management.fields.PaymentWasAddedByAdmin.type'),
    fieldOptions: {
      defaultValue: '',
      validate: value => (!value ? i18n.t('form.errors.required') : false),
    },
    options: {
      searchMethod: ({ options, term }) =>
        options.filter(option => option.label.toLowerCase().includes(term.toLowerCase())),
      contentWrapperStyle: {
        maxHeight: '70vh',
      },
      options: PaymentTypes,
    },
  },
  {
    fieldPath: 'payload.amount',
    Field: DefinitionForm.Fields.Number,
    label: i18n.t('order_management.fields.PaymentWasAddedByAdmin.amount'),
    fieldOptions: {
      defaultValue: null,
      validate: value => (typeof value !== 'number' ? i18n.t('form.errors.number') : false),
    },
    options: {
      label: { text: i18n.t('order_management.fields.cents') },
      labelPosition: 'right',
      placeholder: i18n.t('order_management.fields.PaymentWasAddedByAdmin.amount_placeholder'),
    },
  },
];

export default paymentTypes => [
  { ...FIELDS[0], options: { ...FIELDS[0].options, options: paymentTypes || PaymentTypes } },
  FIELDS[1],
];
