import React, { PureComponent } from 'react';

import { subscribe, unsubscribe } from './datasource';

/**
 * Creates a component that is connected to the report with the given name and query.
 *
 * @param {String} reportName - The name of the report.
 * @param {String} queryName - The query of the report.
 *
 * @return {Component}
 */
const withReport = (reportName, queryName) => Wrapped => {
  return class HOC extends PureComponent {
    /** Display name. */
    static displayName = `withReport(${Wrapped.displayName})`;

    /** Internal state. */
    state = {
      // Whether data are loaded or not.
      loaded: false,

      // Data requested (can be null in case no data could be found or an error occurred).
      data: null,
    };

    /** Handles React mounting event of component's lifecycle. */
    componentDidMount() {
      this.subscribe();
    }

    /** Handles React unmounted event of component's lifecycle. */
    componentWillUnmount() {
      this.unsubscribe();
    }

    /**
     * Subscribes the component to the report's events.
     */
    subscribe = async () => {
      await subscribe(reportName, queryName, 'loaded', this.handleLoadedChanged);
      await subscribe(reportName, queryName, 'data', this.handleDataChanged);
    };

    /**
     * Unsubscribes the component to the report's events.
     */
    unsubscribe = async () => {
      await unsubscribe(reportName, queryName, 'data', this.handleLoadedChanged);
      await unsubscribe(reportName, queryName, 'loaded', this.handleDataChanged);
    };

    /**
     * Handles loaded changed on subscribed data source.
     *
     * @param {Boolean} loaded - Whether data are loaded or not.
     */
    handleLoadedChanged = loaded =>
      new Promise(resolve =>
        this.setState({ ...this.state, loaded }, () => {
          resolve();
        }),
      );

    /**
     * Handles data changed on subscribed data source.
     *
     * @param {Object} data - The data.
     */
    handleDataChanged = data =>
      new Promise(resolve =>
        this.setState({ ...this.state, data }, () => {
          resolve();
        }),
      );

    /**
     * Renders the component.
     *
     * @return {React.ReactNode}
     */
    render() {
      const { loaded, data } = this.state;
      return <Wrapped reportLoaded={loaded} reportData={data} {...this.props} />;
    }
  };
};

export default withReport;
