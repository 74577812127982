import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import useWebSocket from '../../../helpers/hooks/useWebSocket';
import { enableFeature } from '../services';

import {
  FeatureActivation,
  FeatureActivationRequest,
  FeatureActivationResponse,
  FeatureActivationStatus,
} from './types';
import { setStatus } from './utils';

interface Options {
  onSuccess?: (
    data: unknown,
    variables: Omit<FeatureActivation, 'status'>[],
    context: unknown,
  ) => void | Promise<unknown>;
  onError?: (
    error: unknown,
    variables: Omit<FeatureActivation, 'status'>[],
    context: unknown,
  ) => void | Promise<unknown>;
}

export function useFeatureActivation(options?: Options) {
  const [featureActivationsStatus, setFeatureActivationsStatus] = useState<
    FeatureActivationStatus[]
  >([]);

  const { mutate, isLoading } = useMutation(enableFeature, {
    onSuccess: (response, featureActivations, context) => {
      setFeatureActivationsStatus(
        featureActivations.map(featureActivation => ({
          ...featureActivation,
          status: 'succeeded',
        })),
      );
      options?.onSuccess(response, featureActivations, context);
    },
    onError: (response, featureActivations, context) => {
      setFeatureActivationsStatus(
        featureActivations.map(featureActivation => ({
          ...featureActivation,
          status: 'failed',
        })),
      );
      options?.onError(response, featureActivations, context);
    },
    onMutate: featureActivations => {
      setFeatureActivationsStatus(
        featureActivations.map(featureActivation => ({
          ...featureActivation,
          status: 'pending',
        })),
      );
    },
  });

  const { lastJsonMessage, sendJsonMessage } = useWebSocket<
    FeatureActivationRequest,
    FeatureActivationResponse
  >({
    webSocketUrl: `${process.env.REACT_APP_AWS_WEBSOCKET_URL}/feature-enable`,
    filter: (_): _ is FeatureActivationResponse => true,
  });

  const subscribe = useCallback((featureActivations: FeatureActivation[]) => {
    setFeatureActivationsStatus(
      featureActivations.map(featureActivation => ({ ...featureActivation, status: 'idle' })),
    );
    sendJsonMessage({
      event: 'FEATURE_ACTIVATION',
      action: 'SUBSCRIBE',
      payload: featureActivations,
    });
  }, []);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    setFeatureActivationsStatus(
      featureActivationsStatus.map(featureActivationStatus =>
        featureActivationStatus.featureCode === lastJsonMessage.payload.featureCode
          ? { ...featureActivationStatus, status: setStatus(lastJsonMessage.payload.status) }
          : featureActivationStatus,
      ),
    );
  }, [lastJsonMessage]);

  const clear = () => setFeatureActivationsStatus([]);
  const isInProgress = featureActivationsStatus.some(({ status }) => status === 'pending');

  return { status: featureActivationsStatus, isLoading, isInProgress, mutate, subscribe, clear };
}
