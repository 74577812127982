/**
 * Stores sagas
 */

import {
  cloneDeep,
  findKey,
  isEmpty,
  isError,
  isPlainObject,
  mapValues,
  pickBy,
  union,
  zipObject,
} from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  defaultPaymentConfigRetrieveFailed,
  defaultPaymentConfigRetrieveSucceeded,
  defaultPaymentConfigUpdateFailed,
  defaultPaymentConfigUpdateSucceeded,
  paymentsConfigRetrieveFailed,
  paymentsConfigRetrieveSucceeded,
  paymentsConfigUpdateFailed,
  paymentsConfigUpdateSucceeded,
} from './actions';
import {
  DEFAULT_PAYMENT_CONFIG_RETRIEVE_REQUESTED,
  DEFAULT_PAYMENT_CONFIG_UPDATE_REQUESTED,
  PAYMENTS_CONFIG_RETRIEVE_REQUESTED,
  PAYMENTS_CONFIG_UPDATE_REQUESTED,
} from './actionTypes';
import {
  getDefaultPaymentConfig,
  getPaymentsConfig,
  getPaymentsConfigCountries,
  isDefaultPaymentConfigDoneLoading,
  isPaymentsConfigDoneLoading,
} from './selectors';
import {
  createPaymentConfig,
  retrieveDefaultPaymentConfig,
  retrieveLatestConfigVersion,
  retrievePaymentConfig,
  updateDefaultPaymentConfig,
  updatePaymentConfig,
} from './services';

/**
 * Compute a new payment config based on the current
 * that contains the modifications defined in the payload
 *
 * @param {import('./reducer').PaymentConfig} paymentConfig - current payment config
 * @param {string} label - label of the payment data to update
 * @param {Object} config - payment data to update based on the label
 * @return {import('./reducer').PaymentConfig} - the payment config to save for this country
 */
function computePaymentConfigToUpdate(paymentConfig, label, config) {
  // Clone payment config
  const paymentConfigToUpdate = cloneDeep(paymentConfig);

  // Check if the payment config to update exists
  // in one of the categories of current payments config
  const category = findKey(paymentConfigToUpdate, categoryConfig => categoryConfig[label]);
  if (category) {
    // remove it from old category
    delete paymentConfigToUpdate[category][label];
  }

  // Only move the payment config to its new category
  // if the country is in the new countries list
  // otherwise this means that the payment config
  // should be deleted from this country
  if (config) {
    const { category: newCategory, options } = config;
    // Move payment config to new category for country
    paymentConfigToUpdate[newCategory] = {
      ...paymentConfigToUpdate[newCategory],
      [label]: options,
    };
  }

  return paymentConfigToUpdate;
}

/**
 * Save the payment config for the country passed as an argument
 *
 * @param {import('./reducer').PaymentConfig} paymentConfig - the payment config to save for this country
 * @throws {ApiError} in case the api call throws an error
 * @generator
 */
function* saveDefaultPaymentConfig(paymentConfig) {
  const version = yield call(retrieveLatestConfigVersion);
  return yield call(updateDefaultPaymentConfig, version, paymentConfig);
}

/**
 * Get a payment config for given country
 *
 * @param {string} country - 2 letters ISO country code
 * @throws {ApiError} in case the api call throws an error
 * @generator
 */
function* getPaymentConfig(country) {
  const version = yield call(retrieveLatestConfigVersion, country, true);
  return yield call(retrievePaymentConfig, country, version);
}

/**
 * Save the payment config for the country passed as an argument
 *
 * @param {string} country - 2 letters ISO country code
 * @param {import('./reducer').PaymentConfig} paymentConfig - the payment config to save for this country
 * @throws {ApiError} in case the api call throws an error
 * @generator
 */
function* savePaymentConfig(country, paymentConfig) {
  // Get latest config version in country
  const version = yield call(retrieveLatestConfigVersion, country);
  if (version) {
    // If a version is found, country already has a payment config, so replace it
    return yield call(updatePaymentConfig, country, version, paymentConfig);
  }

  // If version is empty, country does not have already a payment config, so create a new one
  return yield call(
    createPaymentConfig,
    country,
    yield call(retrieveLatestConfigVersion, country, true),
    paymentConfig,
  );
}

/**
 * Compute a new payment config based on current on save it
 *
 * @param {string} country - 2 letters ISO country code
 * @param {import('./reducer').PaymentConfig} paymentConfig - current payment config
 * @param {string} label - label of the payment data to update
 * @param {Object} [config] - payment data to update based on the label
 * @throws {ApiError} in case the api call throws an error
 */
function* tryComputeAndSavePaymentConfig(country, paymentConfig, label, config) {
  const paymentConfigToUpdate = computePaymentConfigToUpdate(paymentConfig, label, config);

  try {
    // Save country payment config
    return yield savePaymentConfig(country, paymentConfigToUpdate);
  } catch (e) {
    return e;
  }
}

/**
 * Get the payments config
 * by triggering a call to the api
 * to retrieve the data and dispatch it
 *
 * @generator
 */
export function* getDefaultPaymentConfigSaga() {
  try {
    const version = yield call(retrieveLatestConfigVersion);
    const paymentConfig = yield call(retrieveDefaultPaymentConfig, version);

    // inform Redux to set our client paymentMethods
    yield put(defaultPaymentConfigRetrieveSucceeded({ paymentConfig }));
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put(defaultPaymentConfigRetrieveFailed({ error }));
  }
}

/**
 * Get the payments config for the given countries
 * by triggering a call to the api
 * to retrieve the data and dispatch it
 *
 * @param {Object} payload - payload containing a list of country codes
 * @param {string[]} payload.countries - list of 2 letters ISO country codes
 * @generator
 */
export function* getPaymentsConfigSaga({ payload: { countries } }) {
  try {
    const paymentsConfig = zipObject(
      countries,
      yield all(countries.map(country => getPaymentConfig(country))),
    );

    // inform Redux to set our client paymentMethods
    yield put(paymentsConfigRetrieveSucceeded({ paymentsConfig }));
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put(
      paymentsConfigRetrieveFailed({
        errors: countries.reduce((errors, country) => {
          errors[country] = error;
          return errors;
        }, {}),
      }),
    );
  }
}

/**
 * Update the payments config
 * by calling the api with the new payment config for each country
 * and dispatch an action when the process complete (succeed or fail)
 *
 * @param {Object} payload - the payment config data to update
 * @param {string} payload.label - label of the payment config to update
 * @param {string} payload.category - the new category to use for the default payment config
 * @param {import('./reducer').PaymentOptions} payload.options - the options value to use for the default payment config
 * @generator
 */
export function* updateDefaultPaymentConfigSaga({
  payload: { label, category: newCategory, options },
}) {
  try {
    // Check if payments config is loaded, it not, stop the saga
    const isLoaded = yield select(isDefaultPaymentConfigDoneLoading);
    if (!isLoaded) {
      // If we get an error we send Redux the appropiate action and return
      throw new Error('Cannot update payments config if payments config is not loaded first');
    }

    // Get current default payment config
    const defaultPaymentConfig = yield select(getDefaultPaymentConfig);

    // Find the payment options in one of the categories of the default payments config
    const category = findKey(defaultPaymentConfig, categoryConfig => categoryConfig[label]);
    // remove it from old category
    delete defaultPaymentConfig[category]?.[label];

    // Move payment options to new category
    defaultPaymentConfig[newCategory] = {
      ...defaultPaymentConfig[newCategory],
      [label]: options,
    };

    // Move the payment config from its old category to the new category for all selected countries
    // and delete the payment config for unselected countries
    const updatedDefaultPaymentConfig = yield saveDefaultPaymentConfig(defaultPaymentConfig);

    yield put(defaultPaymentConfigUpdateSucceeded({ paymentConfig: updatedDefaultPaymentConfig }));
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put(defaultPaymentConfigUpdateFailed({ error }));
  }
}

/**
 * Update the payments config
 * by calling the api with the new payment config for each country
 * and dispatch an action when the process complete (succeed or fail)
 *
 * @param {Object} payload - the payments config data to update
 * @param {string} payload.label - label of the payments config to update
 * @param {import('./selectors').CountriesPaymentConfig} payload.config - the specific config for each country for the payments config to update
 * @generator
 */
export function* updatePaymentsConfigSaga({ payload: { label, config } }) {
  try {
    // Check if payments config is loaded, it not, stop the saga
    const isLoaded = yield select(isPaymentsConfigDoneLoading);
    if (!isLoaded) {
      // If we get an error we send Redux the appropiate action and return
      throw new Error('Cannot update payments config if payments config is not loaded first');
    }

    const countries = Object.keys(config);
    // Get currents payments config
    const paymentsConfig = yield select(getPaymentsConfig);
    // Get all countries, merge the countries fron the current payments config and the new and old ones
    const allCountries = union(yield select(getPaymentsConfigCountries), countries);

    // Move the payment config from its old category to the new category for all selected countries
    // and delete the payment config for unselected countries
    const updatedPaymentsConfig = zipObject(
      allCountries,
      yield all(
        allCountries.map(country =>
          tryComputeAndSavePaymentConfig(country, paymentsConfig[country], label, config[country]),
        ),
      ),
    );

    const paymentsConfigSucceeded = pickBy(
      updatedPaymentsConfig,
      paymentConfig => !isError(paymentConfig) && isPlainObject(paymentConfig),
    );
    if (!isEmpty(paymentsConfigSucceeded)) {
      yield put(paymentsConfigUpdateSucceeded({ paymentsConfig: paymentsConfigSucceeded }));
    }

    const paymentsConfigErrors = pickBy(
      updatedPaymentsConfig,
      paymentConfig => isError(paymentConfig) || !isPlainObject(paymentConfig),
    );
    if (!isEmpty(paymentsConfigErrors)) {
      yield put(paymentsConfigUpdateFailed({ errors: paymentsConfigErrors }));
    }
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put(paymentsConfigUpdateFailed({ errors: mapValues(config, () => error) }));
  }
}

/**
 * Global listener.
 */
export default function* paymentsConfigWatcher() {
  yield takeLatest(DEFAULT_PAYMENT_CONFIG_RETRIEVE_REQUESTED, getDefaultPaymentConfigSaga);
  yield takeLatest(DEFAULT_PAYMENT_CONFIG_UPDATE_REQUESTED, updateDefaultPaymentConfigSaga);
  yield takeLatest(PAYMENTS_CONFIG_RETRIEVE_REQUESTED, getPaymentsConfigSaga);
  yield takeLatest(PAYMENTS_CONFIG_UPDATE_REQUESTED, updatePaymentsConfigSaga);
}
