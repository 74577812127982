import { createContext, ProviderProps, useContext } from 'react';

import { Authentication, AuthenticationProviderProps } from './types';
import { useCognito } from './useCognito';

const AuthContext = createContext<Authentication>({
  isAuthenticated: false,
  isRequesting: true,
  signOut: (): Promise<never> => {
    throw new Error('logout function cannot be used outside of the authentication provider');
  },
});

export const AuthProvider = ({
  children,
}: AuthenticationProviderProps): React.ReactElement<ProviderProps<Authentication>> => {
  const auth = useCognito();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) throw new Error('useAuth() cannot be used outside of the <AuthenticationProvider>');
  return auth;
};
