import styled from 'styled-components';

export const ToggleLabel = styled.span`
  margin-right: 1rem;
`;

export const Toggle = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
  margin-right: 1rem;
`;
