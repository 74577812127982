import styled, { css } from 'styled-components';

export const CardTitle = styled.div`
  text-align: left;
  width: 100%;
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
`;

export const CollapsibleCell = styled.td`
  height: 4rem;
`;

export const CollapsibleRow = styled.tr`
  height: 4rem;
  background: ${({ theme: { palette } }) => palette.veryLightGrey};

  th {
    text-align: left;

    &:last-child {
      width: 30%;
    }
  }

  > td {
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  }
`;

export const HeaderCell = styled.th`
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 3rem;
  text-align: right;
  vertical-align: middle;
  white-space: nowrap;
`;

export const OrderLinesTitle = styled.div`
  padding: 1rem 0;
`;

export const Row = styled.tr`
  height: 4rem;
  position: relative;

  td:not(:first-child) {
    text-align: right;
  }

  &:hover {
    > td {
      background: ${({ theme: { palette } }) => palette.veryLightGrey};
      cursor: pointer;
    }
  }

  > td {
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      td {
        background: ${({ theme: { palette } }) => palette.veryLightGrey};
        cursor: pointer;
        border-bottom: none;
      }
    `}
`;

export const Table = styled.table`
  border-spacing: 0;
  min-width: 100%;
  position: relative;
  width: initial;
  table-layout: fixed;
`;

export const TableHeader = styled.thead`
  color: ${({ theme: { palette } }) => palette.darkGrey};
  font-size: ${({
    theme: {
      fonts: { size },
    },
  }) => size.default};
  font-weight: ${({
    theme: {
      fonts: { weight },
    },
  }) => weight.thick};

  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  padding: 1rem 2rem 2rem 2rem;
  border-bottom: solid 1px ${({ theme: { palette } }) => palette.veryLightBlue};
`;
