import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { formatNumber, Icon, Loader, Theme } from '@tillersystems/stardust';

import connect from '../connect';
import { ACCEPTED, CASH, WAITING } from '../helpers';

import {
  Cell,
  CellTax,
  Container,
  ContainerWrapper,
  Header,
  Id,
  OrderLines,
  Payments,
  RawTicketSkeleton,
  Row,
  Signature,
  Status,
  StyledDate,
  Table,
  Title,
  Totals,
  Wrapper,
} from './elements';
import PaginationButtons from './PaginationButtons';

const indexedArrayItemTo1 = arrayItem => arrayItem + 1;

const RawTickets = ({
  actions,
  isRawTicketsLoading,
  hasRawTickets,
  rawTicket,
  rawTicketsIndex,
  rawTicketsCount,
  rawTicketsError,
}) => {
  const [t] = useTranslation();

  const renderLoading = () => (
    <Wrapper>
      <RawTicketSkeleton>
        <Loader width="8rem" height="8rem" />
      </RawTicketSkeleton>
    </Wrapper>
  );

  const renderError = () => (
    <Wrapper>
      <RawTicketSkeleton>
        {t('order_management.raw_tickets.error.no_printed_ticket')}
      </RawTicketSkeleton>
    </Wrapper>
  );

  const renderRawTickets = () => (
    <Wrapper>
      {rawTicketsCount > 1 && (
        <PaginationButtons
          onNext={actions.rawTicketsNextTicket}
          counter={indexedArrayItemTo1(rawTicketsIndex)}
          maxCount={rawTicketsCount}
          onPreview={actions.rawTicketsPrevTicket}
        />
      )}
      <ContainerWrapper>
        <Container>
          <Header>
            <Id>{`#${rawTicket.id}`}</Id>
            <StyledDate>
              {DateTime.fromISO(rawTicket.date).toLocaleString(DateTime.DATETIME_SHORT)}
            </StyledDate>
          </Header>
          <OrderLines>
            <Table>
              <tbody>
                {rawTicket.orderLines.map(orderLine => (
                  <Row key={orderLine.uuid} isHighlighted={orderLine.status === WAITING}>
                    <Cell>{orderLine.count}x</Cell>
                    <Cell>
                      <p>{orderLine.name}</p>
                      {orderLine.status === WAITING && <Status>{orderLine.status}</Status>}
                    </Cell>
                    <Cell>
                      {/*
                      As the translation is not working properly in captain we are obliged to put the value `fr` manually here
                      to correctly display currency.
                      */}
                      {formatNumber({
                        currency: rawTicket.currency,
                        locale: 'fr',
                        number: orderLine.price.tax_included,
                      })}
                    </Cell>
                    <Cell>
                      {formatNumber({
                        currency: rawTicket.currency,
                        locale: 'fr',
                        number: orderLine.total,
                      })}
                    </Cell>
                    <Cell>{orderLine.taxIdentifier}</Cell>
                  </Row>
                ))}
              </tbody>
            </Table>
          </OrderLines>
          <Totals>
            <Table>
              <tbody>
                {rawTicket.taxes.map(tax => (
                  <Row key={tax.key}>
                    <CellTax>
                      {t('order_management.raw_tickets.taxes.vat', {
                        amount: tax.rate,
                        name: tax.identifier,
                      })}
                    </CellTax>
                    <CellTax>
                      {formatNumber({
                        currency: rawTicket.currency,
                        locale: 'fr',
                        number: tax.taxExcluded,
                      })}
                    </CellTax>
                    <CellTax>
                      {formatNumber({
                        currency: rawTicket.currency,
                        locale: 'fr',
                        number: tax.taxAmount,
                      })}
                    </CellTax>
                    <CellTax>
                      {formatNumber({
                        currency: rawTicket.currency,
                        locale: 'fr',
                        number: tax.taxIncluded,
                      })}
                    </CellTax>
                  </Row>
                ))}
                <Row>
                  <CellTax colSpan={3}>
                    {t('order_management.raw_tickets.taxes.excl_tax_total')}
                  </CellTax>
                  <CellTax>
                    {formatNumber({
                      currency: rawTicket.currency,
                      locale: 'fr',
                      number: rawTicket.total.taxExcluded,
                    })}
                  </CellTax>
                </Row>
                <Row>
                  <CellTax colSpan={3}>
                    {t('order_management.raw_tickets.taxes.incl_tax_total')}
                  </CellTax>
                  <CellTax>
                    {formatNumber({
                      currency: rawTicket.currency,
                      locale: 'fr',
                      number: rawTicket.total.taxIncluded,
                    })}
                  </CellTax>
                </Row>
              </tbody>
            </Table>
          </Totals>
          <Payments>
            <Title>{t('order_management.raw_tickets.payments')}</Title>
            <Table>
              <tbody>
                {rawTicket.payments.map(payment => (
                  <Row key={payment.uuid}>
                    <Cell>
                      {payment.status === ACCEPTED ? (
                        <Icon name="check-mark" color={Theme.palette.success.default} size="15px" />
                      ) : (
                        <Icon name="cross" color={Theme.palette.failure.default} size="15px" />
                      )}
                    </Cell>
                    <Cell>
                      {payment.type === CASH
                        ? t('order_management.raw_tickets.cash')
                        : payment.label}
                    </Cell>
                    <Cell>{payment.deviceName}</Cell>
                    <Cell>
                      {formatNumber({
                        currency: rawTicket.currency,
                        locale: 'fr',
                        number: payment.price,
                      })}
                    </Cell>
                  </Row>
                ))}
              </tbody>
            </Table>
          </Payments>
          <Signature>{`Sign. ${rawTicket.signature}`}</Signature>
        </Container>
      </ContainerWrapper>
    </Wrapper>
  );

  return isRawTicketsLoading
    ? renderLoading()
    : rawTicketsError instanceof Error
    ? renderError()
    : hasRawTickets && renderRawTickets();
};

RawTickets.propTypes = {
  actions: PropTypes.shape({
    rawTicketsNextTicket: PropTypes.func,
    rawTicketsPrevTicket: PropTypes.func,
  }).isRequired,
  rawTicket: PropTypes.object,
  isRawTicketsLoading: PropTypes.bool,
  hasRawTickets: PropTypes.bool,
  rawTicketsIndex: PropTypes.number,
  rawTicketsCount: PropTypes.number,
  rawTicketsError: PropTypes.instanceOf(Error),
};

RawTickets.defaultProps = {
  rawTicket: {},
  isRawTicketsLoading: false,
  hasRawTickets: false,
  rawTicketsIndex: 0,
  rawTicketsCount: 0,
  rawTicketsError: null,
};

export default connect(RawTickets);
