import { create as createApi } from 'apisauce';

import { preRequestTransform } from './common';

/**
 * Instance of the api to Tiller Captain.
 */
const api = createApi({
  baseURL: process.env.REACT_APP_TILLER_CAPTAIN_ROOT_URL,
});

api.addRequestTransform(preRequestTransform);

export default () => api;
