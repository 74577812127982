import { create } from 'apisauce';

/**
 * pinguiApi
 *
 * Create a tiller pingui API
 *
 * @return {void}
 */

const pinguiApi = create({
  baseURL: process.env.REACT_APP_PINGUI_BASE_URL,
  mode: 'cors',
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_CLIENT_LAUNCHER,
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_PINGUI_API_TOKEN,
  },
});

/**
 * getEnvironments
 *
 * Get an array of the differents environments on tiller pingui
 *
 * @return {array} array of pingui environments
 */
export const getEnvironments = async () => {
  const results = await pinguiApi.get('/list');
  const { stacks: environments } = results.data;
  return environments || [];
};

/**
 * getCreatedEnvironments
 *
 * Get an array of environments succefully created on tiller pingui
 *
 * @return {array} array of pingui environments succefully created
 */
export const getCreatedEnvironments = async () => {
  const environments = await getEnvironments();
  if (environments.some(environment => environment.state == 'CREATE_DONE')) {
    return environments.filter(environment => environment.state == 'CREATE_DONE');
  } else {
    return [];
  }
};

/**
 * createNewEnvironment
 *
 * Create a new environment in tiller pingui
 *
 * @param {array} projects - An array of params to create a new environment
 *
 * @return {object} - Return result object to check the state of the request
 */
export const createNewEnvironment = async projects => {
  const result = await pinguiApi.post(`/create`, { projects });
  return result;
};

/**
 * deleteEnvironment
 *
 * Delete an environment in tiller pingui
 *
 * @param {string} environment - The environment to delete
 *
 * @return {object} - Return result object to check the state of the request
 */
export const deleteEnvironment = async environment => {
  const result = await pinguiApi.delete(`/delete/${environment}`);
  return result;
};

/**
 * githubTillerApi
 *
 * Create a github tiller API
 *
 * @return {void}
 */
const githubTillerApi = create({
  baseURL: process.env.REACT_APP_GIT_HUB_BASE_URL,
  mode: 'cors',
  headers: {
    'Authorization': `token ${process.env.REACT_APP_GIT_HUB_TOKEN}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

/**
 * getProjectBranches
 *
 * Get an array of git branches according to a project
 *
 * @async
 *
 * @param {string} project - The project where you want to get branches
 *
 * @return {array} - Array of branches
 */
export const getProjectBranches = async project => {
  const results = await githubTillerApi.get(`/repos/tillersystems/${project}/branches`);
  const { data: branches } = results;
  return branches;
};

/**
 * getBranchesName
 *
 * Format the array of branches to only get there name
 *
 * @async
 *
 * @param {string} project - The project where you want to get branches name
 *
 * @return {array} - Array of branches name
 */
export const getBranchesName = async project => {
  const branches = await getProjectBranches(project);
  const names = branches.map(branch => branch.name);
  return names;
};

/**
 * amazonApi
 *
 * Create an amazon service API
 *
 * @return {void}
 */
const amazonApi = create({
  baseURL: process.env.REACT_APP_TILLER_OPS_SERVICE_URL,
  mode: 'cors',
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_CLIENT_LAUNCHER,
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json',
  },
});

/**
 * getBetaList
 *
 * @async
 *
 *
 * @return {array} - Return ran array of betas
 */
export const getBetas = async () => {
  const result = await amazonApi.get('/Prod/list');
  const { data: betas } = result;
  return betas || [];
};

/**
 * postToProd
 *
 * @async
 *
 * @param {strobjecting} build_parameters - Differents params to build a beta
 *
 * @return {object} - Return result object to check the state of the request
 */
export const postToProd = async build_parameters => {
  const result = await amazonApi.post(`/Prod/betaTrigger`, { build_parameters });
  return result;
};

/**
 * getJiraStatus
 *
 * @async
 *
 * @param {string} jiraTicket - jira ticket you want to retrieve the status
 *
 * @return {string} - Return the ticket jira status
 */
export const getJiraStatus = async jiraTicket => {
  const { data } = await amazonApi.get(`/Prod/jira-status/${jiraTicket}`);
  return data;
};
