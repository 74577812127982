import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { Head, TopBar } from '../../components';

import FeatureActivationPage from './FeatureActivationPage';
import NewfeaturePage from './NewfeaturePage';
import { Container, NavBar, NavLink } from './styles';

const Features = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const isNewFeaturePathname = /new($|\/)/g.test(pathname);
  const isActivationFeaturePathname = /activation($|\/)/g.test(pathname);

  return (
    <>
      <Head title={t('navigation.features')} />
      <TopBar title={t('navigation.features')} />
      <NavBar>
        <NavLink isActive={isNewFeaturePathname} to={`${path}/new`}>
          {t('features.create.title')}
        </NavLink>
        <NavLink isActive={isActivationFeaturePathname} to={`${path}/activation`}>
          {t('features.featureActivation.title')}
        </NavLink>
      </NavBar>
      <Container>
        <Switch>
          <Route path={`${path}/new`}>
            <NewfeaturePage />
          </Route>
          <Route path={`${path}/activation`}>
            <FeatureActivationPage />
          </Route>
          <Route>
            <Redirect to={`${path}/new`} />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default Features;
