import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Wrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${({ theme: { palette } }) => palette.lightGrey};
  padding: ${({ theme: { dimensions } }) => dimensions.medium} 0;
  &:first-child {
    padding: 0 0 ${({ theme: { dimensions } }) => dimensions.medium} 0;
    border-top: none;
  }
  &:last-child {
    padding: ${({ theme: { dimensions } }) => dimensions.medium} 0 0 0;
  }

  ${({ wrapped }) =>
    wrapped &&
    breakpoint('xs', 'md')`
    align-items: flex-start;
    flex-direction: column;
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AsideContainer = styled.div`
  ${({ wrapped }) =>
    wrapped &&
    breakpoint('xs', 'md')`
    margin: 0 auto;
  `};
`;

export const Title = styled.h3`
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  line-height: ${({ theme: { fonts } }) => fonts.size.h4};
  color: ${({ theme: { palette } }) => palette.darkBlue};
`;

export const Price = styled.span`
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  line-height: ${({ theme: { fonts } }) => fonts.size.h4};
  color: ${({ theme: { palette } }) => palette.success.default};
`;
