import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { Head, TopBar } from '../../components';
import { getNewEventsByRootUUID } from '../../modules/Order/selectors';

import { Container, NavBar } from './elements';
import OrderContainer from './OrderContainer';
import OrderOMSContainer from './OrderOMSContainer';
import StoreContainer from './StoreContainer';

const OrderManagement = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const newEventsByRootUUID = useSelector(getNewEventsByRootUUID);

  const isStorePathname = pathname.match(/store/g);

  return (
    <>
      <Head title={t('navigation.order_management')} />
      <TopBar title={t('navigation.order_management')} />
      <NavBar>
        <Link isActive={isStorePathname} to={`${path}/store`}>
          {t('order_management.tab.store')}
        </Link>
        <Link isActive={!!pathname.match(/(order$)|(order\/)/g)} to={`${path}/order`}>
          {t('order_management.tab.order')}
        </Link>
        <Link isActive={!!pathname.match(/(order_oms$)|(order_oms\/)/g)} to={`${path}/order_oms`}>
          {t('order_management.tab.order')} OMS
        </Link>
      </NavBar>
      <Container>
        <Switch>
          <Route path={`${path}/store`}>
            <StoreContainer />
          </Route>
          <Route path={`${path}/order`}>
            <OrderContainer newEventsByRootUUID={newEventsByRootUUID} />
          </Route>
          <Route path={`${path}/order_oms`}>
            <OrderOMSContainer />
          </Route>
          <Route>
            <Redirect to={`${path}/store`} />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default OrderManagement;
