/**
 * Payments config actions types
 */

export const DEFAULT_PAYMENT_CONFIG_RETRIEVE_REQUESTED =
  '[DEFAULT_PAYMENT_CONFIG][RETRIEVE] REQUESTED';
export const DEFAULT_PAYMENT_CONFIG_RETRIEVE_SUCCEEDED =
  '[DEFAULT_PAYMENT_CONFIG][RETRIEVE] SUCCEEDED';
export const DEFAULT_PAYMENT_CONFIG_RETRIEVE_FAILED = '[DEFAULT_PAYMENT_CONFIG][RETRIEVE] FAILED';
export const DEFAULT_PAYMENT_CONFIG_UPDATE_REQUESTED = '[DEFAULT_PAYMENT_CONFIG][UPDATE] REQUESTED';
export const DEFAULT_PAYMENT_CONFIG_UPDATE_SUCCEEDED = '[DEFAULT_PAYMENT_CONFIG][UPDATE] SUCCEEDED';
export const DEFAULT_PAYMENT_CONFIG_UPDATE_FAILED = '[DEFAULT_PAYMENT_CONFIG][UPDATE] FAILED';
export const PAYMENTS_CONFIG_RETRIEVE_REQUESTED = '[PAYMENTS_CONFIG][RETRIEVE] REQUESTED';
export const PAYMENTS_CONFIG_RETRIEVE_SUCCEEDED = '[PAYMENTS_CONFIG][RETRIEVE] SUCCEEDED';
export const PAYMENTS_CONFIG_RETRIEVE_FAILED = '[PAYMENTS_CONFIG][RETRIEVE] FAILED';
export const PAYMENTS_CONFIG_UPDATE_REQUESTED = '[PAYMENTS_CONFIG][UPDATE] REQUESTED';
export const PAYMENTS_CONFIG_UPDATE_SUCCEEDED = '[PAYMENTS_CONFIG][UPDATE] SUCCEEDED';
export const PAYMENTS_CONFIG_UPDATE_FAILED = '[PAYMENTS_CONFIG][UPDATE] FAILED';
export const PAYMENTS_CONFIG_SELECT_LABEL = '[PAYMENTS_CONFIG][SELECT] LABEL';
