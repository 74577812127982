import { RestAPI as API } from '@aws-amplify/api-rest';

import { AvailableAPIs } from '../../../amplify-config';

export interface GetIntegrationRequestDetailsParamsType {
  readonly requestUUID: string;
}

export async function getIntegrationRequestDetails({
  requestUUID,
}: GetIntegrationRequestDetailsParamsType) {
  return await API.get(AvailableAPIs.Internal, `/integrations/requests/${requestUUID}`, {});
}
