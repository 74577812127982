import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const SideNavHeader = ({ children, className }) => <div className={className}>{children}</div>;

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
SideNavHeader.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
SideNavHeader.defaultProps = {
  children: null,
  className: '',
};

export default styled(SideNavHeader)`
  grid-area: sidenavHeader;
  justify-self: center;
  align-self: center;

  ${breakpoint('xs', 'sm')`
    display: none;
  `};
`;
