import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

const DateTimeCell = ({ value, dateFormat }) => {
  const date =
    value instanceof DateTime
      ? value
      : typeof value === 'string'
      ? DateTime.fromISO(value)
      : typeof value === 'number'
      ? DateTime.fromMillis(value)
      : value instanceof Date
      ? DateTime.fromJSDate(value)
      : DateTime.invalid('Invalid');

  if (!date.isValid) {
    return <span>Invalid Date</span>;
  }

  return date.toLocaleString(dateFormat);
};

DateTimeCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dateFormat: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DateTimeCell.defaultProps = {
  value: null,
  dateFormat: DateTime.DATETIME_FULL_WITH_SECONDS,
};

export default DateTimeCell;
