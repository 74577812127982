import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';
import Quantity from '../Cells/Quantity';

import BaseEvent from './BaseEvent';

const OrderLineWasAdded = [
  ...BaseEvent,
  {
    name: 'separator',
    isSeparator: true,
  },
  {
    name: 'productID',
    label: i18n.t('order_management.fields.productID'),
    value: item => item.payload.productID,
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'name',
    label: i18n.t('order_management.fields.orderLineName'),
    value: item => item.payload.name,
    Component: DefinitionList.Cells.TextField,
  },
  {
    name: 'menu',
    label: i18n.t('order_management.fields.menu'),
    value: item => item.payload.menuName,
    Component: DefinitionList.Cells.TextField,
    hideEmpty: true,
  },
  {
    name: 'price',
    label: i18n.t('order_management.fields.price'),
    value: item => item.payload.price,
    Component: DefinitionList.Cells.NumberField,
  },
  {
    name: 'tax',
    label: i18n.t('order_management.fields.tax'),
    value: item => item.payload.tax / 100,
    Component: DefinitionList.Cells.NumberField,
    options: { percent: true },
  },
  {
    name: 'taxIdentifier',
    label: i18n.t('order_management.fields.taxIdentifier'),
    value: item => item.payload.taxIdentifier,
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'quantity',
    label: i18n.t('order_management.fields.quantity'),
    value: ({ payload: { quantity, unit } }) => ({ quantity, unit }),
    Component: Quantity,
  },
];

export default OrderLineWasAdded;
