import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@tillersystems/stardust';

import { Button, Container } from './elements';

const PaginationButtons = ({ onPreview, counter, maxCount, onNext }) => (
  <Container>
    <Button type="button" onClick={onPreview}>
      <Icon name="chevron-left" color="hsl(213,17%,20%)" size="15" />
    </Button>
    <div>{`${counter}/${maxCount}`}</div>
    <Button type="button" onClick={onNext}>
      <Icon name="chevron-right" color="hsl(213,17%,20%)" size="15" />
    </Button>
  </Container>
);

PaginationButtons.propTypes = {
  onPreview: PropTypes.func,
  counter: PropTypes.number,
  maxCount: PropTypes.number,
  onNext: PropTypes.func,
};

PaginationButtons.defaultProps = {
  onPreview: () => {
    // handle onPreview here
  },
  counter: 0,
  maxCount: 0,
  onNext: () => {
    // handle onNext here
  },
};

export default PaginationButtons;
