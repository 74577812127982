import _find from 'lodash/find';
import _reduce from 'lodash/reduce';

/**
 * Search for a pattern in the given object
 *
 * @param {string|number|boolean|RegExp} pattern the pattern to search for
 * @param {*} entity the entity to search in
 * @return {boolean} true if the pattern is found false otherwise
 */
export function search(pattern, entity) {
  if (['boolean', 'number', 'string'].includes(typeof pattern)) {
    pattern = new RegExp(String(pattern), 'i');
  } else if (!(pattern instanceof RegExp)) {
    return false;
  }

  switch (typeof entity) {
    case 'string':
    case 'number':
    case 'boolean':
      return pattern.test(String(entity));
    case 'object':
      return _find(entity, value => search(pattern, value)) !== undefined;
    default:
      return false;
  }
}

/**
 * Replace in the ${data} object its values by ${newValue} only for keys present in the given ${filter}
 *
 * @param {object} data object that will have some of its values replaced
 * @param {any} newValue the new value to set
 * @param {object|string[]} [filter=[]] keys to filter the values to be replaced
 * @param {boolean} [useFilterKey=false] if true use the key of the filter argument instead of the value
 */
export function setWithFilter(data, newValue, filter = [], useFilterKey = false) {
  return _reduce(
    filter,
    (replacedData, value, key) => {
      if (newValue !== undefined) {
        replacedData[useFilterKey ? key : value] = newValue;
      } else {
        delete replacedData[useFilterKey ? key : value];
      }
      return replacedData;
    },
    data,
  );
}

/**
 * Delete in the ${data} object the values only for keys present in the given ${filter}
 *
 * @param {object} data object that will have some of its values deleted
 * @param {object|string[]} [filter=[]] keys to filter the values to be deleted
 * @param {boolean} [useFilterKey=false] if true use the key of the filter argument instead of the value
 */
export function deleteWithFilter(data, filter = [], useFilterKey = false) {
  return setWithFilter(data, undefined, filter, useFilterKey);
}
