/**
 *
 * Modules - Store
 *
 * Defines the redux store.
 *
 */

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import getReducers from './reducers';
import getSagas from './sagas';

/** Internal reference to redux's store. */
let store = null;

/**
 * Gets the current state of Redux store.
 *
 * @return {Object}
 */
export const getStoreState = () => (store && store.getState()) || {}; // FIXME: that's ugly.

export default initialState => {
  let enhancers;
  let middlewares;

  const saga = createSagaMiddleware();

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createLogger } = require('redux-logger');

    // Configure Redux logger
    const logger = createLogger({
      collapsed: true,
      level: 'info',
      logger: console,
    });

    // Middlewares for development
    middlewares = [logger, saga];
    enhancers = [
      applyMiddleware(...middlewares),
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f,
    ];
  } else {
    enhancers = [applyMiddleware(saga)];
  }

  store = createStore(getReducers, initialState, compose(...enhancers));
  saga.run(getSagas());

  return store;
};
