import { RestAPI as API } from '@aws-amplify/api-rest';

import { AvailableAPIs } from '../../../amplify-config';
import { IntegrationRequestStatusType } from '../types';

export interface SetIntegrationRequestStatusParamsType {
  readonly requestUUID: string;
  readonly status: IntegrationRequestStatusType;
}

export async function setIntegrationRequestStatus(params: SetIntegrationRequestStatusParamsType) {
  return await API.put(AvailableAPIs.Internal, `/integrations/requests/${params.requestUUID}`, {
    body: {
      status: params.status,
    },
  });
}
