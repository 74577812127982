import styled from 'styled-components';

import { Button } from '@tillersystems/stardust';

export const ButtonLink = styled(Button)``;

export const CenterItem = styled.span`
  display: flex;
  padding: ${({ theme: { dimensions } }) => dimensions.big} 0;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 3rem;
`;

export const TableContainer = styled.div`
  border: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
`;

export const TableHeader = styled.div`
  border-radius: ${({ theme: { dimensions } }) => `${dimensions.radius} ${dimensions.radius} 0 0`};
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  background-color: ${({ theme: { palette } }) => palette.grey};
  display: flex;
  align-items: center;
  padding: ${({ theme: { dimensions } }) => dimensions.small};
  justify-content: space-between;
`;

export const TableBody = styled.div`
  table {
    table-layout: fixed;

    thead th:first-child,
    tbody th {
      width: 8rem;

      > div {
        display: inline-block;
      }
    }
    tbody tr td:nth-child(3) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Wrapper = styled.div`
  padding-right: 3rem;
`;
