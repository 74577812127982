import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { OptionsList } from '@tillersystems/stardust';

import EventDefinitions, { CATEGORIES, getTypesFromCategory } from '../Detail/definitions';

import { getAllOptions, getOptions } from './config';
import { Container } from './elements';

const OrderEventTypeSelect = ({ values, onChange }) => {
  const [t] = useTranslation();
  const options = useMemo(() => getOptions(), []);

  const selectedValues = useMemo(() => (values !== 'all' && values) || getAllOptions(), [values]);
  const selectedTypesEvents = useMemo(
    () => selectedValues.filter(value => !CATEGORIES.includes(value)),
    [selectedValues],
  );
  const typesEventsOptions = useMemo(
    () => options.filter(option => !CATEGORIES.includes(option)),
    [options],
  );

  const searchMethod = useCallback(({ options, term }) => {
    return options.filter(option =>
      option.text.toString().toLowerCase().includes(term.toLowerCase()),
    );
  }, []);

  const handleChange = useCallback(changedValues => {
    // Will disabled filtering.
    // We do this so that events not in definitions will show up.
    if (changedValues && changedValues.length === options.length) return onChange('all');
    if (changedValues && !changedValues.length) return onChange([]);

    const [removedValue] = selectedValues.filter(value => !changedValues.includes(value));

    let newValues = [...selectedValues];
    if (removedValue) {
      if (CATEGORIES.includes(removedValue)) {
        const typesToRemove = getTypesFromCategory(removedValue);
        newValues = selectedValues.filter(
          value => !typesToRemove.includes(value) && value !== removedValue,
        );
      } else {
        newValues = selectedValues.filter(
          value => value !== removedValue && value !== EventDefinitions[removedValue].category,
        );
      }
    } else {
      const [addedValue] = changedValues.filter(value => !selectedValues.includes(value));

      if (CATEGORIES.includes(addedValue)) {
        const typesToAdd = getTypesFromCategory(addedValue);
        newValues.push(addedValue);
        newValues = newValues.concat(typesToAdd.filter(type => !newValues.includes(type)));
      } else {
        const typesFromCategory = getTypesFromCategory(EventDefinitions[addedValue].category);
        newValues.push(addedValue);
        if (typesFromCategory.every(type => newValues.includes(type))) {
          newValues.push(EventDefinitions[addedValue].category);
        }
      }
    }

    onChange(newValues);
  });

  return (
    <Container
      allowMultiple
      displayValue={selected =>
        selectedTypesEvents && selectedTypesEvents.length === typesEventsOptions.length
          ? t('order_management.events_types_select.all.value', {
              count: selectedTypesEvents.length,
            })
          : t('order_management.events_types_select.title', {
              count: selectedTypesEvents.length,
              name: selected[0] && selected[0].text,
            })
      }
      contentWrapperStyle={{ width: 'auto', maxHeight: '26rem' }}
      OptionComponent={OptionsList.Checkbox}
      options={options}
      values={selectedValues}
      searchPlaceholder={t('order_management.events_types_select.placeholder')}
      NoResult={t('order_management.events_types_select.no_result')}
      toggleAllLabel={t('order_management.events_types_select.toggle_all')}
      onChange={handleChange}
      searchMethod={searchMethod}
      usePortal
    />
  );
};

OrderEventTypeSelect.propTypes = {
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OrderEventTypeSelect;
