import React, { Children, cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import STATUS from './constants';
import { Percentage } from './elements';
/**
 * Stepbar
 *
 * This component is in charge of displaying
 * a step bar
 *
 * @param {node} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {number} current // The current index of actual step.
 *
 * @return {jsx}
 */
import StepChild from './Step';

const { COMPLETE, PROCESS } = STATUS;

class StepBar extends PureComponent {
  static Step = StepChild;

  static propTypes = {
    children: PropTypes.node,
    current: PropTypes.number,
    status: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    current: 0,
    status: PROCESS,
  };

  render() {
    const { children, current, ...restProps } = this.props;
    const percentValue = Math.floor((current * 100) / children.length);

    return (
      <ol {...restProps}>
        <Percentage>{percentValue} %</Percentage>
        {Children.map(children, (child, index) => {
          const childProps = {
            stepNumber: `${index + 1}`,
            ...child.props,
          };
          if (index === current) {
            childProps.status = PROCESS;
          } else if (index < current) {
            childProps.status = COMPLETE;
          }
          return cloneElement(child, { ...childProps });
        })}
      </ol>
    );
  }
}

export default styled(StepBar)`
  display: flex;
  align-items: center;

  list-style-type: none;
`;
