/* eslint-disable no-unreachable */
import { CLIENT_ERROR } from 'apisauce';

import { ApiError } from '../../helpers/errors';
import { Order, OrderEvent } from '../../helpers/models';

export async function getEvents(manager, shopID, rootUUID, paginate = undefined) {
  const { ok, status, problem, data } = await manager.get(
    `/shop/${shopID}/order/${rootUUID}/events`,
    {
      paginate,
    },
  );

  if (!ok) {
    if (problem === CLIENT_ERROR) {
      throw new ApiError(data.error, status);
    } else {
      throw new ApiError(data && data.error, status || problem);
    }
  }

  if (data.paginate) {
    const nextPageEvents = await getEvents(manager, shopID, rootUUID, data.paginate);

    return [...data.events.map(data => new OrderEvent(data)), ...nextPageEvents];
  }

  return data.events.map(data => new OrderEvent(data));
}

export async function getOrder(manager, orderId) {
  const { ok, status, problem, data } = await manager.get(`/search/${orderId}`);

  if (!ok) {
    if (problem === CLIENT_ERROR) {
      throw new ApiError(data.error, status);
    } else {
      throw new ApiError(data && data.error, status || problem);
    }
  }

  // Faking orderId if not sent by server
  if (data.order.rootUUID !== orderId && !data.order.orderId) {
    data.order.orderId = orderId;
  }

  return new Order(data.order);
}

export async function syncEvents(manager, shopID, rootUUID, events) {
  const payload = { events };
  const { ok, status, problem, data } = await manager.post(
    `/shop/${shopID}/order/${rootUUID}/events`,
    payload,
  );

  if (!ok) {
    if (problem === CLIENT_ERROR) {
      throw new ApiError(data.error, status);
    } else {
      throw new ApiError(data && data.error, status || problem);
    }
  }

  return data;
}

export async function replayEvents(manager, shopID, rootUUID) {
  const { ok, status, problem, data } = await manager.get(
    `/shop/${shopID}/order/${rootUUID}/replay`,
  );

  if (!ok) {
    if (problem === CLIENT_ERROR) {
      throw new ApiError(data.error, status);
    } else {
      throw new ApiError(data && data.error, status || problem);
    }
  }

  return data;
}
