import { createSelector } from 'reselect';

import { LOADING, NONE } from '../../constants/Status';

/**
 * Country selectors
 */

/**
 * @typedef {Object} Country
 * @property {string} label
 * @property {string} translation_key
 */

/**
 * Get the loading status about the fetched datas
 *
 * @return {string}
 */
export const getCountriesStatus = ({ countries }) => countries.status;

/**
 * If data is being fetched
 *
 * @return {boolean}
 */
export const isCountriesLoading = createSelector(getCountriesStatus, status => status === LOADING);

/**
 * If data is done fetching
 *
 * @return {boolean}
 */
export const isCountriesDoneLoading = createSelector(
  getCountriesStatus,
  status => status !== NONE && status !== LOADING,
);

/**
 * Get the list of countries
 *
 * @return {Object.<string, Country>} a list of countries
 */
export const getCountries = ({ countries }) => countries.entities;

/**
 * Get an array of countries
 *
 * @return {Country[]} an array of countries
 */
export const getCountriesArray = createSelector(getCountries, countries =>
  Object.values(countries),
);
