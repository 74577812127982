import styled from 'styled-components';

export const Logo = styled.img`
  width: ${({ theme: { dimensions } }) => dimensions.big};
`;

export const Wrapper = styled.div`
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  padding: ${({ theme: { dimensions } }) => dimensions.tiny} 0;

  > :first-child {
    margin-right: ${({ theme: { dimensions } }) => dimensions.small};
  }
`;
