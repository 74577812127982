import React from 'react';

import { Loader } from '@tillersystems/stardust';

import { CenterItem } from './elements';

export default () => (
  <CenterItem>
    <Loader width="6rem" height="6rem" />
  </CenterItem>
);
