import { Auth } from '@aws-amplify/auth';

/**
 * Applies transformation to the given request prior to be sent.
 *
 * @param {apisauce.Request} request - The request to transform.
 */
export const preRequestTransform = async request => {
  const token = (await Auth.currentSession()).getAccessToken();

  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }

  if (['post', 'put'].includes(request.method)) {
    request.headers['Content-Type'] = 'application/json';
  }
};
