import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuHeader = ({ children, className }) => <div className={className}>{children}</div>;

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
MenuHeader.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
MenuHeader.defaultProps = {
  children: null,
  className: '',
};

export default styled(MenuHeader)`
  margin-bottom: 1rem;

  text-transform: uppercase;
  font-size: ${({ theme: { fonts } }) => fonts.medium};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};

  color: ${({ theme: { palette } }) => palette.darkBlue};
`;
