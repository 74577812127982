import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { formatNumber, Tag, Theme } from '@tillersystems/stardust';

import { ACCEPTED } from '../helpers';

import { CardTitle, HeaderCell, PaymentsTitle, Row, Table, TableHeader, Wrapper } from './elements';

const Payments = ({ payments, currency }) => {
  const [t] = useTranslation();

  return (
    <Wrapper>
      <PaymentsTitle>
        <CardTitle>{t('order_management.payments.title')}</CardTitle>
      </PaymentsTitle>
      <Table>
        <TableHeader>
          <tr>
            <HeaderCell scope="col" style={{ textAlign: 'left' }}>
              {t('order_management.payments.type')}
            </HeaderCell>
            <HeaderCell scope="col">{t('order_management.payments.id')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.payments.external_id')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.payments.date')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.payments.device')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.payments.amount')}</HeaderCell>
            <HeaderCell scope="col">{t('order_management.payments.status')}</HeaderCell>
          </tr>
        </TableHeader>
        <tbody>
          {payments.map(payment => (
            <Row key={payment.uuid}>
              <td>{payment.label}</td>
              <td>{payment.id}</td>
              <td>{payment.uuid}</td>
              <td>{DateTime.fromISO(payment.date).toLocaleString(DateTime.DATETIME_SHORT)}</td>
              <td>{payment.deviceName}</td>
              <td>
                {formatNumber({
                  currency,
                  locale: 'fr',
                  number: payment.price,
                })}
              </td>
              <td>
                <Tag color={payment.status === ACCEPTED ? Theme.palette.success.default : 'orange'}>
                  {payment.status}
                </Tag>
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

Payments.propTypes = {
  payments: PropTypes.array,
  currency: PropTypes.string,
};

Payments.defaultProps = {
  payments: [],
  currency: '',
};

export default Payments;
