import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Icon, Loader, Theme } from '@tillersystems/stardust';

import { LoaderOverlay } from '../../../../components';
import connect from '../connect';
import OrderLines from '../OrderLines';
import Payments from '../Payments';

import { OrderHeaderDefinitions, storeDefinitions } from './definitions';
import { Body, CardTitle, Content, Header, LoaderContainer, OrderId, Wrapper } from './elements';

const OrderCard = ({ loading, orderDetails, store, rawTicket, isRawTicketsLoading }) => {
  const [t] = useTranslation();

  const [isOrderDetailOpen, setIsOrderDetailOpen] = useState(false);

  const renderLoading = () => (
    <LoaderContainer>
      <Loader width="3rem" height="3rem" />
    </LoaderContainer>
  );

  const renderHeader = () => (
    <>
      <div>
        <CardTitle>
          {t('order_management.order', {
            order: orderDetails && orderDetails.name ? orderDetails.name : null,
          })}
        </CardTitle>
        <OrderId>{orderDetails && orderDetails.orderId && '#' + orderDetails.orderId}</OrderId>
      </div>
      {orderDetails && <Content data={orderDetails} definitions={OrderHeaderDefinitions} />}
      {rawTicket && (
        <Icon
          cursor="pointer"
          name={isOrderDetailOpen ? 'chevron-top' : 'chevron-down'}
          color={Theme.palette.darkBlue}
          title={
            isOrderDetailOpen
              ? t('order_management.actions.show_less')
              : t('order_management.actions.show_more')
          }
          size="25"
          onClick={() => setIsOrderDetailOpen(!isOrderDetailOpen)}
        />
      )}
    </>
  );

  const renderOrderDetail = () => (
    <>
      <Body>
        {loading.order && <LoaderOverlay />}
        {store && <Content data={store} definitions={storeDefinitions} />}
      </Body>
      {isRawTicketsLoading ? (
        renderLoading()
      ) : rawTicket ? (
        <>
          <OrderLines orderLines={rawTicket.orderLines} currency={rawTicket.currency} />
          <Payments payments={rawTicket.payments} currency={rawTicket.currency} />
        </>
      ) : null}
    </>
  );

  return (
    <Wrapper>
      <Header>{renderHeader()}</Header>
      {isOrderDetailOpen ? renderOrderDetail() : null}
    </Wrapper>
  );
};

OrderCard.propTypes = {
  loading: PropTypes.shape({
    order: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    order: PropTypes.instanceOf(Error),
  }),
  orderDetails: PropTypes.object,
  store: PropTypes.object,
  rawTicket: PropTypes.object,
  isRawTicketsLoading: PropTypes.bool,
};

OrderCard.defaultProps = {
  loading: { order: false },
  errors: { order: null },
  orderDetails: null,
  store: null,
  rawTicket: null,
  isRawTicketsLoading: false,
};

export default connect(OrderCard);
