export const defaultOptions = {
  webSocketUrl: process.env.REACT_APP_AWS_WEBSOCKET_URL,
};

export enum WebSocketErrors {
  NO_CONNECTION = 'Connection failed or got disconnected',
  NO_TOKEN = 'Invalid access token',
  WRONG_FORMAT = 'Could not parse the message',
  NO_WEBSOCKET_URL = '`webSocketUrl` is undefined',
}
