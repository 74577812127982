import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

/* eslint-disable react/no-children-prop */
/**
 * Menu Link
 *
 * This component is in charge of displaying
 * a navigation link item
 *
 * @param {string} className // className for styled component.
 * @param {bool} activeOnlyWhenExact // Make the Navigation link active only when exact.
 * @param {string} title // The navigation item title.
 * @param {string} to // The navigation path where you want to go.

 * @return {jsx}
 */
import { Container, Title } from './elements';

const MenuLink = ({ className, title, to, activeOnlyWhenExact }) => (
  <Route
    className={className}
    path={to}
    exact={activeOnlyWhenExact}
    children={({ match }) => (
      <Container active={match} to={to}>
        <Title>{title}</Title>
      </Container>
    )}
  />
);

/**
 * PropTypes Validation
 */
const { bool, string } = PropTypes;
MenuLink.propTypes = {
  activeOnlyWhenExact: bool,
  className: string,
  title: string,
  to: string.isRequired,
};

/**
 * Default props
 */
MenuLink.defaultProps = {
  className: '',
  activeOnlyWhenExact: true,
  title: '',
};

export default MenuLink;
