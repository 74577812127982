import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RestAPI as API } from '@aws-amplify/api-rest';
import { DateTime } from 'luxon';

import { AvailableAPIs } from '../../amplify-config';

import {
  DataIntegrityContextType,
  DataIntegrityQueryType,
  InputName,
  IntegrityCheck,
  RawIntegrityCheck,
} from './type';

const DataIntegrityContext = createContext<DataIntegrityContextType | null>(null);

export const useDataIntegrityContext = () => useContext(DataIntegrityContext);

const DataIntegrityContextProvider = ({
  children,
  testValue = {},
}: PropsWithChildren<{ testValue?: Partial<DataIntegrityContextType> }>) => {
  const { t } = useTranslation();

  const [{ storeId, timePeriod, from }, setQueryData] = useState<DataIntegrityQueryType>({
    [InputName.storeId]: undefined,
    [InputName.timePeriod]: undefined,
    [InputName.from]: undefined,
  });

  const { isLoading, data } = useQuery<RawIntegrityCheck[]>(
    `integrity-archive-${storeId}-${timePeriod}-${from}`,
    () =>
      API.get(
        AvailableAPIs.Internal,
        `/stores/${storeId}/period/${timePeriod}/from/${from}/integrity`,
        null,
      ),
    { enabled: !!storeId && !!timePeriod && !!from },
  );

  const getErrorMessage = (id, error) =>
    (!!error && t(`data_integrity.search_form.${id}.error.${error.type}`)) || '';

  const integrityChecks = useMemo<IntegrityCheck[]>(
    () =>
      (data || [])
        .map(({ filename, integrity }) => {
          const dates = filename
            // Try to match two ISO dates in filename with format 0000-00-00T00:00:00.000+00:00
            .match(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}\+\d{2}:\d{2})/g)
            ?.map(date => DateTime.fromISO(date));

          return {
            period: {
              from: dates?.[0],
              to: dates?.[1],
            },
            filename,
            integrity,
          };
        })
        .sort((a, b) => (a.filename > b.filename ? 1 : -1)),
    [data],
  );

  return (
    <DataIntegrityContext.Provider
      value={{ getErrorMessage, integrityChecks, isLoading, setQueryData, ...testValue }}
    >
      {children}
    </DataIntegrityContext.Provider>
  );
};

export default DataIntegrityContextProvider;
