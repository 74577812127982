import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '@tillersystems/stardust';

import { SearchForm } from './elements';

const SearchBar = ({ onChange, onSubmit, value, placeholder }) => (
  <SearchForm onSubmit={onSubmit}>
    <TextInput type="text" value={value} placeholder={placeholder} onChange={onChange} />
  </SearchForm>
);

SearchBar.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

SearchBar.defaultProps = {
  value: '',
  placeholder: '',
  onChange: () => {
    // handle onChange here
  },
  onSubmit: () => {
    // handle onSubmit here
  },
};

export default SearchBar;
