import React from 'react';
import Media from 'react-media';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Theme from '../../Theme';

/* eslint-disable react/no-children-prop */
/**
 * Side Nav Link
 *
 * This component is in charge of displaying
 * a navigation link item
 *
 * @param {string} className // className for styled component.
 * @param {bool} activeOnlyWhenExact // Make the Navigation link active only when exact.
 * @param {node} icon // The icon shape.
 * @param {string} title // The navigation item title.
 * @param {string} to // The navigation path where you want to go.

 * @return {jsx}
 */
import { Container, Icon, Title } from './elements';

const SideNavLink = ({ className, title, icon, to, activeOnlyWhenExact }) => (
  <Route
    className={className}
    path={to}
    exact={activeOnlyWhenExact}
    children={({ match }) => (
      <Container active={match} to={to}>
        <Icon>{icon}</Icon>
        <Media query={{ maxWidth: Theme.breakpoints.lg }}>
          {matches => (matches ? null : <Title>{title}</Title>)}
        </Media>
      </Container>
    )}
  />
);

/**
 * PropTypes Validation
 */
const { bool, string, object } = PropTypes;
SideNavLink.propTypes = {
  activeOnlyWhenExact: bool,
  className: string,
  icon: object,
  title: string,
  to: string.isRequired,
};

/**
 * Default props
 */
SideNavLink.defaultProps = {
  className: '',
  icon: null,
  activeOnlyWhenExact: false,
  title: '',
};

export default SideNavLink;
