import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Button, Spinner } from '@sumup/circuit-ui';
import { ChevronLeft } from '@sumup/icons';

import { Card } from '../../../components';
import { formatDateShort } from '../../../helpers/date';
import IntegrationRequestStatus from '../IntegrationRequestStatus';

import {
  CardTitle,
  CenterItem,
  Header,
  Id,
  Section,
  StyledCard,
  StyledDL,
  Wrapper,
} from './elements';
import { getIntegrationRequestDetails, GetIntegrationRequestDetailsParamsType } from './services';

const IntegrationRequestDetails: FC = () => {
  const { t } = useTranslation();
  const { requestUUID } = useParams<GetIntegrationRequestDetailsParamsType>();

  const { data: request, isLoading } = useQuery(['integrationRequestDetails', requestUUID], () =>
    getIntegrationRequestDetails({ requestUUID }),
  );

  return (
    <Wrapper>
      <Header>
        <Link to={`/appmarket`}>
          <Button variant="secondary" icon={() => <ChevronLeft />}>
            {t('app_market.integration_request_details.back_to_the_list')}
          </Button>
        </Link>
      </Header>
      <StyledCard>
        <Card.Header>
          <CardTitle>
            {t('app_market.integration_request_details.title')} <Id>{requestUUID}</Id>
          </CardTitle>
        </Card.Header>
        <Card.Body padding="2rem 3rem">
          {isLoading ? (
            <CenterItem>
              <Spinner />
            </CenterItem>
          ) : (
            <Section>
              <StyledDL>
                <div>
                  <dt>{t('app_market.integration_request.status')}</dt>
                  <dd>
                    <IntegrationRequestStatus requestUUID={request.UUID} status={request.status} />
                  </dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.type')}</dt>
                  <dd>{request.type}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.created_at')}</dt>
                  <dd>{formatDateShort(request.createdAt)}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.updated_at')}</dt>
                  <dd>{formatDateShort(request.updatedAt)}</dd>
                </div>
              </StyledDL>
            </Section>
          )}
        </Card.Body>
      </StyledCard>
      <StyledCard>
        <Card.Header>
          <CardTitle>{t('app_market.integration_request_details.product')}</CardTitle>
        </Card.Header>
        <Card.Body padding="2rem 3rem">
          {isLoading ? (
            <CenterItem>
              <Spinner />
            </CenterItem>
          ) : (
            <Section>
              <StyledDL>
                <div>
                  <dt>{t('app_market.integration_request.product_description')}</dt>
                  <dd>{request.productDescription}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.main_country')}</dt>
                  <dd>{request.mainCountry}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.operating_countries')}</dt>
                  <dd>{request.operatingCountries.join(', ')}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.current_pos_integrations')}</dt>
                  <dd>{request.currentPOSIntegrations.join(', ')}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.targetted_segments')}</dt>
                  <dd>{request.targettedSegments.join(', ')}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.customer_base_size')}</dt>
                  <dd>{request.customerBaseSize}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.category')}</dt>
                  <dd>{request.category}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.pricing_model')}</dt>
                  <dd>{request.pricingModel}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.number_of_developers')}</dt>
                  <dd>{request.numberOfDevelopers}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.number_of_sales')}</dt>
                  <dd>{request.numberOfSales}</dd>
                </div>
              </StyledDL>
            </Section>
          )}
        </Card.Body>
      </StyledCard>
      <StyledCard>
        <Card.Header>
          <CardTitle>{t('app_market.integration_request_details.contact')}</CardTitle>
        </Card.Header>
        <Card.Body padding="2rem 3rem">
          {isLoading ? (
            <CenterItem>
              <Spinner />
            </CenterItem>
          ) : (
            <Section>
              <StyledDL>
                <div>
                  <dt>{t('app_market.integration_request.company_name')}</dt>
                  <dd>{request.companyName}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.website')}</dt>
                  <dd>
                    <Link to={request.website} target="_blank">
                      {request.website}
                    </Link>
                  </dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.firstname')}</dt>
                  <dd>{request.firstname}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.lastname')}</dt>
                  <dd>{request.lastname}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.phone_number')}</dt>
                  <dd>{request.phoneNumber}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.email')}</dt>
                  <dd>{request.email}</dd>
                </div>
                <div>
                  <dt>{t('app_market.integration_request.job')}</dt>
                  <dd>{request.job}</dd>
                </div>
              </StyledDL>
            </Section>
          )}
        </Card.Body>
      </StyledCard>
    </Wrapper>
  );
};

export default IntegrationRequestDetails;
