import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Tag } from '@tillersystems/stardust';

import Theme from '../../Theme';

const BooleanField = ({ value, translateTrue, translateFalse }) => {
  const [t] = useTranslation();

  return value ? (
    <Tag color={Theme.palette.success.default}>{t(translateTrue)}</Tag>
  ) : (
    <Tag color={Theme.palette.failure.default}>{t(translateFalse)}</Tag>
  );
};

BooleanField.propTypes = {
  value: PropTypes.bool,
  translateTrue: PropTypes.string,
  translateFalse: PropTypes.string,
};

BooleanField.defaultProps = {
  value: null,
  translateTrue: 'values.Boolean.true',
  translateFalse: 'values.Boolean.false',
};

export default BooleanField;
