import { parse, ParseResult } from 'papaparse';

import { MigrationStatus, Status } from './types';

export function extractValidMigrations(data: ParseResult<unknown>['data']): MigrationStatus[] {
  return data.reduce<MigrationStatus[]>((migrations, data) => {
    if (
      typeof data === 'object' &&
      data !== null &&
      'goodTillClientId' in data &&
      data.goodTillClientId &&
      'posProStoreId' in data &&
      data.posProStoreId
    ) {
      migrations.push({
        goodTillClientId: String(data.goodTillClientId),
        posProStoreId: Number(data.posProStoreId),
        status: 'idle',
      });
    }
    return migrations;
  }, []);
}

export function parseMigrationsFile(file: File, complete: (results: ParseResult<unknown>) => void) {
  parse(file, {
    header: true,
    skipEmptyLines: true,
    transformHeader(header) {
      if (/Goodtill store ID/.test(header)) return 'goodTillClientId';
      if (/Tiller store ID/.test(header)) return 'posProStoreId';
      return header;
    },
    complete,
  });
}

export function setStatus(status: 'DONE' | 'ERROR'): Status {
  switch (status) {
    case 'DONE':
      return 'succeeded';
    case 'ERROR':
      return 'failed';
    default:
      return 'pending';
  }
}
