import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Title } from './elements';

/**
 * TopBar
 *
 * Header of a page that informs the user of its position on the app.
 *
 * @param {node} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {string} className // ClassName needed by styled components.
 * @param {string} title // Title of the page
 *
 * @return {jsx}
 */
class TopBar extends PureComponent {
  render() {
    const { children, className, title } = this.props;

    return (
      <div className={className}>
        <Title>{title}</Title>
        {children}
      </div>
    );
  }
}

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
TopBar.propTypes = {
  children: node,
  className: string,
  title: string.isRequired,
};

/**
 * Default props
 */
TopBar.defaultProps = {
  children: null,
  className: '',
};

export default styled(TopBar)`
  width: 100%;
  height: 6.2rem;
  padding: 0 3rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme: { palette } }) => palette.darkBlue};

  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
  background-color: ${({ theme: { palette } }) => palette.white};
`;
