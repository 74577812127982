/**
 * Stores sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { shopOrdersFailed, shopOrdersSucceeded } from './actions';
import { SHOP_ORDERS_REQUESTED } from './actionType';
import { getOrders, getStore } from './service';

/**
 * getStores saga
 *
 * @generator
 */
export function* getShopDetail({ payload: { shopId, startDate, endDate } }) {
  try {
    yield call(getStore, shopId);
    const orders = yield call(getOrders, shopId, startDate, endDate);
    // inform Redux to set our client store(s)
    yield put(shopOrdersSucceeded({ orders, shopId }));
  } catch (error) {
    console.warn(error);

    // If we get an error we send Redux the appropiate action and return
    yield put(shopOrdersFailed(error));
  }
}

/**
 * Global listener.
 */
export default function* shopOrdersWatcher() {
  yield takeLatest(SHOP_ORDERS_REQUESTED, getShopDetail);
}
