import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Head, PageContainer, TopBar } from '../../components';

import connect from './connect';
// import Language from './Language';
import OrderManagement from './OrderManagement';

const Settings = ({ orderManagement, actions: { setIsDetailedView } }) => {
  const [t] = useTranslation();

  return (
    <>
      <Head title={t('navigation.settings')} />
      <TopBar title={t('navigation.settings')} />
      <PageContainer>
        {/*
          For now traductions are disabled
          In case you want to enable them unquote the following lines and make sure you complete the refered files.
          */}
        {/* <Language /> */}
        <OrderManagement orderManagement={orderManagement} setIsDetailedView={setIsDetailedView} />
      </PageContainer>
    </>
  );
};

Settings.propTypes = {
  actions: PropTypes.object.isRequired,
  orderManagement: PropTypes.shape({
    isDetailedView: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(Settings);
