import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getDefaultEventTypes } from '../../../components/OrderEvent/Select/config';
import * as OrderActions from '../../../modules/Order/actions';
import {
  getErrorEvents,
  getErrorOrder,
  getErrorReplay,
  getNewOrderEvents,
  getOrderDetail,
  getOrderEvents,
  hasReplayed,
  isLoadingEvents,
  isLoadingOrder,
  isLoadingReplay,
} from '../../../modules/Order/selectors';
import * as RawTicketsActions from '../../../modules/RawTickets/actions';
import {
  getCurrentRawTicket,
  getOrderName,
  getOrderStatus,
  getRawTicketCurrency,
  getRawTicketId,
  getRawTicketISODate,
  getRawTicketOrderLines,
  getRawTicketPayments,
  getRawTicketsCount,
  getRawTicketsError,
  getRawTicketSignature,
  getRawTicketsIndex,
  getRawTicketTaxes,
  getRawTicketTotal,
  getRawTicketVersion,
  getStoreCashPayed,
  getStoreCashPrice,
  getStoreId,
  getStoreName,
  hasRawTickets,
  isRawTicketsLoading,
} from '../../../modules/RawTickets/selectors';
import { setSetting } from '../../../modules/Settings/actions';
import { getSetting } from '../../../modules/Settings/selectors';

export const getIsDetailedView = state =>
  getSetting('isDetailedView', false, 'orderManagement')(state);
export const getSelectedEventTypes = state =>
  getSetting('selectedEventTypes', getDefaultEventTypes, 'orderManagement')(state);

export const setIsDetailedView = value => setSetting('isDetailedView', value, 'orderManagement');
export const setSelectedEventTypes = value =>
  setSetting('selectedEventTypes', value, 'orderManagement');

/**
 * Part of the Redux global state
 * does what our component want
 */
const mapStateToProps = (state, props) => {
  const { id } = props.match.params;
  const order = getOrderDetail(state, id);

  return {
    loading: {
      order: isLoadingOrder(state),
      events: isLoadingEvents(state),
      replay: isLoadingReplay(state),
    },
    errors: {
      order: getErrorOrder(state),
      events: getErrorEvents(state),
      replay: getErrorReplay(state),
    },
    order,
    orderDetails: { ...order, status: getOrderStatus(state), name: getOrderName(state) },
    store: {
      name: getStoreName(state),
      id: getStoreId(state),
      cashPayed: getStoreCashPayed(state),
      cashPrice: getStoreCashPrice(state),
      currency: getRawTicketCurrency(state),
    },
    events: (order && getOrderEvents(state, order.rootUUID)) || null,
    newEvents: (order && getNewOrderEvents(state, order.rootUUID)) || null,
    hasReplayed: hasReplayed(state),
    settings: {
      isDetailedView: getIsDetailedView(state),
      selectedEventTypes: getSelectedEventTypes(state),
    },
    hasRawTickets: hasRawTickets(state),
    isRawTicketsLoading: isRawTicketsLoading(state),
    rawTicketsCount: getRawTicketsCount(state),
    rawTicketsIndex: getRawTicketsIndex(state),
    rawTicketsError: getRawTicketsError(state),
    rawTicket: getCurrentRawTicket(state)
      ? {
          id: getRawTicketId(state),
          date: getRawTicketISODate(state),
          currency: getRawTicketCurrency(state),
          orderLines: getRawTicketOrderLines(state),
          taxes: getRawTicketTaxes(state),
          total: getRawTicketTotal(state),
          payments: getRawTicketPayments(state),
          signature: getRawTicketSignature(state),
          version: getRawTicketVersion(state),
        }
      : null,
  };
};

/**
 * Action creators that our component
 * want to receive by props
 */
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...RawTicketsActions,
      ...OrderActions,
      setIsDetailedView,
      setSelectedEventTypes,
    },
    dispatch,
  ),
});

export default Component => withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
