import React from 'react';
import { useTranslation } from 'react-i18next';
import Media from 'react-media';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Theme from '../../../components/Theme';
import { Period, PeriodChanged } from '../../../modules/Period';

import {
  Container,
  ContainerMobile,
  Description,
  DescriptionMobile,
  PeriodButton,
  RetryPeriodActions,
  RetryPeriodContainer,
  Title,
  TitleMobile,
} from './elements';

/**
 * No data chart
 *
 * This components is in charge of display
 * a placholder when do data was found
 *
 * @param {object} actions
 *
 * @return {jsx}
 */
const NoDataCardMessage = ({ onPeriodChange, isPeriodTooLarge }) => {
  // Get the translation keys
  const [t] = useTranslation();

  /**
   * Handles change of period.
   */
  const handlePeriodChange = ({ interval }) => {
    const period = new Period(interval);

    onPeriodChange(period);
  };

  return (
    <Media query={{ maxWidth: Theme.breakpoints.sm - 1 }}>
      {matches => (
        <>
          {/* Desktop target */}
          {!matches && (
            <Container>
              <Title>
                {isPeriodTooLarge
                  ? t(`order_management.no_data.period_too_large.title`)
                  : t('order_management.no_data.wrong_period.title')}
              </Title>
              <Description>
                {' '}
                {isPeriodTooLarge
                  ? t(`order_management.no_data.period_too_large.description`)
                  : t('order_management.no_data.wrong_period.description')}
              </Description>
              <RetryPeriodContainer>
                {t('order_management.no_data.retry')}
                <RetryPeriodActions>
                  <PeriodButton onClick={() => handlePeriodChange(Period.today())}>
                    {t('period.today')}
                  </PeriodButton>
                  <PeriodButton onClick={() => handlePeriodChange(Period.yesterday())}>
                    {t('period.yesterday')}
                  </PeriodButton>
                  <PeriodButton onClick={() => handlePeriodChange(Period.thisWeek())}>
                    {t('period.this_week')}
                  </PeriodButton>
                  <PeriodButton onClick={() => handlePeriodChange(Period.lastWeek())}>
                    {t('period.last_week')}
                  </PeriodButton>
                  <PeriodButton onClick={() => handlePeriodChange(Period.thisMonth())}>
                    {t('period.this_month')}
                  </PeriodButton>
                </RetryPeriodActions>
              </RetryPeriodContainer>
            </Container>
          )}

          {/* Mobile target */}
          {matches && (
            <ContainerMobile>
              <TitleMobile>{t('order_management.no_data.title')}</TitleMobile>
              <DescriptionMobile>{t('order_management.no_data.description')}</DescriptionMobile>
            </ContainerMobile>
          )}
        </>
      )}
    </Media>
  );
};

/**
 * PropTypes Validation.
 */
const { func, bool } = PropTypes;
NoDataCardMessage.propTypes = {
  onPeriodChange: func.isRequired,
  isPeriodTooLarge: bool,
};

NoDataCardMessage.defaultProps = {
  isPeriodTooLarge: false,
};

/**
 * Maps Redux's dispatch to the component's props.
 *
 * @param {Function} dispatch - The Redux's dispatch function.
 *
 * @returns {Object} - The mapped props.
 */
const mapDispatchToProps = dispatch => {
  return {
    onPeriodChange: period => dispatch(PeriodChanged(period)),
  };
};

export default connect(null, mapDispatchToProps)(NoDataCardMessage);
