import styled from 'styled-components';

export const Title = styled.div`
  margin-bottom: 0.8rem;

  color: ${({ theme: { palette } }) => palette.darkBlue};

  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
`;

export const Description = styled.div`
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
`;

export const Container = styled.div`
  text-align: center;
  padding: 1.5rem 0;
`;
