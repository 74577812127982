import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSetting } from '../../modules/Settings/actions';
import { getSetting } from '../../modules/Settings/selectors';

export const getIsDetailedView = state =>
  getSetting('isDetailedView', false, 'orderManagement')(state);
export const getSelectedEventTypes = state =>
  getSetting('selectedEventTypes', null, 'orderManagement')(state); // null implicitely means `all types`

export const setIsDetailedView = value => setSetting('isDetailedView', value, 'orderManagement');
export const setSelectedEventTypes = value =>
  setSetting('selectedEventTypes', value, 'orderManagement');

const mapStateToProps = state => ({
  orderManagement: {
    isDetailedView: getIsDetailedView(state),
  },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setIsDetailedView,
    },
    dispatch,
  ),
});

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
