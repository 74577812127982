import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Card Header
 *
 * This component is in charge of displaying
 * a header of a card
 *
 * @param {node} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {string} className // ClassName needed by styled components.
 *
 * @return {jsx}
 */

const CardHeader = ({ children, className }) => <div className={className}>{children}</div>;

/**
 * PropTypes Validation
 */
const { node, string, bool } = PropTypes;
CardHeader.propTypes = {
  children: node,
  className: string,
  // eslint-disable-next-line react/no-unused-prop-types
  centerAlign: bool,
};

/**
 * Default props
 */
CardHeader.defaultProps = {
  children: null,
  className: '',
  centerAlign: false,
};

export default styled(CardHeader)`
  color: ${({ theme: { palette } }) => palette.darkBlue};
  border-top-left-radius: ${({ theme: { dimensions } }) => dimensions.radius};
  border-top-right-radius: ${({ theme: { dimensions } }) => dimensions.radius};

  font-size: ${({ theme: { fonts } }) => fonts.size.h6};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};

  padding: ${({ padding }) => padding || '1rem 1.5rem'};

  background-color: ${({ theme: { palette } }) => palette.white};
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};

  text-align: ${({ centerAlign }) => (centerAlign ? 'center' : 'left')};
  display: flex;
  align-items: center;
`;
