import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '@tillersystems/stardust';

import {
  getPaymentMethods,
  isPaymentMethodsDoneLoading,
  paymentMethodsRequested,
} from '../../../modules/PaymentMethod';
import DefinitionForm from '../../DefinitionForm';

import { getEventDefinition } from './definitions';

const OrderEventForm = ({ data, onSubmit, onRemove }) => {
  const [t] = useTranslation();
  const initialDefinitions = useMemo(() => getEventDefinition(), []);
  const [eventType, setEventType] = useState((data && data.type) || null);
  const [definitions, setDefinitions] = useState(initialDefinitions);

  const paymentMethods = useSelector(getPaymentMethods);
  const isPaymentMethodsLoaded = useSelector(isPaymentMethodsDoneLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isPaymentMethodsLoaded) {
      dispatch(paymentMethodsRequested());
    }
  }, []);

  useEffect(() => {
    const options = paymentMethods.map(paymentMethod => ({
      label: paymentMethod.label,
      value: paymentMethod.label,
    }));
    const newDefinitions = getEventDefinition(eventType, options);
    setDefinitions(newDefinitions);
  }, [eventType]);

  const onChange = useCallback(
    (values, instance) => {
      if (eventType !== values.type) {
        instance.setValues({
          type: values.type,
          payload: {},
        });
        setEventType(values.type);
      }
    },
    [eventType],
  );

  return (
    <DefinitionForm
      validatePristine
      data={data}
      definitions={definitions}
      onChange={onChange}
      onSubmit={onSubmit}
      submitLabel={data && data.uuid ? t('form.submit_update') : t('form.submit_create')}
      footer={
        <Button appearance={data && data.uuid ? 'failure' : 'secondary'} onClick={onRemove}>
          {data && data.uuid ? t('form.remove') : t('form.cancel')}
        </Button>
      }
    />
  );
};

OrderEventForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
};

OrderEventForm.defaultProps = {
  data: {},
  onSubmit: null,
  onRemove: null,
};

export default OrderEventForm;
