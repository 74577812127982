import { DateTime } from 'luxon';

export function parseDate(dateToParse) {
  if (dateToParse instanceof DateTime) return dateToParse.toUTC();
  if (dateToParse instanceof Date)
    return DateTime.fromJSDate(dateToParse, {
      zone: 'utc',
    });
  if (typeof dateToParse === 'number')
    return DateTime.fromMillis(dateToParse, {
      zone: 'utc',
    });
  if (typeof dateToParse === 'string')
    return DateTime.fromISO(dateToParse, {
      zone: 'utc',
    });
  if (!dateToParse) return null;

  return DateTime.invalid('Cannot recognize Date format');
}

export function formatDateShort(dateToFormat: string): string {
  return DateTime.fromISO(dateToFormat).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}
