import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardFooter, CardHeader, Headline } from '@sumup/circuit-ui';

import { useDataIntegrityContext } from '../context';
import { DataIntegrityFormValues } from '../type';

import FromInput from './FromInput';
import StoreIdInput from './StoreIdInput';
import TimePeriodSelect from './TimePeriodSelect';

const SearchForm = () => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();
  const { isLoading, setQueryData } = useDataIntegrityContext();

  const onSubmit = (data: DataIntegrityFormValues) => {
    setQueryData(data);
  };

  return (
    <Card data-testid="search_form_card">
      <CardHeader>
        <Headline size="four" as="h2" noMargin>
          {t('data_integrity.search_form.title')}
        </Headline>
      </CardHeader>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form-search">
        <StoreIdInput />

        <TimePeriodSelect />

        <FromInput />

        <CardFooter>
          <Button
            data-testid="submit_button"
            type="submit"
            variant={'primary'}
            isLoading={isLoading}
            loadingLabel="Loading"
          >
            {t('data_integrity.search_form.button')}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default SearchForm;
