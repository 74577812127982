import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-right: 3rem;
`;

export const CenterItem = styled.span`
  display: flex;
  padding: ${({ theme: { dimensions } }) => dimensions.big} 0;
  justify-content: center;
`;

export const CardTitle = styled.div`
  text-align: left;
  width: 100%;
`;

export const StatusWithAction = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
