/**
 * Countries actions
 */

import * as t from './actionTypes';

/**
 * Countries Requested
 * dispatch this action when
 * you want to start a request to retrieve all payment methods
 */
export const countriesRequested = payload => ({
  type: t.COUNTRIES_REQUESTED,
  payload,
});

/**
 * Countries Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const countriesSucceeded = data => ({
  type: t.COUNTRIES_SUCCEEDED,
  payload: data,
});

/**
 * Countries Failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {Object} error
 *
 * @return {Object}
 */
export const countriesFailed = error => ({
  type: t.COUNTRIES_FAILED,
  payload: error,
});
