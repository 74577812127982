import styled from 'styled-components';

export const Container = styled.ol`
  display: flex;
  align-items: center;

  list-style-type: none;
`;

export const Percentage = styled.span`
  min-width: 3.5rem;
  margin-right: 0.8rem;

  text-align: center;
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
  color: ${({ theme: { palette } }) => palette.green};
`;
