import { createBrowserHistory } from 'history';

let customHistory = null;

/**
 * Gets the browser navigation history.
 *
 * @returns {Object} - The history.
 */
export const getHistory = () => {
  if (!customHistory) {
    customHistory = createBrowserHistory();
  }

  return customHistory;
};
