/**
 * Settings actions
 */

import * as t from './actionTypes';

/**
 * Save preference (this value will be persisted)
 *
 * @param {string} key - preference key
 * @param {*} value - preference value
 * @param {string} namespace - a parent key for the preference
 */
export const setSetting = (key, value, namespace = null) => ({
  type: t.SET_SETTING,
  payload: { key, value, namespace },
});
