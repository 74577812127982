import styled from 'styled-components';

import { Logo } from '@tillersystems/stardust';

const LoginHeader = ({ className }) => (
  <div className={className}>
    <Logo width="120" height="28" />
  </div>
);

export default styled(LoginHeader)`
  background: ${({ theme: { palette } }) => palette.darkBlue};
  display: flex;
  height: 3em;
  justify-content: center;
  align-items: center;
`;
