/**
 * Period Selectors
 */

/**
 *
 * isPeriodLongerThanOneMonth
 *
 * Check if the period interval is highter than 1 month
 *
 * @return {bool}
 */
export const isPeriodLongerThanOneMonth = ({ period }) =>
  period.interval.toDuration('month').toObject().months > 1;

/**
 *
 * getStartDate
 *
 * Return the start date of selected period
 *
 * @return {string}
 */
export const getStartDate = ({ period }) => period.interval.s.toISO({ includeOffset: false });

/**
 *
 * getEndDate
 *
 * Return the end date of selected period
 *
 * @return {string}
 */
export const getEndDate = ({ period }) => period.interval.e.toISO({ includeOffset: false });
