/**
 * Defines the period reducer.
 */

import { createReducer } from 'redux-act';

import * as Actions from './actions';
import Period from './model';

const initialState = Period.today();

export default createReducer(
  {
    [Actions.PeriodChanged]: (state, period) => period,
  },
  initialState,
);
