import PaymentTypes from '../../../../constants/PaymentTypes';
import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';

import BaseEvent from './BaseEvent';

const PaymentWasAddedToOrder = [
  ...BaseEvent,
  {
    name: 'separator',
    isSeparator: true,
  },
  {
    name: 'type',
    label: i18n.t('order_management.fields.paymentType'),
    value: item => item.payload.type,
    Component: DefinitionList.Cells.Enum,
    options: {
      options: PaymentTypes,
    },
  },
  {
    name: 'amount',
    label: i18n.t('order_management.fields.amount'),
    value: item => item.payload.amount,
    Component: DefinitionList.Cells.NumberField,
  },
  {
    name: 'cashback',
    label: i18n.t('order_management.fields.cashback'),
    value: item => item.payload.cashback,
    Component: DefinitionList.Cells.NumberField,
    hideEmpty: true,
  },
];

export default PaymentWasAddedToOrder;
