import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { reduce } from 'lodash';

import 'styled-components/macro'; // needed to use the css props in this file

import { categoriesRequested } from '../../../modules/Category/index';
import { getCategoriesArray, isCategoriesDoneLoading } from '../../../modules/Category/selectors';
import { countriesRequested } from '../../../modules/Country/index';
import { getCountriesArray, isCountriesDoneLoading } from '../../../modules/Country/selectors';
import {
  paymentMethodsRequested,
  paymentMethodsSelectLabel,
} from '../../../modules/PaymentMethod/actions';
import { getSelectedPaymentMethod } from '../../../modules/PaymentMethod/selectors';
import {
  defaultPaymentConfigRetrieveRequested,
  defaultPaymentConfigUpdateRequested,
  paymentsConfigRetrieveRequested,
  paymentsConfigSelectLabel,
  paymentsConfigUpdateRequested,
} from '../../../modules/PaymentsConfig/actions';
import {
  getPaymentConfigCountries,
  getPaymentConfigDefaultCategory,
  getPaymentConfigDefaultOptions,
  getPaymentConfigSpecificConfig,
  isDefaultPaymentConfigDoneLoading,
  isPaymentsConfigChanging,
  isPaymentsConfigDoneLoading,
} from '../../../modules/PaymentsConfig/selectors';

import BreadCrumb from './BreadCrumb';
import { StyledForm, Wrapper } from './elements';
import FormFooter from './FormFooter';
import FormHeader from './FormHeader';
import Loader from './Loader';
import Select from './Select';
import SpecificConfigsEditor from './SpecificConfigsEditor';

export default () => {
  const [t] = useTranslation();
  let history = useHistory();
  const { paymentMethodLabel } = useParams();

  const paymentMethod = useSelector(getSelectedPaymentMethod);
  const isCategoriesLoaded = useSelector(isCategoriesDoneLoading);
  const isCountriesLoaded = useSelector(isCountriesDoneLoading);
  const isDefaultPaymentConfigLoaded = useSelector(isDefaultPaymentConfigDoneLoading);
  const isPaymentsConfigUpdating = useSelector(isPaymentsConfigChanging);
  const isPaymentsConfigLoaded = useSelector(isPaymentsConfigDoneLoading);
  const allCountries = useSelector(getCountriesArray);
  const allCategories = useSelector(getCategoriesArray);
  const paymentConfigSpecificConfig = useSelector(getPaymentConfigSpecificConfig);
  const paymentConfigCountries = useSelector(getPaymentConfigCountries);
  const paymentConfigCategory = useSelector(getPaymentConfigDefaultCategory);
  const paymentConfigOptions = useSelector(getPaymentConfigDefaultOptions);
  const dispatch = useDispatch();

  const [countries, setCountries] = useState(paymentConfigCountries);
  const [defaultCategory, setDefaultCategory] = useState(paymentConfigCategory);
  const [defaultOptions, setDefaultOptions] = useState(paymentConfigOptions);
  const [specificConfigs, setSpecificConfigs] = useState(paymentConfigSpecificConfig);

  useEffect(() => {
    dispatch(paymentMethodsSelectLabel(paymentMethodLabel));
    dispatch(paymentsConfigSelectLabel(paymentMethodLabel));
  }, [paymentMethodLabel]);
  useEffect(() => {
    if (!paymentMethod) dispatch(paymentMethodsRequested());
  }, [paymentMethod && paymentMethod.label]);
  useEffect(() => {
    if (!isCountriesLoaded) dispatch(countriesRequested());
  }, [allCountries.length]);
  useEffect(() => {
    if (!isCategoriesLoaded) dispatch(categoriesRequested());
  }, [allCategories.length]);
  useEffect(() => {
    if (!isDefaultPaymentConfigLoaded) dispatch(defaultPaymentConfigRetrieveRequested());
  }, []);
  useEffect(() => {
    if (isCountriesLoaded && !isPaymentsConfigLoaded) {
      dispatch(
        paymentsConfigRetrieveRequested({
          countries: allCountries.map(({ countryCode }) => countryCode),
        }),
      );
    }
  }, [allCountries.length, paymentConfigCountries.length]);
  useEffect(() => {
    setCountries(paymentConfigCountries);
    setDefaultCategory(paymentConfigCategory);
    setDefaultOptions(paymentConfigOptions);
    setSpecificConfigs(paymentConfigSpecificConfig);
  }, [paymentConfigCountries.length, paymentConfigCategory]);

  const changeCountries = paymentConfigCountries => {
    setCountries(paymentConfigCountries);
    setSpecificConfigs({});
  };

  const cancelPaymentEdition = () => {
    history.push('/payment-methods');
  };

  const submitPaymentEdition = () => {
    dispatch(
      defaultPaymentConfigUpdateRequested({
        label: paymentMethod.label,
        category: defaultCategory,
        options: defaultOptions,
      }),
    );
    dispatch(
      paymentsConfigUpdateRequested({
        label: paymentMethod.label,
        config: reduce(
          countries,
          (config, country) => {
            config[country] = {
              category: defaultCategory,
              options: defaultOptions,
              ...specificConfigs[country],
            };
            return config;
          },
          {},
        ),
      }),
    );
  };

  const renderWrappedContent = children => (
    <>
      <BreadCrumb />

      <Wrapper>{children}</Wrapper>
    </>
  );

  if (!paymentMethod) {
    return renderWrappedContent(<Loader />);
  }

  return renderWrappedContent(
    <StyledForm name="payment_edit">
      <FormHeader label={paymentMethod.label} icon={paymentMethod.icon} />
      {isPaymentsConfigUpdating && <Loader />}
      <StyledForm.Group css={isPaymentsConfigUpdating ? 'visibility: hidden' : ''}>
        <StyledForm.Field label={t('payment_methods.edit.form.country.label')} labelWidth="20rem">
          <Select
            values={countries}
            options={allCountries.map(({ countryCode, translation_key }) => ({
              label: countryCode,
              translation_key,
            }))}
            onChange={changeCountries}
            allowMultiple
            toggleAllLabel={t('payment_methods.edit.form.country.select_all')}
            disabled={!isCountriesLoaded}
            placeholder={t('payment_methods.edit.form.country.default_select_multiple')}
          />
        </StyledForm.Field>
        <StyledForm.Field label={t('payment_methods.edit.form.category.label')} labelWidth="20rem">
          <Select
            values={defaultCategory}
            options={allCategories}
            onChange={setDefaultCategory}
            disabled={!isCategoriesLoaded}
            placeholder={t('payment_methods.edit.form.category.default_select')}
          />
        </StyledForm.Field>
        <StyledForm.Field
          label={t('payment_methods.edit.form.specific_config.label')}
          labelWidth="20rem"
        >
          <SpecificConfigsEditor
            countries={allCountries.filter(({ countryCode }) => countries.includes(countryCode))}
            categories={allCategories.filter(({ label }) => label !== defaultCategory)}
            options={defaultOptions}
            specificConfigs={specificConfigs}
            onSpecificConfigsChange={setSpecificConfigs}
            onSpecificConfigsReset={() => setSpecificConfigs(paymentConfigSpecificConfig)}
          />
        </StyledForm.Field>
      </StyledForm.Group>
      <FormFooter
        onSubmit={submitPaymentEdition}
        onCancel={cancelPaymentEdition}
        disabled={isPaymentsConfigUpdating || countries.length === 0}
      />
    </StyledForm>,
  );
};
