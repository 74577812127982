import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '@tillersystems/stardust';

const TableCell = ({ value, colsDef }) => <Table data={value} colsDef={colsDef} />;

TableCell.propTypes = {
  value: PropTypes.array,
  colsDef: PropTypes.array.isRequired,
};

TableCell.defaultProps = {
  value: null,
};

export default TableCell;
