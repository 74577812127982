export const cleanSyncedEvents = (newEvents, events) => {
  const eventsIds = events.map(event => event.uuid);

  return newEvents.filter(event => !eventsIds.includes(event.uuid));
};

export const sortEvents = events => {
  return [...events].sort((eventA, eventB) => {
    if (eventA.happenedOn.equals(eventB.happenedOn)) {
      return eventA.playhead - eventB.playhead;
    }

    return eventA.happenedOn > eventB.happenedOn ? 1 : -1;
  });
};

export const remapEventsPlayhead = (existingEvents, newEvents) => {
  const lastPlayhead = existingEvents ? existingEvents.length - 1 : -1;

  return newEvents.map((event, index) => {
    event.setPlayhead(lastPlayhead + index + 1);

    return event;
  });
};

export const setEventsSyncStatus = (newEvents, syncResponse) => {
  if (!syncResponse) return newEvents;

  return newEvents.map(event => {
    if (syncResponse.appliedEvents.includes(event.uuid)) {
      event.setSyncStatus('applied');
    } else if (syncResponse.invalidEvents.includes(event.uuid)) {
      event.setSyncStatus('invalid');
    } else if (syncResponse.errorEvents.includes(event.uuid)) {
      event.setSyncStatus('error');
    }
    return event;
  });
};
