import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';

import BaseEvent from './BaseEvent';

const OrderLineWasAddedDefs = [
  ...BaseEvent,
  {
    name: 'separator',
    isSeparator: true,
  },
  {
    name: 'orderLineUUID',
    label: i18n.t('order_management.fields.orderLineUUID'),
    value: item => item.payload.orderLineUUID,
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'amount',
    label: i18n.t('order_management.fields.amount'),
    value: item => item.payload.amount,
    Component: DefinitionList.Cells.NumberField,
  },
];

export default OrderLineWasAddedDefs;
