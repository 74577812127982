import { RestAPI } from '@aws-amplify/api-rest';

import { AvailableAPIs } from '../../amplify-config';

import { Migration } from './GoodTillMigrationPage/types';

export async function migrateGoodTillBatch(data: Omit<Migration, 'status'>[]) {
  return RestAPI.post(AvailableAPIs.Migration, `/migrate/batch`, {
    body: { data },
  });
}
