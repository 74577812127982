const OrderEventCommentReasons = [
  'Fixing open problem to analyse',
  'Fixing reconciliation / synchronisation issues with multiple iPad',
  'Fixing bulk product issue',
  'Fixing event sequence mixed up during execution',
  'Fixing bug generating Transferred event instead of Close',
  'Fixing issues related to release done on 2019-12-16',
];

export default OrderEventCommentReasons;
