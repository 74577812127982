import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Detail, Label, Wrapper } from './elements';

/**
 * Toggle
 *
 * This component is in charge of displaying a Toggle
 * A Toggle is component which show the choice the user have.
 * It behave like a radio input.
 *
 * @param {string} children // Toggle component main label.
 * @param {func} onChange // Callback when Toggle is clicked.
 * @param {string} value // Defined the Toggle value.
 * @param {string} selectedValue // Defined wich Toggle is checked.
 * @param {string} name // The name of the Toggle button group. In most cases it will be defined by the DeliveryToggle component.
 * @param {string} detail // Toggle component detail label.
 * @param {number} price // Toggle component price label.
 *
 * @return {jsx}
 */

const { func, string, number } = PropTypes;

class Toggle extends PureComponent {
  /**
   * PropTypes Validation
   */
  static propTypes = {
    children: string,
    onChange: func,
    value: string,
    selectedValue: string,
    name: string,
    detail: string,
    price: number,
  };

  /**
   * Default props
   */
  static defaultProps = {
    children: '',
    onChange: () => {
      // handle onChange here
    },
    value: '',
    selectedValue: '',
    name: '',
    detail: '',
    price: 0,
  };

  render() {
    const { children, onChange, value, selectedValue, name, detail, price } = this.props;
    const checked = selectedValue === value;
    return (
      <Wrapper checked={checked} onClick={() => onChange(value)} data-testid="outOfEurope">
        <div>
          <Label>{children}</Label>
          <Detail>{detail}</Detail>
          <Label>{price} €</Label>
        </div>
        <Container checked={checked}>
          <input
            defaultChecked={checked}
            type="radio"
            name={name}
            value={value}
            onChange={() => onChange(value)}
          />
        </Container>
      </Wrapper>
    );
  }
}

export default Toggle;
