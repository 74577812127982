import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { Button, DatePicker, Icon } from '@tillersystems/stardust';

import { Theme } from '../../components';
import i18n from '../../i18n';
import { Period, PeriodChanged } from '../../modules/Period';

import { periodPickerVariants } from './animation';
import { PeriodPickerContent } from './elements';
import QuickBar from './QuickBar';

/**
 * This component defines the period picker component.
 *
 * The period picker component is a button that toggles a popover with a date range picker and a
 * quick period selection bar.
 *
 * @param {Period} period - The currently selected period.
 * @param {Function} onPeriodChange - The callback to notify that the period changed.
 *
 * @returns {jsx}
 */
const PeriodPicker = ({ onPeriodChange, period }) => {
  // Get the translation keys
  const [t] = useTranslation();

  /** Internal state. */
  const [isOpen, setIsOpen] = useState(false);

  const locale = i18n.language;

  /**
   * Handles click on the calendar button.
   *
   * Toggles visibility of the popover.
   */
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  PeriodPicker.prototype = {};

  /**
   * Handle Click Outside
   */
  PeriodPicker.handleClickOutside = () => setIsOpen(false);

  /**
   * Handles change of period.
   */
  const handlePeriodChange = interval => {
    const period = new Period(interval);

    setIsOpen(false);
    onPeriodChange(period);
  };

  const isDefaultPeriod = [
    'today',
    'yesterday',
    'this_week',
    'last_week',
    'this_month',
    'last_month',
    'this_year',
    'last_year',
  ].includes(period.toPrettyString());

  return (
    // To avoid the button beeing hidden by the period picker, components should be wrapped in a div.
    <div>
      <Button
        appearance="secondary"
        onClick={handleButtonClick}
        icon={<Icon name="calendar" color={Theme.palette.clay} />}
        iconPosition="left"
      >
        {isDefaultPeriod ? t(`period.${period.toPrettyString()}`) : period.toPrettyString(locale)}
      </Button>
      <>
        <AnimatePresence>
          {isOpen && (
            <PeriodPickerContent
              key="periodPickerContent"
              data-testid="periodPickerContent"
              className="ignore-react-onclickoutside"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={periodPickerVariants}
            >
              <DatePicker
                locale={locale}
                rangePicker
                numberOfMonthsToDisplay={2}
                defaultValue={period.getInterval()}
                maxDate={DateTime.local()}
                onDateChanged={handlePeriodChange}
                displayOnlyInMonth
              />
              <QuickBar period={period} onPeriodChange={handlePeriodChange} />
            </PeriodPickerContent>
          )}
        </AnimatePresence>
      </>
    </div>
  );
};

const { func, instanceOf } = PropTypes;
PeriodPicker.propTypes = {
  onPeriodChange: func.isRequired,
  period: instanceOf(Period).isRequired,
};

/**
 * Maps Redux's state to component's props.
 *
 * @param {Object} state - The Redux's state.
 *
 * @returns {Object} - The mapped props.
 */
const mapStateToProps = state => {
  return { period: state.period };
};

/**
 * Maps Redux's dispatch to the component's props.
 *
 * @param {Function} dispatch - The Redux's dispatch function.
 *
 * @returns {Object} - The mapped props.
 */
const mapDispatchToProps = dispatch => {
  return {
    onPeriodChange: period => dispatch(PeriodChanged(period)),
  };
};

// In order to use a custom event handler,
// specify the function to be used by the HOC
// as second parameter
// https://github.com/Pomax/react-onclickoutside
const clickOutsideConfig = {
  handleClickOutside: () => PeriodPicker.handleClickOutside,
};

const PeriodPicker2 = onClickOutside(PeriodPicker, clickOutsideConfig);

export default connect(mapStateToProps, mapDispatchToProps)(PeriodPicker2);
