import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.button`
  background-color: ${({ theme: { palette } }) => palette.mysticGrey};
  cursor: pointer;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${({ theme: { palette } }) => palette.lightGrey};
  :hover {
    background-color: ${({ theme: { palette } }) => palette.lightGrey};
  }
`;
