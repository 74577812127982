import React from 'react';
import { Translation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Theme from '../../../../../components/Theme';

/**
 * Gets the pie data from the given "raw" data object.
 *
 * @param {Function} t - The I18n instance.
 * @param {Object} data - The "raw" data.
 *
 * @returns {Array} - The pie data.
 */
const getChartData = (t, data) => [
  {
    id: t('reports.appmarket.usage.stores_distribution.using'),
    label: t('reports.appmarket.usage.stores_distribution.using'),
    value: data.stores_with_apps_count.value,
    ratio: `${((100 * data.stores_with_apps_count.value) / data.stores_count.value).toFixed(2)}%`,
    color: Theme.palette.success.default,
  },
  {
    id: t('reports.appmarket.usage.stores_distribution.not_using'),
    label: t('reports.appmarket.usage.stores_distribution.not_using'),
    value: data.stores_count.value - data.stores_with_apps_count.value,
    ratio: `${(
      (100 * (data.stores_count.value - data.stores_with_apps_count.value)) /
      data.stores_count.value
    ).toFixed(2)}%`,
    color: Theme.palette.failure.default,
  },
];

/**
 * Stores distribution pie chart.
 *
 * @param {string} className - className needed by styled components.
 * @param {Object} data - The data to display.
 *
 * @returns {jsx}
 */
const StoresDistribution = ({ className, data }) =>
  data ? (
    <div className={className}>
      <Translation>
        {t => (
          <ResponsivePie
            data={getChartData(t, data)}
            margin={{
              top: 20,
              right: 20,
              bottom: 40,
              left: 20,
            }}
            innerRadius={0.5}
            padAngle={1}
            cornerRadius={4}
            colorBy={({ color }) => color}
            borderColor="inherit:darker(0.6)"
            radialLabel="value"
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor={Theme.palette.darkBlue}
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={8}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor="inherit"
            sliceLabel="ratio"
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor={Theme.palette.white}
            animate
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                translateY: 25,
                itemWidth: 100,
                itemHeight: 14,
                symbolSize: 14,
                symbolShape: 'circle',
              },
            ]}
          />
        )}
      </Translation>
    </div>
  ) : null;

/** Display name. */
StoresDistribution.displayName = 'StoreDistributionPieChart';

/** Prop types. */
const { string, object } = PropTypes;
StoresDistribution.propTypes = {
  className: string,
  data: object,
};

/** Default props. */
StoresDistribution.defaultProps = {
  className: '',
  data: null,
};

export default styled(StoresDistribution)`
  height: 40rem;
  width: 100%;
`;
