import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ListItem from './Item';

/**
 * List
 *
 * This component is in charge of displaying
 * list
 *
 * @param {node} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {string} className // ClassName needed by styled components.
 *
 * @return {jsx}
 */

const List = ({ children, className }) => <ul className={className}>{children}</ul>;

List.Item = ListItem;

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
List.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
List.defaultProps = {
  children: null,
  className: '',
};

export default styled(List)`
  list-style: none;
`;
