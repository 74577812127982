import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Input,
  SubHeadline,
  useNotificationToast,
} from '@sumup/circuit-ui';

import { useGoodTillMigration } from './useGoodTillMigration';

const SingleStore: React.FC = () => {
  const { setToast } = useNotificationToast();
  const { t } = useTranslation();

  const goodTillClientIdRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);
  const posProStoreIdRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);

  const { subscribe, mutate, isInProgress, status } = useGoodTillMigration();

  useEffect(() => {
    const goodTillClientIdValue = goodTillClientIdRef.current?.value;
    const posProStoreIdValue = posProStoreIdRef.current?.valueAsNumber;

    const migrationStatus = status.find(
      migrationStatus =>
        migrationStatus.goodTillClientId === goodTillClientIdValue &&
        migrationStatus.posProStoreId === posProStoreIdValue,
    );

    if (!migrationStatus) {
      return;
    }

    switch (migrationStatus.status) {
      case 'failed':
        setToast({
          body: t('migration.goodTillMigration.singleStore.error.message', {
            clientId: goodTillClientIdValue,
            storeId: posProStoreIdValue,
          }),
          variant: 'alert',
        });
        break;
      case 'succeeded':
        setToast({
          body: t('migration.goodTillMigration.singleStore.success.message', {
            clientId: goodTillClientIdValue,
            storeId: posProStoreIdValue,
          }),
          variant: 'confirm',
        });
        break;
      default:
        break;
    }
  }, [status]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    const goodTillClientIdValue = goodTillClientIdRef.current?.value;
    const posProStoreIdValue = posProStoreIdRef.current?.valueAsNumber;

    if (!goodTillClientIdValue || !posProStoreIdValue) {
      setToast({ body: t('migration.goodTillMigration.error.message'), variant: 'alert' });
      return;
    }

    const migrations = [
      { goodTillClientId: goodTillClientIdValue, posProStoreId: posProStoreIdValue },
    ];
    subscribe(migrations);
    mutate(migrations);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <CardHeader>
          <SubHeadline as="h4">{t('migration.goodTillMigration.singleStore.title')}</SubHeadline>
        </CardHeader>
        <Body>
          <Input
            ref={goodTillClientIdRef}
            required
            label={t('migration.goodTillMigration.goodTill.clientId.label')}
            name="goodTill-clientId"
            placeholder={t('migration.goodTillMigration.goodTill.clientId.placeholder')}
          />
          <Input
            ref={posProStoreIdRef}
            required
            type="number"
            label={t('migration.goodTillMigration.posPro.storeId.label')}
            name="posPro-storeId"
            placeholder={t('migration.goodTillMigration.posPro.storeId.placeholder')}
          />
        </Body>
        <CardFooter>
          <Button
            loadingLabel={t('migration.goodTillMigration.action.migrate.loadingLabel')}
            isLoading={isInProgress}
            type="submit"
            variant="primary"
          >
            {t('migration.goodTillMigration.action.migrate.label')}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default SingleStore;
