/**
 * Constants
 */

const STATUS = {
  COMPLETE: 'STATUS_COMPLETE',
  PROCESS: 'STATUS_PROCESS',
};

export default STATUS;
