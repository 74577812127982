import React from 'react';
import { Redirect, Route, RouteProps, useParams } from 'react-router-dom';
import { Authenticator, Loader } from '@aws-amplify/ui-react';

import { useAuth } from '../../contexts/Auth';

import LoginHeader from './LoginHeader';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import './aws-styles-override.css';

type Props = Omit<RouteProps, 'children'> & {
  defaultRedirectPath: string;
};

const LoginRoute: React.FC<Props> = ({ defaultRedirectPath, ...rest }) => {
  const { isRequesting } = useAuth();
  const { from = defaultRedirectPath } = useParams<{ from?: string }>();

  if (isRequesting) return <Loader />;

  return (
    <Route {...rest}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        :root { font-size: 14px; }
      `,
        }}
      />
      <Authenticator
        socialProviders={['google']}
        variation="modal"
        components={{
          Header: LoginHeader,
          SignUp: null,
        }}
      >
        {() => <Redirect to={{ pathname: from }} />}
      </Authenticator>
    </Route>
  );
};

export default LoginRoute;
