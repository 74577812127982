import { transparentize } from 'polished';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const TableWrapper = styled.div`
  background: ${({ theme: { palette } }) => palette.white};
  border: 1px solid ${({ theme: { palette } }) => palette.veryLightBlue};
  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
  box-shadow: 0 1px 3px 0 ${({ theme: { palette } }) => transparentize(0.96, palette.black)};
  overflow: hidden;
`;

export const TableHeader = styled.div`
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.veryLightBlue};
  height: 7.4rem;
  display: flex;
  align-items: center;
  padding: 0 3rem;
`;

export const WarningMsg = styled.div`
  padding: 1.5rem 3rem;
`;

export const Wrapper = styled.div`
  padding-right: 3rem;
`;
