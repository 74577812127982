import { createSelector } from 'reselect';

import { LOADING, NONE } from '../../constants/Status';

/**
 * Payment method selectors
 */

/**
 * If last attempt at fetching data resulted in an error, returns the error value
 *
 * @return {Error}
 */
export const getPaymentMethodsError = ({ paymentMethods }) => paymentMethods.error;

/**
 * Get the loading status about the fetched datas
 *
 * @return {string}
 */
export const getPaymentMethodsStatus = ({ paymentMethods }) => paymentMethods.status;

/**
 * If data is being fetched
 *
 * @return {boolean}
 */
export const isPaymentMethodsLoading = createSelector(
  [getPaymentMethodsStatus],
  status => status === LOADING,
);

/**
 * If data is done fetching
 *
 * @return {boolean}
 */
export const isPaymentMethodsDoneLoading = createSelector(
  [getPaymentMethodsStatus],
  status => status !== NONE && status !== LOADING,
);

/**
 * Get all payment methods
 *
 * @return {import('./reducer').PaymentMethod[]} payment methods
 */
export const getPaymentMethods = ({ paymentMethods }) =>
  Object.values(paymentMethods.entities) || [];

/**
 * Get the number of payment methods
 *
 * @return {number} number of payment methods
 */
export const getPaymentMethodsCount = ({ paymentMethods }) => paymentMethods.count;

/**
 * Return true if there is at least one payment method
 *
 * @return {boolean}
 */
export const hasPaymentMethods = ({ paymentMethods }) => paymentMethods.count > 0;

/**
 * Get a specific payment method
 *
 * @return {import('./reducer').PaymentMethod | null}
 */
export const getSelectedPaymentMethod = ({ paymentMethods }) =>
  paymentMethods.entities[paymentMethods.selectedLabel] || null;
