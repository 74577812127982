import { formatNumber, Tag, Theme } from '@tillersystems/stardust';

import { DefinitionList } from '../../../../components';
import i18n from '../../../../i18n';

export const storeDefinitions = [
  {
    name: 'cashedPayed',
    label: i18n.t('order_management.fields.Order.cashed_payed'),
    value: item =>
      formatNumber({
        currency: item.currency,
        locale: 'fr',
        number: item.cashPayed,
      }),
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'cashedPrice',
    label: i18n.t('order_management.fields.Order.cashed_price'),
    value: item =>
      formatNumber({
        currency: item.currency,
        locale: 'fr',
        number: item.cashPrice,
      }),
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'storeName',
    label: i18n.t('order_management.fields.Order.store_name'),
    value: item => item.name,
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'storeID',
    label: i18n.t('order_management.fields.Order.shopID'),
    value: item => item.id,
    Component: DefinitionList.Cells.StringField,
  },
];

export const OrderHeaderDefinitions = [
  {
    name: 'rootUUID',
    label: i18n.t('order_management.fields.Order.rootUUID'),
    value: item => item.rootUUID,
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'notInCurrentTill',
    label: i18n.t('order_management.fields.Order.notInCurrentTill'),
    value: item => !item.inCurrentTill,
    Component: DefinitionList.Cells.BooleanField,
    options: {
      translateTrue: 'values.Boolean.yes',
      translateFalse: 'values.Boolean.no',
    },
  },
  {
    name: 'status',
    label: '',
    value: item =>
      item.status ? <Tag color={Theme.palette.warning.default}>{item.status}</Tag> : '',
    Component: DefinitionList.Cells.StringField,
  },
];
