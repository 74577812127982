import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TextContent = styled.div`
  padding: 0;
`;

const NullContent = styled.span`
  opacity: 0.7;
  font-style: italic;
`;

const TextField = ({ value }) =>
  value === null ? <NullContent>null</NullContent> : <TextContent>{value}</TextContent>;

TextField.propTypes = {
  value: PropTypes.string,
};

TextField.defaultProps = {
  value: null,
};

export default TextField;
