import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Grid, Row } from '@sumup/circuit-ui';

import { Head, TopBar } from '../../components';

import DataIntegrityContextProvider from './context';
import { Container } from './elements';
import ResultCard from './ResultCard';
import SearchForm from './SearchForm';

const DataIntegrity = () => {
  const [t] = useTranslation();
  const formContext = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      storeId: '',
      timePeriod: '',
      from: 'test',
    },
  });

  return (
    <>
      <Head title={t('navigation.data_integrity')} />
      <TopBar title={t('navigation.data_integrity')} />
      <DataIntegrityContextProvider>
        <Container>
          <Grid>
            <Row>
              <Col span="8">
                <FormProvider {...formContext}>
                  <SearchForm />
                </FormProvider>
              </Col>
              <Col span="4">
                <ResultCard />
              </Col>
            </Row>
          </Grid>
        </Container>
      </DataIntegrityContextProvider>
    </>
  );
};

export default DataIntegrity;
