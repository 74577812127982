import React, { Children, cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './elements';
import Toggle from './Toggle';

/**
 * DeliveryToggle
 *
 * This component is in charge of displaying a DeliveryToggle
 * A DeliveryToggle is a component which contain toggles
 *
 * @param {string} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment).
 * @param {string} name // The name of the Toggle button group. In most cases it will be defined by the DeliveryToggle component.
 * @param {string} selectedValue // Defined wich Toggle is checked.
 *
 * @return {jsx}
 */

const { node, string } = PropTypes;

class DeliveryToggle extends PureComponent {
  static Toggle = Toggle;
  /**
   * PropTypes Validation
   */
  static propTypes = {
    children: node,
    name: string,
    selectedValue: string,
  };

  /**
   * Default props
   */
  static defaultProps = {
    children: null,
    name: '',
    selectedValue: '',
  };

  state = {
    selectedValue: null,
  };

  /**
   * Handles mounting in component's lifecycle.
   */
  componentDidMount() {
    const { selectedValue } = this.props;
    /* eslint-disable react/no-did-mount-set-state */
    if (selectedValue !== null) {
      this.setState({ selectedValue });
    }
  }

  /**
   * Function called when a new radio button is selected.
   */
  handleChange = value => {
    this.setState({ selectedValue: value });
  };

  /**
   * Renders the component.
   *
   * @return {jsx}
   */
  render() {
    const { selectedValue } = this.state;

    const { children, name } = this.props;

    const toggles = Children.map(children, toggle =>
      cloneElement(toggle, {
        onChange: this.handleChange,
        selectedValue,
        name,
      }),
    );
    return <Wrapper>{toggles}</Wrapper>;
  }
}

export default DeliveryToggle;
