import { useMutation, useQueryClient } from 'react-query';

import setQueryDataIfCached from '../../../helpers/react-query/setQueryIfCached';
import { IntegrationRequestStatusType } from '../types';

import { setIntegrationRequestStatus, SetIntegrationRequestStatusParamsType } from './services';

const updateCachedIntegrationRequestStatus = (queryClient, requestUUID, status) => () => {
  const requestUpdater = request => ({ ...request, status });
  setQueryDataIfCached(queryClient, ['integrationRequestDetails', requestUUID], requestUpdater);

  setQueryDataIfCached(
    queryClient,
    'integrationRequests',
    (oldData: { data: Array<{ UUID: string }> }) => {
      return {
        data: oldData.data.map(r => (r.UUID === requestUUID ? requestUpdater(r) : r)),
      };
    },
  );
};

const useStatusMutation = requestUUID => {
  const queryClient = useQueryClient();
  const { mutate, ...mutationReturn } = useMutation(
    (params: SetIntegrationRequestStatusParamsType) => {
      return setIntegrationRequestStatus(params);
    },
  );

  const mutateStatusTo = (status: IntegrationRequestStatusType) =>
    mutate(
      { requestUUID, status },
      {
        onSuccess: updateCachedIntegrationRequestStatus(queryClient, requestUUID, status),
      },
    );

  return { ...mutationReturn, mutateStatusTo };
};

export default useStatusMutation;
