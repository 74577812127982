import React from 'react';
import { string } from 'prop-types';

import { getImageUrl } from '../../helpers';

import { Logo, Wrapper } from './elements';

const FormHeader = ({ icon, label }) => (
  <Wrapper>
    <Logo src={getImageUrl(icon)} alt="" />
    {label}
  </Wrapper>
);

FormHeader.propTypes = {
  icon: string.isRequired,
  label: string.isRequired,
};

export default FormHeader;
