import React from 'react';
import { DateTime } from 'luxon';

import { Icon, Tag } from '@tillersystems/stardust';

import Theme from '../../../../components/Theme';
import i18n from '../../../../i18n';

import { AlignCenter, JiraLink, JiraLinkContainer, StyledDate } from './elements';
import Status from './Status';

export const colsDef = [
  {
    title: i18n.t('ops.beta_container.beta_browser.project'),
    name: 'project',
    value: d => d,
    // eslint-disable-next-line react/prop-types
    format: ({ createdAt, project }) => (
      <div>
        <AlignCenter>
          {createdAt && formatExpirationDate(createdAt)} {project}
        </AlignCenter>
        <StyledDate>{createdAt && formatDate(createdAt)}</StyledDate>
      </div>
    ),
    align: 'right',
    sortBy: ({ createdAt, project }) => (createdAt ? createdAt : project),
    isSortable: true,
    isRowHeader: true,
  },
  {
    title: i18n.t('ops.beta_container.beta_browser.pos_branch'),
    name: 'posBranch',
    value: d => d,
    format: ({ posLink, posBranch }) => formatBetaLink(posLink, posBranch),
    align: 'right',
    sortBy: ({ posBranch }) =>
      branchNameToTicket(posBranch) ? branchNameToTicket(posBranch) : posBranch,
    isSortable: true,
  },
  {
    title: i18n.t('ops.beta_container.beta_browser.component_branch'),
    name: 'componentBranch',
    value: d => d,
    format: ({ componentLink, componentBranch }) => formatBetaLink(componentLink, componentBranch),
    align: 'right',
    sortBy: ({ componentBranch }) =>
      branchNameToTicket(componentBranch) ? branchNameToTicket(componentBranch) : componentBranch,
    isSortable: true,
  },
  {
    title: i18n.t('ops.beta_container.beta_browser.beta_type'),
    name: 'betaType',
    value: d => d.betaType,
    format: v => formatBetaType(v),
    align: 'right',
    isSortable: true,
  },
];

const formatBetaType = betaType => (
  <Tag
    color={betaType === 'closed' ? Theme.palette.failure.default : Theme.palette.success.default}
  >
    {betaType}
  </Tag>
);

/**
 * formatExpirationDate
 *
 * Get created_at date and convert her into an expiration date and check
 * if this date is almost expirated (5 days before the expiration) if so it return an alert Icon
 *
 * @param {string} date - Date in iso format you want to check
 *
 * @return {node}  An icon to alert user the the date going to expire soon
 */
const formatExpirationDate = date => {
  const today = DateTime.local();
  const expirationDate = DateTime.fromISO(date).plus({ days: 25 });
  const isExpirated = today.startOf('day') > expirationDate.startOf('day');
  return isExpirated ? (
    <Icon
      name="exclamation-mark"
      color={Theme.palette.warning.default}
      title={i18n.t('ops.beta_container.beta_browser.expire')}
    />
  ) : null;
};

/**
 * formatBetaLink
 *
 * Check if the branch have a jira link associated with. If so it return the link to the jira task
 * in other way it return the branch name.
 *
 * @param {string} betaLink - The jira link to the task related to the branch name
 * @param {string} branchName - The branch name of the issue
 *
 * @return {node}  A jira link to the task or a branch name
 */
const formatBetaLink = (betaLink, branchName) =>
  branchNameToTicket(betaLink) ? (
    <JiraLinkContainer>
      <Status jiraTicket={branchNameToTicket(betaLink)} />
      <JiraLink
        onClick={e => e.stopPropagation()}
        target="_blank"
        rel="noopener noreferrer"
        href={betaLink}
      >
        {branchNameToTicket(betaLink)}
      </JiraLink>
    </JiraLinkContainer>
  ) : (
    branchName
  );

/**
 * branchNameToTicket
 *
 * Converte a branch name into a ticket jira name
 *
 * @param {string} branchName - The branch name you want to convert into a ticket name
 *
 * @return {string}  A jira ticket name
 */
export const branchNameToTicket = (branchName = '') => {
  const jiraRegEx = /([A-Z]{2})-(\d)+/;
  const [jiraTicket] = branchName.match(jiraRegEx) || [];

  return jiraTicket;
};

export const formatDate = date => DateTime.fromISO(date).setLocale(i18n.language).toFormat('f');
