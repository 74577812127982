import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@sumup/circuit-ui';

import { useDataIntegrityContext } from '../context';
import { InputWrapper } from '../elements';
import { InputName } from '../type';

const FromInput = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext();
  const { getErrorMessage } = useDataIntegrityContext();

  const id = InputName.from;
  const testId = 'input-from';
  const error = getErrorMessage(id, errors[id]);
  const timePeriod = watch(InputName.timePeriod);

  const yearOptions = useMemo(() => {
    const years = [];
    const startYear = 2014;
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= startYear; year--) {
      years.push({ value: year, label: year });
    }
    return years;
  }, []);

  switch (timePeriod) {
    case 'daily':
      return (
        <InputWrapper>
          <Input
            data-testid={testId}
            {...register(id, { required: true })}
            label={t(`data_integrity.search_form.${id}.label`)}
            type="date"
            invalid={!!error}
            validationHint={error}
            noMargin
          />
        </InputWrapper>
      );

    case 'monthly':
      return (
        <InputWrapper>
          <Input
            data-testid={testId}
            {...register(id, { required: true })}
            label={t(`data_integrity.search_form.${id}.label`)}
            type="month"
            invalid={!!error}
            validationHint={error}
            noMargin
          />
        </InputWrapper>
      );

    case 'yearly':
      return (
        <InputWrapper>
          <Select
            data-testid={testId}
            {...register(id, { required: true })}
            label={t(`data_integrity.search_form.${id}.label`)}
            options={yearOptions}
            invalid={!!error}
            validationHint={error}
            noMargin
          />
        </InputWrapper>
      );

    default:
      return (
        <InputWrapper>
          <Input
            data-testid={testId}
            {...register(id, { required: true })}
            label={t(`data_integrity.search_form.${id}.label`)}
            disabled
            noMargin
          />
        </InputWrapper>
      );
  }
};

export default FromInput;
