import styled from 'styled-components';

import { PageContainer } from '../../components';

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const Container = styled(PageContainer)`
  display: flex;
`;
