import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '@tillersystems/stardust';

const TextField = ({ name, error, onChange, onBlur, options, value }) => {
  const { fluid = true, ...rest } = options;

  return (
    <TextInput
      id={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={!!error}
      fluid={fluid}
      {...rest}
    />
  );
};

/** Prop types. */
TextField.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.object,
  value: PropTypes.string,
};

/** Default prop types. */
TextField.defaultProps = {
  options: {},
  onBlur: null,
  value: '',
  error: null,
};

export default TextField;
