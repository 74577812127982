import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ToggleButton } from '@tillersystems/stardust';

import { Card } from '../../../components';
import { CardTitle, SectionBody } from '../elements';

import { Toggle, ToggleLabel } from './elements';

const OrderManagement = ({ orderManagement: { isDetailedView }, setIsDetailedView }) => {
  const [t] = useTranslation();

  const handleToggle = useCallback(() => {
    setIsDetailedView(!isDetailedView);
  }, [isDetailedView]);

  return (
    <Card>
      <Card.Header>
        <CardTitle>{t('settings.section.order_management.title')}</CardTitle>
      </Card.Header>
      <Card.Body padding="2rem 3rem">
        <SectionBody>
          <Toggle>
            <ToggleLabel onClick={handleToggle}>{t('order_management.detailed_view')}</ToggleLabel>
            <ToggleButton isChecked={isDetailedView} onToggle={handleToggle} />
          </Toggle>
        </SectionBody>
      </Card.Body>
    </Card>
  );
};

OrderManagement.propTypes = {
  orderManagement: PropTypes.shape({
    isDetailedView: PropTypes.bool.isRequired,
  }).isRequired,
  setIsDetailedView: PropTypes.func.isRequired,
};

export default OrderManagement;
