import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background ${({ theme: { palette } }) => palette.veryLightGrey};
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
`;

export const Body = styled.div`
  padding: 1rem;
`;
