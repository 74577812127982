/**
 * Defines the theming of fonts.
 */

export const fonts = {
  size: {
    tiny: '0.3rem',
    small: '0.7rem',
    medium: '1.2rem',
    big: '3.2rem',
    huge: '6.4rem',

    tinyInt: 0.3,
    smallInt: 0.7,
    mediumInt: 1.2,
    bigInt: 3.2,
    hugeInt: 6.4,
  },

  spacing: {
    small: '0.5px',
    medium: '1px',
    big: '1.5px',
  },

  weight: {
    thin: 200,
    normal: 400,
    thick: 700,
  },

  family: '"Open Sans", Helvetica, Arial, sans-serif',
};
