import { Auth } from '@aws-amplify/auth';

interface Headers {
  [header: string]: string;
}

export enum AvailableAPIs {
  FeatureAPI = 'FeatureAPI',
  Internal = 'InternalAPI',
  Migration = 'MigrationAPI',
}

const APIs: Record<AvailableAPIs, string> = {
  [AvailableAPIs.FeatureAPI]: process.env.REACT_APP_FEATURE_API_URL,
  [AvailableAPIs.Internal]: process.env.REACT_APP_INTERNAL_API_URL,
  [AvailableAPIs.Migration]: process.env.REACT_APP_MIGRATION_API_URL,
};

function declareAPI(APIName: string, APIEndpoint: string) {
  return {
    name: APIName,
    endpoint: APIEndpoint,
    region: process.env.REACT_APP_AMPLIFY_REGION,
    custom_header: async (): Promise<Headers> => {
      // @see https://docs.amplify.aws/lib/restapi/authz/q/platform/js#note-related-to-use-access-token-or-id-token
      return {
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      };
    },
  };
}

const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_AMPLIFY_OAUTH_DOMAIN,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_AMPLIFY_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AMPLIFY_OAUTH_REDIRECT_SIGN_OUT,
    responseType: 'token',
  },
  API: {
    endpoints: Object.entries(APIs).map(([apiName, apiEndpoint]) =>
      declareAPI(apiName, apiEndpoint),
    ),
  },
};

export default amplifyConfig;
