import { create } from 'apisauce';

import { ApiError } from '../../helpers/errors';

/**
 * @typedef {Object} Category
 * @property {number} id
 * @property {string} label
 * @property {string} translation_key
 */

/**
 * Create the global configuration API
 */
const globalConfigurationApi = create({
  baseURL: process.env.REACT_APP_TILLER_GLOBAL_CONFIGURATION_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_TILLER_GLOBAL_CONFIGURATION_API_TOKEN,
  },
});

/**
 * Retrieve payment categories from the api
 *
 * @return {Promise<Category[]>} an array of payment category
 * @throws {ApiError} if an error occur with api call
 */
export const retrieveCategories = async () => {
  const { ok, problem, data } = await globalConfigurationApi.get('/categories');

  if (!ok) {
    throw new ApiError(problem);
  }

  return data.response;
};
