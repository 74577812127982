import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Head, PageContainer, TopBar } from '../../components';

import AppMarket from './AppMarket';
import { Menu, MenuItem } from './elements';

/**
 * Reports
 *
 * This Container is in charge of display
 * a the reports page
 *
 * @return {jsx}
 */

const Reports = ({ match: { url }, location: { pathname } }) => {
  const [t] = useTranslation();
  const allowRedirect = url === pathname;

  return (
    <>
      <Head title={t('navigation.reports')} />
      <TopBar title={t('navigation.reports')} />
      <Menu>
        <MenuItem>
          <NavLink
            exact
            to={`${url}/app-market`}
            className={location.pathname === `${url}/app-market` ? 'active' : null}
          >
            AppMarket
          </NavLink>
        </MenuItem>
      </Menu>
      <PageContainer>
        {allowRedirect && <Redirect to={`${url}/app-market`} />}
        <Switch>
          <Route exact path={`${url}/app-market`}>
            <AppMarket />
          </Route>
        </Switch>
      </PageContainer>
    </>
  );
};

/**
 * PropTypes Validation
 *
 * @param {object} location
 * @param {object} match
 */
Reports.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(Reports);
