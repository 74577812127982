import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Loader, Message, Modal, Table } from '@tillersystems/stardust';

import Theme from '../../../../components/Theme';
import { getBetas } from '../../service';

import { branchNameToTicket, colsDef, formatDate } from './config';
import {
  BetaDetails,
  BetaDetailsRight,
  Body,
  Bold,
  CenterItem,
  Footer,
  Header,
  JiraLinkContainer,
  JiraModalLink,
  TableBody,
  TableContainer,
  TableHeader,
} from './elements';
import Status from './Status';

const BetaBrowser = () => {
  const [t] = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBetasLoading, setIsBetasLoading] = useState(false);
  const [betas, setBetas] = useState([]);
  const [modalDatas, setModalDatas] = useState({});

  useEffect(() => {
    loadBetas();
  }, []);

  const loadBetas = () => {
    setIsBetasLoading(true);
    getBetas().then(betas => {
      setBetas([...addLinksToBetas(betas)]);
      setIsBetasLoading(false);
    });
  };

  /**
   * addLinksToBetas
   *
   * Add a link to jira ticket to the beta list
   *
   * @param {array} betas - the beta list
   *
   * @return {array}  the beta list with the jira ticket link
   */
  const addLinksToBetas = betas => {
    const TILLER_JIRA_BASE_URL = 'https://tillersystems.atlassian.net/browse/';
    return betas.map(row => ({
      ...row,
      posLink: `${TILLER_JIRA_BASE_URL}${branchNameToTicket(row.posBranch)}`,
      componentLink: `${TILLER_JIRA_BASE_URL}${branchNameToTicket(row.componentBranch)}`,
    }));
  };

  const rowsDef = {
    onClick: item => {
      setModalDatas(item);
      setIsModalOpen(true);
    },
  };

  return (
    <>
      <TableContainer>
        <TableHeader>
          {t('ops.beta_container.beta_browser.title')}
          <Button disabled={isBetasLoading} appearance="primary" onClick={loadBetas}>
            {t('ops.pingui.reload')}
          </Button>
        </TableHeader>
        <TableBody>
          {isBetasLoading ? (
            <CenterItem>
              <Loader width="6rem" height="6rem" />
            </CenterItem>
          ) : betas.length === 0 ? (
            <CenterItem>
              <Message description={t('ops.pingui.no_envs')} type="warning" />
            </CenterItem>
          ) : (
            <Table isScrollable data={betas} colsDef={colsDef} rowsDef={rowsDef} isHoverable />
          )}
        </TableBody>
      </TableContainer>
      <Modal
        width="60rem"
        height="50rem"
        active={isModalOpen}
        onOverlayClick={() => setIsModalOpen(false)}
      >
        <Header>{modalDatas.project}</Header>
        <Body>
          <BetaDetails>
            <div>
              {t('ops.beta_container.beta_browser.uuid')}
              <Bold>{modalDatas.uuid}</Bold>
            </div>
            {modalDatas.createdAt && (
              <div>
                {t('ops.beta_container.beta_browser.created_at')}
                <Bold>{formatDate(modalDatas.createdAt)}</Bold>
              </div>
            )}
            <div>
              {t('ops.beta_container.beta_browser.pos_branch')}
              <Bold>{modalDatas.posBranch}</Bold>
              {branchNameToTicket(modalDatas.posBranch) && (
                <JiraLinkContainer>
                  {t('ops.beta_container.beta_browser.ticket')}
                  <JiraModalLink
                    href={modalDatas.posLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {branchNameToTicket(modalDatas.posBranch)}
                  </JiraModalLink>
                  <Status jiraTicket={branchNameToTicket(modalDatas.posBranch)} />
                </JiraLinkContainer>
              )}
            </div>
            <div>
              {t('ops.beta_container.beta_browser.component_branch')}
              <Bold>{modalDatas.componentBranch}</Bold>
              {branchNameToTicket(modalDatas.componentBranch) && (
                <JiraLinkContainer>
                  {t('ops.beta_container.beta_browser.ticket')}
                  <JiraModalLink
                    href={modalDatas.componentLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {branchNameToTicket(modalDatas.componentBranch)}
                  </JiraModalLink>
                  <Status jiraTicket={branchNameToTicket(modalDatas.componentBranch)} />
                </JiraLinkContainer>
              )}
            </div>
            {modalDatas.environment && (
              <div>
                {t('ops.beta_container.beta_browser.environment')}
                <Bold>{modalDatas.environment}</Bold>
              </div>
            )}
            <div>
              <Button
                icon={<Icon name="download" color={Theme.palette.white} />}
                appearance="primary"
                onClick={() => window.location.assign(modalDatas.dsymUrl)}
              >
                {t('ops.beta_container.beta_browser.dsym')}
              </Button>
            </div>
          </BetaDetails>
          <div>
            <img src={modalDatas.qrUrl} alt="" />
            <BetaDetailsRight>
              <div>
                {t('ops.beta_container.beta_browser.version')}
                <Bold>{modalDatas.version}</Bold>
              </div>
              <div>
                {t('ops.beta_container.beta_browser.build_number')}
                <Bold>{modalDatas.buildNumber}</Bold>
              </div>
              <div>
                {t('ops.beta_container.beta_browser.beta_type')}
                <Bold>{modalDatas.betaType}</Bold>
              </div>
            </BetaDetailsRight>
          </div>
        </Body>
        <Footer>
          <Button appearance="secondary" onClick={() => setIsModalOpen(false)}>
            {t('ops.pingui.close')}
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export default BetaBrowser;
