export const getDevices = (events, newEvents) => {
  const allEvents = [...(events || []), ...(newEvents || [])];

  const devices = allEvents.reduce((devices, event) => {
    const deviceID = event.deviceID;
    const id = (event.source && event.source.device && event.source.device.id) || deviceID;
    const identifier =
      (event.source && event.source.device && event.source.device.identifier) ||
      `DEVICE ${deviceID}`;
    devices[deviceID] = { id, identifier };

    return devices;
  }, {});

  return Object.values(devices);
};

/**
 * mapTypeToUUIDTypes
 *
 * This function convert the event type into the UUID types associated with.
 * This mapping is based on the product specification here: https://tillersystems.atlassian.net/browse/OO-1750
 *
 * @param {string} type - the event type
 *
 * @return {Array} UUIDTypes' array
 */

export const mapTypeToUUIDTypes = type => {
  const UUIDTypes = {
    OrderLineWasAdded: ['orderLineUUID'],
    OrderLineWasRemoved: ['orderLineUUID'],
    OptionWasAdded: ['optionUUID', 'orderLineUUID'],
    OptionWasRemoved: ['optionUUID', 'orderLineUUID'],
    ProductQuantityWasUpdated: ['orderLineUUID'],
    DiscountWasAddedToProduct: ['orderLineUUID'],
    DiscountWasRemovedFromProduct: ['orderLineUUID'],
    OrderLineParentWasChanged: ['orderLineUUID', 'price', 'mealUUID'],
    MealWasAddedToOrder: ['mealUUID', 'orderLineUUID'],
    ProductWasAddedToMeal: ['mealUUID'],
    MealWasDissociated: ['mealUUID'],
    ProductWasRemovedFromMeal: ['mealUUID', 'productLineUUID'],
    PaymentTypeWasUpdated: ['paymentUUID'],
    PaymentWasCanceled: ['paymentUUID'],
    PaymentWasAddedToOrder: ['paymentUUID', 'orderLineUUID'],
    DiscountWasAddedToOrder: ['discountUUID', 'orderLineUUID'],
    DiscountWasRemovedFromOrder: ['discountUUID'],
    OrderLineWasTransferredToOrder: ['toUUID', 'orderlineData'],
  };
  return UUIDTypes[type] || [];
};

export const addingEvents = [
  'OrderLineWasAdded',
  'PaymentWasAddedToOrder',
  'OptionWasAdded',
  'DiscountWasAddedToOrder',
  'MealWasAddedToOrder',
];
