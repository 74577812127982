import { create } from 'apisauce';

import { ApiError } from '../../helpers/errors';

/**
 * orderApi
 *
 * Create a tiller order API
 *
 * @return {void}
 */
const orderApi = create({
  baseURL: process.env.REACT_APP_TILLER_ORDER_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_TILLER_ORDER_API_TOKEN,
  },
});

/**
 * storeApi
 *
 * Create a tiller store API
 *
 * @return {void}
 */
const storeApi = create({
  baseURL: process.env.REACT_APP_TILLER_STORE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_TILLER_STORE_API_TOKEN,
  },
});

/**
 * getStore
 *
 * Check if the storeId is exist or not
 *
 * @param {string} store id we want to trigger
 *
 * @return return nothing in case the store exist, throw an error if not
 */
export const getStore = async storeId => {
  const results = await storeApi.get(`/stores/${storeId}`);
  const { ok, status, problem } = results;
  if (!ok) {
    if (status === 404) throw new Error('store_not_found');
    throw new ApiError(problem);
  }
};

/**
 * getOrders
 *
 * Get an array of the differents orders
 *
 * @param {string} store id we want to trigger orders from
 *
 * @return {array} array of order
 */
export const getOrders = async (storeId, startDate, endDate) => {
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  const results = await orderApi.get(
    `/stores/${storeId}/orders?status=IN_PROGRESS&from=${encodedStartDate}&to=${encodedEndDate}`,
  );
  const {
    data: { orders },
    ok,
    problem,
  } = results;
  if (!ok) {
    throw new ApiError(problem);
  }
  return formateOrders(filterOrdersV3(orders));
};
/**
 * filterOrdersV3
 *
 * Filter order which are not order V3
 *
 * @param {array} orders - orders needs to be filtered
 *
 * @return {array} array of filtered orders
 */
const filterOrdersV3 = orders =>
  orders.filter(({ source, external_id }) => source !== 'Tiller iPAD' && external_id);

/**
 * formateOrders
 *
 * Get an array of the differents orders formated to match product recommendations
 *
 * @param {array} orders - orders needs to be formated
 *
 * @return {array} array of formated orders
 */
const formateOrders = orders => {
  return orders.map(
    ({
      id: order_id,
      external_id,
      table_id,
      waiter_id,
      number,
      name,
      nb_customers,
      status,
      open_date,
      cached_price,
      cached_payed,
    }) => ({
      order_id,
      uuid: external_id,
      table_id,
      waiter_id,
      number,
      name,
      nb_customers,
      status,
      open_date,
      cached_price,
      cached_payed,
    }),
  );
};
