import styled from 'styled-components';

import { Button } from '@tillersystems/stardust';

export const QuickBarButton = styled(Button)`
  color: ${({ isSelected, theme: { palette } }) =>
    isSelected ? palette.primary.default : palette.spaceGrey};
  font-size: ${({ theme: { fonts } }) => fonts.size.medium};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  padding: 0 0 1.6rem 0;

  white-space: nowrap;
`;

export const Wrapper = styled.div`
  background: ${({ theme: { palette } }) => palette.mysticGrey};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ theme: { dimensions } }) => dimensions.medium};
  padding: 1.6rem;
`;
