/**
 * Shop Orders actions
 */

import * as t from './actionType';

/**
 * Shop Orders Requested
 * dispatch this action when
 * you want to start a request to retrieve all orders by shop.
 */
export const shopOrdersRequested = payload => ({
  type: t.SHOP_ORDERS_REQUESTED,
  payload,
});

/**
 * Shop Orders Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {object}
 */
export const shopOrdersSucceeded = data => ({
  type: t.SHOP_ORDERS_SUCCEEDED,
  payload: data,
});

/**
 * Shop Orders Failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {object} error
 *
 * @return {object}
 */
export const shopOrdersFailed = error => ({
  type: t.SHOP_ORDERS_FAILED,
  payload: error,
});
