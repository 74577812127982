import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Loader, Theme } from '@tillersystems/stardust';

import { ConvertedRawTicket, Order, RawTicket } from '../../../../modules/OrderOMS/types';
import { JSONViewer } from '../EventsCard/elements';
import OrderLines from '../OrderLines';
import Payments from '../Payments';

import { OrderHeaderDefinitions, storeDefinitions } from './definitions';
import { Body, CardTitle, Content, Header, LoaderContainer, Wrapper } from './elements';

type Props = {
  order: Order;
  orderUuid: string;
  rawTicket?: RawTicket;
  convertedRawTicket?: ConvertedRawTicket;
  isRawTicketsLoading: boolean;
};

const OrderCard: FC<Props> = ({
  order,
  orderUuid,
  rawTicket,
  isRawTicketsLoading,
  convertedRawTicket,
}) => {
  const [t] = useTranslation();

  const [isOrderDetailOpen, setIsOrderDetailOpen] = useState(false);

  const orderDetails = {
    inCurrentTill: order.isCurrentTill,
    orderUuid,
    status: rawTicket?.order?.status,
  };

  const store = {
    name: rawTicket?.store?.store_name,
    id: order?.storeId,
    cashPayed: convertedRawTicket?.summary?.total_paid?.value,
    cashPrice: convertedRawTicket?.summary?.total_tax_included?.value,
    currency: rawTicket?.order?.currency?.code,
  };

  const renderLoading = () => (
    <LoaderContainer>
      <Loader width="3rem" height="3rem" />
    </LoaderContainer>
  );

  const renderHeader = () => (
    <>
      <div>
        <CardTitle>
          {t('order_management.order', {
            order: rawTicket?.order?.name,
          })}
        </CardTitle>
      </div>
      <Content data={orderDetails} definitions={OrderHeaderDefinitions} />
      <Icon
        cursor="pointer"
        name={isOrderDetailOpen ? 'chevron-top' : 'chevron-down'}
        color={Theme.palette.darkBlue}
        title={
          isOrderDetailOpen
            ? t('order_management.actions.show_less')
            : t('order_management.actions.show_more')
        }
        size="25"
        onClick={() => setIsOrderDetailOpen(!isOrderDetailOpen)}
      />
    </>
  );

  const renderOrderDetail = () => (
    <>
      <Body>
        <Content data={store} definitions={storeDefinitions} />
      </Body>
      {isRawTicketsLoading ? (
        renderLoading()
      ) : rawTicket ? (
        <>
          <OrderLines
            orderLines={convertedRawTicket.orderLines}
            currency={rawTicket.order.currency.code}
          />
          <Payments
            payments={convertedRawTicket.payments}
            currency={rawTicket.order.currency.code}
          />
          <Body>
            <JSONViewer name="rawTicket" src={rawTicket} collapsed={true} />
          </Body>
        </>
      ) : (
        <Body>No ticket was printed for this order</Body>
      )}
    </>
  );

  return (
    <Wrapper>
      <Header>{renderHeader()}</Header>
      {isOrderDetailOpen ? renderOrderDetail() : null}
    </Wrapper>
  );
};

export default OrderCard;
