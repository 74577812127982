import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Syntax highlighting: https://highlightjs.org/
const hljs = window.hljs;

/**
 * Code Block
 *
 * This component is in charge of displaying
 * a block of code
 *
 * @param {string} language // code language.
 *
 * @return {jsx}
 */

class CodeBlock extends PureComponent {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  setRef(el) {
    this.codeEl = el;
  }

  highlightCode() {
    hljs.highlightBlock(this.codeEl);
  }

  render() {
    const { language, value } = this.props;
    return (
      <pre>
        <code ref={this.setRef} className={`language-${language}`}>
          {value}
        </code>
      </pre>
    );
  }
}

CodeBlock.defaultProps = {
  language: '',
};

CodeBlock.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string,
};

export default CodeBlock;
