const config = {
  projects: [
    {
      id: 0,
      title: 'Pos v2',
      slug: 'pos-v2',
      githubName: 'TillerPOS',
    },
    {
      id: 1,
      title: 'Pos v3',
      slug: 'pos-v3',
      githubName: 'tiller-pos',
    },
    {
      id: 3,
      title: 'Back',
      slug: 'back',
      githubName: 'tiller-back',
    },
    {
      id: 4,
      title: 'Sonar',
      slug: 'sonar',
      githubName: 'tiller-sonar',
    },
  ],

  get(project) {
    const index = this.projects.findIndex(({ slug }) => slug === project);

    return this.projects[index].githubName;
  },
};

export default config;
