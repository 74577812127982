import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Navigation } from '@tillersystems/stardust';

import { PageContainer } from '../../components';

export const NavBar = styled(Navigation)`
  padding: 0 3rem;
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.lightGrey};
`;

export const Container = styled(PageContainer)`
  display: flex;
  padding: 2rem 3rem 0 3rem;
  height: calc(100vh - 11.3rem);
  flex-direction: column;
`;

export const NavLink = styled(Link)<{ isActive: boolean }>``;
