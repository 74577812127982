/* eslint-disable no-underscore-dangle */
import { parseDate } from '../../helpers/date';

const DEFAULT_WAITER = {
  id: null,
  name: 'UNKNOWN',
};

const DEFAULT_DEVICE = {
  id: null,
  identifier: 'UNKNOWN',
};

export default class OrderEvent {
  constructor(data) {
    this.shopID = data.shopID; // ID of store
    this.deviceID = data.deviceID; // ID of device
    this.deviceUUID = data.deviceUUID; // UUID of device
    this.uuid = data.uuid; // UUID of event
    this.rootUUID = data.rootUUID; // UUID of root ticket event
    this.playhead = data.playhead; // Incremental Number

    this.type = data.type; // Type of event, in a enumerable list

    this.recordedOn = parseDate(data.recordedOn);
    this.happenedOn = parseDate(data.happenedOn);

    this.payload = this.parsePayload(data.payload);
    this.source = this.parseSource(data.source);
  }

  parsePayload(raw = '{}') {
    try {
      const payload = JSON.parse(raw);

      if (payload.date) {
        payload.date = parseDate(payload.date);
      }

      return payload;
    } catch (err) {
      this._parseError = err;
    }
    return {};
  }

  parseSource(raw = '{}') {
    try {
      const { app_version, version, is_training, device, waiter, ...rest } = JSON.parse(raw);

      return {
        appVersion: app_version,
        version: version,
        isTraining: is_training,
        device: device || DEFAULT_DEVICE,
        waiter: waiter || DEFAULT_WAITER,
        ...rest, // In case of additional data
      };
    } catch (err) {
      this._parseError = err;
    }
    return {};
  }

  get parseError() {
    return this._parseError;
  }
}
