import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { Period } from '../../../modules/Period';

import { QuickBarButton, Wrapper } from './elements';

/**
 * This component defines the quick period selection bar.
 *
 * @param {Period} period - The currently selected period.
 * @param {Function} onPeriodChange - The callback to notify that the period changed.
 *
 * @returns {jsx}
 */
const QuickBar = ({ period, onPeriodChange }) => {
  const [t] = useTranslation();

  return (
    <Wrapper>
      <QuickBarButton
        isSelected={period.isToday()}
        onClick={() => onPeriodChange(Period.today().interval)}
      >
        {capitalize(t('period.today'))}
      </QuickBarButton>
      <QuickBarButton
        isSelected={period.isYesterday()}
        onClick={() => onPeriodChange(Period.yesterday().interval)}
      >
        {capitalize(t('period.yesterday'))}
      </QuickBarButton>
      <QuickBarButton
        isSelected={period.isThisWeek()}
        onClick={() => onPeriodChange(Period.thisWeek().interval)}
      >
        {capitalize(t('period.this_week'))}
      </QuickBarButton>
      <QuickBarButton
        isSelected={period.isLastWeek()}
        onClick={() => onPeriodChange(Period.lastWeek().interval)}
      >
        {capitalize(t('period.last_week'))}
      </QuickBarButton>
      <QuickBarButton
        isSelected={period.isThisMonth()}
        onClick={() => onPeriodChange(Period.thisMonth().interval)}
      >
        {capitalize(t('period.this_month'))}
      </QuickBarButton>
      <QuickBarButton
        isSelected={period.isLastMonth()}
        onClick={() => onPeriodChange(Period.lastMonth().interval)}
      >
        {capitalize(t('period.last_month'))}
      </QuickBarButton>
    </Wrapper>
  );
};

const { instanceOf, func } = PropTypes;

QuickBar.propTypes = {
  period: instanceOf(Period).isRequired,
  onPeriodChange: func,
};

QuickBar.defaultProps = {
  onPeriodChange: () => {
    // handle onPeriodChange here
  },
};

export default QuickBar;
