import OrderEventCommentReasons from '../../../../constants/OrderEventCommentReasons';
import i18n from '../../../../i18n';
import DefinitionForm from '../../../DefinitionForm';

import OrderLineWasAddedByAdmin from './OrderLineWasAddedByAdmin';
import OrderWasCanceledByAdmin from './OrderWasCanceledByAdmin';
import OrderWasClosedByAdmin from './OrderWasClosedByAdmin';
import PaymentWasAddedByAdmin from './PaymentWasAddedByAdmin';

const AdminEventTypesFields = {
  OrderLineWasAddedByAdmin,
  PaymentWasAddedByAdmin,
  OrderWasClosedByAdmin,
  OrderWasCanceledByAdmin,
};

const AdminEventTypes = Object.keys(AdminEventTypesFields).map(value => ({ label: value, value }));

export function getEventDefinition(type, options) {
  const eventTypeField = {
    Field: DefinitionForm.Fields.Select,
    fieldPath: 'type',
    label: i18n.t('order_management.fields.type'),
    fieldOptions: {
      validate: value => (!value ? i18n.t('form.errors.required') : false),
    },
    options: {
      placeholder: i18n.t('order_management.fields.type_placeholder'),
      options: AdminEventTypes,
    },
  };

  if (!type || !AdminEventTypesFields[type]) {
    return [eventTypeField];
  }

  return [eventTypeField, ...AdminEventTypesFields[type](options)];
}

export function getCommentOptions() {
  return OrderEventCommentReasons.map(reason => ({ value: reason, label: reason }));
}
