import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import useWebSocket from '../../../helpers/hooks/useWebSocket';
import { migrateGoodTillBatch } from '../services';

import { Migration, MigrationRequest, MigrationResponse, MigrationStatus } from './types';
import { setStatus } from './utils';

interface Options {
  onSuccess?: (
    data: unknown,
    variables: Omit<Migration, 'status'>[],
    context: unknown,
  ) => void | Promise<unknown>;
  onError?: (
    error: unknown,
    variables: Omit<Migration, 'status'>[],
    context: unknown,
  ) => void | Promise<unknown>;
}

export function useGoodTillMigration(options?: Options) {
  const [migrationsStatus, setMigrationsStatus] = useState<MigrationStatus[]>([]);

  const { mutate, isLoading } = useMutation(migrateGoodTillBatch, {
    onSuccess: options?.onSuccess,
    onError: options?.onError,
    onMutate: migrations => {
      setMigrationsStatus(migrations.map(migration => ({ ...migration, status: 'pending' })));
    },
  });

  const { lastJsonMessage, sendJsonMessage } = useWebSocket<MigrationRequest, MigrationResponse>({
    webSocketUrl: `${process.env.REACT_APP_AWS_WEBSOCKET_URL}/good-till-migration`,
    filter: (_): _ is MigrationResponse => true,
  });

  const subscribe = useCallback((migrations: Migration[]) => {
    setMigrationsStatus(migrations.map(migration => ({ ...migration, status: 'idle' })));
    sendJsonMessage({
      event: 'GOOD_TILL_MIGRATION',
      action: 'SUBSCRIBE',
      payload: migrations,
    });
  }, []);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    setMigrationsStatus(
      migrationsStatus.map(migrationStatus =>
        migrationStatus.posProStoreId === lastJsonMessage.payload.posProStoreId
          ? { ...migrationStatus, status: setStatus(lastJsonMessage.payload.status) }
          : migrationStatus,
      ),
    );
  }, [lastJsonMessage]);

  const clear = () => setMigrationsStatus([]);
  const isInProgress = migrationsStatus.some(({ status }) => status === 'pending');

  return { status: migrationsStatus, isLoading, isInProgress, mutate, subscribe, clear };
}
