import styled from 'styled-components';

import { Card } from '../../../components';

export const CardTitle = styled.div`
  text-align: left;
  width: 100%;
`;

export const Header = styled.div`
  margin-bottom: 1.5rem;

  text-align: center;
`;

export const Title = styled.div`
  font-size: ${({ theme: { fonts } }) => `${fonts.size.mediumInt + 0.2}rem`};

  color: ${({ theme: { palette } }) => palette.darkBlue};
`;

export const Body = styled.div`
  ${Card} {
    margin-bottom: 1.5rem;
  }
`;

export const ErrorMessage = styled.div`
  text-align: center;

  color: ${({ theme: { palette } }) => palette.failure.default};
`;

export const Picture = styled.img`
  width: 6rem;
  height: 6rem;

  margin-right: ${({ theme: { dimensions } }) => dimensions.small};

  border-radius: ${({ theme: { dimensions } }) => dimensions.radius};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
