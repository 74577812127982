import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Form, Loader, Select, TextInput } from '@tillersystems/stardust';

import 'styled-components/macro'; // needed to use the css props in this file

import Theme from '../../../../components/Theme';
import { getBranchesName, getCreatedEnvironments, postToProd } from '../../service';

import { BotMarginMessage, Link, TopMarginMessage } from './elements';
import {
  apiEndpoints,
  appVersionRegEx,
  backEndpoints,
  buildRegEx,
  isEmpty,
  kongEndpoints,
} from './helpers';

/**
 * BetaContainer
 *
 * This components is in charge of display
 * the beta container
 *
 * @return {React.ReactNode}
 */

const BetaLauncher = () => {
  const [t] = useTranslation();

  const [appVersion, setAppVersion] = useState('');
  const [buildNumber, setBuildNumber] = useState('');
  const [tillerPosBranch, setTillerPosBranch] = useState(['master']);
  const [tillerComponentBranch, setTillerComponentBranch] = useState(['master']);
  const [tillerKmmBranch, setTillerKmmBranch] = useState(['master']);
  const [targetEnvironment, setTargetEnvironment] = useState(['staging']);
  const [betaType, setBetaType] = useState(['closed']);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isValidAppVersion, setIsValidAppVersion] = useState(true);
  const [isValidBuildNumber, setIsValidBuildNumber] = useState(true);
  const [tillerPos, setTillerPos] = useState([]);
  const [tillerComponents, setTillerComponents] = useState([]);
  const [tillerKmm, setTillerKmm] = useState([]);
  const [environments, setEnvironments] = useState(['staging', 'production']);

  const [tillerPosBranchValue] = tillerPosBranch;
  const [tillerComponentBranchValue] = tillerComponentBranch;
  const [tillerKmmBranchValue] = tillerKmmBranch;
  const [targetEnvironmentValue] = targetEnvironment;
  const [betaTypeValue] = betaType;

  useEffect(() => {
    getCreatedEnvironments().then(stacks =>
      stacks.map(stack => setEnvironments([...environments, stack.id])),
    );
    getBranchesName('tiller-pos').then(branchesNames =>
      setTillerPos([...tillerPos, ...branchesNames]),
    );
    getBranchesName('tillerComponents').then(branchesNames =>
      setTillerComponents([...tillerPos, ...branchesNames]),
    );
    getBranchesName('kmm-components').then(branchesNames =>
      setTillerKmm([...tillerPos, ...branchesNames]),
    );
  }, []);

  const handleChangeApp = appVersion => {
    setAppVersion(appVersion);
    !isEmpty(appVersion) && appVersionRegEx.test(appVersion)
      ? setIsValidAppVersion(true)
      : setIsValidAppVersion(false);
  };

  const handleChangeBuild = buildNumber => {
    setBuildNumber(buildNumber);
    !isEmpty(buildNumber) && buildRegEx.test(buildNumber)
      ? setIsValidBuildNumber(true)
      : setIsValidBuildNumber(false);
  };

  const triggerJob = () => {
    // Refresh messages
    setIsLoading(true);
    postToProd({
      CIRCLE_JOB: 'beta',
      TILLER_POS_BRANCH: tillerPosBranchValue,
      TILLER_COMPONENTS_BRANCH: tillerComponentBranchValue,
      TILLER_KMM_BRANCH: tillerKmmBranchValue,
      APP_VERSION: appVersion,
      BUILD_NUMBER: buildNumber,
      ENVIRONMENT: targetEnvironmentValue,
      BETA_TYPE: betaTypeValue,
      API_ENDPOINT: apiEndpoints[targetEnvironmentValue],
      BACK_ENDPOINT: backEndpoints[targetEnvironmentValue],
      KONG_ENDPOINT: kongEndpoints[targetEnvironmentValue],
    }).then(({ ok }) => {
      ok ? setIsSuccess(true) : setIsSuccess(false);
      setIsLoading(false);
      setIsRequestSent(true);
    });
  };

  const searchMethod = useCallback(({ options, term }) => {
    return options.filter(option =>
      option.label.toString().toLowerCase().includes(term.toLowerCase()),
    );
  }, []);

  const namedOptions = options =>
    options.map(name => (
      <Select.Option key={name} value={name}>
        {name}
      </Select.Option>
    ));

  return (
    <>
      <BotMarginMessage description={t('ops.beta_container.beta_launcher.info')} type="info" />
      <Form name="beta_launcher">
        <Form.Group inlineLabels>
          <Form.Field label={t('ops.beta_container.beta_launcher.tiller_pos')} labelWidth="20rem">
            <Select
              usePortal
              placeholder={tillerPosBranchValue}
              values={tillerPosBranch}
              onChange={setTillerPosBranch}
              css="width: 100%;"
              contentWrapperStyle={{ maxHeight: '26rem' }}
              searchMethod={searchMethod}
            >
              {namedOptions(tillerPos)}
            </Select>
          </Form.Field>
          <Form.Field
            label={t('ops.beta_container.beta_launcher.tiller_component')}
            labelWidth="20rem"
          >
            <Select
              usePortal
              placeholder={tillerComponentBranchValue}
              values={tillerComponentBranch}
              onChange={setTillerComponentBranch}
              css="width: 100%;"
              contentWrapperStyle={{ maxHeight: '26rem' }}
              searchMethod={searchMethod}
            >
              {namedOptions(tillerComponents)}
            </Select>
          </Form.Field>
          <Form.Field label={t('ops.beta_container.beta_launcher.tiller_kmm')} labelWidth="20rem">
            <Select
              usePortal
              placeholder={tillerKmmBranchValue}
              values={tillerKmmBranch}
              onChange={setTillerKmmBranch}
              css="width: 100%;"
              contentWrapperStyle={{ maxHeight: '26rem' }}
              searchMethod={searchMethod}
            >
              {namedOptions(tillerKmm)}
            </Select>
          </Form.Field>
          <Form.Field label={t('ops.beta_container.beta_launcher.environment')} labelWidth="20rem">
            <Select
              usePortal
              values={targetEnvironment}
              onChange={setTargetEnvironment}
              css="width: 100%;"
              contentWrapperStyle={{ maxHeight: '26rem' }}
            >
              {namedOptions(environments)}
            </Select>
          </Form.Field>
          <Form.Field label={t('ops.beta_container.beta_launcher.beta')} labelWidth="20rem">
            <Select
              usePortal
              values={betaType}
              onChange={setBetaType}
              css="width: 100%;"
              contentWrapperStyle={{ maxHeight: '26rem' }}
            >
              <Select.Option value="closed">
                {t('ops.beta_container.beta_launcher.closed')}
              </Select.Option>
              <Select.Option value="open">
                {t('ops.beta_container.beta_launcher.open')}
              </Select.Option>
            </Select>
          </Form.Field>
          <Form.Field label={t('ops.beta_container.beta_launcher.app_version')} labelWidth="20rem">
            <TextInput
              error={!isValidAppVersion}
              value={appVersion}
              onChange={newValueApp => handleChangeApp(newValueApp)}
              placeholder="3.8.X"
              fluid
            />
          </Form.Field>
          <Form.Field label={t('ops.beta_container.beta_launcher.build_number')} labelWidth="20rem">
            <TextInput
              error={!isValidBuildNumber}
              value={buildNumber}
              onChange={newValueBuild => handleChangeBuild(newValueBuild)}
              placeholder="92"
              fluid
            />
          </Form.Field>
          <Button
            type="submit"
            appearance="primary"
            disabled={
              !isValidAppVersion ||
              !isValidBuildNumber ||
              isEmpty(appVersion) ||
              isEmpty(buildNumber) ||
              isLoading
            }
            onClick={triggerJob}
          >
            {isLoading ? (
              <Loader width="2rem" height="2rem" color={Theme.palette.white} />
            ) : (
              t('ops.beta_container.beta_launcher.submit')
            )}
          </Button>
        </Form.Group>
      </Form>
      {isRequestSent && (
        <TopMarginMessage
          description={
            isSuccess ? (
              <Trans i18nKey="ops.beta_container.beta_launcher.success">
                <span />
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://circleci.com/gh/tillersystems/tiller-pos/tree/master"
                >
                  {{
                    link: 'https://circleci.com/gh/tillersystems/tiller-pos/tree/master',
                  }}
                </Link>
              </Trans>
            ) : (
              t('ops.beta_container.beta_launcher.error')
            )
          }
          type={isSuccess ? 'success' : 'error'}
        />
      )}
    </>
  );
};

export default BetaLauncher;
