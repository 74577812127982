/**
 * Payment method sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { paymentMethodsFailed, paymentMethodsSucceeded } from './actions';
import { PAYMENT_METHODS_REQUESTED } from './actionTypes';
import { retrievePaymentMethods } from './services';

/**
 * getPaymentMethods saga
 *
 * @generator
 */
export function* getPaymentMethodsSaga() {
  try {
    const paymentMethods = yield call(retrievePaymentMethods);
    // inform Redux to set our client paymentMethods
    yield put(paymentMethodsSucceeded({ paymentMethods }));
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put(paymentMethodsFailed(error));
  }
}

/**
 * Global listener.
 */
export default function* paymentMethodWatcher() {
  yield takeLatest(PAYMENT_METHODS_REQUESTED, getPaymentMethodsSaga);
}
