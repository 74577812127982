import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatNumber } from '@tillersystems/stardust';

/**
 * Format an object with value and unit
 *
 * @return {string}
 */
const Quantity = ({ value }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { unit, quantity } = value;

  // return formatUnitNumber(locale, quantity, unit); // Not working because unit not in ENUM.
  if (unit && unit !== 'unit') {
    return `${formatNumber({ locale, number: quantity })}${unit}`;
  }

  return formatNumber({ locale, number: quantity });
};

/** Prop types. */
Quantity.propTypes = {
  value: PropTypes.shape({
    quantity: PropTypes.number,
    unit: PropTypes.string,
  }),
};

/** Default prop types. */
Quantity.defaultProps = {
  value: { quantity: null, unit: null },
};

export default Quantity;
