import { useTranslation } from 'react-i18next';
import { Headline, Hr } from '@sumup/circuit-ui';

import MultiStore from './MultiStore';
import SingleStore from './SingleStore';

/**
 * POS Pro feature enabler (toggle) page.
 */
const FeatureActivationPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Headline as="h4">{t('features.featureActivation.title')}</Headline>

      <SingleStore />
      <Hr />

      <MultiStore />
    </div>
  );
};

export default FeatureActivationPage;
