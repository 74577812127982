import getApi from '../interfaces/TillerCaptain';
import { getStoreState } from '../store';

import { eventNames } from './config';

/** Internal URL to report route. */
const getReportUrl = '/report';

// List of subscribers. The structure of this object is the following:
// subscriber = {
//   reportName: {
//     queryName: {
//       eventName: [handlers],
//     },
//   },
// };
// The idea behind this structure is to load only the strict necessary (only reports for which there
// are subscribers).
const subscribers = {};

// Data.The structure of this object is the following:
// subscriber = {
//   reportName: {
//     queryName: {data},
//   },
// };
const data = {};

/**
 * Loads the data of the report with the given name and the given query.
 *
 * @param {String} reportName - The name of the report to subscribe to.
 * @param {String} queryName - The name of the query to subscribe to.
 * @param {Period} newPeriod - The new period to fetch data for.
 */
const loadData = async (reportName, queryName, newPeriod) => {
  const period = newPeriod || getStoreState().period;
  if (
    Object.propertyIsEnumerable.call(subscribers, reportName) &&
    Object.propertyIsEnumerable.call(subscribers[reportName], queryName)
  ) {
    for (let loadedHandler of subscribers[reportName][queryName]['loaded']) {
      await loadedHandler(false);
    }

    // - - - Prepares request params - - -
    // report parameter
    const r = reportName.replace('-', '');
    // query parameter
    const q = queryName.replace('-', '');
    // from parameter (start date included)
    const f = period.getInterval().start.toISO();
    // to parameter (end date excluded)
    const t = period.getInterval().end.toISO();

    const { ok, data } = await getApi().get(getReportUrl, { r, q, f, t });

    if (ok) {
      await handleLoadDataSuccess(reportName, queryName, data);
    } else {
      await handleLoadDataFailure(reportName, queryName, data);
    }
  }
};

/**
 * Handles successful loading of data.
 *
 * @param {String} reportName - The name of the report to subscribe to.
 * @param {String} queryName - The name of the query to subscribe to.
 * @param {Object} d - The loaded data.
 */
const handleLoadDataSuccess = async (reportName, queryName, d) => {
  // Checks if it's first time the report is loaded.
  if (!Object.propertyIsEnumerable.call(data, reportName)) data[reportName] = {};

  // Sets new data into internal data.
  data[reportName][queryName] = d;

  await notifySubscribers(reportName, queryName, d);
};

/**
 * Handles failed loading of data.
 *
 * @param {String} reportName - The name of the report to subscribe to.
 * @param {String} queryName - The name of the query to subscribe to.
 * @param {Object} d - The loaded data.
 */
const handleLoadDataFailure = async (reportName, queryName, d) => {
  // Checks if it's first time the report is loaded.
  if (!Object.propertyIsEnumerable.call(data, reportName)) data[reportName] = {};

  // Sets new data into internal data.
  data[reportName][queryName] = d;

  await notifySubscribers(reportName, queryName, d);
};

/**
 * Notifies the subscribers about changes.
 *
 * @param {String} reportName - The name of the report to subscribe to.
 * @param {String} queryName - The name of the query to subscribe to.
 * @param {Object} d - The loaded data.
 */
const notifySubscribers = async (reportName, queryName, d) => {
  if (
    Object.propertyIsEnumerable.call(subscribers, reportName) &&
    Object.propertyIsEnumerable.call(subscribers[reportName], queryName)
  ) {
    for (let dataHandler of subscribers[reportName][queryName]['data']) {
      await dataHandler(d);
    }

    for (let loadedHandler of subscribers[reportName][queryName]['loaded']) {
      await loadedHandler(true);
    }
  }
};

/**
 * Resets the internal the data source to its initial state.
 * This function is used for testing purposes.
 */
export const reset = () => {
  for (let reportName in subscribers) {
    delete subscribers[reportName];
  }

  for (let reportName in data) {
    delete data[reportName];
  }
};

/**
 * Subscribes to the event on a report with a query.
 *
 * @param {String} reportName - The name of the report to subscribe to.
 * @param {String} queryName - The name of the query to subscribe to.
 * @param {String} eventName - The name of the event to subscribe to.
 * @param {Function} handler - The handler.
 */
export const subscribe = async (reportName, queryName, eventName, handler) => {
  // Checks that event exists.
  if (!eventNames.has(eventName)) return;

  // Check if there is a report entry in subscribers list.
  if (Object.propertyIsEnumerable.call(subscribers, reportName)) {
    // Check if there is a query entry in subscribers list of report.
    if (Object.propertyIsEnumerable.call(subscribers[reportName], queryName)) {
      // There is an entry in the subscribers list.
      // Adds the given handler to the list and notifies it with already loaded data (if Subscribes
      // to data event).
      subscribers[reportName][queryName][eventName].push(handler);

      // In case data were already loaded, triggers the handler, otherwise loads data.
      if (
        Object.propertyIsEnumerable.call(data, reportName) &&
        Object.propertyIsEnumerable.call(data[reportName], queryName)
      ) {
        if (eventName === 'data') {
          // data handler
          await handler(data[reportName][queryName]);
        } else {
          // loaded handler
          await handler(true);
        }
      } else {
        if (eventName === 'data') {
          // data handler
          await loadData(reportName, queryName);
        } else {
          // loaded handler
          await handler(false);
        }
      }
    } else {
      // No entry for query in subscribers list of report. Creates a new one.
      subscribers[reportName][queryName] = eventNames.createStorage();
      subscribers[reportName][queryName][eventName].push(handler);

      if (eventName === 'data') {
        // data handler
        await loadData(reportName, queryName);
      } else {
        // loaded handler
        await handler(false);
      }
    }
  } else {
    // No entry for report in subscribers list. Creates a new one.
    subscribers[reportName] = {};
    subscribers[reportName][queryName] = eventNames.createStorage();
    subscribers[reportName][queryName][eventName].push(handler);

    if (eventName === 'data') {
      // data handler
      await loadData(reportName, queryName);
    } else {
      // loaded handler
      await handler(false);
    }
  }
};

/**
 * Unsubscribes to the event on a report with a query.
 *
 * @param {String} reportName - The name of the report to subscribe to.
 * @param {String} queryName - The name of the query to subscribe to.
 * @param {String} eventName - The name of the event to subscribe to.
 * @param {Function} handler - The handler.
 */
export const unsubscribe = (reportName, queryName, eventName, handler) => {
  // Check that event exists.
  if (!eventNames.has(eventName)) return;

  // Check that there is a report/query entry in the subscribers list.
  if (
    Object.propertyIsEnumerable.call(subscribers, reportName) &&
    Object.propertyIsEnumerable.call(subscribers[reportName], queryName)
  ) {
    const reportSubscribers = subscribers[reportName][queryName];
    const handlerIndex = reportSubscribers[eventName].findIndex(h => h === handler);

    // If handler found, removes it from the list of subscribers.
    if (handlerIndex >= 0) {
      reportSubscribers[eventName].splice(handlerIndex, 1);
    }

    // Checks whether there are no more subscribers to any event of the report/query.
    // If there no subscribers left, removes the query from the list of queries to fetch data for.
    if (Object.keys(reportSubscribers).every(k => reportSubscribers[k].length == 0)) {
      delete subscribers[reportName][queryName];
    }

    // Checks whether there are no more subscribers to any event on a query.
    // If there are no subscribers left, removers the report from the list of reports to fetch data
    // for.
    if (Object.keys(subscribers[reportName]).length == 0) {
      delete subscribers[reportName];
    }
  }
};

/**
 * Notifies the data source that the period was changed.
 *
 * @param {Period} newPeriod - The new period.
 */
export const notifyPeriodChanged = newPeriod =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async resolve => {
    for (let reportName in subscribers) {
      for (let queryName in subscribers[reportName]) {
        await loadData(reportName, queryName, newPeriod);
      }
    }

    resolve();
  });
