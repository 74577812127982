import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: end;
  padding-top: ${({ theme: { dimensions } }) => dimensions.big};

  > button:first-of-type {
    margin-right: ${({ theme: { dimensions } }) => dimensions.medium};
  }
`;
