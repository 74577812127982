import React from 'react';

import { Icon, Loader, Tag } from '@tillersystems/stardust';

import Theme from '../../../../components/Theme';

export const colsDef = (infoCallback, configCallback) => [
  {
    title: 'Stack ID',
    name: 'id',
    value: d => d.id,
    format: v => <span style={{ color: 'hsl(2213, 17%, 20%)', fontWeight: 600 }}>{v}</span>,
    align: 'left',
    isSortable: true,
    isRowHeader: true,
  },
  {
    title: 'back endpoint',
    name: 'back_endpoint',
    value: d => d.endpoints,
    format: v => formatLink('back', v),
    align: 'center',
  },
  {
    title: 'Api endpoint',
    name: 'api_endpoint',
    value: d => d.endpoints,
    format: v => formatLink('api', v),
    align: 'center',
  },
  {
    title: 'Status',
    name: 'status',
    value: d => d.state,
    format: v => formatStatus(v),
    align: 'center',
    isSortable: true,
  },
  {
    title: 'Info',
    name: 'info',
    value: d => d,
    format: v => formatInfo(v, infoCallback),
    align: 'center',
  },
  {
    title: 'Config',
    name: 'config',
    value: d => d,
    format: v => formatConfig(v, configCallback),
    align: 'center',
  },
];

const formatLink = (endpoint, endpoints) => {
  if (endpoints) {
    const [back, api] = endpoints;
    return (
      <a target="_blank" rel="noopener noreferrer" href={endpoint === 'back' ? back.url : api.url}>
        <Icon
          name="external-link"
          color={Theme.palette.darkBlue}
          size="medium"
          title="icon title"
        />
      </a>
    );
  } else {
    return (
      <Icon
        name="exclamation-mark"
        color={Theme.palette.darkBlue}
        size="medium"
        title="icon title"
      />
    );
  }
};

const formatInfo = (environment, infoCallback) => (
  <Icon
    style={{ cursor: 'pointer' }}
    onClick={() => infoCallback(environment)}
    name="info"
    color={Theme.palette.primary.default}
    size="medium"
    title="icon title"
  />
);

const formatConfig = (environment, configCallback) =>
  environment.state == 'CREATE_DONE' ? (
    <Icon
      style={{ cursor: 'pointer' }}
      onClick={() => configCallback(environment)}
      name="cog"
      color={Theme.palette.darkBlue}
      size="medium"
      title="icon title"
    />
  ) : (
    <Icon name="exclamation-mark" color={Theme.palette.darkBlue} size="medium" title="icon title" />
  );

const formatStatus = status => {
  const format = {
    CREATE_START: <Loader width="2rem" height="2rem" />,
    CREATE_DONE: <Tag color={Theme.palette.success.default}>Running</Tag>,
    CREATE_ERROR: <Tag color={Theme.palette.failure.default}>Create error</Tag>,
    DELETE_START: <Tag color={Theme.palette.warning.default}>Deleting</Tag>,
    DELETE_DONE: <Tag color={Theme.palette.lightGrey}>Deleted</Tag>,
    DELETE_ERROR: <Tag color={Theme.palette.failure.default}>Delete Error</Tag>,
    ERROR: <Tag color={Theme.palette.failure.default}>Error</Tag>,
    default: <Tag color={Theme.palette.primary.default}>Default state</Tag>,
  };
  return format[status] || format.default;
};
