import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton } from '@tillersystems/stardust';

import { Card, LoaderOverlay } from '../../../../components';
import { Order } from '../../../../modules/OrderOMS/types';

import { Table } from './components/Table';
import {
  Body,
  CardTitle,
  Header,
  SettingsBar,
  SettingsBarTitle,
  Toggle,
  ToggleLabel,
} from './elements';

type Props = {
  order: Order;
  isOrderLoading: boolean;
};

const EventsCard: FC<Props> = ({ order, isOrderLoading }) => {
  const [t] = useTranslation();
  const [isDetailedToggle, setDetailedToggle] = useState(false);

  const handleDetailedViewToggle = () => {
    setDetailedToggle(!isDetailedToggle);
  };

  return (
    <Card>
      <Header>
        <CardTitle>{t('order_management.events')}</CardTitle>
      </Header>
      <SettingsBar>
        <SettingsBarTitle>{t('order_management.settings_title')}</SettingsBarTitle>
        <Toggle>
          <ToggleLabel onClick={handleDetailedViewToggle}>
            {t('order_management.detailed_view')}
          </ToggleLabel>
          <ToggleButton isChecked={isDetailedToggle} onToggle={handleDetailedViewToggle} />
        </Toggle>
      </SettingsBar>
      <Body>
        {isOrderLoading && <LoaderOverlay />}
        <Table order={order} isDetailedToggle={isDetailedToggle} />
      </Body>
    </Card>
  );
};

export default EventsCard;
