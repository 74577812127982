import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@sumup/circuit-ui';

import { useDataIntegrityContext } from '../context';
import { InputWrapper } from '../elements';
import { InputName } from '../type';

const TimePeriodSelect = () => {
  const { t } = useTranslation();
  const {
    register,
    resetField,
    formState: { errors },
  } = useFormContext();
  const { getErrorMessage } = useDataIntegrityContext();

  const id = InputName.timePeriod;
  const error = getErrorMessage(id, errors[id]);

  return (
    <InputWrapper>
      <Select
        data-testid="select-time_period"
        {...register(id, {
          required: true,
          onChange: () => {
            resetField(InputName.from);
          },
        })}
        label={t(`data_integrity.search_form.${id}.label`)}
        placeholder={t(`data_integrity.search_form.${id}.placeholder`)}
        options={[
          {
            label: t(`data_integrity.search_form.${id}.options.daily`),
            value: 'daily',
          },
          {
            label: t(`data_integrity.search_form.${id}.options.monthly`),
            value: 'monthly',
          },
          {
            label: t(`data_integrity.search_form.${id}.options.yearly`),
            value: 'yearly',
          },
        ]}
        invalid={!!error}
        validationHint={error}
        noMargin
      />
    </InputWrapper>
  );
};

export default TimePeriodSelect;
