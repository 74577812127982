import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Loader } from '@tillersystems/stardust';

import { getJiraStatus } from '../../../service';

import { Tag } from './elements';

const Status = ({ jiraTicket }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    setIsLoading(true);
    jiraTicket &&
      getJiraStatus(jiraTicket).then(status => {
        setStatus(status);
        setIsLoading(false);
      });
  }, []);

  return <span>{isLoading ? <Loader /> : <Tag status={status}>{status}</Tag>}</span>;
};

/**
 * PropTypes Validation
 */
const { string } = PropTypes;
Status.propTypes = {
  jiraTicket: string,
};

/**
 * Default props
 */
Status.defaultProps = {
  jiraTicket: '',
};

export default Status;
