import styled, { css } from 'styled-components';

const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.2rem;
  font-weight: normal;

  width: 5.5rem;
  padding: 0.5rem;

  border-radius: 2rem;

  color: ${({ theme: { palette } }) => palette.success.fore};
  background: ${({ theme: { palette } }) => palette.success.back};

  ${({ negative }) =>
    negative &&
    css`
      background-color: ${({ theme: { palette } }) => palette.failure.back};
    `};
`;

export default Badge;
