import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import * as Cells from './Cells';
import { Container, Definition, Item, Separator, Term } from './elements';
import { createDefinitions } from './helpers';

const DefinitionList = ({ className, definitions, data }) => {
  const defs = useMemo(() => definitions || createDefinitions(data));

  return (
    <Container className={className}>
      {defs.map(definition => {
        if (definition.isSeparator) return <Separator key={definition.name} />;

        const value = definition.value(data);

        if (definition.hideEmpty && !value) {
          return null;
        }

        return (
          <Item key={definition.name} isHeader={definition.isHeader}>
            <Term>{definition.label}</Term>
            <Definition>
              <definition.Component value={value} {...definition.options} />
            </Definition>
          </Item>
        );
      })}
    </Container>
  );
};

DefinitionList.Cells = Cells;

DefinitionList.propTypes = {
  definitions: PropTypes.array,
  data: PropTypes.object,
  className: PropTypes.string,
};

DefinitionList.defaultProps = {
  definitions: null,
  data: {},
  className: '',
};

export default DefinitionList;
