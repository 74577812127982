import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

/**
 * Head
 *
 * This component is in charge of displaying
 * the app Head
 *
 * @param {import('react').ReactNode} children // Anything that can be rendered: numbers, strings, elements or an array (or fragment)..
 * @param {string} description // The app description.
 * @param {string} title // The app title.
 *
 * @return {jsx}
 */
const Head = ({ children, description, title }) => {
  return (
    <Helmet titleTemplate="%s | Tiller Captain">
      {/* title attributes and value */}
      <title>{title}</title>

      {/* multiple meta elements */}
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {children}
    </Helmet>
  );
};

/**
 * PropTypes Validation.
 */
const { string, node } = PropTypes;
Head.propTypes = {
  children: node,
  description: string,
  title: string,
};

/**
 * Default props.
 */
Head.defaultProps = {
  children: null,
  description: 'La caisse enregistreuse. Réinventée.',
  title: 'Tiller',
};

export default Head;
