/* eslint-disable import/no-named-as-default-member */
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translate_en from './locales/en/translations.json';
// import translate_es from './locales/es/translations.json';
// import translate_fr from './locales/fr/translations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    resources: {
      en: {
        translations: translate_en,
      },
      // For now traductions are disabled
      // In case you want to enable them unquote the following lines and make sure you complete the refered files.
      // fr: {
      //   translations: translate_fr,
      // },
      // es: {
      //   translations: translate_es,
      // },
    },

    debug: process.env.NODE_ENV === 'development',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  });

const getPrefix = n => Number(n > 1 ? 2 : n);

const languages = [
  { code: 'en', name: 'English' },
  // { code: 'es', name: 'Spanish' },
  // { code: 'fr', name: 'French' },
];

languages.forEach(({ code, name }) => {
  i18n.services.pluralResolver.addRule(code, {
    name,
    numbers: [0, 1, 2],
    plurals: getPrefix,
  });
});

export default i18n;
