const PaymentTypes = [
  { value: 'CASH', label: 'Cash' },
  { value: 'CARD', label: 'Card' },
  { value: 'TICKET_RESTAURANT', label: 'Ticket restaurant' },
  { value: 'CREDIT_NOTE', label: 'Voucher' },
  { value: 'FIVORY', label: 'Lyfpay' },
  { value: 'SUMUP', label: 'Sumup' },
];

export default PaymentTypes;
