/**
 * Order Detail actions
 */

import * as t from './actionTypes';

/**
 * Order Detail Requested
 * dispatch this action when
 * you want to start a request to retrieve all orderDetail.
 */
export const orderDetailRequested = payload => ({
  type: t.ORDER_DETAIL_REQUESTED,
  payload,
});

/**
 * Order Detail Succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {object}
 */
export const orderDetailSucceeded = data => ({
  type: t.ORDER_DETAIL_SUCCEEDED,
  payload: data,
});

/**
 * Order Detail Failed
 * dispatch this action when request
 * has not been successfully received.
 *
 * @param {object} error
 *
 * @return {object}
 */
export const orderDetailFailed = error => ({
  type: t.ORDER_DETAIL_FAILED,
  payload: error,
});
