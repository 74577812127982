import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Head, Menu, PageContainer, TopBar } from '../../components';

import BetaContainer from './BetaContainer';
import { Container, Wrapper } from './elements';
import PinguiContainer from './PinguiContainer';

/**
 * Ops
 *
 * This components is in charge of display
 * the ops page
 *
 * @param {object} match
 * @param {object} location
 *
 * @return {jsx}
 */

const Ops = ({ match: { url }, location: { pathname } }) => {
  const [t] = useTranslation();
  const allowRedirect = url === pathname;

  return (
    <>
      {allowRedirect && <Redirect to={`${url}/beta-launcher`} />}
      <Head title={t('navigation.ops')} />
      <TopBar title={t('navigation.ops')} />
      <PageContainer>
        <Wrapper>
          <Menu>
            <Menu.Header>{t('releases.menu.title')}</Menu.Header>
            <Menu.Body>
              <Menu.Link to={`${url}/beta-launcher`} activeOnlyWhenExact title="beta-launcher" />
              <Menu.Link to={`${url}/pingui`} activeOnlyWhenExact title="pingui" />
            </Menu.Body>
          </Menu>
          <Container>
            <Switch>
              <Route path={`${url}/beta-launcher`}>
                <BetaContainer />
              </Route>
              <Route path={`${url}/pingui`}>
                <PinguiContainer />
              </Route>
            </Switch>
          </Container>
        </Wrapper>
      </PageContainer>
    </>
  );
};

Ops.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Ops);
