import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';

const BaseEvent = [
  {
    name: 'uuid',
    label: i18n.t('order_management.fields.uuid'),
    value: item => item.uuid,
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'date',
    label: i18n.t('order_management.date'),
    value: item => item.happenedOn,
    Component: DefinitionList.Cells.DateTime,
  },
  {
    name: 'waiter',
    label: i18n.t('order_management.fields.waiter'),
    value: item => item.waiter && item.waiter.name,
    Component: DefinitionList.Cells.TextField,
    hideEmpty: true,
  },
  {
    name: 'device',
    label: i18n.t('order_management.device'),
    value: item => item.source && item.source.device && item.source.device.identifier,
    Component: DefinitionList.Cells.TextField,
    hideEmpty: true,
  },
  {
    name: 'appVersion',
    label: i18n.t('order_management.fields.appVersion'),
    value: item => item.source && item.source.appVersion,
    Component: DefinitionList.Cells.StringField,
    hideEmpty: true,
  },
  {
    name: 'comment',
    label: i18n.t('order_management.fields.comment'),
    value: item => item.payload.comment,
    Component: DefinitionList.Cells.StringField,
    hideEmpty: true,
  },
];

export default BaseEvent;
