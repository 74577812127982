import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

/**
 * Releases
 *
 * This component is in charge of displaying
 * all releases
 *
 * @param {string} className // className needed by styled components.
 * @param {array} releasesData // The result of the api call
 *
 * @return {jsx}
 */
import Release from './Release';

const Releases = ({ className, releasesData }) => (
  <div className={className} data-testid="releasesList">
    {releasesData.map(({ id, body, name, published_at, draft }) => {
      const publishAt = DateTime.fromISO(published_at).toFormat('dd/LL/yyyy');
      return <Release key={id} body={body} name={name} publishDate={publishAt} isDraft={draft} />;
    })}
  </div>
);

Releases.propTypes = {
  className: PropTypes.string,
  releasesData: PropTypes.array.isRequired,
};

Releases.defaultProps = {
  className: '',
};

export default Releases;
