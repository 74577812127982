import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const MenuBody = ({ children, className }) => <div className={className}>{children}</div>;

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
MenuBody.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
MenuBody.defaultProps = {
  children: null,
  className: '',
};

export default styled(MenuBody)`
  /* For small devices */
  ${breakpoint('xs', 'md')`
    display: flex;

  `};
`;
