import i18next from 'i18next';

/* ==================
 * GENERIC SETTINGS
 * ================== */

/**
 * Creates a selector for setting, with a setting name, a default value and an optional namespace.
 *
 * @param {string} settingName - the key of preference in the namespace
 * @param {*} defaultValue - the value if no value is present in settings
 * @param {string} namespace - the namespace of preference (default: no namespace)
 *
 * @return {*}
 */
export const getSetting =
  (settingName, defaultValue = null, namespace = false) =>
  ({ settings }) => {
    if (namespace) {
      return settings[namespace] &&
        settings[namespace][settingName] !== undefined &&
        settings[namespace][settingName] !== null
        ? settings[namespace][settingName]
        : defaultValue;
    }

    return settings[settingName] !== undefined && settings[settingName] !== null
      ? settings[settingName]
      : defaultValue;
  };

/* ==================
 * GLOBAL settings
 * ================== */

/**
 * Selected language Selector
 *
 * @param {Object} state
 *
 * @return {string}
 */
export const getLanguage = state => getSetting('language', i18next.language)(state);
