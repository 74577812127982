/**
 * Client entry point.
 */

import React from 'react';
import { render } from 'react-dom';
import { initialize } from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { BaseStyles, ToastProvider } from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider as OldThemeProvider } from 'styled-components';

import './i18n'; // initialized i18next instance

import Theme from './components/Theme';
import { AuthProvider } from './contexts/Auth';
import { getStore } from './modules';
import { getHistory } from './navigation';
import Routes from './routes';
import GlobalStyle from './styles';

/* Redux initialization */
const initialState = {};
const store = getStore(initialState);
const persistor = persistStore(store);

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  /* Google Analytics initialization. */
  initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

// create a query client
const queryClient = new QueryClient();

/* Client Render */
const MOUNT_NODE = document.getElementById('root');

function renderClient(Component) {
  if (process.env.NODE_ENV === 'development') {
    // enable debugger
    window.React = React;
  }

  render(
    <OldThemeProvider theme={Theme}>
      <ThemeProvider theme={light}>
        <BaseStyles />
        <Provider store={store} key="provider">
          <ToastProvider>
            <QueryClientProvider client={queryClient}>
              <PersistGate persistor={persistor}>
                <HelmetProvider>
                  <AuthProvider>
                    <GlobalStyle />
                    <Router history={getHistory()}>
                      <Component />
                    </Router>
                  </AuthProvider>
                </HelmetProvider>
              </PersistGate>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ToastProvider>
        </Provider>
      </ThemeProvider>
    </OldThemeProvider>,
    MOUNT_NODE,
  );
}

renderClient(Routes);
