import styled from 'styled-components';

import { Select } from '@tillersystems/stardust';

export const Container = styled(Select)`
  width: 27rem;
`;

export const ColoredLabel = styled.span`
  color: ${({ color, theme: { palette } }) => color || palette.spaceGrey};
`;
