import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Cell, Grid } from 'styled-css-grid';

import { KpiBlock, KpiChart, Loader } from '@tillersystems/stardust';

import { withReport } from '../../../../modules/Report';
import { Section, Title } from '../../elements';
import { Row } from '../elements';

import AppsTable from './AppsTable';
import StoresDistribution from './StoresDistribution';

/**
 * Defines the AppMarket's apps usage report.
 *
 * @param {boolean} reportLoaded - A value indicating whether the report is loaded or not.
 * @param {boolean} reportData - The data of the report.
 *
 * @returns {jsx}
 */
const AppsUsage = ({ reportLoaded, reportData }) => {
  const [t] = useTranslation();
  const data = reportData && ['ok', 'warning'].includes(reportData.status) ? reportData : null;

  return (
    <Section>
      <Title>{t('reports.appmarket.usage.title')}</Title>
      {reportLoaded ? (
        <>
          <Grid columns="repeat(auto-fit,minmax(20rem,1fr))" gap="2.3rem">
            <Cell>
              <KpiBlock
                title={t('reports.appmarket.usage.activated_apps_total_count')}
                value={data ? data.activated_apps.value.toString() : t('reports.no_data')}
                variation={data ? 100 * data.activated_apps.variation : 0}
              />
            </Cell>
            <Cell>
              <KpiBlock
                title={t('reports.appmarket.usage.stores_total_count')}
                value={data ? data.stores_count.value.toString() : t('reports.no_data')}
                variation={data ? 100 * data.stores_count.variation : 0}
              />
            </Cell>
            <Cell>
              <KpiBlock
                title={t('reports.appmarket.usage.stores_using_an_app')}
                value={data ? data.stores_with_apps_count.value.toString() : t('reports.no_data')}
                variation={data ? 100 * data.stores_with_apps_count.variation : 0}
              />
            </Cell>
          </Grid>
          <Grid columns="repeat(auto-fit,minmax(20rem,1fr))" gap="2.3rem">
            <Cell>
              <KpiChart>
                <KpiChart.Header>
                  <KpiChart.Title>{t('reports.appmarket.usage.apps_table.title')}</KpiChart.Title>
                </KpiChart.Header>
                <KpiChart.Body>
                  <AppsTable data={data} />
                </KpiChart.Body>
              </KpiChart>
            </Cell>
            <Cell>
              <KpiChart>
                <KpiChart.Header>
                  <KpiChart.Title>
                    {t('reports.appmarket.usage.stores_distribution.title')}
                  </KpiChart.Title>
                </KpiChart.Header>
                <KpiChart.Body>
                  <StoresDistribution data={data} />
                </KpiChart.Body>
              </KpiChart>
            </Cell>
          </Grid>
        </>
      ) : (
        <>
          <Row>
            <Loader width="5rem" height="5rem" />
            <Loader width="5rem" height="5rem" />
            <Loader width="5rem" height="5rem" />
          </Row>
          <Row chart>
            <Loader width="5rem" height="5rem" />
            <Loader width="5rem" height="5rem" />
          </Row>
        </>
      )}
    </Section>
  );
};

/** Component's props type validation. */
const { bool, object } = PropTypes;
AppsUsage.propTypes = {
  reportLoaded: bool.isRequired,
  reportData: object,
};

/** Component's props default values. */
AppsUsage.defaultProps = {
  reportData: null,
};

export default withReport('app-market', 'usage')(AppsUsage);
