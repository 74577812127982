import styled, { css } from 'styled-components';

export const Container = styled.dl``;

export const Separator = styled.hr`
  margin: 1rem 0;
  border-bottom: 1px solid #eee;
`;

export const Term = styled.dt`
  width: 24rem;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.2rem 0.5rem 0.2rem 0;
  font-weight: bold;
`;

export const Definition = styled.dd`
  flex-grow: 1;
  padding: 0.2rem 0.5rem;
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  ${({ isHeader }) =>
    isHeader &&
    css`
      > ${Term} {
        width: 100%;
        border-bottom: 1px solid #eee;
        padding: 0.2rem 0.5rem 1rem 0;
      }
      > ${Definition} {
        border-left: 0.4rem solid #eee;
        padding: 0.2rem 1rem;
      }
    `}
`;
