import i18n from '../../../../i18n';
import DefinitionList from '../../../DefinitionList';
import Quantity from '../Cells/Quantity';

import BaseEvent from './BaseEvent';

const ProductQuantityWasUpdated = [
  ...BaseEvent,
  {
    name: 'separator',
    isSeparator: true,
  },
  {
    name: 'orderLineUUID',
    label: i18n.t('order_management.fields.orderLineUUID'),
    value: item => item.payload.orderLineUUID,
    Component: DefinitionList.Cells.StringField,
  },
  {
    name: 'quantity',
    label: i18n.t('order_management.fields.quantity'),
    value: ({ payload: { quantity, unit } }) => ({ quantity, unit }),
    Component: Quantity,
  },
];

export default ProductQuantityWasUpdated;
