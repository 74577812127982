import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const SideNavBody = ({ children, className }) => <div className={className}>{children}</div>;

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
SideNavBody.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
SideNavBody.defaultProps = {
  children: null,
  className: '',
};

export default styled(SideNavBody)`
  grid-area: sidenavBody;

  /* For small devices */
  ${breakpoint('xs', 'md')`
    display: grid;
    grid-template-columns: repeat(5,minmax(5rem, auto));
    grid-template-rows: 6rem;
  `};
`;
