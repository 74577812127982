/**
 * Payment actions
 */

import * as t from './actionTypes';

/**
 * Default payment config retrieve requested
 * dispatch this action when
 * you want to start a request to retrieve a payment methods
 */
export const defaultPaymentConfigRetrieveRequested = () => ({
  type: t.DEFAULT_PAYMENT_CONFIG_RETRIEVE_REQUESTED,
});

/**
 * Default payment config retrieve succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const defaultPaymentConfigRetrieveSucceeded = data => ({
  type: t.DEFAULT_PAYMENT_CONFIG_RETRIEVE_SUCCEEDED,
  payload: data,
});

/**
 * Default payment config retrieve failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {Object} error
 *
 * @return {Object}
 */
export const defaultPaymentConfigRetrieveFailed = error => ({
  type: t.DEFAULT_PAYMENT_CONFIG_RETRIEVE_FAILED,
  payload: error,
});

/**
 * Default payment config update requested
 * dispatch this action when
 * you want to start a request to update a payment methods
 * @param {Object} payload
 */
export const defaultPaymentConfigUpdateRequested = payload => ({
  type: t.DEFAULT_PAYMENT_CONFIG_UPDATE_REQUESTED,
  payload,
});

/**
 * Default payment config update succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const defaultPaymentConfigUpdateSucceeded = data => ({
  type: t.DEFAULT_PAYMENT_CONFIG_UPDATE_SUCCEEDED,
  payload: data,
});

/**
 * Default payment config update failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {Object} error
 *
 * @return {Object}
 */
export const defaultPaymentConfigUpdateFailed = error => ({
  type: t.DEFAULT_PAYMENT_CONFIG_UPDATE_FAILED,
  payload: error,
});

/**
 * Payments config retrieve requested
 * dispatch this action when
 * you want to start a request to retrieve a payment methods
 * @param {Object} payload
 */
export const paymentsConfigRetrieveRequested = payload => ({
  type: t.PAYMENTS_CONFIG_RETRIEVE_REQUESTED,
  payload,
});

/**
 * Payments config retrieve succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const paymentsConfigRetrieveSucceeded = data => ({
  type: t.PAYMENTS_CONFIG_RETRIEVE_SUCCEEDED,
  payload: data,
});

/**
 * Payments config retrieve failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {Object} error
 *
 * @return {Object}
 */
export const paymentsConfigRetrieveFailed = error => ({
  type: t.PAYMENTS_CONFIG_RETRIEVE_FAILED,
  payload: error,
});

/**
 * Payments config update requested
 * dispatch this action when
 * you want to start a request to update a payment methods
 * @param {Object} payload
 */
export const paymentsConfigUpdateRequested = payload => ({
  type: t.PAYMENTS_CONFIG_UPDATE_REQUESTED,
  payload,
});

/**
 * Payments config update succeeded
 * dispatch this action when request
 * has been successfully received.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const paymentsConfigUpdateSucceeded = data => ({
  type: t.PAYMENTS_CONFIG_UPDATE_SUCCEEDED,
  payload: data,
});

/**
 * Payments config update failed
 * dispatch this action when request
 * has been failed.
 *
 * @param {Object} errors
 *
 * @return {Object}
 */
export const paymentsConfigUpdateFailed = errors => ({
  type: t.PAYMENTS_CONFIG_UPDATE_FAILED,
  payload: errors,
});

/**
 * Select a payment config by its label
 * dispatch this action when user click on a payment method label
 *
 * @param {string} label
 *
 * @return {Object}
 */
export const paymentsConfigSelectLabel = label => ({
  type: t.PAYMENTS_CONFIG_SELECT_LABEL,
  payload: label,
});
