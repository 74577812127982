import React from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';

const List = ({ value, itemFormat: { Component, options } }) => (
  <ul>
    {value.map((item, index) => (
      <li key={index}>
        <Component value={item} {...options} />
      </li>
    ))}
  </ul>
);

List.propTypes = {
  value: PropTypes.array,
  itemFormat: PropTypes.shape({
    Component: PropTypes.func,
    options: PropTypes.object,
  }),
};

List.defaultProps = {
  value: null,
  itemFormat: { Component: TextField },
};

export default List;
