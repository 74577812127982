import { fork } from 'redux-saga/effects';

import orderDetailWatcher from './Detail/sagas';
import orderEventsWatcher from './Events/sagas';

/**
 * Global listener.
 */
export default function* orderWatcher() {
  yield fork(orderDetailWatcher);
  yield fork(orderEventsWatcher);
}
