/**
 * Payment method reducer
 */
import { produce } from 'immer';

import { FAILED, LOADING, NONE, SUCCEEDED, UPDATING } from '../../constants/Status';
import { deleteWithFilter, setWithFilter } from '../../helpers/utils';

import * as t from './actionTypes';

/**
 * @typedef {Object} PaymentOptions
 * @property {boolean} hasExtraConfig
 * @property {boolean} creditNoteOnly
 * @property {string} [featureCode]
 *
 * @typedef {Object.<string, PaymentOptions>} CategoryPaymentConfig
 * @typedef {Object.<string, CategoryPaymentConfig>} PaymentConfig
 * @typedef {Object.<string, PaymentConfig>} PaymentsConfig
 *
 * @typedef {Object} State
 * @property {PaymentsConfig} entities
 * @property {Object.<string, string>} status
 * @property {Object.<string, Error>} [errors]
 * @property {string} [selectedLabel]
 */

/**
 * @type {State}
 */
const initialState = {
  entities: {},
  status: { default: NONE },
  errors: {},
  selectedLabel: null,
};

/**
 * Payment config root reducer
 *
 * @param {State} state initial or previous state of data
 * @param {Object} action the action object
 *
 * @return {Object}
 */
export default function root(state = initialState, { type, payload } = {}) {
  switch (type) {
    case t.PAYMENTS_CONFIG_RETRIEVE_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status = setWithFilter(draft.status, LOADING, payload.countries);
        draft.errors = deleteWithFilter(draft.errors, payload.countries);
      });
    case t.PAYMENTS_CONFIG_RETRIEVE_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.status = setWithFilter(draft.status, SUCCEEDED, payload.paymentsConfig, true);
        draft.errors = deleteWithFilter(draft.errors, payload.paymentsConfig, true);
        draft.entities = { ...draft.entities, ...payload.paymentsConfig };
      });
    case t.PAYMENTS_CONFIG_RETRIEVE_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.status = setWithFilter(draft.status, FAILED, payload.errors, true);
        draft.errors = { ...draft.errors, ...payload.errors };
      });
    case t.PAYMENTS_CONFIG_UPDATE_REQUESTED:
      // Mark the state as "updating" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status = setWithFilter(draft.status, UPDATING, payload.config, true);
        draft.errors = deleteWithFilter(draft.errors, payload.config, true);
      });
    case t.PAYMENTS_CONFIG_UPDATE_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.status = setWithFilter(draft.status, SUCCEEDED, payload.paymentsConfig, true);
        draft.errors = deleteWithFilter(draft.errors, payload.paymentsConfig, true);
        draft.entities = { ...draft.entities, ...payload.paymentsConfig };
      });
    case t.PAYMENTS_CONFIG_UPDATE_FAILED:
      // The reauest failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.status = setWithFilter(draft.status, FAILED, payload.errors, true);
        draft.errors = { ...draft.errors, ...payload.errors };
      });
    case t.DEFAULT_PAYMENT_CONFIG_RETRIEVE_REQUESTED:
      // Mark the state as "loading" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status.default = LOADING;
        delete draft.errors.default;
      });
    case t.DEFAULT_PAYMENT_CONFIG_RETRIEVE_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.status.default = SUCCEEDED;
        delete draft.errors.default;
        draft.entities.default = payload.paymentConfig;
      });
    case t.DEFAULT_PAYMENT_CONFIG_RETRIEVE_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.status.default = FAILED;
        draft.errors.default = payload.error;
      });
    case t.DEFAULT_PAYMENT_CONFIG_UPDATE_REQUESTED:
      // Mark the state as "updating" so we can show a spinner or something.
      // Also, reset any errors. We're starting fresh.
      return produce(state, draft => {
        draft.status.default = UPDATING;
        delete draft.errors.default;
      });
    case t.DEFAULT_PAYMENT_CONFIG_UPDATE_SUCCEEDED:
      // All done: set loading to "false".
      // Also, replace the items with the ones from the server
      return produce(state, draft => {
        draft.status.default = SUCCEEDED;
        delete draft.errors.default;
        draft.entities.default = payload.paymentConfig;
      });
    case t.DEFAULT_PAYMENT_CONFIG_UPDATE_FAILED:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.status.default = FAILED;
        draft.errors.default = payload.error;
      });
    case t.PAYMENTS_CONFIG_SELECT_LABEL:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere.
      return produce(state, draft => {
        draft.selectedLabel = payload;
      });
    default:
      return state;
  }
}
