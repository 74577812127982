import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { debounce } from 'lodash';

import { CheckBox, Loader, Table } from '@tillersystems/stardust';

import SearchBar from '../../../components/SearchBar';
import { search } from '../../../helpers/utils';
import {
  countriesRequested,
  getCountriesArray,
  isCountriesDoneLoading,
} from '../../../modules/Country';
import {
  getPaymentMethodsError,
  isPaymentMethodsDoneLoading,
  paymentMethodsRequested,
} from '../../../modules/PaymentMethod';
import {
  defaultPaymentConfigRetrieveRequested,
  getPaymentMethodsWithConfig,
  isDefaultPaymentConfigDoneLoading,
  isPaymentsConfigDoneLoading,
  paymentsConfigRetrieveRequested,
} from '../../../modules/PaymentsConfig';

import { colsDef, formatData } from './config';
import { CenterItem, TableBody, TableContainer, TableHeader, Wrapper } from './elements';

export default () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const countries = useSelector(getCountriesArray);
  const paymentMethods = useSelector(getPaymentMethodsWithConfig);
  const isCountriesLoaded = useSelector(isCountriesDoneLoading);
  const isPaymentMethodsLoaded = useSelector(isPaymentMethodsDoneLoading);
  const isDefaultPaymentConfigLoaded = useSelector(isDefaultPaymentConfigDoneLoading);
  const isPaymentsConfigLoaded = useSelector(isPaymentsConfigDoneLoading);
  const error = useSelector(getPaymentMethodsError);
  const isLoaded =
    isCountriesLoaded &&
    isPaymentMethodsLoaded &&
    isDefaultPaymentConfigLoaded &&
    isPaymentsConfigLoaded;

  const [showDefaultCategory, setShowDefaultCategory] = useState(false);
  const [searchPattern, setSearchPattern] = useState('');
  const [data, setData] = useState(paymentMethods);

  useEffect(() => {
    if (!isPaymentMethodsLoaded) dispatch(paymentMethodsRequested());
  }, [isPaymentMethodsLoaded, paymentMethods.length]);

  useEffect(() => {
    if (!isCountriesLoaded) dispatch(countriesRequested());
  }, [isCountriesLoaded]);

  useEffect(() => {
    if (!isDefaultPaymentConfigLoaded) dispatch(defaultPaymentConfigRetrieveRequested());
  }, [isDefaultPaymentConfigLoaded]);

  useEffect(() => {
    if (!isPaymentsConfigLoaded && isCountriesLoaded) {
      dispatch(
        paymentsConfigRetrieveRequested({
          countries: countries.map(({ countryCode }) => countryCode),
        }),
      );
    }
  }, [isPaymentsConfigLoaded, isCountriesLoaded, countries.length]);

  useEffect(() => {
    setSearchPattern(searchPattern);
    setData(paymentMethods);
  }, [paymentMethods.length, isLoaded]);

  const navigateToPaymentMethod = ({ label }) => history.push(`${path}/edit/${label}`);

  const filterPaymentMethods = debounce(value => {
    setData(paymentMethods.filter(({ id, label }) => search(value, { id, label })));
  }, 200);

  const onSearbarChange = value => {
    setSearchPattern(value);
    filterPaymentMethods(value);
  };

  return (
    <Wrapper>
      <TableContainer>
        <TableBody>
          {!isLoaded ? (
            <CenterItem>
              <Loader width="6rem" height="6rem" />
            </CenterItem>
          ) : error instanceof Error ? (
            <CenterItem>{error.message}</CenterItem>
          ) : (
            <>
              <TableHeader>
                <CheckBox
                  onChange={() => setShowDefaultCategory(!showDefaultCategory)}
                  isChecked={showDefaultCategory}
                >
                  {showDefaultCategory
                    ? t('payment_methods.hide_default_category')
                    : t('payment_methods.show_default_category')}
                </CheckBox>
                <SearchBar
                  value={searchPattern}
                  placeholder={t('payment_methods.search_placeholder')}
                  onChange={onSearbarChange}
                />
              </TableHeader>
              <Table
                data={formatData(data)}
                colsDef={colsDef(showDefaultCategory)}
                rowsDef={{ onClick: navigateToPaymentMethod }}
                isHoverable
              />
            </>
          )}
        </TableBody>
      </TableContainer>
    </Wrapper>
  );
};
