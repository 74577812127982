import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

/**
 * Card Body
 *
 * This component is in charge of displaying
 * a body of a card
 *
 * @param {string} children // children.
 * @param {string} className // className.
 *
 * @return {jsx}
 */

const CardBody = ({ children, className }) => <div className={className}>{children}</div>;

/**
 * PropTypes Validation
 */
const { node, string } = PropTypes;
CardBody.propTypes = {
  children: node,
  className: string,
};

/**
 * Default props
 */
CardBody.defaultProps = {
  children: null,
  className: '',
};

export default styled(CardBody)`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: ${({ padding }) => padding || 0};

  /* center content */
  ${({ center }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;
