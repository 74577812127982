import { useQuery } from 'react-query';
import { RestAPI as API, RestAPI } from '@aws-amplify/api-rest';

import { AvailableAPIs } from '../../amplify-config';

import { FeatureActivation } from './FeatureActivationPage/types';

const currentVersion = '2022-11-04';
export const AvailableCountries = ['FR', 'IT', 'SP', 'DE', 'US', 'DEFAULT'];

/**
 * Get all features per country.
 *
 * @returns
 */
export function useGetCountryInformationsFeatures() {
  return useQuery(['getCountryInformationFeatures'], async () => {
    const promises = AvailableCountries.map(async country => {
      return await API.get(
        AvailableAPIs.Internal,
        `/countries/${country}/informations/${currentVersion}/modules`,
        {},
      );
    });

    const result = await Promise.all(promises);

    const response = AvailableCountries.reduce((acc, country, currentIndex) => {
      acc[country] = result[currentIndex];
      return acc;
    }, {});

    return response;
  });
}

/**
 * Create a new feature
 * Call to feature-flag to create feature
 * Call to country-information x number of country selected to add it to country module list.
 *
 * @param body
 * @param countries
 * @param features
 * @returns
 */
export async function createNewFeature(body, countries, features) {
  const createFeatureInit = {
    body,
    headers: {},
  };

  const newFeature = await API.post(AvailableAPIs.FeatureAPI, '/features', createFeatureInit);

  const newModule = {
    id: newFeature.id,
    code: newFeature.code,
  };

  const promises = countries.map(async country => {
    const createConfigEntryInit = {
      body: {
        schemas: {
          modules: [...features[country], newModule],
        },
      },
      headers: {},
    };
    return await API.post(
      AvailableAPIs.Internal,
      `/countries/${country}/informations/${currentVersion}/modules`,
      createConfigEntryInit,
    );
  });

  const result = await Promise.all(promises);

  return result;
}

/**
 * Get feature categories
 *
 * @returns
 */
export function useGetFeatureCategories() {
  return useQuery(['getFeatureCategories'], () =>
    API.get(AvailableAPIs.FeatureAPI, '/feature-categories', {}),
  );
}

export async function enableFeature(data: Omit<FeatureActivation, 'status'>[]) {
  return RestAPI.post(AvailableAPIs.FeatureAPI, `/features/enable`, {
    body: { data },
  });
}
