import { RestAPI as API } from '@aws-amplify/api-rest';

import { AvailableAPIs } from '../../../amplify-config';
import { IntegrationRequestStatusType } from '../types';

export interface GetIntegrationRequestsParamsType {
  readonly lastEvaluatedKey?: string;
  readonly from?: string;
  readonly to?: string;
  readonly status?: IntegrationRequestStatusType;
  readonly paginate?: string;
}

export async function getIntegrationRequests(
  queryStringParameters: GetIntegrationRequestsParamsType,
) {
  return await API.get(AvailableAPIs.Internal, '/integrations/requests', {
    queryStringParameters,
  });
}
