import { call, takeLatest } from 'redux-saga/effects';

import { PeriodChanged } from '../Period';

import { notifyPeriodChanged } from './datasource';

/**
 * Global listener.
 */
export default function* saga() {
  yield takeLatest(PeriodChanged, handlePeriodChanged);
}

/**
 * Handles period changed event.
 *
 * This triggers a call to Tiller Captain's authentication service to retrieve the user's
 * permissions (if any).
 *
 * @param {Object} action - The dispatched action.
 */
export function* handlePeriodChanged({ payload: period }) {
  yield call(notifyPeriodChanged, period);
}
