import styled from 'styled-components';

import { Message } from '@tillersystems/stardust';

export const BotMarginMessage = styled(Message)`
  margin-bottom: 2rem;
`;

export const TopMarginMessage = styled(Message)`
  margin-top: 2rem;
`;

export const Link = styled.a`
  color: ${({ theme: { palette } }) => palette.primary.default};
`;
