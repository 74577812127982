/**
 * Stores sagas
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { rawTicketsFailed, rawTicketsSucceeded } from './actions';
import { RAW_TICKETS_REQUESTED } from './actionType';
import { getRawTicket } from './service';

/**
 * getRawTickets saga
 *
 * @generator
 */
export function* getRawTickets({ payload: { shopID, rootUUID } }) {
  try {
    const rawTickets = yield call(getRawTicket, shopID, rootUUID);
    // inform Redux to set our client rawTickets
    yield put(rawTicketsSucceeded({ rawTickets }));
  } catch (error) {
    console.warn(error);

    // If we get an error we send Redux the appropiate action and return
    yield put(rawTicketsFailed(error));
  }
}

/**
 * Global listener.
 */
export default function* rawTicketsWatcher() {
  yield takeLatest(RAW_TICKETS_REQUESTED, getRawTickets);
}
