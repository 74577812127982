/* eslint-disable react/display-name */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Table, Value } from '@tillersystems/stardust';

import { getPrettyAppName } from '../../utils';

/**
 * Gets the columns definition of the table.
 *
 * @param {Function} t - The I18n instance.
 */
const getColsDef = t => [
  {
    name: 'app_name',
    title: t('reports.appmarket.usage.apps_table.app_name'),
    value: d => getPrettyAppName(d.code),
    align: 'left',
    sortable: true,
  },
  {
    name: 'count',
    title: t('reports.appmarket.usage.apps_table.count'),
    value: d => d.value,
    width: '10rem',
    sortable: true,
  },
  {
    name: 'variation',
    title: t('reports.appmarket.usage.apps_table.variation'),
    value: d => d.variation,
    format: v => <Value positive={v > 0} negative={v < 0}>{`${v.toFixed(2)}%`}</Value>,
    width: '10rem',
    sortable: true,
  },
];

/**
 * This class defines the Apps table that displays the apps usage per app.
 *
 * @param {string} className - Styled components' class name.
 * @param {Object} data - The raw data.
 *
 * @returns {jsx}
 */
const AppsTable = ({ className, data }) => {
  const [t] = useTranslation();

  return data ? (
    <div className={className}>
      <Table data={data.activated_apps.apps} colsDef={getColsDef(t)} />
    </div>
  ) : null;
};

/** Display name. */
AppsTable.displayName = 'AppUsageTable';

/** Prop types. */
const { string, object } = PropTypes;
AppsTable.propTypes = {
  className: string,
  data: object,
};

/** Default props. */
AppsTable.defaultProps = {
  className: '',
  data: null,
};

export default styled(AppsTable)`
  height: 100%;
  overflow-y: scroll;
`;
