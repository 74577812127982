import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { StyledBreadCrumb } from './elements';

export default () => {
  const [t] = useTranslation();

  return (
    <StyledBreadCrumb>
      <StyledBreadCrumb.Item>
        <Link to="/payment-methods">{t('payment_methods.short_title')}</Link>
      </StyledBreadCrumb.Item>
      <StyledBreadCrumb.Item>{t('payment_methods.edit.title')}</StyledBreadCrumb.Item>
    </StyledBreadCrumb>
  );
};
