import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map, omit, size } from 'lodash';
import { arrayOf, bool, func, objectOf, shape, string } from 'prop-types';

import { Button } from '@tillersystems/stardust';

import Select from '../Select';

import {
  ActionsCell,
  AddButton,
  CategoryCell,
  ConfigGrid,
  ConfigWrapper,
  CountryCell,
  EditorWrapper,
  ResetButton,
} from './elements';

const SpecificConfigEditor = ({
  countries,
  categories,
  options,
  specificConfigs,
  onSpecificConfigsChange,
  onSpecificConfigsReset,
}) => {
  const [t] = useTranslation();

  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [specificCountry, setSpecificCountry] = useState();
  const [specificCategory, setSpecificCategory] = useState();
  const [isSpecificConfigEditorOpen, setIsSpecificConfigEditorOpen] = useState(false);

  useEffect(() => {
    filterCountries(Object.keys(specificConfigs));
  }, [countries.length, size(specificConfigs)]);

  const filterCountries = countriesToExlude => {
    const filteredCountriesData = countries.filter(
      ({ countryCode }) => !countriesToExlude.includes(countryCode),
    );
    setFilteredCountries(filteredCountriesData);
  };

  const removeSpecificConfig = country => {
    const updatedSpecificConfigs = omit(specificConfigs, country);
    onSpecificConfigsChange(updatedSpecificConfigs);
    filterCountries(Object.keys(updatedSpecificConfigs));
  };

  const addSpecificConfig = () => {
    const updatedSpecificConfigs = {
      ...specificConfigs,
      [specificCountry]: {
        category: specificCategory,
        options: options,
      },
    };

    onSpecificConfigsChange(updatedSpecificConfigs);
    setSpecificCategory(null);
    setSpecificCountry(null);
    setIsSpecificConfigEditorOpen(false);
    filterCountries(Object.keys(updatedSpecificConfigs));
  };

  const openSpecificConfig = () => {
    setIsSpecificConfigEditorOpen(true);
  };

  const closeSpecificConfig = () => {
    setIsSpecificConfigEditorOpen(false);
  };

  return (
    <ConfigWrapper>
      <ConfigGrid>
        {map(specificConfigs, ({ category }, country) => (
          <>
            <CountryCell>
              {t(countries.find(({ countryCode }) => countryCode === country)?.translation_key)} :
            </CountryCell>
            <CategoryCell>
              {t(categories.find(({ label }) => label === category)?.translation_key)}
            </CategoryCell>
            <ActionsCell>
              <Button
                appearance="failure"
                size="small"
                onClick={() => removeSpecificConfig(country)}
              >
                {t('form.delete')}
              </Button>
            </ActionsCell>
          </>
        ))}
      </ConfigGrid>

      {isSpecificConfigEditorOpen ? (
        <EditorWrapper>
          <Select
            values={specificCountry}
            options={filteredCountries.map(({ countryCode, translation_key }) => ({
              label: countryCode,
              translation_key,
            }))}
            onChange={setSpecificCountry}
            placeholder={t('payment_methods.edit.form.country.default_select')}
          />
          <Select
            values={specificCategory}
            options={categories}
            onChange={setSpecificCategory}
            placeholder={t('payment_methods.edit.form.category.default_select')}
          />
          <Button appearance="secondary" onClick={closeSpecificConfig}>
            {t('form.cancel')}
          </Button>
          <Button
            appearance="success"
            onClick={addSpecificConfig}
            disabled={!(specificCountry && specificCategory)}
          >
            {t('form.add')}
          </Button>
        </EditorWrapper>
      ) : (
        <>
          <AddButton
            disabled={filteredCountries.length === 0}
            appearance="primary"
            onClick={openSpecificConfig}
          >
            {t('form.add')}
          </AddButton>
          <ResetButton appearance="secondary" onClick={onSpecificConfigsReset}>
            {t('form.reset')}
          </ResetButton>
        </>
      )}
    </ConfigWrapper>
  );
};

SpecificConfigEditor.propTypes = {
  countries: arrayOf(shape({ countryCode: string.isRequired, translation_key: string.isRequired }))
    .isRequired,
  categories: arrayOf(shape({ label: string.isRequired, translation_key: string.isRequired }))
    .isRequired,
  options: shape({
    creditNoteOnly: bool.isRequired,
    hasExtraConfig: bool.isRequired,
    featureCode: string,
  }).isRequired,
  specificConfigs: objectOf(
    shape({
      category: string.isRequired,
      options: shape({
        creditNoteOnly: bool.isRequired,
        hasExtraConfig: bool.isRequired,
        featureCode: string,
      }).isRequired,
    }),
  ).isRequired,
  onSpecificConfigsChange: func,
  onSpecificConfigsReset: func,
};

SpecificConfigEditor.defaultProps = {
  onSpecificConfigsChange: () => {
    // handle onSpecificConfigsChange here
  },
  onSpecificConfigsReset: () => {
    // handle onSpecificConfigsReset here
  },
};

export default SpecificConfigEditor;
