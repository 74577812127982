import useBaseWebSocket from 'react-use-websocket';

import { useAuth } from '../../../contexts/Auth';

import { defaultOptions, WebSocketErrors as Err } from './constants';
import { UseWebSocketOptions, UseWebSocketReturn, WebSocketMessageRequest } from './types';

/**
 * @description Hook for websocket
 *
 * @template Payload Outbound message's type
 * @template Message Inbound message's type
 * @param { UseWebSocketOptions<Message> } options the options for the websocket
 * @return { UseWebSocketReturn<Payload, Message> } received messages and send function
 */
const useWebSocket = <Payload extends WebSocketMessageRequest, Message>(
  options: UseWebSocketOptions<Message> = {},
): UseWebSocketReturn<Payload, Message> => {
  const { webSocketUrl, filter } = { ...defaultOptions, ...options };
  const { accessToken = '' } = useAuth();

  if (!webSocketUrl) throw new Error(Err.NO_WEBSOCKET_URL);

  return useBaseWebSocket(webSocketUrl, {
    queryParams: { token: accessToken },
    retryOnError: true,
    share: true,
    filter: ({ data }: { data: string }) => {
      try {
        return Boolean(filter?.(JSON.parse(data)));
      } catch (error) {
        return false;
      }
    },
    shouldReconnect: () => accessToken !== '',
  });
};

export default useWebSocket;
