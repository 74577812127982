import { createSelector } from 'reselect';

import { LOADING, SUCCEEDED } from '../../../constants/Status';

/**
 * Order Events Selectors
 */

/**
 * If last attempt at fetching data resulted in an error, returns the error value
 *
 * @return {string}
 */
export const getErrorEvents = ({ order }) => order.events.error;

/**
 * If last attempt at fetching data resulted in an error, returns the error value
 *
 * @return {string}
 */
export const getStatusEvents = ({ order }) => order.events.status;

/**
 * Get the latest replay status of events
 *
 * @return {string}
 */
export const getErrorReplay = ({ order }) => order.events.replayError;

/**
 * Get the latest replay status of events
 *
 * @return {string}
 */
export const getStatusReplay = ({ order }) => order.events.replayStatus;

/**
 * If data is being fetched
 *
 * @return {boolean}
 */
export const isLoadingEvents = createSelector([getStatusEvents], status => status === LOADING);

/**
 * If replay is being requested
 *
 * @return {boolean}
 */
export const isLoadingReplay = createSelector([getStatusReplay], status => status === LOADING);

/**
 * If replay is loading or has succeeded
 *
 * @return {boolean}
 */
export const hasReplayed = createSelector([getStatusReplay], status => status === SUCCEEDED);

/**
 * Get current events retrieved from api
 *
 * @param {Object} state
 *
 * @return {Array}
 */
export const getCurrentEvents = ({ order }) => order.events.entities || [];

/**
 * Get new event grouped by root UUID
 *
 * @param {Object} state
 *
 * @return {Array}
 */
export const getNewEventsByRootUUID = ({ order }) => order.events.newEntities || {};

/**
 * Get the events for an order id if it has been retrieved
 */
export const getOrderEvents = createSelector(
  [getCurrentEvents, (_state, rootUUID) => rootUUID],
  (currentEvents, rootUUID) =>
    currentEvents && currentEvents.length && currentEvents[0].rootUUID == rootUUID
      ? currentEvents
      : null,
);

/**
 * Get the events for an order id if it has been retrieved
 */
export const getNewOrderEvents = createSelector(
  [getNewEventsByRootUUID, (_state, rootUUID) => rootUUID],
  (newEventsByRootUUID, rootUUID) => newEventsByRootUUID[rootUUID],
);
