import { Dictionary } from 'lodash';

import { AuthenticationError, AwsAuthenticationError, ErrorCode } from './types';

const AwsErrorCodes: Dictionary<number> = {
  InvalidParameterException: ErrorCode.BadRequest,
  NotAuthorizedException: ErrorCode.Forbidden,
  ServerError: ErrorCode.ServerError,
  UserNotFoundException: ErrorCode.NotFound,
} as const;

export const transformToAuthenticationError = (
  error: AwsAuthenticationError,
): AuthenticationError => ({
  code: AwsErrorCodes[error.code] || AwsErrorCodes.ServerError,
  message: error.message,
  original: error,
});

export const logError = (error: AuthenticationError): void => {
  if (error.code === ErrorCode.ServerError) {
    console.error(error);
  }
};
