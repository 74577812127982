import React from 'react';

import { Head, PeriodPicker } from '../../../components';

import AppsUsage from './AppsUsage';
import AppsVariation from './AppsVariation';
import { Header } from './elements';

/**
 * App Market
 *
 * This component is in charge of displaying
 * the app market page
 *
 * @return {jsx}
 */
const AppMarket = () => (
  <>
    <Head title="App market" />
    <Header>
      <PeriodPicker />
    </Header>

    <AppsUsage />
    <AppsVariation />
  </>
);

export default AppMarket;
