import styled from 'styled-components';

import { Message, Modal } from '@tillersystems/stardust';

export const Header = styled(Modal.Header)`
  font-size: 2.5rem;
  height: initial;
  padding-bottom: 0.5rem;
`;

export const Body = styled(Modal.Body)`
  padding: 1.5rem 0;
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
`;

export const Footer = styled(Modal.Footer)`
  height: initial;
  justify-content: flex-end;
`;

export const Bold = styled.b`
  font-weight: 700;
`;

export const TopMarginMessage = styled(Message)`
  margin-top: 2rem;
`;

export const DeletContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableContainer = styled.div`
  margin: -1.5rem -2rem;
`;

export const TableHeader = styled.div`
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.veryLightBlue};
  font-size: ${({ theme: { fonts } }) => fonts.size.big};
  font-weight: ${({ theme: { fonts } }) => fonts.weight.thick};
  display: flex;
  height: 7.4rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`;

export const TableBody = styled.div`
  min-height: 10rem;
  position: relative;
`;

export const CenterItem = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
