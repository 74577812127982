import styled from 'styled-components';

import { Message, TabSwitcher } from '@tillersystems/stardust';

export const TabHeader = styled(TabSwitcher.Tabs)`
  margin-bottom: 1.5rem;
`;

export const BotMarginMessage = styled(Message)`
  margin-bottom: 2rem;
`;

export const TopMarginMessage = styled(Message)`
  margin-top: 2rem;
`;
