import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatCompactedNumber, formatNumber, formatUnitNumber } from '@tillersystems/stardust';

const { bool, number, string } = PropTypes;

/**
 * Format a number with thousand and decimal separators according to locale.
 * Number can be formatted with its currency, or its unit.
 *
 * @param {string} currency - currency to display next to the value
 * @param {string} unit - unit to display next to the value (cannot be used with currency or percent
 * @param {boolean} isCompacted - should long numbers be compacted
 * @param {number} number - number to be formatted
 * @param {boolean} percent - if the number should be percent formatted
 *
 * @return {string}
 */
const NumberField = ({ currency, unit, isCompacted, value, percent }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  if (unit) {
    return formatUnitNumber(locale, value, unit);
  }

  if (isCompacted && value >= 1000) {
    return formatCompactedNumber(locale, value, currency);
  }

  return formatNumber({ currency, locale, number: value, percent });
};

/** Prop types. */
NumberField.propTypes = {
  currency: string,
  isCompacted: bool,
  value: number,
  unit: string,
};

/** Default prop types. */
NumberField.defaultProps = {
  currency: null,
  isCompacted: false,
  value: null,
  unit: null,
};

export default NumberField;
