import i18n from '../../../../i18n';
import DefinitionForm from '../../../DefinitionForm';

const FIELDS = [
  {
    fieldPath: 'payload.name',
    Field: DefinitionForm.Fields.Text,
    label: i18n.t('order_management.fields.OrderLineWasAddedByAdmin.name'),
    fieldOptions: {
      defaultValue: 'VARIOUS',
      validate: value => (!value ? i18n.t('form.errors.required') : false),
    },
  },
  {
    fieldPath: 'payload.price',
    Field: DefinitionForm.Fields.Number,
    label: i18n.t('order_management.fields.OrderLineWasAddedByAdmin.price'),
    fieldOptions: {
      defaultValue: null,
      validate: value => {
        if (typeof value !== 'number') return i18n.t('form.errors.number');
        if (value < 0) return i18n.t('form.errors.positiveNumber');
        return false;
      },
    },
    options: {
      label: { text: i18n.t('order_management.fields.cents') },
      labelPosition: 'right',
      placeholder: i18n.t('order_management.fields.OrderLineWasAddedByAdmin.price_placeholder'),
    },
  },
  {
    fieldPath: 'payload.tax',
    Field: DefinitionForm.Fields.Number,
    label: i18n.t('order_management.fields.OrderLineWasAddedByAdmin.tax'),
    fieldOptions: {
      defaultValue: null,
      validate: value => (typeof value !== 'number' ? i18n.t('form.errors.percent') : false),
    },
    options: {
      decimals: 2,
      min: 0,
      max: 100,
      label: { text: '%' },
      labelPosition: 'right',
      placeholder: i18n.t('order_management.fields.OrderLineWasAddedByAdmin.tax_placeholder'),
    },
  },
  {
    fieldPath: 'payload.taxIdentifier',
    Field: DefinitionForm.Fields.Text,
    label: i18n.t('order_management.fields.OrderLineWasAddedByAdmin.taxIdentifier'),
    fieldOptions: {
      validate: value => (!value ? i18n.t('form.errors.required') : false),
    },
    options: {
      placeholder: i18n.t(
        'order_management.fields.OrderLineWasAddedByAdmin.taxIdentifier_placeholder',
      ),
    },
  },
  {
    fieldPath: 'payload.quantity',
    Field: DefinitionForm.Fields.Number,
    label: i18n.t('order_management.fields.OrderLineWasAddedByAdmin.quantity'),
    fieldOptions: {
      defaultValue: 1,
      validate: value => (typeof value !== 'number' ? i18n.t('form.errors.number') : false),
    },
    options: {},
  },
];

export default () => FIELDS;
