import React from 'react';
import PropTypes from 'prop-types';

import { TableRowCell, TableRowCellBody, TableRowContainer, TableRowItem } from './elements';

/**
 * Render each loader row.
 *
 * @param {number} numberOfChildrens
 */
const renderChildrens = (numberOfChildrens, colsDef) => {
  const rows = [];
  for (let i = 0; i < numberOfChildrens; i++) {
    rows.push(
      <TableRowItem key={i}>
        {colsDef.map(({ width, align }, index) => {
          return (
            <TableRowCell width={width} align={align} key={`flrc-${index}`}>
              <TableRowCellBody width={width} />
            </TableRowCell>
          );
        })}
      </TableRowItem>,
    );
  }
  return rows;
};

/**
 * TableRow fake loader.
 * This component fake a TableRow as placeholder loader.
 *
 * @param {string} className
 * @param {number} numberOfChildrens
 */
const TableRow = ({ className, numberOfChildrens, colsDef }) => (
  <TableRowContainer className={className}>
    {renderChildrens(numberOfChildrens, colsDef)}
  </TableRowContainer>
);

/**
 * PropTypes validation.
 */
const { string, number, arrayOf, shape, oneOfType, oneOf } = PropTypes;
TableRow.propTypes = {
  className: string,
  numberOfChildrens: number,
  colsDef: arrayOf(
    shape({
      width: oneOfType([string, number]),
      align: oneOf(['left', 'center', 'right']),
    }),
  ).isRequired,
};

/**
 * Default props.
 */
TableRow.defaultProps = {
  className: '',
  numberOfChildrens: 1,
};

export default TableRow;
