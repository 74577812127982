import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Head } from '../../../components';

import connect from './connect';
import { Container } from './elements';
import EventsCard from './EventsCard';
import OrderCard from './OrderCard';
import RawTickets from './RawTickets';

const OrderDetail = ({ actions, match, loading, errors, order, events }) => {
  const [t] = useTranslation();

  const { id: orderId } = match.params;

  useEffect(() => {
    if (!order && orderId) {
      actions.orderDetailRequested({ orderId });
    }
  }, [orderId, order]);

  useEffect(() => {
    if (order && !events) {
      actions.orderEventsRequested(order);
      actions.rawTicketsRequested(order);
    }
  }, [order]);

  if (!loading.order && !order) {
    return (
      <p>
        {errors.order instanceof Error
          ? errors.order.message
          : `Cannot find any order matching ${orderId}`}
      </p>
    );
  }

  return (
    <>
      <Head title={`${t('navigation.order_management')} > ${orderId}`} />
      <Container>
        <OrderCard />
        <EventsCard />
      </Container>
      <RawTickets />
    </>
  );
};

OrderDetail.propTypes = {
  actions: PropTypes.shape({
    orderDetailRequested: PropTypes.func,
    orderEventsRequested: PropTypes.func,
    rawTicketsRequested: PropTypes.func,
  }).isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.shape({
    order: PropTypes.bool,
    events: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    order: PropTypes.instanceOf(Error),
    events: PropTypes.instanceOf(Error),
  }),
  order: PropTypes.object,
  events: PropTypes.array,
};

OrderDetail.defaultProps = {
  loading: {
    order: false,
    events: false,
  },
  errors: {
    order: null,
    events: null,
  },
  order: null,
  events: null,
};

export default connect(OrderDetail);
