import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Head, PageContainer, TopBar } from '../../components';

import PaymentMethodEdit from './PaymentMethodEdit';
import PaymentMethodList from './PaymentMethodList';

const PaymentMethods = () => {
  const [t] = useTranslation();
  const { path } = useRouteMatch();

  return (
    <>
      <Head title={t('payment_methods.title')} />
      <TopBar title={t('payment_methods.title')} />
      <PageContainer>
        <Switch>
          <Route exact path={`${path}`}>
            <PaymentMethodList />
          </Route>
          <Route path={`${path}/edit/:paymentMethodLabel`}>
            <PaymentMethodEdit />
          </Route>
        </Switch>
      </PageContainer>
    </>
  );
};

export default PaymentMethods;
